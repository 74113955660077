import { gql } from "@apollo/client";

export const GET_ATHLETE_TRAINING_EVENTS = gql`
  query GetAthleteTrainingEvents($athleteId: String!, $isUpcoming: Boolean!, $page: Float = 1, $limit: Float = 10) {
    getAthleteTrainingEvents(athleteId: $athleteId, isUpcoming: $isUpcoming, page: $page, limit: $limit) {
      id
      totalCount
      allTrainingEvents {
        __typename
        ... on TrainingGroupEvent {
          id
          startDateTime
          endDateTime
          trainingGroup {
            id
            name
            seasonPlans {
              id
              startDateTime
              endDateTime
              skillSets {
                id
                skillSet {
                  id
                  name
                }
                skills {
                  id
                  name
                }
              }
            }
          }
          athletes {
            id
            name
            seasonPlans {
              id
              startDateTime
              endDateTime
            }
          }
          venue {
            id
            name
            center {
              id
              name
            }
          }
        }
        ... on SingleTrainingEvent {
          id
          startDateTime
          endDateTime
          athletes {
            id
            name
          }
          venue {
            id
            name
            center {
              id
              name
            }
          }
        }
      }
    }
  }
`;
