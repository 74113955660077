/**
 * Returns a set of common styles for various common components.
 *
 * @param hasValue - A boolean that determines if the component has a value and different style should be applied.
 * @returns An object containing common styles
 */
export const commonStyles = (hasValue: boolean) => ({
  border: "1px solid",
  borderRadius: "md",
  boxShadow: hasValue ? "none" : "sm",
  borderColor: hasValue ? "orange.400" : "blackAlpha.300",
  transition: "all 0.3s ease",
  _hover: { borderColor: "orange.500", boxShadow: "none" },
  _focusWithin: { borderColor: "orange.500", boxShadow: "none" },
});

/** Returns a set of common styles for tags. */
export const tagStyles = (tagColor?: string) => ({
  size: "md",
  borderRadius: "full",
  variant: "solid",
  backgroundColor: tagColor || "orange.400",
  transition: "all 0.3s ease",
  _hover: { backgroundColor: "orange.500" },
});

/** Returns a set of common styles for cards */
export const cardStyles = ({
  disableClick,
  disableHover,
  selected,
}: {
  disableClick?: boolean;
  disableHover?: boolean;
  selected?: boolean;
}) => ({
  border: "1px solid",
  borderColor: {
    mobile: selected ? "orange.400" : "blackAlpha.300",
    laptop: selected ? "orange.400" : "blackAlpha.300",
  },
  borderRadius: "md",
  transition: "all 0.3s ease",
  _hover: disableHover
    ? undefined
    : {
        borderColor: { mobile: undefined, laptop: selected ? "orange.400" : "blackAlpha.300" },
        boxShadow: { mobile: undefined, laptop: "0px 4px 6px -2px #0000005C, 0px 10px 15px -3px #0000005C" }, // lg blackAlpha.500
        cursor: { mobile: undefined, laptop: disableClick ? "default" : "pointer" },
      },
  _focusWithin: disableHover
    ? undefined
    : {
        borderColor: { mobile: undefined, laptop: selected ? "orange.400" : "blackAlpha.300" },
        boxShadow: { mobile: undefined, laptop: "0px 4px 6px -2px #0000005C, 0px 10px 15px -3px #0000005C" }, // lg blackAlpha.500
        cursor: { mobile: undefined, laptop: disableClick ? "default" : "pointer" },
      },
});

/** Returns a set of shadow styles for component containers. Used e.g. when parent clips shadow overflows otherwise */
export const componentContainerShadowStyles = (errorHighlight?: boolean) => ({
  borderRadius: { mobile: undefined, laptop: "md" },
  boxShadow: { mobile: undefined, laptop: "sm" },
  transition: { mobile: undefined, laptop: "all 0.3s ease" },
  _hover: {
    mobile: {},
    laptop: {
      boxShadow: `0px 4px 6px -2px #0000005C, 0px 10px 15px -3px ${errorHighlight ? "#E53E3E" : "#00000029"}`, // lg red.500 or (default) blackAlpha.300
    },
  },
});
