import React, { useMemo, useState } from "react";
import { Box, Text, Tooltip, Stack } from "@chakra-ui/react";
import { CommonTable, type Column, type SortConfig } from "../../../../../common/components/CommonTable/CommonTable";
import { CommonButton } from "../../../../../common/components/CommonButton";
import { CommonIconButton } from "../../../../../common/components/CommonIconButton";
import { Invite, InviteType } from "../../../../../types";
import { CommonScrollbar } from "../../../../../common/components/CommonScrollbar";

export type InviteSortConfig = {
  key: keyof Invite;
  direction: "ascending" | "descending";
} | null;

type InviteTableProps = {
  /** An array of invite objects to display in the table */
  invites: Invite[];

  /** The current sorting configuration used in the table */
  sortConfig: InviteSortConfig;

  /** Function to handle sort changes. Receives the key of the column to be sorted. */
  onSort: (key: keyof Invite) => void;

  /** Copies the invite link to the clipboard */
  onCopyLink: (link: string) => void;

  onInviteModalOpen: () => void;
};

export const InviteTable: React.FC<InviteTableProps> = (props) => {
  const [sortConfig, setSortConfig] = useState<SortConfig<Invite> | undefined>(undefined);

  const sortedInvites = useMemo(() => {
    let sorted = [...props.invites];
    if (sortConfig) {
      sorted.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (sortConfig.key === "usedAt") {
          aValue = aValue || "-";
          bValue = bValue || "-";
        }

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        if (typeof aValue === "number" && typeof bValue === "number") {
          return sortConfig.direction === "ascending" ? aValue - bValue : bValue - aValue;
        }

        return 0;
      });
    }
    return sorted;
  }, [props.invites, sortConfig]);

  const columns: Column<Invite>[] = [
    {
      label: "Reference",
      key: "receiver",
      width: "30%",
      sortable: true,
      render: (invite) => (
        <Tooltip label={invite.receiver}>
          <Text isTruncated>{invite.receiver}</Text>
        </Tooltip>
      ),
    },
    {
      label: "Access type",
      key: "type",
      width: "20%",
      render: (invite) => (
        <Text
          border="1px solid"
          borderRadius="md"
          borderColor={
            invite.type === InviteType.CreateOrganization
              ? "red.500"
              : invite.type === InviteType.JoinOrganizationFullAccess
                ? "teal.800"
                : "blackAlpha.500"
          }
          textColor="white"
          backgroundColor={
            invite.type === InviteType.CreateOrganization
              ? "red.500"
              : invite.type === InviteType.JoinOrganizationFullAccess
                ? "teal.800"
                : "blackAlpha.500"
          }
          width="fit-content"
          paddingY="1"
          paddingX="2"
        >
          Full
        </Text>
      ),
    },
    {
      label: "Status",
      key: "usedAt",
      width: "15%",
      sortable: true,
      render: (invite) => (
        <Text
          border="1px solid"
          borderRadius="md"
          borderColor="teal.800"
          textColor="white"
          backgroundColor="teal.800"
          width="fit-content"
          paddingY="1"
          paddingX="2"
        >
          {invite.usedAt ? "Used" : "Unused"}
        </Text>
      ),
    },
    {
      label: "Invite Link",
      key: "inviteCode",
      width: "35%",
      render: (invite) => {
        const inviteLink = `${window.location.origin}/create-account/?inviteCode=${invite.inviteCode}`;
        return (
          <Stack direction="row" alignItems="center" spacing="4" justifyContent="space-between">
            <Tooltip label={inviteLink}>
              <Text isTruncated>{inviteLink}</Text>
            </Tooltip>
            <Box width="fit-content">
              <CommonButton
                variantType="outlineSecondary"
                onClick={() => props.onCopyLink(inviteLink)}
                tooltip={invite.used ? undefined : "Copy to clipboard"}
                disabled={invite.used}
              >
                Copy Link
              </CommonButton>
            </Box>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box>
      <Stack direction="row" paddingBottom="6" spacing="4" width="100%" justifyContent="center">
        <Text fontSize="x-large">Invited Users ({props.invites.length})</Text>
        <CommonIconButton height="36px" onClick={props.onInviteModalOpen} />
      </Stack>

      <CommonScrollbar maxHeight="40svh" overflow="auto" invisibleBorderWidth="0px 8px 0px 0px">
        <CommonTable
          columns={columns}
          rows={sortedInvites}
          sortConfig={sortConfig}
          onSort={(key) =>
            setSortConfig((prev) =>
              prev?.key === key
                ? { key, direction: prev.direction === "ascending" ? "descending" : "ascending" }
                : { key, direction: "ascending" },
            )
          }
          emptyMessage="No Invitations created"
          getRowStyle={(invite) => ({ opacity: invite.used ? 0.5 : 1 })}
        />
      </CommonScrollbar>
    </Box>
  );
};
