import React, { useState, useEffect } from "react";
import { Drill, type SkillSet } from "../../../../../types";
import { MultiTagSelectMenu } from "../../../../../common/components/MultiTagSelectMenu";
import { FormModal } from "../../../../../common/components/FormModal";
import { Grid, GridItem, Stack } from "@chakra-ui/react";
import { CommonInput } from "../../../../../common/components/CommonInput";
import { CommonTextArea } from "../../../../../common/components/CommonTextArea";
import type { DrillInput } from "../../../containers/DrillLibraryContainer";

/** DrillBuilder properties */
type DrillBuilderProps = {
  /** Callback function for saving a drill */
  onSave: (drillInput: DrillInput) => void;
  /** Callback function for closing the DrillBuilder */
  onClose: () => void;
  /** The currently selected drill to edit */
  selectedDrill: Drill | null;
  /** Whether the DrillBuilder is open */
  open: boolean;
  /** All available tags */
  allTags: string[];
  /** All available skill sets */
  allSkillSets: SkillSet[];
};

/** DrillBuilder component */
export const DrillBuilder: React.FC<DrillBuilderProps> = (props) => {
  const [drill, setDrill] = useState<Drill>(
    props.selectedDrill || {
      id: undefined,
      name: "",
      goal: "",
      implementation: "",
      tags: [],
      skillSets: [],
    },
  );
  const [isModified, setIsModified] = useState(false);
  const submitButtonText = props.selectedDrill ? "Update Drill" : "Create Drill";

  useEffect(() => {
    setIsModified(false);
  }, [props.selectedDrill]);

  /**
   * Generates a list of reasons why the submit button is disabled.
   *
   * @returns An object containing the submit disabled reasons if any condition is not met, otherwise undefined.
   */
  const getSubmitDisabledReasons = (): string | undefined => {
    if (props.selectedDrill && !isModified) {
      return "No changes to update";
    }

    const reasons: string[] = [];

    if (!drill.name) {
      reasons.push("Drill has a name");
    }
    if (!drill.goal) {
      reasons.push("Drill has a goal");
    }
    if (!drill.implementation) {
      reasons.push("Drill has an implementation");
    }

    return reasons.length > 0 ? `Saving is enabled once\n- ${reasons.join("\n- ")}` : undefined;
  };

  const submitDisabledReasons = getSubmitDisabledReasons();

  /** Submit the form */
  const handleSubmit = (remove: boolean) => {
    const dataToSave: DrillInput = {
      id: drill.id,
      name: drill.name,
      goal: drill.goal,
      implementation: drill.implementation,
      tags: drill.tags,
      skillSetIds: drill.skillSets?.map((skillSet) => skillSet.id || "") || [],
      remove: remove,
    };
    props.onSave(dataToSave);
    props.onClose();
  };

  /**
   * Handle changes to an input field
   *
   * @param {string} field - The field to change
   * @param {string | number | string[]} value - The new value
   */
  const handleInputChange = (field: string, value: string | number | string[]) => {
    const updatedDrill = { ...drill, [field]: value };
    setDrill(updatedDrill);
    setIsModified(
      !props.selectedDrill ||
        updatedDrill.name !== props.selectedDrill.name ||
        updatedDrill.goal !== props.selectedDrill.goal ||
        updatedDrill.implementation !== props.selectedDrill.implementation ||
        JSON.stringify(updatedDrill.tags) !== JSON.stringify(props.selectedDrill.tags) ||
        updatedDrill.skillSets !== props.selectedDrill.skillSets,
    );
  };

  const handleSave = () => {
    handleSubmit(false);
  };

  /** Handle removal of the drill */
  const handleRemove = () => {
    handleSubmit(true);
  };

  /** Handle new tag creation */
  const handleCreateTag = (newTag: string) => {
    const prevTags = [...(drill.tags || [])];
    prevTags.push(newTag);
    handleInputChange("tags", prevTags);
  };

  return (
    <FormModal
      open={props.open}
      onClose={props.onClose}
      handleSubmit={handleSave}
      submitButtonText={submitButtonText}
      title={props.selectedDrill ? "Edit Drill" : "Create a new Drill"}
      submitDisabled={!!submitDisabledReasons || !isModified}
      submitButtonHoverText={submitDisabledReasons}
      handleRemove={props.selectedDrill ? handleRemove : undefined}
      confirmationDialogTitle="Delete Drill"
    >
      <Stack spacing="6" data-testid="drill-modal">
        <Grid templateColumns="repeat(2, 1fr)" gap="4" alignItems="stretch">
          <GridItem colSpan={1}>
            <CommonInput
              required
              placeholder="Name"
              value={drill.name}
              onChange={(value) => handleInputChange("name", value)}
              dataTestId="drill-name"
            />
          </GridItem>
          <GridItem colSpan={1}>
            <MultiTagSelectMenu
              allowCreateTag
              disableActive
              selectedTags={drill.tags || []}
              handleTagSelection={(newValues) => {
                handleInputChange("tags", newValues);
              }}
              getOptionLabel={(option) => option}
              handleCreateTag={handleCreateTag}
              label="Tags"
              options={props.allTags}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <CommonTextArea
              required
              placeholder="Goal"
              value={drill.goal}
              onChange={(value) => handleInputChange("goal", value)}
              minHeight="200px"
              dataTestId="drill-goal"
            />
          </GridItem>
          <GridItem colSpan={1}>
            <CommonTextArea
              required
              placeholder="Implementation"
              value={drill.implementation}
              onChange={(value) => handleInputChange("implementation", value)}
              minHeight="200px"
              dataTestId="drill-implementation"
            />
          </GridItem>
          <GridItem colSpan={2}>
            <MultiTagSelectMenu
              selectedTags={drill.skillSets || []}
              handleTagSelection={(newValues) => {
                handleInputChange("skillSets", newValues);
              }}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              label="Related Skill Sets"
              options={props.allSkillSets}
              dataTestId="skill-set-tags"
            />
          </GridItem>
        </Grid>
      </Stack>
    </FormModal>
  );
};
