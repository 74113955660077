import React, { useState } from "react";
import { Box, Stack, Text } from "@chakra-ui/react";
import { CommonButton } from "../../../../common/components/CommonButton";
import { Organization, AccessType, OrganizationStatus } from "../../../../types";
import { ConfirmationDialog } from "../../../../common/components/ConfirmationDialog";
import { useAlert } from "../../../../common/components/AlertProvider";
import { addDays } from "date-fns";
import { extractDateFromUTC } from "../../../../common/utils/dateAndTime";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";

/** Props for DangerZone component. */
type DangerZoneProps = {
  /** The currently loaded organization. */
  organization: Organization;
  /** The current user's access type to determine if deletion is allowed. */
  currentUserAccess: AccessType | null;
  /** Callback to mark organization for deletion. */
  onMarkDeletion: () => Promise<any>;
  /** Callback to cancel pending deletion. */
  onCancelDeletion: () => Promise<any>;
  /** Whether the component is in a loading state. */
  loading: boolean;
};

/**
 * A "Danger Zone" section for marking the organization for deletion or cancelling a pending deletion. Typically placed
 * at the bottom of an organization's settings page.
 */
export const DangerZone: React.FC<DangerZoneProps> = (props) => {
  const [isMarkDeleteDialogOpen, setIsMarkDeleteDialogOpen] = useState(false);
  const [isCancelDeleteDialogOpen, setIsCancelDeleteDialogOpen] = useState(false);

  const { showAlert } = useAlert();

  const handleMarkDeletion = async () => {
    setIsMarkDeleteDialogOpen(false);
    showAlert("Initiating deletion process", "info", undefined, true);
    await props.onMarkDeletion();
  };

  const handleRevertDeletion = async () => {
    setIsCancelDeleteDialogOpen(false);
    showAlert("Canceling deletion process", "info", undefined, true);
    const result = await props.onCancelDeletion();
    if (result) {
      showAlert(
        "The deletion process has been canceled. The organization is now active and fully accessible.",
        "success",
        5000,
      );
    }
  };

  const userCanDelete = props.currentUserAccess === AccessType.Admin || props.currentUserAccess === AccessType.Owner;
  const effectiveDeletionDate = props.organization.pendingDeletionAt
    ? addDays(props.organization.pendingDeletionAt, 14).toISOString()
    : undefined;

  return (
    <Box position="relative">
      <LoadingOverlay display={props.loading} spinnerSize="xl" />

      <Text fontSize="x-large" color="red.500" marginBottom="6">
        Danger Zone
      </Text>

      <Stack position="relative" direction="column" spacing="6">
        <Stack direction="row" justifyContent="space-between" align="center">
          <Text fontSize="large">Organization Status</Text>
          <Text
            border="1px solid"
            borderRadius="md"
            borderColor={
              props.organization.status === OrganizationStatus.Active
                ? "teal.800"
                : props.organization.status === OrganizationStatus.Disabled
                  ? "black"
                  : props.organization.status === OrganizationStatus.PendingDeletion
                    ? "red.500"
                    : undefined
            }
            textColor="white"
            backgroundColor={
              props.organization.status === OrganizationStatus.Active
                ? "teal.800"
                : props.organization.status === OrganizationStatus.Disabled
                  ? "black"
                  : props.organization.status === OrganizationStatus.PendingDeletion
                    ? "red.500"
                    : undefined
            }
            width="fit-content"
            paddingY="1"
            paddingX="2"
          >
            {props.organization.status === OrganizationStatus.PendingDeletion
              ? "Pending Deletion"
              : props.organization.status}
          </Text>
        </Stack>

        {/* If organization is in Active/Disabled status, show delete button */}
        {(props.organization.status === OrganizationStatus.Active ||
          props.organization.status === OrganizationStatus.Disabled) && (
          <Stack direction="row" justifyContent="space-between" gap="4" align="end">
            <Box>
              <Text fontSize="large">Delete Organization</Text>
              <Text color="blackAlpha.600" as="span">
                Start the deletion process for your organization. Once initiated, you have 14 days to cancel before
                permanent deletion.
              </Text>
            </Box>
            <CommonButton
              variantType="outlineSecondary"
              minWidth="fit-content"
              onClick={() => setIsMarkDeleteDialogOpen(true)}
              tooltip={!userCanDelete ? "Only Owner can delete organization" : undefined}
              disabled={!userCanDelete || props.loading}
              color="red.500"
              hoverColor="red.600"
            >
              Delete
            </CommonButton>
          </Stack>
        )}

        {/* If organization is in PendingDeletion status, show cancel deletion button */}
        {props.organization.status === OrganizationStatus.PendingDeletion && (
          <Stack direction="column" spacing="0">
            <Text color="red.500" fontWeight="bold">
              Your organization is scheduled for permanent deletion.
            </Text>
            <Text color="red.500" marginTop="2">
              Deletion process started:{" "}
              {props.organization.pendingDeletionAt
                ? extractDateFromUTC(props.organization.pendingDeletionAt, ".", true)
                : "N/A"}
            </Text>

            <Text color="red.500">
              Scheduled permanent deletion:{" "}
              {effectiveDeletionDate ? extractDateFromUTC(effectiveDeletionDate, ".", true) : "N/A"}
            </Text>

            <Text color="red.500" marginTop="6" fontWeight="bold">
              Once deleted, the organization and all its data will be permanently removed, including user accounts,
              login access, training data, and associated records. This action cannot be undone.
            </Text>

            <Stack direction="row" justifyContent="space-between" gap="4" marginTop="6" align="end">
              <Text color="blackAlpha.600">
                You have until {effectiveDeletionDate ? extractDateFromUTC(effectiveDeletionDate, ".", true) : "N/A"} to
                cancel the deletion and restore access. After this deadline, the organization cannot be recovered.
              </Text>

              <CommonButton
                variantType="outlineSecondary"
                minWidth="fit-content"
                onClick={() => setIsCancelDeleteDialogOpen(true)}
                tooltip={!userCanDelete ? "Only Owner can cancel deletion" : undefined}
                disabled={!userCanDelete || props.loading}
              >
                Cancel Deletion
              </CommonButton>
            </Stack>
          </Stack>
        )}
      </Stack>

      {/* ConfirmationDialog for marking deletion */}
      <ConfirmationDialog
        isOpen={isMarkDeleteDialogOpen}
        onClose={() => setIsMarkDeleteDialogOpen(false)}
        onCancel={() => setIsMarkDeleteDialogOpen(false)}
        onConfirm={handleMarkDeletion}
        isLoading={props.loading}
        isDeletion={true}
        title="Confirm Organization Deletion"
        message={
          <Stack direction="column" spacing="4">
            <Text>
              Confirm you want to start the deletion process for your organization.{" "}
              <Text as="span" color="red.500">
                All organization data, including users, training data, and login credentials, will be permanently
                deleted after 14 days.
              </Text>
            </Text>
            <Text>
              This will make the organization inaccessible immediately. You have 14 days to cancel before it is
              permanently removed.
            </Text>
          </Stack>
        }
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
      />

      {/* ConfirmationDialog for canceling deletion */}
      <ConfirmationDialog
        isOpen={isCancelDeleteDialogOpen}
        onClose={() => setIsCancelDeleteDialogOpen(false)}
        onCancel={() => setIsCancelDeleteDialogOpen(false)}
        onConfirm={handleRevertDeletion}
        isLoading={props.loading}
        isDeletion={false}
        title="Confirm Cancellation of Deletion"
        message="Confirm you want to cancel the deletion process. This will restore the organization to Active status, making it immediately accessible again."
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
      />
    </Box>
  );
};
