import React, { useMemo, useState } from "react";
import { Box, Text, Tooltip, Stack } from "@chakra-ui/react";
import { CommonInput } from "../../../../common/components/CommonInput";
import { CommonButton } from "../../../../common/components/CommonButton";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import type { Invite } from "../../../../types";
import { CommonTable, type Column, type SortConfig } from "../../../../common/components/CommonTable/CommonTable";

type InvitesProps = {
  allInvites: Invite[];
  createInvite: (receiver?: string) => Promise<void>;
  createInviteLoading: boolean;
};

/** Invites handles invite creation and displays existing invites with sorting and visual consistency. */
export const Invites: React.FC<InvitesProps> = (props) => {
  const [receiver, setReceiver] = useState("");
  const [sortConfig, setSortConfig] = useState<SortConfig<Invite> | undefined>(undefined);
  const [copySuccess, setCopySuccess] = useState<string | null>(null);
  const baseLink = `${window.location.origin}/create-account/?inviteCode=`;

  const handleCreateInvite = async () => {
    await props.createInvite(receiver);
    setReceiver("");
  };

  const sortedInvites = useMemo(() => {
    let sorted = [...props.allInvites];
    if (sortConfig) {
      sorted.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (sortConfig.key === "receiver") {
          aValue = aValue || "-";
          bValue = bValue || "-";
        }
        if (sortConfig.key === "usedAt") {
          aValue = aValue || "-";
          bValue = bValue || "-";
        }

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        if (typeof aValue === "number" && typeof bValue === "number") {
          return sortConfig.direction === "ascending" ? aValue - bValue : bValue - aValue;
        }
        if (typeof aValue === "boolean" && typeof bValue === "boolean") {
          return sortConfig.direction === "ascending" ? (aValue ? 1 : -1) : bValue ? 1 : -1;
        }

        return 0;
      });
    }
    return sorted;
  }, [props.allInvites, sortConfig]);

  const handleCopyLink = (link: string) => {
    navigator.clipboard.writeText(`${baseLink}${link}`);
    setCopySuccess(link);
    setTimeout(() => setCopySuccess(null), 2000);
  };

  const columns: Column<Invite>[] = [
    {
      label: "Invite Link",
      key: "inviteCode",
      width: "25%",
      render: (invite) => (
        <Tooltip label={`${baseLink}${invite.inviteCode}`}>
          <Text isTruncated>{`${baseLink}${invite.inviteCode}`}</Text>
        </Tooltip>
      ),
    },
    {
      label: "Receiver",
      key: "receiver",
      width: "15%",
      sortable: true,
      render: (invite) => <>{invite.receiver || "N/A"}</>,
    },
    {
      label: "Status",
      key: "used",
      width: "10%",
      sortable: true,
      render: (invite) => <>{invite.used ? "Used" : "Unused"}</>,
    },
    {
      label: "Used At",
      key: "usedAt" as keyof Invite,
      width: "15%",
      sortable: true,
      render: (invite) => <>{invite.usedAt ? new Date(invite.usedAt).toLocaleString().slice(0, -3) : "N/A"}</>,
    },
    {
      label: "Created At",
      key: "createdAt",
      width: "20%",
      sortable: true,
      render: (invite) => <>{new Date(invite.createdAt).toLocaleString().slice(0, -3)}</>,
    },
    {
      label: "Actions",
      key: "id",
      width: "15%",
      render: (invite) => (
        <CommonButton variantType="outlineSecondary" onClick={() => handleCopyLink(invite.inviteCode)}>
          {copySuccess === invite.inviteCode ? "Copied!" : "Copy"}
        </CommonButton>
      ),
    },
  ];

  return (
    <Box position="relative">
      <LoadingOverlay display={props.createInviteLoading} />

      <Box padding="6" display="flex" justifyContent="center">
        <Text fontSize="x-large" fontWeight="bold">
          Invites ({props.allInvites.length})
        </Text>
      </Box>

      <Box paddingBottom="6" paddingX="6">
        <Text fontSize="large">Create a new invite</Text>
        <Stack direction="row" spacing="4" marginTop="2">
          <CommonInput placeholder="Receiver (optional)" value={receiver} onChange={(value) => setReceiver(value)} />
          <CommonButton variantType="outlineSecondary" onClick={handleCreateInvite}>
            Create Invite
          </CommonButton>
        </Stack>
      </Box>

      <CommonTable
        columns={columns}
        rows={sortedInvites}
        sortConfig={sortConfig}
        onSort={(key) =>
          setSortConfig((prev) =>
            prev?.key === key
              ? { key, direction: prev.direction === "ascending" ? "descending" : "ascending" }
              : { key, direction: "ascending" },
          )
        }
        emptyMessage="No invites available"
        getRowStyle={(invite) => ({ opacity: invite.used ? 0.5 : 1 })}
      />

      {props.allInvites.length === 0 && (
        <Text fontSize="large" paddingY="6" textColor="blackAlpha.600" textAlign="center">
          No invites available. Create a new invite to get started.
        </Text>
      )}
    </Box>
  );
};
