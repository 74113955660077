import React, { useEffect, useState } from "react";
import { Box, Stack, Text, Tooltip } from "@chakra-ui/react";
import type { Log } from "../../../../types";
import { CommonScrollbar } from "../../../../common/components/CommonScrollbar";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { LogItem } from "./components/LogItem";
import { PaginationControls } from "../../../../common/components/PaginationControls";
import { LOGS_PAGINATION_LIMIT } from "../../containers/AthleteContainer";

type LogsProps = {
  /** Athlete ID for pagination callback. */
  athleteId: string;
  logs: Log[];
  /** Total number of logs in the paginated list */
  totalLogsCount: number;
  aggregations?: {
    withdrawns: number;
    cancellations: number;
    replacements: number;
  };
  /** Whether the component is loading */
  isLoading: boolean;
  /** Current page number */
  page: number;
  /** Called when user clicks 'Next page' */
  onNextPage: (athleteId: string) => void;
  /** Called when user clicks 'Previous page' */
  onPrevPage: (athleteId: string) => void;
};

export const Logs: React.FC<LogsProps> = (props) => {
  const [displayedTotalCount, setDisplayedTotalCount] = useState<number>(props.totalLogsCount);
  const [displayedLogs, setDisplayedLogs] = useState<Log[]>(props.logs);

  useEffect(() => {
    if (!props.isLoading) {
      setDisplayedTotalCount(props.totalLogsCount);
      setDisplayedLogs(props.logs);
    }
  }, [props.logs, props.isLoading]);

  const withdrawns = props.aggregations?.withdrawns || 0;
  const cancellations = props.aggregations?.cancellations || 0;
  const replacements = props.aggregations?.replacements || 0;
  const eventsToRecover = withdrawns + cancellations - replacements;

  return (
    <Box
      position="relative"
      width="100%"
      minHeight={{ laptop: "400px" }}
      maxHeight={{ laptop: "75svh" }}
      display="flex"
      flexDirection="column"
    >
      <LoadingOverlay display={props.isLoading || false} spinnerSize="xl" spinnerTopPosition="60px" />

      <Stack
        spacing="4"
        padding="6"
        paddingTop={{ mobile: "0", laptop: "6" }}
        direction={{ mobile: "column", laptop: "row" }}
        justifyContent="space-between"
        alignItems={{ mobile: "center", laptop: "start" }}
      >
        <Text fontSize="xl" color="blackAlpha.900">
          Logs ({displayedTotalCount})
        </Text>
        <Tooltip
          label={
            <Stack direction="column" spacing="1">
              <Text color="white">{`${withdrawns} times withdrawn from weekly trainings`}</Text>
              <Text color="white">{`${cancellations} weekly trainings cancelled`}</Text>
              <Text color="white">{`${replacements} participations in extra weekly trainings`}</Text>
            </Stack>
          }
        >
          <Text fontSize="medium" color="blackAlpha.900">
            {eventsToRecover < 0
              ? `${Math.abs(eventsToRecover)} extra trainings attended`
              : `${eventsToRecover} training to recover`}
          </Text>
        </Tooltip>
      </Stack>

      <CommonScrollbar
        height="auto"
        overflow="auto"
        invisibleBorderWidth="0px 8px 0px 0px"
        paddingRight="2"
        paddingLeft="6"
        paddingBottom="6"
        marginBottom="6"
      >
        {displayedLogs.length > 0 ? (
          <Stack direction="column" spacing="6" align="stretch">
            {displayedLogs.map((log, index) => (
              <LogItem key={index} log={log} />
            ))}
          </Stack>
        ) : (
          <Text color="blackAlpha.600">No logs</Text>
        )}
      </CommonScrollbar>

      {/* Pagination controls for logs */}
      <PaginationControls
        currentPage={props.page ?? 1}
        itemsPerPage={LOGS_PAGINATION_LIMIT}
        totalItems={props.totalLogsCount ?? 0}
        onNextPage={() => props.onNextPage(props.athleteId)}
        onPrevPage={() => props.onPrevPage(props.athleteId)}
        disableButtons={props.isLoading}
      />
    </Box>
  );
};
