import React, { useState } from "react";
import { AccessType, Coach } from "../../../../types";
import { useAlert } from "../../../../common/components/AlertProvider";
import { sortNames } from "../../../../common/utils/dataProcessing";
import { Box, Grid, GridItem, SimpleGrid, Stack, Text, Tooltip } from "@chakra-ui/react";
import { FormModal } from "../../../../common/components/FormModal";
import { CommonButton } from "../../../../common/components/CommonButton";
import { CommonIconButton } from "../../../../common/components/CommonIconButton";
import { CommonInput } from "../../../../common/components/CommonInput";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { CommonScrollbar } from "../../../../common/components/CommonScrollbar";
import { useAuth } from "../../../Auth/components/AuthProvider";

type CoachesProps = {
  allCoaches: Coach[];
  saveCoachLoading: boolean;
  saveCoach: (data: any) => Promise<any>;
};

export const Coaches: React.FC<CoachesProps> = (props) => {
  const [selectedCoach, setSelectedCoach] = useState<Coach | null>(null);
  const [isNewCoach, setIsNewCoach] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { showAlert } = useAlert();
  const { email, setIsAuthenticated, accessType } = useAuth();

  const handleOpenDialog = (coach: Coach) => {
    setSelectedCoach(coach);
    setIsDialogOpen(true);
  };

  const handleSave = async (remove: boolean) => {
    if (remove) {
      showAlert("Deleting Coach", "info", undefined, true);
    } else if (selectedCoach?.id) {
      showAlert("Updating Coach", "info", undefined, true);
    } else {
      showAlert("Creating new Coach", "info", undefined, true);
    }

    setIsNewCoach(false);
    setIsDialogOpen(false);

    const { __typename, user, ...dataToSave } = selectedCoach || {};
    const result = await props.saveCoach({ ...dataToSave, remove });
    if (result) {
      if (remove) {
        if (selectedCoach?.user?.username === email) {
          // self deleted
          showAlert("Your User & related Coach is now deleted", "success");
          setIsAuthenticated(false);
        } else {
          // Owner user deleted
          // TODO: removed user can still perform actions until they log out, find a fix for this
          const message = selectedCoach?.user?.username
            ? `Coach & related User ${selectedCoach.user.username} deleted!`
            : "Coach deleted!";
          showAlert(message, "success", 5000);
        }
      } else {
        showAlert(selectedCoach?.id ? "Coach updated!" : "Coach created!", "success", 5000);
      }
    }
  };

  const allCoachNames = props.allCoaches.map((coach) => coach.name.toLowerCase());
  const submitDisabledReasons = allCoachNames.includes(selectedCoach?.name.toLowerCase() || "")
    ? `Coach "${selectedCoach?.name}" already exists`
    : selectedCoach?.name.trim() === ""
      ? "Coach name cannot be empty"
      : undefined;

  const featurePurpose =
    "Coaches are essential for managing Training Groups and organizing events. Add Coaches for effective training management";

  // if user is associated, only own coach can be deleted, unless owner
  const isAllowedToDeleteCoach =
    !selectedCoach?.user || accessType === AccessType.Owner || selectedCoach?.user?.username === email;

  return (
    <Box position="relative" paddingY="6" data-testid="coaches">
      <LoadingOverlay display={props.saveCoachLoading} spinnerSize="xl" spinnerTopPosition="40px" />
      <Stack direction="row" spacing="4" marginBottom="6" width="100%" justifyContent="center">
        <Tooltip label={featurePurpose}>
          <Text fontSize="x-large">Coaches ({props.allCoaches.length})</Text>
        </Tooltip>
        <CommonIconButton
          height="36px"
          onClick={() => {
            setIsNewCoach(true);
            handleOpenDialog({ name: "" });
          }}
          dataTestId="add-coach"
        />
      </Stack>
      <CommonScrollbar
        maxHeight="30svh"
        overflow="auto"
        paddingLeft="6"
        paddingRight="2"
        invisibleBorderWidth="0px 8px 0px 0px"
      >
        <SimpleGrid
          gap="4"
          width={props.allCoaches.length < 2 ? "fit-content" : "100%"}
          columns={props.allCoaches.length < 2 ? 1 : 2}
          justifySelf={props.allCoaches.length < 2 ? "center" : "start"}
        >
          {props.allCoaches.length === 0 ? (
            <GridItem colSpan={4}>
              <Text fontSize="large" textAlign="center" color="blackAlpha.600" data-testid="no-coaches">
                {featurePurpose}
              </Text>
            </GridItem>
          ) : (
            props.allCoaches
              .slice()
              .sort((a, b) => sortNames(a.name, b.name))
              .map((coach, index) => (
                <GridItem key={index} colSpan={1} data-testid="coach-grid">
                  <CommonButton
                    variantType="outlineSecondary"
                    fullWidth
                    onClick={() => handleOpenDialog(coach)}
                    dataTestId={`coach-${index}`}
                  >
                    <Text fontSize="medium" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                      {coach.name}
                    </Text>
                  </CommonButton>
                </GridItem>
              ))
          )}
        </SimpleGrid>
      </CommonScrollbar>
      <FormModal
        open={isDialogOpen}
        title={isNewCoach ? "Create new Coach" : "Edit Coach"}
        submitButtonText={isNewCoach ? "Create" : "Confirm"}
        handleSubmit={() => handleSave(false)}
        onClose={() => setIsDialogOpen(false)}
        handleRemove={() => handleSave(true)}
        removeDisabled={
          selectedCoach?.user?.accessType === AccessType.Owner ||
          props.allCoaches.length === 1 ||
          !isAllowedToDeleteCoach
        }
        removeDisabledReason={
          selectedCoach?.user?.accessType === AccessType.Owner
            ? "Cannot delete the Coach associated with the Owner"
            : props.allCoaches.length === 1
              ? "Cannot delete the only Coach"
              : !isAllowedToDeleteCoach
                ? "You can't delete a Coach which is related to other User's account. Only Owner can do this"
                : undefined
        }
        confirmationDialogTitle="Delete Coach"
        confirmationDialogMessage={
          <Stack direction="column" spacing="3">
            <Text>
              You're about to delete the Coach{" "}
              <Text as="span" color="red.500">
                {selectedCoach?.name}
              </Text>
              . This action will permanently remove all data associated with this Coach.
            </Text>
            {selectedCoach?.user && (
              <Text>
                Additionally, the related User{" "}
                <Text as="span" color="red.500">
                  {selectedCoach.user.username}
                </Text>{" "}
                will be deleted and will no longer be able to log in.
              </Text>
            )}
            <Text>This action is irreversible. Please confirm to proceed.</Text>
          </Stack>
        }
        submitDisabled={!!submitDisabledReasons}
        submitButtonHoverText={submitDisabledReasons}
      >
        <Grid templateColumns="repeat(2, 1fr)" gap={4} data-testid="coach-modal">
          <GridItem colSpan={2}>
            <Text fontSize="lg" color="blackAlpha.600">
              Associated User to this Coach: {selectedCoach?.user ? selectedCoach.user.username : "None"}
            </Text>
          </GridItem>

          <GridItem colSpan={2}>
            <CommonInput
              placeholder="Coach name"
              value={selectedCoach?.name || ""}
              onChange={(value) => setSelectedCoach({ ...selectedCoach, name: value })}
              dataTestId="coach-name"
            />
          </GridItem>
        </Grid>
      </FormModal>
    </Box>
  );
};
