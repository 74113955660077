import React from "react";
import { Text, Stack, useDisclosure, Tag, TagLabel, Tooltip } from "@chakra-ui/react";
import type { TrainingGroupEvent, SingleTrainingEvent } from "../../../../../types";
import { cardStyles, tagStyles } from "../../../../../common/utils/styles";
import { TrainingEventModal } from "./TrainingEventModal";
import { format, getISOWeek } from "date-fns";
import { extractDateFromUTC, extractUtcTime } from "../../../../../common/utils/dateAndTime";

type TrainingEventCardProps = {
  event: TrainingGroupEvent | SingleTrainingEvent;
  isOneTimeTrainingEvent: boolean;
};

/**
 * A card that displays a basic overview of a training event (group or single). Clicking on the card opens a modal with
 * more detailed info.
 */
export const TrainingEventCard: React.FC<TrainingEventCardProps> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const startTime = extractUtcTime(props.event.startDateTime);
  const endTime = extractUtcTime(props.event.endDateTime);

  const groupName = props.isOneTimeTrainingEvent
    ? "One-time training"
    : (props.event as TrainingGroupEvent)?.trainingGroup?.name ?? "Unnamed Group";

  const eventSeasonPlans =
    !props.isOneTimeTrainingEvent && (props.event as TrainingGroupEvent).trainingGroup.seasonPlans;

  return (
    <>
      <Stack
        direction={{ mobile: "column", laptop: "row" }}
        spacing={{ mobile: "4", laptop: "4" }}
        justifyContent="space-between"
        padding="4"
        onClick={onOpen}
        {...cardStyles({})}
      >
        <Stack direction="column" spacing="2">
          <Text isTruncated fontSize="medium" color="teal.800">
            {`${format(props.event.startDateTime, "EEEE")}, ${format(extractDateFromUTC(props.event.startDateTime), "dd.MM.yyyy")}`}
            <Text as="span" color="blackAlpha.600">
              {` ${startTime} - ${endTime}`}
            </Text>
          </Text>

          <Text fontSize="small" color="blackAlpha.600">
            {groupName} (week {getISOWeek(props.event.startDateTime)})
          </Text>
        </Stack>

        {props.isOneTimeTrainingEvent ? (
          <Text color="blackAlpha.600" textAlign="end">
            No Season Plans for One-time training events
          </Text>
        ) : eventSeasonPlans && eventSeasonPlans?.length > 0 ? (
          <Stack
            direction="column"
            spacing="2"
            alignItems={{ mobile: "start", laptop: "end" }}
            maxWidth={{ mobile: "100%", laptop: "50%" }}
            overflow="hidden"
            textOverflow="ellipsis"
          >
            <Stack direction="row" spacing="2" maxWidth="100%">
              {(props.event as TrainingGroupEvent).trainingGroup.seasonPlans?.map((plan) =>
                plan.skillSets?.map((skillSet) => (
                  <Tag key={skillSet.id} maxWidth="200px" {...tagStyles("teal.800")}>
                    <Tooltip
                      hasArrow
                      label={`Skill Set to train in the training event: ${skillSet.skillSet.name}`}
                      aria-label="Tag"
                      placement="top"
                    >
                      <TagLabel wordBreak="break-all">{skillSet.skillSet.name}</TagLabel>
                    </Tooltip>
                  </Tag>
                )),
              )}
            </Stack>
            <Stack direction="row" spacing="2" maxWidth="100%">
              {(props.event as TrainingGroupEvent).trainingGroup.seasonPlans?.map((plan) =>
                plan.skillSets?.map((skillSet) =>
                  skillSet.skills?.map((skill) => (
                    <Tag key={skill.id} {...tagStyles()}>
                      <Tooltip
                        hasArrow
                        label={`Skill to train in the training event: ${skill.name}`}
                        aria-label="Tag"
                        placement="top"
                      >
                        <TagLabel>{skill.name}</TagLabel>
                      </Tooltip>
                    </Tag>
                  )),
                ),
              )}
            </Stack>
          </Stack>
        ) : (
          <Tag maxWidth="200px" width="fit-content" height="26px" {...tagStyles("blackAlpha.600")}>
            <Tooltip hasArrow label="No targeted training plan for the training event" aria-label="Tag" placement="top">
              <TagLabel wordBreak="break-all">Not planned</TagLabel>
            </Tooltip>
          </Tag>
        )}
      </Stack>

      {/* Modal for detailed info */}
      {isOpen && (
        <TrainingEventModal
          isOpen={isOpen}
          onClose={onClose}
          event={props.event}
          isOneTimeTrainingEvent={props.isOneTimeTrainingEvent}
          groupName={groupName}
        />
      )}
    </>
  );
};
