import React from "react";
import { Box } from "@chakra-ui/react";
import { componentContainerShadowStyles } from "../../utils/styles";

type ComponentContainerProps = {
  /** The child elements to be rendered within the container */
  children: React.ReactNode;
  /**
   * Optional flag to indicate if padding should be handled manually. Paddings/margins should still total to 24px for
   * consistency
   */
  handlePaddingManually?: boolean;
  /** Optional order of the component */
  order?: number | { mobile?: number; laptop?: number };
  /** Optional flag to indicate if the container should have an error highlight */
  errorHighlight?: boolean;
};

export const ComponentContainer: React.FC<ComponentContainerProps> = (props) => {
  return (
    <Box
      height="100%"
      width="100%"
      padding={props.handlePaddingManually ? "0" : { mobile: "0", laptop: "6" }}
      border={{ mobile: undefined, laptop: "1px solid" }}
      borderColor={{ mobile: undefined, laptop: props.errorHighlight ? "red.500" : "blackAlpha.300" }}
      order={props.order}
      {...componentContainerShadowStyles(props.errorHighlight)}
    >
      {props.children}
    </Box>
  );
};
