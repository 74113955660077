import React, { forwardRef } from "react";
import { Button, Tooltip, type ButtonProps } from "@chakra-ui/react";

/** Props for the CommonButton component. */
export type CommonButtonProps = {
  /** The variant type of the button. */
  variantType: "outlineSecondary" | "solidPrimary";
  /** The content to be displayed inside the button. */
  children: React.ReactNode;
  /** The function to be called when the button is clicked. */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /** Whether the button is disabled. */
  disabled?: boolean;
  /** Whether the button hover effect is disabled. */
  disableHover?: boolean;
  /** The type of the button. */
  buttonType?: ButtonProps["type"];
  /** Whether the button should take up the full width of its container. */
  fullWidth?: boolean;
  /** The minimum width of the button. */
  minWidth?: string;
  /** The color of the button, defaults to orange. */
  color?: string;
  /** The color of the button on hover, defaults to orange. */
  hoverColor?: string;
  /** Tooltip text to be displayed on hover */
  tooltip?: string;
  /** The data-testid attribute to be added to the button. */
  dataTestId?: string;
};

/** A common button component that supports different variants and customization options. */
export const CommonButton = forwardRef<HTMLButtonElement, CommonButtonProps>((props, ref) => {
  const disableHover = props.disabled || props.disableHover;

  const commonButtonProps: ButtonProps = {
    width: props.fullWidth ? "100%" : "auto",
    minWidth: props.minWidth,
    borderRadius: "md",
    onClick: props.onClick,
    isDisabled: props.disabled,
    type: props.buttonType,
    boxShadow: "sm",
    transition: "all 0.3s ease",
  };

  const variantProps: { outlineSecondary: ButtonProps; solidPrimary: ButtonProps } = {
    outlineSecondary: {
      ...commonButtonProps,
      variant: "outline",
      borderColor: "blackAlpha.300",
      _hover: disableHover ? {} : { boxShadow: "none", borderColor: props.hoverColor || "orange.500" },
    },
    solidPrimary: {
      ...commonButtonProps,
      variant: "outline",
      color: "white",
      bgColor: props.color || "orange.400",
      borderColor: props.color || "orange.400",
      _hover: disableHover
        ? {}
        : {
            boxShadow: "none",
            backgroundColor: props.hoverColor || "orange.500",
            borderColor: props.hoverColor ? "blackAlpha.200" : "orange.500",
          },
    },
  };

  return (
    <Tooltip label={props.tooltip} isDisabled={!props.tooltip} placement="auto" whiteSpace="pre-wrap">
      <Button ref={ref} {...variantProps[props.variantType]} data-testid={props.dataTestId}>
        {props.children}
      </Button>
    </Tooltip>
  );
});
