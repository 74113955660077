/** Represents the structure of a GraphQL error object. */
type GraphQLErrorType = {
  networkError?: {
    result?: {
      errors?: {
        message: string;
        extensions?: { code: string; [key: string]: any };
      }[];
    };
    message?: string;
  };
  graphQLErrors?: {
    message: string;
    extensions?: { code: string; [key: string]: any };
    path?: string[];
  }[];
};

/**
 * Extracts user-friendly error messages and checks for authentication errors.
 *
 * @param errors - An array of error objects with optional extensions containing error codes.
 * @returns An object containing an array of error messages and a boolean indicating if authentication failed.
 */
const extractErrorMessages = (
  errors: { message: string; extensions?: { code: string; [key: string]: any } }[] = [],
): { messages: string[]; hasAuthenticationError: boolean } => {
  const errorMessages: string[] = [];
  let hasAuthenticationError = false;

  errors.forEach((error) => {
    switch (error.extensions?.code) {
      case "UNAUTHENTICATED":
        errorMessages.push("Your session has expired, please log in again");
        hasAuthenticationError = true;
        break;
      case "BAD_USER_INPUT":
        const missingFieldMatch = error.message.match(/Field "(.+)" of required type/);
        if (missingFieldMatch && missingFieldMatch[1]) {
          errorMessages.push(`Please provide the "${missingFieldMatch[1]}" field`);
        } else {
          errorMessages.push("Some required information is missing or incorrect");
        }
        break;
      case "UNAUTHORIZED":
        errorMessages.push("You do not have permission to perform this action");
        break;
      case "INTERNAL_SERVER_ERROR":
        if (error.message.includes("Access denied")) {
          errorMessages.push("You do not have permission to perform this action");
          break;
        }
      case "ORGANIZATION_STATUS_ERROR":
        if (error.message.includes("disabled")) {
          errorMessages.push("Your organization is disabled, changes are not allowed");
        } else if (error.message.includes("pending deletion")) {
          errorMessages.push("Your organization is pending deletion, changes are not allowed");
        } else {
          errorMessages.push("Your organization is in an invalid state, changes are not allowed");
        }
        break;
      default:
        errorMessages.push("An error occurred while processing your request");
        break;
    }
  });

  return { messages: errorMessages, hasAuthenticationError };
};

/**
 * Processes a GraphQL error and extracts user-friendly error messages.
 *
 * @param error - The GraphQL error object to process.
 * @returns An object containing an array of error messages and a boolean indicating if authentication failed.
 */
const handleGraphQLError = (error: GraphQLErrorType): { messages: string[]; hasAuthenticationError: boolean } => {
  const errorMessages: string[] = [];
  let hasAuthenticationError = false;

  // Handle network errors
  if (error.networkError) {
    const networkErrors = error.networkError.result?.errors || [];
    const { messages, hasAuthenticationError: networkAuthError } = extractErrorMessages(networkErrors);
    errorMessages.push(...messages);
    hasAuthenticationError ||= networkAuthError;

    if (messages.length === 0) {
      errorMessages.push("A network error occurred.");
    }
  }

  // Handle GraphQL errors
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    const { messages, hasAuthenticationError: gqlAuthError } = extractErrorMessages(error.graphQLErrors);
    errorMessages.push(...messages);
    hasAuthenticationError ||= gqlAuthError;
  }

  // Provide a fallback message if no specific errors were captured
  if (errorMessages.length === 0) {
    errorMessages.push("An unexpected error occurred, please refresh the page and try again");
  }

  return { messages: errorMessages, hasAuthenticationError };
};

/**
 * Handles an array of GraphQL errors by extracting user-friendly messages and identifying authentication errors.
 *
 * @param errors - An array of GraphQL error objects to process.
 * @returns An object containing a combined error message and a boolean indicating if authentication failed.
 */
export const handleError = (
  errors: (GraphQLErrorType | any)[],
): { message: string; hasAuthenticationError: boolean } => {
  const allErrorMessages: string[] = [];
  const uniqueErrors = new Set<string>();
  let hasAuthenticationError = false;

  errors.forEach((error) => {
    const { messages, hasAuthenticationError: errorAuthError } = handleGraphQLError(error);

    // Add unique error messages
    messages.forEach((msg) => {
      if (!uniqueErrors.has(msg)) {
        uniqueErrors.add(msg);
        allErrorMessages.push(msg);
      }
    });

    hasAuthenticationError ||= errorAuthError;
  });

  return { message: allErrorMessages.join("\n"), hasAuthenticationError };
};
