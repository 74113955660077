import { gql } from "@apollo/client";

export const GET_INVOICES = gql`
  query GetInvoices {
    getInvoices {
      billingMonth
      billingYear
      dueDateTimestampMs
      hostedInvoiceUrl
      invoicePdf
      status
      freeTrialInvoice
      postFreeTrialOneTimeInvoice
    }
  }
`;
