import React, { useEffect, useState } from "react";
import { Box, Divider, Stack, Text, Tooltip } from "@chakra-ui/react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { LoadingContainer, loadingContainerFadeIn } from "../../../common/components/LoadingContainer";
import { Evaluation } from "../components/Evaluation/Evaluation";
import { Notes } from "../components/Notes/Notes";
import { Logs } from "../components/Logs/Logs";
import { TrainingGroups } from "../components/TrainingGroups/TrainingGroups";
import { useAuth } from "../../Auth/components/AuthProvider";
import { GET_USER_ATHLETE } from "../graphql/get-user-athlete.query";
import { useHandleQueryErrors } from "../../../common/hooks";
import { GET_ALL_SKILL_SETS } from "../../../common/graphql/get-all-skill-sets.query";
import { ComponentContainer } from "../../../common/components/ComponentContainer";
import { GET_USER_ORGANIZATION } from "../../../common/graphql/get-user-organization.query";
import { GET_GENERIC_EVALUATION_CRITERIA } from "../../SkillSets/graphql/get-generic-evaluation-criteria.query";
import {
  AccessType,
  type Center,
  type EvaluationCriteria,
  type SingleTrainingEvent,
  type TrainingGroupEvent,
} from "../../../types";
import { GET_SKILL_SET_EVALUATION_CRITERIA } from "../../SkillSets/graphql/get-skill-set-evaluation-criteria.query";
import { GET_ALL_TRAINING_SEASONS } from "../../Organization/graphql/get-all-training-seasons.query";
import { GET_ALL_COACHES } from "../../../common/graphql/get-all-coaches.query";
import { GET_ALL_CENTERS } from "../../../common/graphql/get-all-centers.query";
import { GET_ALL_SKILL_SET_EVALUATION_CRITERIA } from "../graphql/get-all-skill-set-evaluation-criteria.query";
import { useParams } from "react-router-dom";
import { GET_ATHLETE_BY_ID } from "../graphql/get-athlete-by-id.query";
import { useAlert } from "../../../common/components/AlertProvider";
import { GET_ATHLETE_EVALUATION } from "../graphql/get-athlete-evaluation.query";
import { GET_ATHLETE_NOTES } from "../graphql/get-athlete-notes.query";
import { GET_ATHLETE_TRAINING_GROUPS } from "../graphql/get-athlete-training-groups.query";
import { GET_ATHLETE_LOGS } from "../graphql/get-athlete-logs.query";
import { GET_ATHLETE_TRAINING_EVENTS } from "../graphql/get-athlete-training-events.query";
import { TrainingEvents } from "../components/TrainingEvents/TrainingEvents";
import { LOGS_PAGINATION_LIMIT, NOTES_PAGINATION_LIMIT, TRAINING_EVENTS_PAGINATION_LIMIT } from "./AthleteContainer";

export const AthleteReadOnlyContainer: React.FC = () => {
  const [upcomingPage, setUpcomingPage] = useState<number>(1);
  const [pastPage, setPastPage] = useState<number>(1);
  const [notesPage, setNotesPage] = useState<number>(1);
  const [logsPage, setLogsPage] = useState<number>(1);

  const { showAlert } = useAlert();
  const { accessType } = useAuth();

  // get athleteId from URL params (if in preview mode)
  const { athleteId } = useParams();
  const isNotAthlete = accessType !== AccessType.Athlete;
  const isPreviewMode = athleteId && isNotAthlete;

  useEffect(() => {
    if (isPreviewMode) {
      showAlert(
        "You are viewing this page as the Athlete would see it. This is their view when they log in to their account",
        "warning",
      );
    }
  }, []);

  const useQueryToLoadAthlete = isPreviewMode ? GET_ATHLETE_BY_ID : GET_USER_ATHLETE;

  const variables = isPreviewMode ? { athleteId: athleteId } : undefined;

  const {
    data: athleteData,
    loading: athleteLoading,
    error: athleteError,
  } = useQuery(useQueryToLoadAthlete, {
    variables,
    fetchPolicy: "network-only",
  });
  const [
    getAthleteEvaluation,
    { data: athleteEvaluationData, loading: athleteEvaluationLoading, error: athleteEvaluationError },
  ] = useLazyQuery(GET_ATHLETE_EVALUATION, { fetchPolicy: "network-only" });

  const [getAthleteNotes, { data: athleteNotesData, loading: athleteNotesLoading, error: athleteNotesError }] =
    useLazyQuery(GET_ATHLETE_NOTES, { fetchPolicy: "network-only" });
  const [getAthleteLogs, { data: athleteLogsData, loading: athleteLogsLoading, error: athleteLogsError }] =
    useLazyQuery(GET_ATHLETE_LOGS, { fetchPolicy: "network-only" });

  const {
    data: organizationData,
    loading: organizationDataLoading,
    error: organizationDataError,
  } = useQuery(GET_USER_ORGANIZATION);

  const { data: skillSetData, loading: skillSetDataLoading, error: skillSetDataError } = useQuery(GET_ALL_SKILL_SETS);
  const {
    data: evaluationCriteriaData,
    loading: evaluationCriteriaLoading,
    error: evaluationCriteriaError,
  } = useQuery(GET_GENERIC_EVALUATION_CRITERIA);

  const {
    data: allSkillSetEvaluationCriteriaData,
    loading: allSkillSetEvaluationCriteriaLoading,
    error: allSkillSetEvaluationCriteriaError,
  } = useQuery(GET_ALL_SKILL_SET_EVALUATION_CRITERIA);

  const [getSkillSetEvaluationCriteriaQuery, { error: skillSetEvaluationCriteriaDataError }] = useLazyQuery(
    GET_SKILL_SET_EVALUATION_CRITERIA,
    { fetchPolicy: "network-only" },
  );

  const [
    getAthleteTrainingGroups,
    { data: athleteTrainingGroupsData, loading: athleteTrainingGroupsLoading, error: athleteTrainingGroupsError },
  ] = useLazyQuery(GET_ATHLETE_TRAINING_GROUPS, { fetchPolicy: "network-only" });

  // no-cache due to the manual filtering of season plans to events
  // TODO: to improve, create direct relation with season plan & training group events
  const [getUpcomingEvents, { data: upcomingEventsData, loading: upcomingLoading, error: upcomingError }] =
    useLazyQuery(GET_ATHLETE_TRAINING_EVENTS, { fetchPolicy: "no-cache" });
  const [getPastEvents, { data: pastEventsData, loading: pastLoading, error: pastError }] = useLazyQuery(
    GET_ATHLETE_TRAINING_EVENTS,
    { fetchPolicy: "no-cache" },
  );

  const {
    data: allTrainingSeasonsData,
    loading: allTrainingSeasonsLoading,
    error: allTrainingSeasonsError,
  } = useQuery(GET_ALL_TRAINING_SEASONS);

  const { data: coachData, loading: coachDataLoading, error: coachDataError } = useQuery(GET_ALL_COACHES);
  const { data: centerData, loading: centerLoading, error: centerError } = useQuery(GET_ALL_CENTERS);

  const data = isPreviewMode ? athleteData?.getAthleteById : athleteData?.getUserAthlete;

  const loadUpcomingEvents = async (athleteId: string, page: number) => {
    await getUpcomingEvents({
      variables: {
        athleteId,
        isUpcoming: true,
        page,
        limit: TRAINING_EVENTS_PAGINATION_LIMIT,
      },
    });
  };

  const loadPastEvents = async (athleteId: string, page: number) => {
    await getPastEvents({
      variables: {
        athleteId,
        isUpcoming: false,
        page,
        limit: TRAINING_EVENTS_PAGINATION_LIMIT,
      },
    });
  };

  const loadNotes = async (athleteId: string, page: number) => {
    await getAthleteNotes({
      variables: {
        athleteId,
        page,
        limit: NOTES_PAGINATION_LIMIT,
      },
    });
  };

  const handleNextNotesPage = (athleteId: string) => {
    setNotesPage((prev) => prev + 1);
    loadNotes(athleteId, notesPage + 1);
  };

  const handlePrevNotesPage = (athleteId: string) => {
    if (notesPage > 1) {
      setNotesPage((prev) => prev - 1);
      loadNotes(athleteId, notesPage - 1);
    }
  };

  const loadLogs = async (athleteId: string, page: number) => {
    await getAthleteLogs({
      variables: {
        athleteId,
        page,
        limit: LOGS_PAGINATION_LIMIT,
      },
    });
  };

  const handleNextLogsPage = (athleteId: string) => {
    setLogsPage((prev) => prev + 1);
    loadLogs(athleteId, logsPage + 1);
  };

  const handlePrevLogsPage = (athleteId: string) => {
    if (logsPage > 1) {
      setLogsPage((prev) => prev - 1);
      loadLogs(athleteId, logsPage - 1);
    }
  };

  useEffect(() => {
    if (data?.id) {
      const id = data.id as string;
      getAthleteEvaluation({ variables: { id: id } });
      loadNotes(id, 1);
      loadLogs(id, 1);
      getAthleteTrainingGroups({ variables: { athleteId: id } });
    }
  }, [athleteData?.getAthleteById, athleteData?.getUserAthlete, getAthleteEvaluation, getAthleteNotes, isPreviewMode]);

  useEffect(() => {
    if (athleteTrainingGroupsData?.getAthleteTrainingGroups && data?.id) {
      // update events "manually" due to the cache problems of the season plan events
      // this replaces the initial data fetch & subscription functionality
      // NOTE: only changes in the training groups will trigger a re-fetch, so e.g. calendar event changes will not be reflected unless refreshed

      loadUpcomingEvents(data.id, upcomingPage);
      loadPastEvents(data.id, pastPage);
    }
  }, [athleteTrainingGroupsData]);

  const isLoading =
    athleteLoading ||
    organizationDataLoading ||
    skillSetDataLoading ||
    evaluationCriteriaLoading ||
    allTrainingSeasonsLoading ||
    coachDataLoading ||
    centerLoading ||
    allSkillSetEvaluationCriteriaLoading;
  const [showContent, setShowContent] = useState(!isLoading);

  const [skillSetEvaluationCriteriaData, setSkillSetEvaluationCriteriaData] = useState<{
    [key: string]: EvaluationCriteria[];
  }>({});
  const [skillSetEvaluationCriteriaLoading, setSkillSetEvaluationCriteriaLoading] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    } else {
      setShowContent(false);
    }
  }, [isLoading]);

  useHandleQueryErrors([
    athleteError,
    athleteEvaluationError,
    athleteNotesError,
    organizationDataError,
    skillSetDataError,
    evaluationCriteriaError,
    skillSetEvaluationCriteriaDataError,
    allTrainingSeasonsError,
    coachDataError,
    centerError,
    allSkillSetEvaluationCriteriaError,
    athleteTrainingGroupsError,
    athleteLogsError,
    upcomingError,
    pastError,
  ]);

  const getSkillSetEvaluationCriteria = async (skillSetId: string) => {
    setSkillSetEvaluationCriteriaLoading((prev) => ({
      ...prev,
      [skillSetId]: true,
    }));
    const { data } = await getSkillSetEvaluationCriteriaQuery({
      variables: { skillSetId },
    });
    setSkillSetEvaluationCriteriaData((prev) => ({
      ...prev,
      [skillSetId]: data.getSkillSetEvaluationCriteria,
    }));

    setSkillSetEvaluationCriteriaLoading((prev) => ({
      ...prev,
      [skillSetId]: false,
    }));
  };

  const handleNextUpcomingEventsPage = async (athleteId: string) => {
    setUpcomingPage((prev) => prev + 1);
    await loadUpcomingEvents(athleteId, upcomingPage + 1);
  };

  const handlePrevUpcomingEventsPage = async (athleteId: string) => {
    if (upcomingPage > 1) {
      setUpcomingPage((prev) => prev - 1);
      await loadUpcomingEvents(athleteId, upcomingPage - 1);
    }
  };

  const handleNextPastEventsPage = async (athleteId: string) => {
    setPastPage((prev) => prev + 1);
    await loadPastEvents(athleteId, pastPage + 1);
  };

  const handlePrevPastEventsPage = async (athleteId: string) => {
    if (pastPage > 1) {
      setPastPage((prev) => prev - 1);
      await loadPastEvents(athleteId, pastPage - 1);
    }
  };

  return (
    <Box id="athlete-readonly-container" animation={`${loadingContainerFadeIn} 0.3s`}>
      <LoadingContainer display={isLoading} />

      {showContent && (
        <Box animation={isLoading ? undefined : `${loadingContainerFadeIn} 0.3s`}>
          <Stack direction="column" spacing="8" alignItems={{ mobile: "center", laptop: "start" }}>
            <Stack direction={{ mobile: "column", laptop: "row" }} spacing="3" alignItems="center">
              <Text fontSize="x-large" width="fit-content">
                {data?.name}
              </Text>
              {data?.birthYear && new Date().getFullYear() - data.birthYear < 16 && (
                <Tooltip label="Athlete is under 16 years old">
                  <Text
                    border="1px solid"
                    borderRadius="md"
                    borderColor="orange.400"
                    textColor="white"
                    backgroundColor="orange.400"
                    width="fit-content"
                    paddingY="1"
                    paddingX="2"
                  >
                    Junior
                  </Text>
                </Tooltip>
              )}
            </Stack>

            <Divider order={2} display={{ mobile: "block", laptop: "none" }} />

            <ComponentContainer order={3} handlePaddingManually={true}>
              <Evaluation
                evaluation={athleteEvaluationData?.getAthleteEvaluation || null}
                allSkillSets={skillSetData?.getAllSkillSets || []}
                allCriteria={evaluationCriteriaData?.getGenericEvaluationCriteria || []}
                organization={organizationData?.getUserOrganization || null}
                disabled={true}
                readOnly={true}
                getSkillSetEvaluationCriteria={getSkillSetEvaluationCriteria}
                skillSetEvaluationCriteriaData={skillSetEvaluationCriteriaData}
                skillSetEvaluationCriteriaLoading={skillSetEvaluationCriteriaLoading}
                skillSetEvaluationCriteriaIds={Array.from(
                  new Set(
                    allSkillSetEvaluationCriteriaData?.getAllSkillSetEvaluationCriteria?.map(
                      (criteria: any) => criteria?.skillSet?.id,
                    ),
                  ),
                )}
                isLoading={athleteEvaluationLoading}
              />
            </ComponentContainer>

            <Divider order={4} display={{ mobile: "block", laptop: "none" }} />

            <Stack order={5} direction={{ mobile: "column", laptop: "row" }} spacing="8" width="100%">
              <ComponentContainer order={6} handlePaddingManually={true}>
                <Notes
                  athleteId={data?.id}
                  onNextPage={() => handleNextNotesPage(data?.id)}
                  onPrevPage={() => handlePrevNotesPage(data?.id)}
                  page={notesPage}
                  totalNoteCount={athleteNotesData?.getAthleteNotes?.totalCount || 0}
                  notes={athleteNotesData?.getAthleteNotes?.notes || []}
                  isLoading={athleteNotesLoading}
                  readOnly={true}
                />
              </ComponentContainer>

              <Divider order={7} display={{ mobile: "block", laptop: "none" }} />

              <ComponentContainer order={8} handlePaddingManually={true}>
                {/* Upcoming training events */}
                <TrainingEvents
                  title="Upcoming Training Events"
                  allEvents={upcomingEventsData?.getAthleteTrainingEvents?.allTrainingEvents || []}
                  totalEventCount={upcomingEventsData?.getAthleteTrainingEvents?.totalCount || 0}
                  page={upcomingPage}
                  onNextPage={() => handleNextUpcomingEventsPage(data?.id)}
                  onPrevPage={() => handlePrevUpcomingEventsPage(data?.id)}
                  athleteId={data?.id}
                  isLoading={upcomingLoading}
                />
              </ComponentContainer>
            </Stack>

            <Divider order={9} display={{ mobile: "block", laptop: "none" }} />

            <Stack order={10} direction={{ mobile: "column", laptop: "row" }} spacing="8" width="100%">
              <ComponentContainer order={{ mobile: 13, laptop: 11 }} handlePaddingManually={true}>
                <Logs
                  athleteId={data?.id}
                  logs={athleteLogsData?.getAthleteLogs?.logs || []}
                  totalLogsCount={athleteLogsData?.getAthleteLogs?.totalCount || 0}
                  aggregations={athleteLogsData?.getAthleteLogs?.aggregations || null}
                  page={logsPage}
                  onNextPage={() => handleNextLogsPage(data?.id)}
                  onPrevPage={() => handlePrevLogsPage(data?.id)}
                  isLoading={athleteLogsLoading}
                />
              </ComponentContainer>

              <Divider order={12} display={{ mobile: "block", laptop: "none" }} />

              <ComponentContainer order={{ mobile: 11, laptop: 13 }} handlePaddingManually={true}>
                {/* Past training events */}
                <TrainingEvents
                  title="Past Training Events"
                  allEvents={pastEventsData?.getAthleteTrainingEvents?.allTrainingEvents || []}
                  totalEventCount={pastEventsData?.getAthleteTrainingEvents?.totalCount || 0}
                  page={pastPage}
                  onNextPage={() => handleNextPastEventsPage(data?.id)}
                  onPrevPage={() => handlePrevPastEventsPage(data?.id)}
                  athleteId={data?.id}
                  isLoading={pastLoading}
                />
              </ComponentContainer>
            </Stack>

            <Divider order={14} display={{ mobile: "block", laptop: "none" }} />

            <ComponentContainer order={15}>
              <TrainingGroups
                athleteName={data?.name || ""}
                coaches={coachData?.getAllCoaches || []}
                venues={
                  centerData?.getAllCenters?.flatMap((center: Center) =>
                    center.venues.map((venue) => ({ ...venue, center })),
                  ) || []
                }
                athletesTrainingGroups={athleteTrainingGroupsData?.getAthleteTrainingGroups || []}
                allTrainingSeasons={allTrainingSeasonsData?.getAllTrainingSeasons || []}
                isLoading={athleteTrainingGroupsLoading}
                readOnly={true}
              />
            </ComponentContainer>
          </Stack>
        </Box>
      )}
    </Box>
  );
};
