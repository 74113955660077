import React, { useState } from "react";
import { Box, Divider, Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import { TrainingSeason, TrainingSeasonStatus, type TrainingGroup } from "../../../../../types";
import { CommonDayPicker } from "../../../../../common/components/CommonDayPicker";
import { commonStyles } from "../../../../../common/utils/styles";
import { convertToLocalDateTime, formatToUtcDayStartString } from "../../../../../common/utils/dateAndTime";
import { weekdayOptions } from "../../../../../common/utils/dataProcessing";
import { CommonInput } from "../../../../../common/components/CommonInput";
import { CommonSelect } from "../../../../../common/components/CommonSelect";
import { CommonCheckbox } from "../../../../../common/components/CommonCheckbox";
import { CommonScrollbar } from "../../../../../common/components/CommonScrollbar";

export type TrainingSeasonWithTrainingGroups = TrainingSeason & {
  trainingGroups: TrainingGroup[];
};

type TrainingSeasonEditorProps = {
  trainingSeason: TrainingSeason;
  allTrainingGroups: TrainingGroup[];
  onChange: (seasonWithGroups: TrainingSeasonWithTrainingGroups) => void;
};

export const TrainingSeasonEditor: React.FC<TrainingSeasonEditorProps> = (props) => {
  const [selectedTrainingGroups, setSelectedTrainingGroups] = useState<TrainingGroup[]>([]);

  const handleNameChange = (value: string) => {
    props.onChange({
      ...props.trainingSeason,
      name: value,
      trainingGroups: selectedTrainingGroups,
    });
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.onChange({
      ...props.trainingSeason,
      status: e.target.value as TrainingSeasonStatus,
      trainingGroups: selectedTrainingGroups,
    });
  };

  const handleSeasonStartChange = (date?: string) => {
    props.onChange({
      ...props.trainingSeason,
      seasonStart: date || "",
      trainingGroups: selectedTrainingGroups,
    });
  };

  const handleSeasonEndChange = (date?: string) => {
    props.onChange({
      ...props.trainingSeason,
      seasonEnd: date || "",
      trainingGroups: selectedTrainingGroups,
    });
  };

  const handleTrainingGroupClick = (event: React.MouseEvent, group: TrainingGroup) => {
    event.stopPropagation();
    event.preventDefault();

    const checked = selectedTrainingGroups.includes(group);
    if (checked) {
      setSelectedTrainingGroups(selectedTrainingGroups.filter((g) => g.id !== group.id));
    } else {
      setSelectedTrainingGroups([...selectedTrainingGroups, group]);
    }

    props.onChange({
      ...props.trainingSeason,
      trainingGroups: checked
        ? selectedTrainingGroups.filter((g) => g.id !== group.id)
        : [...selectedTrainingGroups, group],
    });
  };

  const trainingGroupsInCurrentSeason = props.allTrainingGroups.filter(
    (group) => group.trainingSeason.id === props.trainingSeason.id,
  );

  const trainingGroups = props.allTrainingGroups.filter((trainingGroup) => {
    // Exclude if the training group is in the current season
    if (trainingGroup.trainingSeason.id === props.trainingSeason.id) {
      return false;
    }

    // Exclude if the training group is identical to any in the current season
    const isIdentical = trainingGroupsInCurrentSeason.some((currentGroup) => {
      return (
        trainingGroup.recurrenceWeekday === currentGroup.recurrenceWeekday &&
        trainingGroup.recurrenceStartTime === currentGroup.recurrenceStartTime &&
        trainingGroup.recurrenceEndTime === currentGroup.recurrenceEndTime &&
        trainingGroup.venue.id === currentGroup.venue.id
      );
    });

    return !isIdentical;
  });

  trainingGroups.sort((a, b) => b.trainingSeason.name.localeCompare(a.trainingSeason.name));

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Grid height="fit-content" templateColumns="repeat(4, 1fr)" gap="4" paddingX="6">
        <GridItem colSpan={2}>
          <CommonSelect
            data-testid="training-season-status"
            placeholder="Season stage"
            value={props.trainingSeason.status}
            onChange={handleStatusChange}
            options={[
              { value: TrainingSeasonStatus.Upcoming, label: TrainingSeasonStatus.Upcoming },
              { value: TrainingSeasonStatus.Active, label: TrainingSeasonStatus.Active },
              ...(props.trainingSeason.status === TrainingSeasonStatus.Active && props.trainingSeason.id
                ? [{ value: TrainingSeasonStatus.Completed, label: TrainingSeasonStatus.Completed }]
                : [{ value: TrainingSeasonStatus.Completed, label: TrainingSeasonStatus.Completed, disabled: true }]),
            ]}
            disabledOptionsToLast={true}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <CommonInput
            placeholder="Season name"
            value={props.trainingSeason.name}
            onChange={handleNameChange}
            dataTestId="training-season-name"
          />
        </GridItem>
        <GridItem colSpan={2}>
          <CommonDayPicker
            onDateChange={handleSeasonStartChange}
            initialDate={
              props.trainingSeason.seasonStart ? convertToLocalDateTime(props.trainingSeason.seasonStart) : undefined
            }
            dateUtcMode="start"
            label="Season start"
            dataTestId="training-season-start"
          />
        </GridItem>
        <GridItem colSpan={2}>
          <CommonDayPicker
            onDateChange={handleSeasonEndChange}
            initialDate={
              props.trainingSeason.seasonEnd ? convertToLocalDateTime(props.trainingSeason.seasonEnd) : undefined
            }
            dateUtcMode="end"
            label="Season end"
            dataTestId="training-season-end"
          />
        </GridItem>
        <GridItem colSpan={4}>
          <Divider marginY="4" />
        </GridItem>
        <GridItem colSpan={4}>
          <Text
            fontSize="lg"
            color={
              trainingGroups.length === 0 || props.trainingSeason.status === TrainingSeasonStatus.Completed
                ? "blackAlpha.600"
                : undefined
            }
          >
            {props.trainingSeason.status === TrainingSeasonStatus.Completed
              ? "Training Season is completed, no Training Groups available to duplicate"
              : "Choose Training Groups to duplicate into this Training Season"}
          </Text>
        </GridItem>
      </Grid>

      {props.trainingSeason.status !== TrainingSeasonStatus.Completed && (
        <Box flex={1} overflow="hidden" marginTop="4">
          <CommonScrollbar
            overflow="auto"
            height="100%"
            invisibleBorderWidth="0px 8px 0px 0px"
            paddingLeft="6"
            paddingRight="2"
          >
            <Stack direction="column" spacing="2">
              {trainingGroups.length > 0 ? (
                trainingGroups.map((group) => (
                  <Box
                    key={group.id}
                    onClick={(event) => handleTrainingGroupClick(event, group)}
                    paddingY="1"
                    paddingX="2"
                    cursor="pointer"
                    {...commonStyles(selectedTrainingGroups.includes(group))}
                  >
                    <Grid
                      templateColumns="repeat(16, 1fr)"
                      gap="4"
                      width="100%"
                      justifyItems="start"
                      alignItems="center"
                    >
                      <GridItem colSpan={3} maxWidth="100%">
                        <Stack direction="row" spacing="2" maxWidth="inherit">
                          <CommonCheckbox checked={selectedTrainingGroups.includes(group)} />
                          <Text maxWidth="inherit" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                            {group.trainingSeason.name}
                          </Text>
                        </Stack>
                      </GridItem>
                      <GridItem colSpan={3} maxWidth="100%">
                        <Text maxWidth="inherit" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                          {group.name}
                        </Text>
                      </GridItem>
                      <GridItem colSpan={2}>
                        <Text>{weekdayOptions[(group.recurrenceWeekday + 6) % 7].label}</Text>
                      </GridItem>
                      <GridItem colSpan={2}>
                        <Text>
                          {group.recurrenceStartTime} - {group.recurrenceEndTime}
                        </Text>
                      </GridItem>
                      <GridItem colSpan={6} maxWidth="100%">
                        <Text maxWidth="inherit" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                          {group.athletes?.map((athlete) => athlete.name).join(", ")}
                        </Text>
                      </GridItem>
                    </Grid>
                  </Box>
                ))
              ) : (
                <Text color="blackAlpha.600">No Training Groups available to duplicate</Text>
              )}
            </Stack>
          </CommonScrollbar>
        </Box>
      )}
    </Box>
  );
};
