import { gql } from "@apollo/client";

export const GET_ATHLETE_LOGS = gql`
  query GetAthleteLogs($athleteId: String!, $page: Int = 1, $limit: Int = 10) {
    getAthleteLogs(athleteId: $athleteId, page: $page, limit: $limit) {
      totalCount
      logs {
        id
        type
        description
        createdAt
        eventId
        eventStartDateTime
        eventEndDateTime
        eventVenue
        eventCenter
        eventTrainingGroupName
        eventCoaches
      }
      aggregations {
        withdrawns
        cancellations
        replacements
      }
    }
  }
`;
