import React, { useMemo, useState } from "react";
import { Box, Text, Tooltip } from "@chakra-ui/react";
import { CommonTable, type Column, type SortConfig } from "../../../../../common/components/CommonTable/CommonTable";
import { OrganizationUser, AccessType } from "../../../../../types";
import { CommonScrollbar } from "../../../../../common/components/CommonScrollbar";

export type UserSortConfig = {
  key: keyof OrganizationUser;
  direction: "ascending" | "descending";
} | null;

interface UsersTableProps {
  /** An array of organization users to display */
  allUsers: OrganizationUser[];
}

export const UserTable: React.FC<UsersTableProps> = (props) => {
  const [sortConfig, setSortConfig] = useState<SortConfig<OrganizationUser> | undefined>({
    key: "accessType",
    direction: "ascending",
  });

  const sortedUsers = useMemo(() => {
    let sorted = [...props.allUsers];
    if (sortConfig) {
      sorted.sort((a, b) => {
        let aValue: string | number | boolean | undefined = a[sortConfig.key];
        let bValue: string | number | boolean | undefined = b[sortConfig.key];

        if (sortConfig.key === "accessType") {
          const getAccessPriority = (accesType: AccessType): number => {
            switch (accesType) {
              case AccessType.Admin:
                return 1;
              case AccessType.Owner:
                return 2;
              case AccessType.Full:
                return 3;
              case AccessType.Limited:
                return 4;
              case AccessType.Athlete:
                return 5;
              default:
                return 99;
            }
          };

          aValue = getAccessPriority(a.accessType || AccessType.Athlete);
          bValue = getAccessPriority(b.accessType || AccessType.Athlete);
        }

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }

        if (typeof aValue === "number" && typeof bValue === "number") {
          return sortConfig.direction === "ascending" ? aValue - bValue : bValue - aValue;
        }

        return 0;
      });
    }
    return sorted;
  }, [props.allUsers, sortConfig]);

  const parseAccessType = (accessType: AccessType) => {
    switch (accessType) {
      case AccessType.Admin:
        return "Admin";
      case AccessType.Owner:
        return "Owner";
      case AccessType.Full:
        return "Full";
      case AccessType.Limited:
        return "Limited";
      case AccessType.Athlete:
        return "Athlete";
      default:
        return "Unknown";
    }
  };

  const columns: Column<OrganizationUser>[] = [
    {
      label: "Email",
      key: "username",
      width: "45%",
      sortable: true,
      render: (user) => (
        <Tooltip label={user.username}>
          <Text isTruncated>{user.username}</Text>
        </Tooltip>
      ),
    },
    {
      label: "Access",
      key: "accessType",
      width: "22%",
      sortable: true,
      render: (user) => (
        <Text
          border="1px solid"
          borderRadius="md"
          borderColor={
            user.accessType === AccessType.Admin
              ? "black"
              : user.accessType === AccessType.Owner
                ? "red.500"
                : user.accessType === AccessType.Full
                  ? "teal.800"
                  : "orange.400"
          }
          textColor="white"
          backgroundColor={
            user.accessType === AccessType.Admin
              ? "black"
              : user.accessType === AccessType.Owner
                ? "red.500"
                : user.accessType === AccessType.Full
                  ? "teal.800"
                  : "orange.400"
          }
          width="fit-content"
          paddingY="1"
          paddingX="2"
        >
          {parseAccessType(user.accessType!)}
        </Text>
      ),
    },
    {
      label: "Joined",
      key: "createdAt",
      width: "33%",
      sortable: true,
      render: (user) => <Text>{new Date(user.createdAt!).toLocaleString().slice(0, -3)}</Text>,
    },
  ];

  return (
    <Box>
      <Text fontSize="x-large" justifySelf="center" paddingBottom="6">
        Users ({props.allUsers.length})
      </Text>

      <CommonScrollbar maxHeight="40svh" overflow="auto" invisibleBorderWidth="0px 8px 0px 0px">
        <CommonTable
          columns={columns}
          rows={sortedUsers}
          sortConfig={sortConfig}
          onSort={(key) =>
            setSortConfig((prev) =>
              prev?.key === key
                ? { key, direction: prev.direction === "ascending" ? "descending" : "ascending" }
                : { key, direction: "ascending" },
            )
          }
          emptyMessage="No users available"
        />
      </CommonScrollbar>
    </Box>
  );
};
