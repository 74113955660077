import jwt_decode from "jwt-decode";
import { AccessType } from "../../types";

/**
 * Decodes a Cognito ID token and determines authentication status and access type.
 *
 * @param idToken - The Cognito ID token to decode.
 * @returns An object containing `authStatus`, `accessType`, `email`, and `isEmailVerified` flags.
 */
export const parseToken = (
  idToken?: string,
): {
  authStatus: boolean;
  accessType: AccessType | null;
  email: string;
  isEmailVerified: boolean;
} => {
  if (!idToken) {
    return { authStatus: false, accessType: null, email: "", isEmailVerified: false };
  }

  const decodedToken = jwt_decode(idToken) as {
    "custom:userId": string;
    "custom:organizationId": string;
    "custom:accessType": string;
    email: string;
    email_verified: boolean;
  };

  const isAuthenticated = !!decodedToken["custom:userId"] && !!decodedToken["custom:organizationId"];

  const accessType = Object.values(AccessType).includes(decodedToken["custom:accessType"] as AccessType)
    ? (decodedToken["custom:accessType"] as AccessType)
    : null;

  return {
    authStatus: isAuthenticated,
    accessType,
    email: decodedToken.email,
    isEmailVerified: decodedToken.email_verified,
  };
};

/**
 * Determines whether a Cognito ID token is expired by comparing the current time to its expiration time.
 *
 * @param idToken - The Cognito ID token to check.
 * @returns True if the Cognito ID token is expired, false otherwise.
 */
export const isTokenExpired = (idToken: string | null): boolean => {
  if (!idToken) {
    return true;
  }

  // Decode the token payload to access the expiration time
  const payload: any = jwt_decode(idToken);
  // Convert expiration time to milliseconds
  const expiryTime = payload?.exp * 1000;
  return Date.now() >= expiryTime;
};

/**
 * Refreshes the ID token by calling the `refreshIdToken` mutation.
 *
 * This function retrieves the current ID token from local storage, checks if it is expired, and if so, uses the refresh
 * token to request a new ID token from the backend.
 *
 * @param currentToken - The current ID token from local storage.
 * @param apiUri - The URI of the GraphQL API.
 * @returns A promise that resolves to the refreshed ID token.
 * @throws An error if the token refresh fails or if the response contains errors.
 */
export const refreshIdToken = async (currentToken: string, apiUri: string): Promise<string> => {
  const response = await fetch(apiUri, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      operationName: "RefreshIdToken",
      query: `
        mutation RefreshIdToken($idToken: String!) {
          refreshIdToken(idToken: $idToken)
        }
      `,
      variables: {
        idToken: currentToken,
      },
    }),
  });

  const json = await response.json();
  if (json.errors) {
    throw new Error("Token refresh failed");
  }

  const newToken = json.data.refreshIdToken;
  if (!newToken || typeof newToken !== "string") {
    throw new Error("Token refresh failed: No token returned");
  }

  localStorage.setItem("idToken", newToken);
  return newToken;
};

export const generateFullInviteLink = (inviteCode: string): string => {
  return `${window.location.origin}/create-account/?inviteCode=${inviteCode}`;
};
