import React, { useState } from "react";

import { Divider, Grid, GridItem, Stack } from "@chakra-ui/react";
import {
  TrainingSeasonStatus,
  type Coach,
  type Venue,
  type Athlete,
  type TrainingGroup,
  type TrainingSeason,
} from "../../../types";
import { CommonInput } from "../CommonInput";
import { CommonSelect } from "../CommonSelect";
import { TimePicker } from "../TimePicker";
import { VenueSelect } from "../VenueSelect";
import { CoachSelect } from "../CoachSelect";
import { sortNames } from "../../utils/dataProcessing";
import { MultiTagSelectMenu } from "../MultiTagSelectMenu";
import { CommonButton } from "../CommonButton";
import { weekdayOptions } from "../../utils/dataProcessing";

type TrainingGroupEditorProps = {
  trainingGroup: TrainingGroup;
  allCoaches: Coach[];
  allVenues: Venue[];
  allTrainingSeasons: TrainingSeason[];
  onChange: (editedEvent: TrainingGroup) => void;
  displayAthletes: boolean;
  allAthletes?: Athlete[];
  initialTrainingGroup?: TrainingGroup;
  dataTestId?: string;
};

/** Component for editing a single Training Group */
export const TrainingGroupEditor: React.FC<TrainingGroupEditorProps> = (props) => {
  const [trainingGroupToEdit, setTrainingGroupToEdit] = useState(props.trainingGroup);
  const [isCopying, setIsCopying] = useState(false);
  const [copiedTrainingGroup, setCopiedTrainingGroup] = useState<TrainingGroup | undefined>(undefined);

  const handleChange = (field: keyof TrainingGroup, value: any) => {
    setTrainingGroupToEdit((prev) => {
      const updatedTrainingGroup = { ...prev };
      switch (field) {
        case "recurrenceWeekday":
          updatedTrainingGroup.recurrenceWeekday = value as number;
          break;
        case "recurrenceStartTime":
          updatedTrainingGroup.recurrenceStartTime = value as string;
          break;
        case "recurrenceEndTime":
          updatedTrainingGroup.recurrenceEndTime = value as string;
          break;
        case "venue":
          updatedTrainingGroup.venue = props.allVenues.find((venue) => venue.id === value) as Venue;
          break;
        case "coaches":
          updatedTrainingGroup.coaches = props.allCoaches.filter((coach) =>
            (value as string[]).includes(coach.id || ""),
          );
          break;
        case "name":
          updatedTrainingGroup.name = value as string;
          break;
        case "athletes":
          updatedTrainingGroup.athletes = props.allAthletes?.filter((athlete) =>
            (value as Athlete[]).some((selectedAthlete) => selectedAthlete.id === athlete.id),
          );
          break;
        case "trainingSeason":
          updatedTrainingGroup.trainingSeason = props.allTrainingSeasons.find(
            (season) => season.id === value.id,
          ) as TrainingSeason;
          break;
      }

      props.onChange(updatedTrainingGroup);
      return updatedTrainingGroup;
    });
  };

  const initializeCopy = () => {
    if (props.initialTrainingGroup) {
      setIsCopying(true);
      const copy = {
        ...props.initialTrainingGroup,
        id: undefined,
      };
      setCopiedTrainingGroup(copy);
      props.onChange(copy);

      // reset any changes to the initial training group
      setTrainingGroupToEdit(props.initialTrainingGroup);
    }
  };

  const handleCopyTargetChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSeasonId = e.target.value;
    const selectedSeason = props.allTrainingSeasons.find((season) => season.id === selectedSeasonId) as TrainingSeason;
    const newTrainingGroup = { ...copiedTrainingGroup } as TrainingGroup;
    newTrainingGroup.trainingSeason = selectedSeason;

    setCopiedTrainingGroup(newTrainingGroup);
    props.onChange(newTrainingGroup);
  };

  const handleUpcomingSeasonNameChange = (value: string) => {
    const newTrainingGroup = { ...copiedTrainingGroup } as TrainingGroup;
    newTrainingGroup.name = value;

    setCopiedTrainingGroup(newTrainingGroup);
    props.onChange(newTrainingGroup);
  };

  return (
    <Stack direction="row" spacing="4" width="100%" data-testid={props.dataTestId}>
      <Grid
        templateColumns={props.displayAthletes ? "repeat(4, 1fr)" : "repeat(16, 1fr)"}
        gap="4"
        alignItems="center"
        width="100%"
      >
        <GridItem colSpan={props.displayAthletes ? 2 : { mobile: 16, laptop: 4 }} data-testid="input-group">
          <CommonInput
            placeholder="Group name"
            value={trainingGroupToEdit.name || ""}
            onChange={(value) => handleChange("name", value)}
            dataTestId="input-group"
            disabled={isCopying}
          />
        </GridItem>

        <GridItem colSpan={props.displayAthletes ? 2 : { mobile: 16, laptop: 4 }} data-testid="select-weekday">
          <CommonSelect
            placeholder="Weekday"
            value={trainingGroupToEdit.recurrenceWeekday}
            options={weekdayOptions.map((weekday) => ({
              value: weekday.value,
              label: weekday.label,
            }))}
            onChange={(e) => handleChange("recurrenceWeekday", parseInt(e.target.value))}
            isDisabled={isCopying}
          />
        </GridItem>

        <GridItem colSpan={props.displayAthletes ? 2 : { mobile: 8, laptop: 2 }} data-testid="select-start">
          <TimePicker
            label="Start"
            selectedTime={trainingGroupToEdit.recurrenceStartTime}
            setSelectedTime={(value: string | Date) => handleChange("recurrenceStartTime", value)}
            maxTime={trainingGroupToEdit.recurrenceEndTime}
            disabled={isCopying}
          />
        </GridItem>

        <GridItem colSpan={props.displayAthletes ? 2 : { mobile: 8, laptop: 2 }} data-testid="select-end">
          <TimePicker
            label="End"
            selectedTime={trainingGroupToEdit.recurrenceEndTime}
            setSelectedTime={(value: string | Date) => handleChange("recurrenceEndTime", value)}
            minTime={trainingGroupToEdit.recurrenceStartTime}
            disabled={isCopying}
          />
        </GridItem>

        <GridItem colSpan={props.displayAthletes ? 2 : { mobile: 16, laptop: 4 }} data-testid="select-venue">
          <VenueSelect
            venues={props.allVenues.slice().sort((a, b) => sortNames(a.name, b.name))}
            selectedVenue={trainingGroupToEdit.venue}
            setSelectedVenue={(venueId: string) => handleChange("venue", venueId)}
            disabled={isCopying}
          />
        </GridItem>

        <GridItem colSpan={props.displayAthletes ? 2 : { mobile: 16, laptop: 4 }} data-testid="select-coach">
          <CoachSelect
            availableCoaches={props.allCoaches}
            selectedCoaches={trainingGroupToEdit.coaches?.map((coach) => coach) || []}
            setSelectedCoach={(selectedCoaches) =>
              handleChange(
                "coaches",
                selectedCoaches.map((coach) => coach.id || ""),
              )
            }
            disabled={isCopying}
          />
        </GridItem>

        {props.displayAthletes && (
          <GridItem colSpan={4} data-testid="select-athletes">
            <MultiTagSelectMenu
              options={props.allAthletes || []}
              getOptionLabel={(option) => option.name}
              selectedTags={trainingGroupToEdit.athletes || []}
              handleTagSelection={(newAthletes: Athlete[]) => handleChange("athletes", newAthletes)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              label="Athletes"
              disableActive={true}
              dataTestId="athlete-select"
              disabled={isCopying}
            />
          </GridItem>
        )}

        <GridItem colSpan={props.displayAthletes ? 4 : { mobile: 16, laptop: 4 }} data-testid="select-training-season">
          <CommonSelect
            placeholder="Training Season"
            value={
              trainingGroupToEdit.trainingSeason.id ||
              props.allTrainingSeasons.find((season) => season.status === TrainingSeasonStatus.Active)?.id ||
              props.allTrainingSeasons[0]?.id
            }
            options={props.allTrainingSeasons.map((season) => ({
              value: season.id as string,
              label: season.name,
              disabled: season.status === TrainingSeasonStatus.Completed,
            }))}
            disabledOptionsToLast={true}
            onChange={(e) =>
              handleChange(
                "trainingSeason",
                props.allTrainingSeasons.find((season) => season.id === e.target.value),
              )
            }
            isDisabled={isCopying}
          />
        </GridItem>

        {props.displayAthletes && (
          <>
            <GridItem colSpan={4}>
              <Divider marginY="4" />
            </GridItem>

            <GridItem colSpan={1}>
              <CommonButton
                fullWidth
                variantType="outlineSecondary"
                onClick={initializeCopy}
                disabled={isCopying || !trainingGroupToEdit.id}
                tooltip={
                  isCopying
                    ? "Select the target Training Season and save to duplicate this Training Group"
                    : !trainingGroupToEdit.id
                      ? "Training Group can't be duplicated as it isn't created yet"
                      : "Duplicate this Training Group with the same venue, time, athletes and coach to another Training Season"
                }
              >
                Duplicate to another Training Season
              </CommonButton>
            </GridItem>
            <GridItem colSpan={1}>
              <CommonSelect
                placeholder="Target Training Season"
                value={copiedTrainingGroup?.trainingSeason?.id}
                options={props.allTrainingSeasons.map((season) => ({
                  value: season.id as string,
                  label: season.name,
                  disabled:
                    season.status === TrainingSeasonStatus.Completed ||
                    season.id === trainingGroupToEdit.trainingSeason.id ||
                    season.id === props.trainingGroup.trainingSeason.id,
                }))}
                disabledOptionsToLast={true}
                onChange={handleCopyTargetChange}
                isDisabled={!isCopying}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <CommonInput
                placeholder="Group name on upcoming season"
                value={copiedTrainingGroup?.name || ""}
                onChange={handleUpcomingSeasonNameChange}
                disabled={!isCopying}
                dataTestId="input-group"
              />
            </GridItem>
          </>
        )}
      </Grid>
    </Stack>
  );
};
