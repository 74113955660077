import { gql } from "@apollo/client";

export const GET_ATHLETE_NOTES = gql`
  query GetAthleteNotes($athleteId: String!, $page: Int = 1, $limit: Int = 10) {
    getAthleteNotes(athleteId: $athleteId, page: $page, limit: $limit) {
      totalCount
      notes {
        id
        content
        createdAt
        isGroupNote
        createdBy {
          id
          username
        }
        athletes {
          id
          name
        }
        trainingEventDetails {
          eventId
          eventStartDateTime
          eventEndDateTime
          eventCenter
          eventVenue
          eventTrainingGroupName
          eventCoaches
        }
      }
    }
  }
`;
