import { useApolloClient, useSubscription } from "@apollo/client";
import { ORGANIZATION_EVENT_SUBSCRIPTION } from "../../graphql/organization-event.subscription";
import { EVENT_TYPES, eventHandlers } from "./utils";
import { useEffect, useState } from "react";

import { GET_ALL_ATHLETES } from "../../../features/Athletes/graphql/get-all-athletes.query";
import { GET_ALL_ATHLETES_FOR_CALENDAR } from "../../../features/Calendar/graphql/get-all-athletes-for-calendar.query";
import { GET_ALL_ATHLETES_FOR_MANAGEMENT } from "../../../features/Organization/graphql/get-all-athletes-for-management.query";
import { GET_ALL_ATHLETES_FOR_SEASON_PLAN } from "../../../features/SeasonPlans/graphql/get-all-athletes-for-season-plan.query";
import { GET_ALL_CENTERS } from "../../graphql/get-all-centers.query";
import { GET_ALL_COACHES } from "../../graphql/get-all-coaches.query";
import { GET_ALL_DRILLS } from "../../graphql/get-all-drills.query";
import { GET_GENERIC_EVALUATION_CRITERIA } from "../../../features/SkillSets/graphql/get-generic-evaluation-criteria.query";
import { GET_SKILL_SET_EVALUATION_CRITERIA } from "../../../features/SkillSets/graphql/get-skill-set-evaluation-criteria.query";
import { GET_USER_ORGANIZATION } from "../../graphql/get-user-organization.query";
import { GET_ALL_ORGANIZATION_INVITES } from "../../../features/Organization/graphql/get-all-organization-invites.query";
import { GET_ALL_SEASON_PLANS } from "../../../features/SeasonPlans/graphql/get-all-season-plans.query";
import { GET_ALL_SESSIONS } from "../../graphql/get-all-sessions.query";
import { GET_ALL_SINGLE_TRAINING_EVENTS } from "../../../features/Calendar/graphql/get-all-single-training-events.query";
import { GET_ALL_SKILL_SETS } from "../../graphql/get-all-skill-sets.query";
import { GET_ALL_TRAINING_GROUPS } from "../../graphql/get-all-training-groups.query";
import { GET_ALL_TRAINING_GROUPS_FOR_SEASON_PLAN } from "../../../features/SeasonPlans/graphql/get-all-training-groups-for-season-plan.query";
import { GET_ALL_TRAINING_GROUP_EVENTS } from "../../graphql/get-all-training-group-events.query";
import { GET_ALL_TRAINING_GROUP_EVENTS_FOR_CALENDAR } from "../../../features/Calendar/graphql/get-all-training-group-events.query";
import { GET_ALL_TRAINING_GROUP_EVENTS_FOR_SEASON_PLAN } from "../../../features/SeasonPlans/graphql/get-all-training-group-events-for-season-plan.query";
import { GET_ALL_TRAINING_SEASONS } from "../../../features/Organization/graphql/get-all-training-seasons.query";
import { GET_ALL_VENUES } from "../../../features/Organization/graphql/get-all-venues.query";
import { handleError } from "../../hooks/utils/handleErrors";
import { useAlert } from "../AlertProvider";
import { webSocketEmitter } from "../../../webSocketEvents";
import { useAuth } from "../../../features/Auth/components/AuthProvider";
import { GET_BILLING_INFO } from "../../../features/Organization/graphql/get-billing-info.query";

const RECONNECT_TIMEOUT = 60000;

/** React component that listens to global events for the current organization */
export const OrganizationEventListener: React.FC = () => {
  const client = useApolloClient();
  const { showAlert, hideAlert } = useAlert();
  const { accessType } = useAuth();

  const [hasError, setHasError] = useState(false);
  const [isConnected, setIsConnected] = useState(true);
  const [wasDisconnected, setWasDisconnected] = useState(false);

  useEffect(() => {
    const handler = (status: boolean) => {
      setIsConnected(status);
    };
    webSocketEmitter.on("connectionStatusChanged", handler);

    return () => {
      webSocketEmitter.off("connectionStatusChanged", handler);
    };
  }, []);

  let timeoutId: ReturnType<typeof setTimeout>;
  useEffect(() => {
    if (!isConnected) {
      if (wasDisconnected) {
        showAlert(
          "Connection lost, reconnecting automatically. Live data is unavailable for now",
          "warning",
          undefined,
          true,
        );
      }
      setWasDisconnected(true);

      // show alert if connection is not restored after RECONNECT_TIMEOUT
      timeoutId = setTimeout(() => {
        if (!isConnected) {
          hideAlert();
          showAlert(
            "Connection lost, live data is unavailable. Try refreshing the page or logging out and back in if the problem persists",
            "error",
          );
        }
      }, RECONNECT_TIMEOUT);
    } else {
      clearTimeout(timeoutId);
      if (wasDisconnected) {
        showAlert("Reconnected successfully, live data is available again", "success", 3000);

        setWasDisconnected(false);
      }
    }

    if (hasError && isConnected) {
      showAlert("Reconnected successfully, live data is available again.", "success", 3000);
      setHasError(false);
    }

    // cleanup
    return () => clearTimeout(timeoutId);
  }, [hasError, isConnected, wasDisconnected]);

  /**
   * Checks if the given type is a valid key of EVENT_TYPES.
   *
   * @param type - The type to check.
   * @returns True if the type is a valid key of EVENT_TYPES, false otherwise.
   */
  const isEventType = (type: any): type is keyof typeof EVENT_TYPES => type in EVENT_TYPES;

  useSubscription(ORGANIZATION_EVENT_SUBSCRIPTION, {
    onData: async ({ data }) => {
      const eventType = data?.data?.organizationEvent?.type;
      const entityId = data?.data?.organizationEvent?.entityId;

      if (isEventType(eventType) && eventHandlers[eventType] && accessType) {
        await eventHandlers[eventType](accessType, client, entityId);
      }
    },
    onError(error) {
      setHasError(true);
      const { hasAuthenticationError } = handleError([error]);
      if (hasAuthenticationError) {
        showAlert(
          "Connection lost, reconnecting automatically. You can refresh the page to load the latest data if needed",
          "warning",
          undefined,
          true,
        );
      } else if (error.message === "Socket closed") {
        showAlert(
          "Connection lost, reconnecting automatically. You can refresh the page to load the latest data if needed",
          "warning",
          undefined,
          true,
        );
      } else {
        showAlert(
          "An issue occurred with the connection. Try refreshing the page or logging out and back in if the problem persists",
          "warning",
        );
      }
    },
  });

  useEffect(() => {
    // make queries known for apollo client by wathcQuery so they can be refetched without errors

    const queries = [
      GET_ALL_ATHLETES,
      GET_ALL_ATHLETES_FOR_CALENDAR,
      GET_ALL_ATHLETES_FOR_MANAGEMENT,
      GET_ALL_ATHLETES_FOR_SEASON_PLAN,
      GET_ALL_CENTERS,
      GET_ALL_COACHES,
      GET_ALL_DRILLS,
      GET_GENERIC_EVALUATION_CRITERIA,
      GET_SKILL_SET_EVALUATION_CRITERIA,
      GET_USER_ORGANIZATION,
      GET_ALL_ORGANIZATION_INVITES,
      GET_ALL_SEASON_PLANS,
      GET_ALL_SESSIONS,
      GET_ALL_SINGLE_TRAINING_EVENTS,
      GET_ALL_SKILL_SETS,
      GET_ALL_TRAINING_GROUPS,
      GET_ALL_TRAINING_GROUPS_FOR_SEASON_PLAN,
      GET_ALL_TRAINING_GROUP_EVENTS,
      GET_ALL_TRAINING_GROUP_EVENTS_FOR_CALENDAR,
      GET_ALL_TRAINING_GROUP_EVENTS_FOR_SEASON_PLAN,
      GET_ALL_TRAINING_SEASONS,
      GET_ALL_VENUES,
      GET_BILLING_INFO,
    ];

    queries.forEach((query) => {
      client.watchQuery({
        query,
      });
    });
  }, [client]);

  return null;
};
