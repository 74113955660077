import { gql } from "@apollo/client";

export const ORGANIZATION_EVENT_SUBSCRIPTION = gql`
  subscription {
    organizationEvent {
      type
      entityId
    }
  }
`;
