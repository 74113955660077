import type { ApolloClient, DocumentNode } from "@apollo/client";

import { GET_ALL_ATHLETES } from "../../../features/Athletes/graphql/get-all-athletes.query";
import { GET_ALL_ATHLETES_FOR_CALENDAR } from "../../../features/Calendar/graphql/get-all-athletes-for-calendar.query";
import { GET_ALL_ATHLETES_FOR_MANAGEMENT } from "../../../features/Organization/graphql/get-all-athletes-for-management.query";
import { GET_ALL_ATHLETES_FOR_SEASON_PLAN } from "../../../features/SeasonPlans/graphql/get-all-athletes-for-season-plan.query";

import { GET_ALL_CENTERS } from "../../graphql/get-all-centers.query";

import { GET_ALL_COACHES } from "../../graphql/get-all-coaches.query";

import { GET_ALL_DRILLS } from "../../graphql/get-all-drills.query";

import { GET_GENERIC_EVALUATION_CRITERIA } from "../../../features/SkillSets/graphql/get-generic-evaluation-criteria.query";

import { GET_USER_ORGANIZATION } from "../../graphql/get-user-organization.query";

import { GET_ALL_ORGANIZATION_INVITES } from "../../../features/Organization/graphql/get-all-organization-invites.query";

import { GET_ALL_SEASON_PLANS } from "../../../features/SeasonPlans/graphql/get-all-season-plans.query";

import { GET_ALL_SESSIONS } from "../../graphql/get-all-sessions.query";

import { GET_ALL_SINGLE_TRAINING_EVENTS } from "../../../features/Calendar/graphql/get-all-single-training-events.query";

import { GET_ALL_SKILL_SETS } from "../../graphql/get-all-skill-sets.query";

import { GET_ALL_TRAINING_GROUPS } from "../../graphql/get-all-training-groups.query";
import { GET_ALL_TRAINING_GROUPS_FOR_SEASON_PLAN } from "../../../features/SeasonPlans/graphql/get-all-training-groups-for-season-plan.query";

import { GET_ALL_TRAINING_GROUP_EVENTS } from "../../graphql/get-all-training-group-events.query";
import { GET_ALL_TRAINING_GROUP_EVENTS_FOR_CALENDAR } from "../../../features/Calendar/graphql/get-all-training-group-events.query";
import { GET_ALL_TRAINING_GROUP_EVENTS_FOR_SEASON_PLAN } from "../../../features/SeasonPlans/graphql/get-all-training-group-events-for-season-plan.query";

import { GET_ALL_TRAINING_SEASONS } from "../../../features/Organization/graphql/get-all-training-seasons.query";
import { GET_ALL_VENUES } from "../../../features/Organization/graphql/get-all-venues.query";

import { GET_ATHLETE_NOTES } from "../../../features/Athletes/graphql/get-athlete-notes.query";
import { GET_ATHLETE_EVALUATION } from "../../../features/Athletes/graphql/get-athlete-evaluation.query";

import { GET_USER_ATHLETE } from "../../../features/Athletes/graphql/get-user-athlete.query";

import { AccessType } from "../../../types";
import { GET_ATHLETE_LOGS } from "../../../features/Athletes/graphql/get-athlete-logs.query";
import { GET_ATHLETE_TRAINING_GROUPS } from "../../../features/Athletes/graphql/get-athlete-training-groups.query";
import { LOGS_PAGINATION_LIMIT, NOTES_PAGINATION_LIMIT } from "../../../features/Athletes/containers/AthleteContainer";
import { GET_BILLING_INFO } from "../../../features/Organization/graphql/get-billing-info.query";

export const EVENT_TYPES = {
  ATHLETE_EVENT: "ATHLETE_EVENT",
  CENTER_EVENT: "CENTER_EVENT",
  COACH_EVENT: "COACH_EVENT",
  DRILL_EVENT: "DRILL_EVENT",
  EVALUATION_CRITERIA_EVENT: "EVALUATION_CRITERIA_EVENT",
  EVALUATION_EVENT: "EVALUATION_EVENT",
  LOG_EVENT: "LOG_EVENT",
  NOTE_EVENT: "NOTE_EVENT",
  ORGANIZATION_EVENT: "ORGANIZATION_EVENT",
  SEASON_PLAN_EVENT: "SEASON_PLAN_EVENT",
  SESSION_EVENT: "SESSION_EVENT",
  SINGLE_TRAINING_EVENT_EVENT: "SINGLE_TRAINING_EVENT_EVENT",
  SKILL_SET_EVENT: "SKILL_SET_EVENT",
  TRAINING_GROUP_EVENT: "TRAINING_GROUP_EVENT",
  TRAINING_GROUP_EVENT_EVENT: "TRAINING_GROUP_EVENT_EVENT",
  TRAINING_SEASON_EVENT: "TRAINING_SEASON_EVENT",
  USER_EVENT: "USER_EVENT",
  VENUE_EVENT: "VENUE_EVENT",
} as const;

const allowedQueriesByAccessType: Record<AccessType, DocumentNode[] | "all"> = {
  [AccessType.Admin]: "all",
  [AccessType.Owner]: "all",
  [AccessType.Full]: "all",
  [AccessType.Limited]: "all",
  [AccessType.Athlete]: [
    GET_USER_ORGANIZATION,
    GET_USER_ATHLETE,
    GET_ALL_SKILL_SETS,
    GET_GENERIC_EVALUATION_CRITERIA,
    GET_ALL_TRAINING_SEASONS,
    GET_ALL_COACHES,
    GET_ALL_CENTERS,
  ],
};

/**
 * Handles refetching for known queries based on access type.
 *
 * @param client - Apollo Client instance.
 * @param queries - Array of GraphQL queries to refetch.
 * @param accessType - The access type of the user.
 */
const handleQueries = async (accessType: AccessType, client: ApolloClient<object>, queries: DocumentNode[]) => {
  const allowedQueries = allowedQueriesByAccessType[accessType];

  // If all queries are allowed, skip filtering
  const filteredQueries =
    allowedQueries === "all" ? queries : queries.filter((query) => allowedQueries.includes(query));

  try {
    await client.refetchQueries({ include: filteredQueries });
  } catch {
    // catch silently, errors are handled through useHandleQueryErrors / initial useQuery
  }
};

/** Event handlers for different organization events. */
export const eventHandlers: Record<
  keyof typeof EVENT_TYPES,
  (accessType: AccessType, client: ApolloClient<object>, entityId?: string) => Promise<void>
> = {
  ATHLETE_EVENT: async (accessType, client) => {
    await handleQueries(accessType, client, [
      GET_ALL_ATHLETES,
      GET_ALL_ATHLETES_FOR_CALENDAR,
      GET_ALL_ATHLETES_FOR_MANAGEMENT,
      GET_ALL_ATHLETES_FOR_SEASON_PLAN,
      GET_ALL_TRAINING_GROUP_EVENTS,
      GET_ALL_TRAINING_GROUPS,
      ...(accessType === AccessType.Athlete ? [GET_USER_ATHLETE] : []),
    ]);
  },
  CENTER_EVENT: async (accessType, client) => {
    await handleQueries(accessType, client, [GET_ALL_CENTERS, GET_ALL_TRAINING_GROUPS, GET_ALL_TRAINING_GROUP_EVENTS]);
  },
  COACH_EVENT: async (accessType, client) => {
    await handleQueries(accessType, client, [GET_ALL_COACHES]);
  },
  DRILL_EVENT: async (accessType, client) => {
    await handleQueries(accessType, client, [GET_ALL_DRILLS]);
  },
  EVALUATION_CRITERIA_EVENT: async (accessType, client) => {
    await handleQueries(accessType, client, [GET_GENERIC_EVALUATION_CRITERIA]);
  },
  EVALUATION_EVENT: async (_accessType, client, entityId) => {
    if (entityId) {
      await client.query({ query: GET_ATHLETE_EVALUATION, variables: { id: entityId }, fetchPolicy: "network-only" });
    }
  },
  LOG_EVENT: async (_accessType, client, entityId) => {
    if (entityId) {
      await client.query({
        query: GET_ATHLETE_LOGS,
        variables: { athleteId: entityId, page: 1, limit: LOGS_PAGINATION_LIMIT },
        fetchPolicy: "network-only",
      });
    }
  },
  NOTE_EVENT: async (_accessType, client, entityId) => {
    if (entityId) {
      await client.query({
        query: GET_ATHLETE_NOTES,
        variables: { athleteId: entityId, page: 1, limit: NOTES_PAGINATION_LIMIT },
        fetchPolicy: "network-only",
      });
    }
  },
  ORGANIZATION_EVENT: async (accessType, client) => {
    await handleQueries(accessType, client, [GET_USER_ORGANIZATION, GET_BILLING_INFO]);
  },
  SEASON_PLAN_EVENT: async (accessType, client) => {
    await handleQueries(accessType, client, [GET_ALL_SEASON_PLANS, GET_ALL_TRAINING_GROUP_EVENTS_FOR_SEASON_PLAN]);
  },
  SESSION_EVENT: async (accessType, client) => {
    await handleQueries(accessType, client, [GET_ALL_SESSIONS]);
  },
  SINGLE_TRAINING_EVENT_EVENT: async (accessType, client) => {
    await handleQueries(accessType, client, [
      GET_ALL_SINGLE_TRAINING_EVENTS,
      GET_ALL_TRAINING_GROUP_EVENTS_FOR_CALENDAR,
      GET_ALL_ATHLETES_FOR_CALENDAR,
    ]);
  },
  SKILL_SET_EVENT: async (accessType, client) => {
    await handleQueries(accessType, client, [GET_ALL_SKILL_SETS]);
  },
  TRAINING_GROUP_EVENT: async (accessType, client, entityId) => {
    await handleQueries(accessType, client, [
      GET_ALL_TRAINING_GROUPS,
      GET_ALL_TRAINING_GROUPS_FOR_SEASON_PLAN,
      GET_ALL_ATHLETES,
      GET_ALL_TRAINING_GROUP_EVENTS,
      GET_ALL_ATHLETES_FOR_MANAGEMENT,
      GET_ALL_TRAINING_GROUP_EVENTS_FOR_CALENDAR,
    ]);

    if (entityId) {
      await client.query({
        query: GET_ATHLETE_TRAINING_GROUPS,
        variables: { athleteId: entityId },
        fetchPolicy: "network-only",
      });
    }
  },
  TRAINING_GROUP_EVENT_EVENT: async (accessType, client) => {
    await handleQueries(accessType, client, [
      GET_ALL_TRAINING_GROUP_EVENTS,
      GET_ALL_TRAINING_GROUP_EVENTS_FOR_CALENDAR,
      GET_ALL_TRAINING_GROUP_EVENTS_FOR_SEASON_PLAN,
      GET_ALL_SINGLE_TRAINING_EVENTS,
      GET_ALL_ATHLETES_FOR_CALENDAR,
    ]);
  },
  TRAINING_SEASON_EVENT: async (accessType, client) => {
    await handleQueries(accessType, client, [
      GET_ALL_TRAINING_SEASONS,
      GET_ALL_TRAINING_GROUPS,
      GET_ALL_ATHLETES_FOR_MANAGEMENT,
      GET_ALL_TRAINING_GROUP_EVENTS,
      GET_ALL_TRAINING_GROUP_EVENTS,
      GET_ALL_TRAINING_GROUP_EVENTS_FOR_CALENDAR,
      GET_ALL_TRAINING_GROUP_EVENTS_FOR_SEASON_PLAN,
    ]);
  },
  USER_EVENT: async (accessType, client) => {
    await handleQueries(accessType, client, [GET_USER_ORGANIZATION, GET_ALL_ORGANIZATION_INVITES]);
  },
  VENUE_EVENT: async (accessType, client) => {
    await handleQueries(accessType, client, [
      GET_ALL_VENUES,
      GET_ALL_CENTERS,
      GET_ALL_TRAINING_GROUPS,
      GET_ALL_TRAINING_GROUP_EVENTS,
    ]);
  },
};
