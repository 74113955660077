import React from "react";
import { createRoot, type Root } from "react-dom/client";
import App from "./App";
import { ApolloProvider, split } from "@apollo/client";
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { AlertProvider } from "./common/components/AlertProvider";
import { GlobalProvider } from "./common/components/GlobalProvider";
import { theme } from "./theme";
import { ChakraProvider } from "@chakra-ui/react";
import { ErrorBoundary } from "./common/components/AlertProvider/components/ErrorBoundary";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from "crypto-hash";
import { AuthProvider } from "./features/Auth/components/AuthProvider";
import { isTokenExpired, refreshIdToken } from "./features/Auth/utils";
import { webSocketEmitter } from "./webSocketEvents";

const uri = process.env.NODE_ENV === "production" ? "https://api.striveon.cloud/" : "http://localhost:4000/";

const httpLink = new HttpLink({
  uri: uri,
  credentials: "include",
});

let isRefreshing = false;
let refreshPromise: Promise<string | null> | null = null;
const authLink = setContext(async (_, { headers }) => {
  let idToken = localStorage.getItem("idToken");

  if (idToken && isTokenExpired(idToken)) {
    try {
      // If a refresh is already in progress, wait for it to complete
      if (isRefreshing) {
        if (refreshPromise) {
          idToken = await refreshPromise;
        }
      } else {
        // Initiate a new refresh if not already in progress
        isRefreshing = true;

        refreshPromise = refreshIdToken(idToken, uri)
          .then((newToken) => {
            isRefreshing = false;
            refreshPromise = null;
            return newToken;
          })
          .catch(() => {
            isRefreshing = false;
            refreshPromise = null;
            return null;
          });

        idToken = await refreshPromise;
      }
    } catch {
      // catch silently, user will be automatically logged out due to expired idToken
    }
  }

  return {
    headers: {
      ...headers,
      authorization: idToken ? `Bearer ${idToken}` : "",
    },
  };
});

const wsLink = new GraphQLWsLink(
  createClient({
    url:
      process.env.NODE_ENV === "production"
        ? "wss://api.striveon.cloud/subscriptions"
        : "ws://localhost:4000/subscriptions",
    connectionParams: () => {
      const idToken = localStorage.getItem("idToken");
      return { Authorization: idToken ? `Bearer ${idToken}` : "" };
    },
    on: {
      connected: () => webSocketEmitter.emit("connectionStatusChanged", true),
      closed: () => webSocketEmitter.emit("connectionStatusChanged", false),
      error: () => webSocketEmitter.emit("connectionStatusChanged", false),
    },
    retryAttempts: 10,
    shouldRetry: () => true,
    lazy: true,
  }),
);

const persistedQueriesLink = createPersistedQueryLink({ sha256 }).concat(authLink.concat(httpLink));

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  persistedQueriesLink,
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  connectToDevTools: false,
});

let root: Root;
const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error("Root element not found");
}

if (!window.__root__) {
  root = createRoot(rootElement);
  window.__root__ = root;
} else {
  root = window.__root__;
}

root.render(
  <ApolloProvider client={client}>
    <GlobalProvider>
      <ChakraProvider theme={theme}>
        <AlertProvider>
          <ErrorBoundary>
            <AuthProvider>
              <App />
            </AuthProvider>
          </ErrorBoundary>
        </AlertProvider>
      </ChakraProvider>
    </GlobalProvider>
  </ApolloProvider>,
);

if ((module as any).hot) {
  (module as any).hot.accept();
}
