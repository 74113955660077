import { gql } from "@apollo/client";

export const GET_ATHLETE_EVALUATION = gql`
  query GetAthleteEvaluation($id: String!) {
    getAthleteEvaluation(id: $id) {
      id
      overallRating
      skillSetEvaluations {
        id
        rating
        comment
        skillSet {
          id
          name
        }
        skillEvaluations {
          id
          rating
          skill {
            id
            name
          }
        }
      }
    }
  }
`;
