import React from "react";
import { Box } from "@chakra-ui/react";
import { useAsyncMutation } from "../../../common/hooks";
import { REQUEST_EMAIL_VERIFICATION_CODE } from "../graphql/request-email-verification-code.mutation";
import { VERIFY_EMAIL_WITH_CODE } from "../graphql/verify-email-with-code.mutation";
import { Account } from "../components/Account";
import { useAuth } from "../../Auth/components/AuthProvider";
import { ComponentContainer } from "../../../common/components/ComponentContainer";
import { useAlert } from "../../../common/components/AlertProvider";
import { refreshIdToken } from "../../Auth/utils";

export const AccountContainer: React.FC = () => {
  const { execute: requestEmailVerificationCode, loading: requestEmailLoading } = useAsyncMutation(
    REQUEST_EMAIL_VERIFICATION_CODE,
  );
  const { execute: verifyEmailWithCode, loading: verifyEmailLoading } = useAsyncMutation(VERIFY_EMAIL_WITH_CODE, {
    onCustomError(error) {
      if (error.message.includes("Invalid")) {
        return error.message;
      } else {
        return undefined;
      }
    },
  });

  const isLoading = requestEmailLoading || verifyEmailLoading;

  const { email, isEmailVerified, setIsEmailVerified } = useAuth();
  const { showAlert } = useAlert();

  const handleSendVerificationEmail = async (): Promise<void> => {
    showAlert("Sending verification email", "info", undefined, true);
    const accessToken = localStorage.getItem("accessToken");
    const result = await requestEmailVerificationCode({ accessToken: accessToken });

    if (result) {
      showAlert("Verification email sent, please check your inbox", "success");
    }
  };

  const handleVerifyEmail = async (code: string): Promise<void> => {
    showAlert("Verifying email", "info", undefined, true);
    const accessToken = localStorage.getItem("accessToken");
    const result = await verifyEmailWithCode({ accessToken: accessToken, code: code });

    if (result) {
      showAlert("Email verified", "success", 5000);
      setIsEmailVerified(true);

      // Refresh the ID token to reflect the updated email verification status
      const idToken = localStorage.getItem("idToken");
      if (idToken) {
        const uri = process.env.NODE_ENV === "production" ? "https://api.striveon.cloud/" : "http://localhost:4000/";
        refreshIdToken(idToken, uri);
      }
    }
  };

  return (
    <Box>
      <ComponentContainer>
        <Account
          userEmail={email}
          isEmailVerified={isEmailVerified}
          loading={isLoading}
          onSendVerificationEmail={handleSendVerificationEmail}
          onVerifyEmail={handleVerifyEmail}
        />
      </ComponentContainer>
    </Box>
  );
};
