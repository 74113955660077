import type { Drill, Session, SkillSet } from "../../types";

/* Common weekdays to use with value & label, starting from monday */
export const weekdayOptions = [
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
  { value: 0, label: "Sunday" },
];

/**
 * Filters the provided sessions based on a search query and tags.
 *
 * The session matches the search query if the query is included in the session's name, goal, or any of the drills'
 * names. The comparison is case insensitive.
 *
 * The session matches the tags if it has any of the provided tags. The comparison is case insensitive.
 *
 * A session is included in the results if it matches both the search query and the tags.
 *
 * @param {Session[]} sessions - The sessions to filter.
 * @param {string} searchQuery - The search query.
 * @param {string[]} searchTags - The tags to filter by.
 * @param {SkillSet[]} searchSkillSets - The skill sets to filter by.
 * @returns {Session[]} The filtered sessions.
 */
export const filterSessions = (
  sessions: Session[],
  searchQuery: string,
  searchTags: string[],
  searchSkillSets: SkillSet[],
) => {
  return sessions.filter((session) => {
    // Check if the session matches the search query
    const matchesSearchQuery =
      searchQuery === "" ||
      session.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      session.goal.toLowerCase().includes(searchQuery.toLowerCase()) ||
      session.sessionDrills.some((sessionDrill) =>
        sessionDrill.drill.name.toLowerCase().includes(searchQuery.toLowerCase()),
      );

    // Check if the session matches the search tags
    const matchesSearchTags = searchTags.every((searchTag) => session.tags?.includes(searchTag));
    // Check if the session matches the search skill sets
    const matchesSearchSkillSets = searchSkillSets.every((searchSkillSet) =>
      session.skillSets?.some((sessionSkillSet) => sessionSkillSet.id === searchSkillSet.id),
    );

    // Return the session if it matches all the search criteria
    return matchesSearchQuery && matchesSearchTags && matchesSearchSkillSets;
  });
};

/**
 * Filters the provided drills based on a search query, tags, and skill sets.
 *
 * The drill matches the search query if the query is included in the drill's name, goal, or implementation. The
 * comparison is case insensitive.
 *
 * The drill matches the tags if it has any of the provided tags. The comparison is case insensitive.
 *
 * The drill matches the skill sets if it contains any of the provided skill sets.
 *
 * A drill is included in the results if it matches the search query, tags, and skill sets.
 *
 * @param {Drill[]} drills - The drills to filter.
 * @param {string} searchQuery - The search query.
 * @param {string[]} searchTags - The tags to filter by.
 * @param {SkillSet[]} searchSkillSets - The skill sets to filter by.
 * @returns {Drill[]} The filtered drills.
 */
export const filterDrills = (
  drills: Drill[],
  searchQuery: string,
  searchTags: string[],
  searchSkillSets: SkillSet[],
): Drill[] => {
  return drills.filter((drill) => {
    // Check if the drill matches the search query
    const matchesSearchQuery =
      searchQuery === "" ||
      drill.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      drill.goal?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      drill.implementation?.toLowerCase().includes(searchQuery.toLowerCase());

    // Check if the drill matches the search tags
    const matchesSearchTags = searchTags.every((searchTag) => drill.tags?.includes(searchTag));

    // Check if the drill matches the search skill sets
    const matchesSearchSkillSets = searchSkillSets.every((searchSkillSet) =>
      drill.skillSets?.some((drillSkillSet) => drillSkillSet.id === searchSkillSet.id),
    );

    // Return the drill if it matches all the search criteria
    return matchesSearchQuery && matchesSearchTags && matchesSearchSkillSets;
  });
};

/**
 * Sorts two strings by combining lexical and numerical sorting.
 *
 * @remarks
 *   This function breaks down the input strings into numeric and non-numeric segments. It goes through each segment of
 *   both strings and compares them either lexically or numerically, depending on the type of the segment.
 * @example
 *   ```typescript
 *   const arr = ['Venue 10', 'Venue 2', 'Venue 1'];
 *   arr.sort(sortNames);
 *   console.log(arr); // Output: ["Venue 1", "Venue 2", "Venue 10"]
 *   ```;
 *
 * @param a - The first string to sort.
 * @param b - The second string to sort.
 * @returns Returns a sorting value. Negative means `a` comes before `b`. Positive means `b` comes before `a`. Zero
 *   means they are equivalent.
 */
export const sortNames = (a: string, b: string): number => {
  const regex = /(\d+|\D+)/g;
  const aFragments = a.match(regex);
  const bFragments = b.match(regex);

  if (!aFragments || !bFragments) return 0;

  for (let i = 0; i < Math.min(aFragments.length, bFragments.length); i++) {
    if (isNaN(Number(aFragments[i])) || isNaN(Number(bFragments[i]))) {
      const lexicalOrder = aFragments[i].localeCompare(bFragments[i]);
      if (lexicalOrder !== 0) return lexicalOrder;
    } else {
      const numericOrder = Number(aFragments[i]) - Number(bFragments[i]);
      if (numericOrder !== 0) return numericOrder;
    }
  }
  return aFragments.length - bFragments.length;
};

/** Represents the result of the validation and change checking. */
export type ValidationResult = {
  /** A string containing reasons why the form cannot be submitted, or undefined if valid. */
  submitDisabledReasons?: string;
  /** A boolean indicating if there are any changes. */
  hasChanges: boolean;
};

/**
 * Validates the provided data and checks if changes have been made compared to the initial state. Returns either the
 * reasons why submission is disabled (if any) and whether changes exist.
 *
 * @param currentData - An object or data structure representing the current state of form fields.
 * @param initialData - An object or data structure representing the initial or saved state of form fields.
 * @param validationLogic - A function that implements domain-specific validation logic. It returns an array of reason
 *   strings if invalid, or an empty array if valid.
 * @param compareLogic - A function that checks if there have been changes between currentData and initialData.
 * @returns ValidationResult
 */
export const validateAndCheckChanges = <T>(
  currentData: T,
  validationLogic: (current: T) => string[],
  initialData?: T,
  compareLogic?: (current: T, initial: T) => boolean,
): ValidationResult => {
  const reasons = validationLogic(currentData);
  const hasChanges = compareLogic && initialData ? compareLogic(currentData, initialData) : true;

  return {
    submitDisabledReasons: reasons.length > 0 ? `Please address the following:\n- ${reasons.join("\n- ")}` : undefined,
    hasChanges,
  };
};
