import React, { createContext, useContext, useState } from "react";
import type { AccessType } from "../../../types";

type AuthContextProps = {
  isAuthenticated: boolean;
  accessType: AccessType | null;
  email: string;
  isEmailVerified: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  setAccessType: React.Dispatch<React.SetStateAction<AccessType | null>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setIsEmailVerified: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accessType, setAccessType] = useState<AccessType | null>(null);
  const [email, setEmail] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        accessType,
        email,
        isEmailVerified,
        setIsAuthenticated,
        setAccessType,
        setEmail,
        setIsEmailVerified,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
