import { gql } from "@apollo/client";

export const GET_ORGANIZATION_BY_ID = gql`
  query GetOrganizationById($id: String!) {
    getOrganizationById(id: $id) {
      id
      name
      users {
        id
        username
      }
    }
  }
`;
