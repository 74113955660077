import { gql } from "@apollo/client";

export const GET_ALL_ORGANIZATION_INVITES = gql`
  query GetAllOrganizationInvites {
    getAllOrganizationInvites {
      id
      inviteCode
      receiver
      used
      usedAt
      createdAt
      type
    }
  }
`;
