import { gql } from "@apollo/client";

export const CANCEL_ORGANIZATION_DELETION = gql`
  mutation CancelOrganizationDeletion {
    cancelOrganizationDeletion {
      id
      status
      pendingDeletionAt
    }
  }
`;
