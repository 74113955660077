import React, { ReactNode } from "react";
import { Table, TableContainer, Tbody, Td, Text, Thead, Tr, Box, type SystemStyleObject } from "@chakra-ui/react";
import { TriangleUpIcon, TriangleDownIcon } from "@chakra-ui/icons";

/**
 * A type representing a responsive string value:
 *
 * - A single string (e.g. "30%") for all breakpoints
 * - An object with custom breakpoints { mobile?: string; laptop?: string } if you want different widths per breakpoint.
 */
type ResponsiveString =
  | string
  | {
      /** Width on "mobile" breakpoint. */
      mobile?: string;
      /** Width on "laptop" breakpoint. */
      laptop?: string;
    };

/**
 * Defines the structure of a table column.
 *
 * @template T - The type of data being displayed in the table.
 */
export type Column<T> = {
  /** Label to display in the column header. */
  label: string;
  /** Key corresponding to the property in the data object. */
  key: keyof T;
  /**
   * Width of the column. It can be a single string (e.g., "30%") or an object with 'mobile'/'laptop' keys. If an object
   * is not given, a single string value will be used for all breakpoints.
   */
  width?: ResponsiveString;
  /** Whether the column is sortable. */
  sortable?: boolean;
  /** Custom renderer function for the cell content. */
  render?: (row: T) => ReactNode;
  /** Override default paddingY 4 for column. */
  paddingY?: string;
};

/**
 * Configuration for sorting the table.
 *
 * @template T - The type of data being displayed in the table.
 */
export type SortConfig<T> = {
  /** Key corresponding to the property to sort by. */
  key: keyof T;
  /** Sorting direction: "ascending" or "descending". */
  direction: "ascending" | "descending";
};

/**
 * Props for the CommonTable component.
 *
 * @template T - The type of data being displayed in the table.
 */
type CommonTableProps<T> = {
  /** Array of column definitions. */
  columns: Column<T>[];
  /** Array of data rows to display. */
  rows: T[];
  /**
   * Callback function to apply custom styles to table rows.
   *
   * @param row - The data object for the current row.
   * @returns A Chakra-UI style object for the row.
   */
  getRowStyle?: (row: T) => SystemStyleObject;
  /**
   * Configuration for the current sorting state.
   *
   * @example
   *   { "key": "name", "direction": "ascending" }
   */
  sortConfig?: SortConfig<T>;
  /**
   * Callback function triggered when a sortable column is clicked.
   *
   * @param key - The key of the column to sort by.
   */
  onSort?: (key: keyof T) => void;
  /**
   * Callback function triggered when a row is clicked.
   *
   * @param row - The data object for the clicked row.
   */
  onRowClick?: (row: T) => void;
  /** Message to display when there are no rows. */
  emptyMessage?: string;
  /** Test ID for testing purposes. */
  testId?: string;
};

/**
 * CommonTable component renders a table with customizable columns, sorting, and row actions.
 *
 * @template T - The type of data being displayed in the table.
 * @param props - Properties to configure the table.
 */
export const CommonTable = <T,>({
  columns,
  rows,
  getRowStyle,
  sortConfig,
  onSort,
  onRowClick,
  emptyMessage = "No data available",
  testId,
}: CommonTableProps<T>) => {
  const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: T) => {
    event.preventDefault();
    event.stopPropagation();
    onRowClick?.(row);
  };

  return (
    <TableContainer overflowX="auto" paddingX={{ mobile: "0", laptop: "6" }} paddingBottom="6">
      <Table size="lg" variant="unstyled" layout="fixed" data-testid={testId}>
        <Thead>
          <Tr>
            {columns.map((column) => (
              <Td
                key={column.key as string}
                /** If 'width' is a string, we use it as-is; if it's an object, we pass the mobile/laptop values. */
                width={
                  typeof column.width === "object"
                    ? {
                        mobile: column.width.mobile,
                        laptop: column.width.laptop,
                      }
                    : column.width
                }
                fontSize="md"
                textTransform="uppercase"
                paddingY={{ mobile: "2", laptop: "4" }}
                paddingX={{ mobile: "4", laptop: "8" }}
                transition="all 0.3s ease"
                borderBottom="1px solid"
                borderColor="blackAlpha.200"
                onClick={() => column.sortable && onSort?.(column.key)}
                _hover={column.sortable ? { color: "orange.400", cursor: "pointer" } : undefined}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  color={sortConfig?.key === column.key ? "orange.400" : "inherit"}
                >
                  {column.label}
                  {column.sortable && (
                    <Box marginLeft="1" display="flex" alignItems="center">
                      {sortConfig?.key === column.key ? (
                        sortConfig.direction === "ascending" ? (
                          <TriangleUpIcon color="orange.400" />
                        ) : (
                          <TriangleDownIcon color="orange.400" />
                        )
                      ) : (
                        <TriangleDownIcon opacity={0.3} />
                      )}
                    </Box>
                  )}
                </Box>
              </Td>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rows.length > 0 ? (
            rows.map((row, rowIndex) => (
              <Tr
                key={rowIndex}
                {...(getRowStyle
                  ? Object.fromEntries(Object.entries(getRowStyle(row)).filter(([key]) => key !== "translate"))
                  : {})} // Apply custom row styles
                onClick={(event) => (onRowClick ? handleRowClick(event, row) : undefined)}
                cursor={onRowClick ? "pointer" : undefined}
                borderTop="1px solid"
                borderRadius="md"
                borderColor="blackAlpha.50"
                transition="all 0.3s ease"
                _hover={{
                  color: "orange.400",
                  borderColor: "transparent",
                  boxShadow: "0px 4px 6px -2px #0000005C, 0px 10px 15px -3px #0000005C", // lg blackAlpha.500
                }}
              >
                {columns.map((column) => (
                  <Td
                    key={column.key as string}
                    paddingY={column.paddingY || { mobile: "2", laptop: "4" }}
                    paddingX={{ mobile: "4", laptop: "8" }}
                  >
                    {column.render ? column.render(row) : (row[column.key] as ReactNode)}
                  </Td>
                ))}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={columns.length} paddingY="6">
                <Text textAlign="center" fontSize="large" color="blackAlpha.600">
                  {emptyMessage}
                </Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
