import { gql } from "@apollo/client";

export const GET_ALL_COACHES = gql`
  query GetAllCoaches {
    getAllCoaches {
      id
      name
      user {
        id
        username
        accessType
      }
    }
  }
`;
