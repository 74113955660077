import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  SimpleGrid,
  Stack,
  Tooltip,
  CircularProgress,
  CircularProgressLabel,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from "@chakra-ui/react";
import type {
  Evaluation as EvaluationType,
  SkillSet,
  SkillSetEvaluation,
  EvaluationCriteria as EvaluationCriteriaType,
  Organization,
} from "../../../../types";
import { SkillSetCard } from "../../../../common/components/SkillSetCard";
import { CommonScrollbar } from "../../../../common/components/CommonScrollbar/CommonScrollbar";
import { CommonButton } from "../../../../common/components/CommonButton";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { EvaluationCriteria } from "../../../SkillSets/components/EvaluationCriteria";
import { cardStyles } from "../../../../common/utils/styles";
import { ConfirmationDialog } from "../../../../common/components/ConfirmationDialog";

/** Props for Evaluation component */
type EvaluationProps = {
  evaluation: EvaluationType | null;
  allSkillSets: SkillSet[];
  allCriteria: EvaluationCriteriaType[];
  disabled: boolean;
  onSaveEvaluation?: (evaluation: EvaluationType) => void;
  getSkillSetEvaluationCriteria: (skillSetId: string) => Promise<void>;
  skillSetEvaluationCriteriaData: { [key: string]: EvaluationCriteriaType[] };
  skillSetEvaluationCriteriaLoading: { [key: string]: boolean };
  organization?: Organization;
  isLoading?: boolean;
  readOnly?: boolean;
  skillSetEvaluationCriteriaIds?: string[];
};

/** The Evaluation component allows users to evaluate an athlete by rating skills within each skill set. */
export const Evaluation: React.FC<EvaluationProps> = (props) => {
  const [evaluation, setEvaluation] = useState<EvaluationType | null>(props.evaluation);
  const [isEditingDisabled, setIsEditingDisabled] = useState(true);
  const [mobileDialogOpen, setMobileDialogOpen] = useState(false);

  useEffect(() => {
    setEvaluation(props.evaluation);
  }, [props.evaluation]);

  const updateSkillSetEvaluation = (skillSetId: string, updates: Partial<SkillSetEvaluation>) => {
    setEvaluation((prev) => {
      const skillSetEvaluations = prev?.skillSetEvaluations || [];
      const skillSetIndex = skillSetEvaluations.findIndex((sse) => sse.skillSet.id === skillSetId);

      const updatedSkillSetEvaluations =
        skillSetIndex > -1
          ? skillSetEvaluations.map((sse, index) => (index === skillSetIndex ? { ...sse, ...updates } : sse))
          : [
              ...skillSetEvaluations,
              { skillSet: { id: skillSetId, name: "", skills: [] }, skillEvaluations: [], ...updates },
            ];

      return { ...prev, skillSetEvaluations: updatedSkillSetEvaluations };
    });
  };

  const handleSkillRatingChange = (skillSetId: string, skillId: string, rating: number) => {
    setEvaluation((prev) => {
      const skillSetEvaluations = prev?.skillSetEvaluations || [];
      const skillSetIndex = skillSetEvaluations.findIndex((sse) => sse.skillSet.id === skillSetId);

      if (skillSetIndex > -1) {
        const skillEvaluations = skillSetEvaluations[skillSetIndex].skillEvaluations || [];
        const skillEvaluationIndex = skillEvaluations.findIndex((se) => se.skill.id === skillId);

        const updatedSkillEvaluations =
          skillEvaluationIndex > -1
            ? skillEvaluations.map((se, index) => (index === skillEvaluationIndex ? { ...se, rating } : se))
            : [...skillEvaluations, { skill: { id: skillId, name: "" }, rating }];

        const updatedSkillSetEvaluations = skillSetEvaluations.map((sse, index) =>
          index === skillSetIndex ? { ...sse, skillEvaluations: updatedSkillEvaluations } : sse,
        );

        return { ...prev, skillSetEvaluations: updatedSkillSetEvaluations };
      } else {
        return {
          ...prev,
          skillSetEvaluations: [
            ...skillSetEvaluations,
            {
              skillSet: { id: skillSetId, name: "", skills: [] },
              skillEvaluations: [{ skill: { id: skillId, name: "" }, rating }],
            },
          ],
        };
      }
    });
  };

  const handleSkillSetCommentChange = (skillSetId: string, comment: string) => {
    updateSkillSetEvaluation(skillSetId, { comment });
  };

  const handleSaveEvaluation = () => {
    if (!props.onSaveEvaluation) return;

    const filteredSkillSetEvaluations = evaluation?.skillSetEvaluations.filter((sse) =>
      props.allSkillSets.some((ss) => ss.id === sse.skillSet.id),
    );

    props.onSaveEvaluation({ ...evaluation, skillSetEvaluations: filteredSkillSetEvaluations || [] });
    setIsEditingDisabled(true);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight={{ mobile: undefined, laptop: "400px" }}
      maxHeight={{ mobile: undefined, laptop: "75svh" }}
      margin={{ mobile: undefined, laptop: "auto" }}
      position="relative"
    >
      <LoadingOverlay display={props.isLoading || false} spinnerSize="xl" spinnerTopPosition="60px" />

      <Stack
        direction={{ mobile: "column", laptop: "row" }}
        padding={{ laptop: "6" }}
        spacing="6"
        justifyContent="space-between"
        marginBottom={{ mobile: "6" }}
      >
        <Stack
          direction={{ mobile: "column", laptop: "row" }}
          spacing="4"
          alignItems="center"
          justifyContent={{ mobile: "center", laptop: "start" }}
        >
          <Text fontSize="xl">Evaluation</Text>
          <Tooltip label="Overall rating is the average of Skill Set evaluations below">
            <CircularProgress
              min={props.organization?.evaluationScaleMin || 1}
              max={props.organization?.evaluationScaleMax || 10}
              value={evaluation?.overallRating || 0}
              color="orange.400"
              size="55px"
              capIsRound={true}
              trackColor="gray.200"
            >
              <CircularProgressLabel fontSize="large" cursor="default">
                {evaluation?.overallRating?.toFixed(1) || "-"}
              </CircularProgressLabel>
            </CircularProgress>
          </Tooltip>
          <Text display={{ mobile: "block", laptop: "none" }} fontSize="md" color="blackAlpha.600" textAlign="center">
            Overall rating is the average of Skill Set evaluations below
          </Text>
        </Stack>

        {props.allCriteria.length > 0 && (
          <>
            {/* Render the Popover in laptop */}
            <Box display={{ mobile: "none", laptop: "block" }} position="relative" width={{ laptop: "fit-content" }}>
              <Popover closeOnBlur={false} placement="auto" isLazy>
                <PopoverTrigger>
                  <CommonButton
                    fullWidth
                    variantType="outlineSecondary"
                    disabled={props.allCriteria.length === 0}
                    tooltip={
                      props.allCriteria.length === 0 ? "No generic criteria available for rating levels" : undefined
                    }
                  >
                    Generic Evaluation Criteria
                  </CommonButton>
                </PopoverTrigger>
                <PopoverContent paddingY="4" width="fit-content" maxWidth="xl" {...cardStyles({ selected: true })}>
                  <PopoverArrow backgroundColor="orange.400" />
                  <PopoverBody>
                    <EvaluationCriteria
                      readonly={true}
                      allCriteria={props.allCriteria}
                      isLoading={false}
                      displayFeaturePurpose
                    />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
            {/* Render the Dialog in mobile */}
            <Box display={{ mobile: "block", laptop: "none" }} width={{ mobile: "100%" }}>
              <CommonButton
                fullWidth
                variantType="outlineSecondary"
                onClick={() => setMobileDialogOpen(true)}
                disabled={props.allCriteria.length === 0}
              >
                Generic Evaluation Criteria
              </CommonButton>

              <ConfirmationDialog
                isOpen={mobileDialogOpen}
                message={
                  <EvaluationCriteria
                    readonly={true}
                    allCriteria={props.allCriteria}
                    isLoading={false}
                    displayFeaturePurpose
                  />
                }
                onCancel={() => setMobileDialogOpen(false)}
                onConfirm={() => setMobileDialogOpen(false)}
                cancelButtonText="Close"
              />
            </Box>
          </>
        )}
      </Stack>

      {props.allSkillSets.length === 0 ? (
        <Text paddingLeft="6" textColor="blackAlpha.600" flex={1}>
          No Skill Sets defined for athlete evaluation. Create Skill Sets in the 'Skill Sets' section to start
          evaluating performance.
        </Text>
      ) : (
        <CommonScrollbar
          height="100%"
          overflow="scroll"
          flex={1}
          invisibleBorderWidth="0px 8px 0px 0px"
          paddingRight="0"
          paddingLeft="6"
          paddingBottom="6"
        >
          <SimpleGrid
            minChildWidth={{ mobile: undefined, laptop: "80" }}
            width="100%"
            gap="6"
            justifyItems={{ mobile: undefined, laptop: "center" }}
          >
            {props.allSkillSets.map((skillSet) => (
              <SkillSetCard
                key={skillSet.id}
                skillSet={skillSet}
                evaluationMode={true}
                isDisabled={isEditingDisabled || props.readOnly}
                isLoading={false}
                evaluationValues={{
                  rating: evaluation?.skillSetEvaluations?.find((sse) => sse.skillSet.id === skillSet.id)?.rating,
                  comment: evaluation?.skillSetEvaluations?.find((sse) => sse.skillSet.id === skillSet.id)?.comment,
                  skillEvaluations:
                    evaluation?.skillSetEvaluations
                      ?.find((sse) => sse.skillSet.id === skillSet.id)
                      ?.skillEvaluations.reduce(
                        (acc, se) => ({
                          ...acc,
                          [se.skill.id || ""]: { rating: se.rating, comment: se.comment },
                        }),
                        {},
                      ) || {},
                }}
                onSkillEvaluationChange={props.readOnly ? undefined : handleSkillRatingChange}
                onSkillSetCommentChange={props.readOnly ? undefined : handleSkillSetCommentChange}
                getEvaluationCriteria={props.getSkillSetEvaluationCriteria}
                evaluationCriteriaData={props.skillSetEvaluationCriteriaData[skillSet.id || ""] || []}
                evaluationCriteriaLoading={props.skillSetEvaluationCriteriaLoading[skillSet.id || ""] || false}
                organization={props.organization}
                skillSetEvaluationCriteriaIds={props.skillSetEvaluationCriteriaIds}
              />
            ))}
          </SimpleGrid>
        </CommonScrollbar>
      )}

      {!props.readOnly && (
        <Stack
          direction="row"
          paddingY="6"
          paddingX={{ mobile: "0", laptop: "6" }}
          spacing="4"
          alignSelf="end"
          width={{ mobile: "100%", laptop: "fit-content" }}
        >
          <CommonButton
            fullWidth
            variantType="outlineSecondary"
            onClick={() => setIsEditingDisabled(!isEditingDisabled)}
            disabled={props.disabled || props.allSkillSets.length === 0}
          >
            {isEditingDisabled ? "Edit" : "Cancel"}
          </CommonButton>
          <CommonButton
            fullWidth
            variantType="solidPrimary"
            onClick={handleSaveEvaluation}
            disabled={props.disabled || isEditingDisabled}
          >
            Save
          </CommonButton>
        </Stack>
      )}
    </Box>
  );
};
