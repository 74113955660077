import { gql } from "@apollo/client";

export const CREATE_ACCOUNT_WITH_INVITE = gql`
  mutation CreateAccountWithInvite($inviteCode: String!, $data: CreateAccountInput!) {
    createAccountWithInvite(inviteCode: $inviteCode, data: $data) {
      idToken
      accessToken
    }
  }
`;
