import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Divider,
  Editable,
  EditableInput,
  EditablePreview,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { EditableControls } from "./components/EditableControls";
import { CommonIconButton } from "../CommonIconButton";
import { CommonScrollbar } from "../CommonScrollbar";
import type {
  SkillSet,
  Skill,
  DeletionNode,
  EvaluationCriteria as EvaluationCriteriaType,
  Organization,
} from "../../../types";
import { LoadingOverlay } from "../LoadingOverlay";
import { cardStyles, commonStyles } from "../../utils/styles";
import { CommonTextArea } from "../CommonTextArea";
import { CommonButton } from "../CommonButton";
import {
  EvaluationCriteria,
  type EvaluationCriteriaProps,
} from "../../../features/SkillSets/components/EvaluationCriteria";
import { ConfirmationDialog } from "../ConfirmationDialog";

/** Props for the SkillSetCard component. */
export type SkillSetCardProps = {
  /** Determines whether the SkillSet is loading. */
  isLoading: boolean;
  /** Whether the SkillSet is active or not */
  isActive?: boolean;
  /** The SkillSet to be displayed */
  skillSet: SkillSet;
  getEvaluationCriteria: (skillSetId: string) => Promise<void>;
  evaluationCriteriaData: EvaluationCriteriaType[];
  evaluationCriteriaLoading: boolean;
  onSaveEvaluationCriteria?: (criteriaToSave: EvaluationCriteriaType) => Promise<void>;
  /** Function to be called when a SkillSet should be saved */
  onSave?: (updatedSkillSet: SkillSet) => void;
  /** Function to be called when a new skill is added */
  onAdd?: () => void;
  /** Function to be called when a SkillSet is reset */
  resetSkillSet?: (skillSetId: string | null) => void;
  /** Indicates if the card is in evaluation mode */
  evaluationMode?: boolean;
  /** Evaluation values for the skills and skill set */
  evaluationValues?: {
    rating?: number;
    comment?: string;
    skillEvaluations: { [skillId: string]: { rating: number; comment?: string } };
  };
  /** Callback when a skill's evaluation changes */
  onSkillEvaluationChange?: (skillSetId: string, skillId: string, rating: number) => void;
  /** Callback when the skill set's comment changes */
  onSkillSetCommentChange?: (skillSetId: string, comment: string) => void;
  entitiesToBeRemovedWithSkillSet?: DeletionNode[];
  entitiesToBeRemovedWithSkill?: DeletionNode[];
  getEntitiesToBeRemovedWithSkillSet?: (id: string) => Promise<void>;
  getEntitiesToBeRemovedWithSkill?: (id: string) => Promise<void>;
  entitiesToBeRemovedLoading?: boolean;
  /** Whether the card is disabled */
  isDisabled?: boolean;
  dataTestId?: string;
  organization?: Organization;
  skillSetEvaluationCriteriaIds?: string[];
};

/**
 * SkillSetCard component displays a SkillSet card. In evaluation mode, it allows rating skills and adding comments. In
 * edit mode, it allows editing the skill set and its skills.
 */
export const SkillSetCard: React.FC<SkillSetCardProps> = (props) => {
  const [isAddSkillDisabled, setIsAddSkillDisabled] = useState(props.isActive);
  const [activeValueIsEmpty, setActiveValueIsEmpty] = useState(!props.skillSet.name);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [mobileDialogOpen, setMobileDialogOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const minLevel = props.organization?.evaluationScaleMin || 1;
  const maxLevel = props.organization?.evaluationScaleMax || 10;

  // TODO: save this to db as well to skill set rating and use that as an initial value
  const averageRating = React.useMemo(() => {
    const skillEvaluations = Object.values(props.evaluationValues?.skillEvaluations || {});
    if (skillEvaluations.length === 0) return 0;

    const total = skillEvaluations.reduce((sum, evaluation) => sum + evaluation.rating, 0);
    return total / skillEvaluations.length;
  }, [props.evaluationValues]);

  /** Handle the addition of a new skill. */
  const handleAddSkill = () => {
    setIsAddSkillDisabled(true);
    setDisableSubmit(true);
    props.onAdd?.();
    setActiveValueIsEmpty(true);
  };

  /** Handle the submission of a skill or skill set edit. */
  const handleSubmit = (updatedSkillSet: SkillSetCardProps["skillSet"], remove?: boolean) => {
    setDisableSubmit(false);
    setActiveValueIsEmpty(false);
    setIsAddSkillDisabled(false);

    const { __typename, ...skillSet } = updatedSkillSet;
    if (remove) {
      props.onSave?.({ ...skillSet, remove: true });
    } else {
      props.onSave?.(skillSet);
    }
  };

  const handleValueChange = (type: "skillSet" | "skill", id: string | null, newValue: string) => {
    const updatedSkillSet =
      type === "skillSet"
        ? { ...props.skillSet, name: newValue }
        : {
            ...props.skillSet,
            skills: id
              ? props.skillSet.skills.map((skill) => (skill.id === id ? { ...skill, name: newValue } : skill))
              : props.skillSet.skills.some((skill) => skill.name === "")
                ? props.skillSet.skills.map((skill) => (skill.name === "" ? { ...skill, name: newValue } : skill))
                : [...props.skillSet.skills, { name: newValue } as Skill],
          };

    handleSubmit(updatedSkillSet);
  };

  /** Handle the cancellation of a skill or skill set edit. */
  const handleCancel = (previousValue: string) => {
    setIsAddSkillDisabled(false);
    setDisableSubmit(false);
    setActiveValueIsEmpty(false);
    if (!previousValue) {
      // remove the empty skill by resetting the skill set
      props.resetSkillSet?.(props.skillSet.id || null);
    }
  };

  const handleSkillRemove = (skillId: string) => {
    const updatedSkillSet = {
      ...props.skillSet,
      skills: props.skillSet.skills.map((skill) => (skill.id === skillId ? { ...skill, remove: true } : skill)),
    };

    handleSubmit(updatedSkillSet);
  };

  const isEvaluationMode = props.evaluationMode;

  const evaluationCriteriaProps: EvaluationCriteriaProps = {
    title: `Evaluation Criteria for ${props.skillSet.name}`,
    skillSetId: props.skillSet.id,
    isLoading: props.evaluationCriteriaLoading,
    readonly: isEvaluationMode,
    allCriteria: props.evaluationCriteriaData,
    organization: props.organization,
    ...(isEvaluationMode ? {} : { onSave: props.onSaveEvaluationCriteria }),
  };

  // Handler to fetch evaluation criteria when the popover opens
  const handlePopoverOpen = () => {
    props.getEvaluationCriteria(props.skillSet.id!);
  };

  const handleDialogOpenOnMobile = () => {
    setMobileDialogOpen(true);
    props.getEvaluationCriteria(props.skillSet.id!);
  };

  const percentage = (averageRating / maxLevel) * 100;

  return (
    <Box
      ref={containerRef}
      width={{ mobile: "100%", laptop: "80" }}
      height={props.evaluationMode ? "inherit" : "50svh"}
      maxHeight={props.evaluationMode ? "inherit" : "50svh"}
      pointerEvents="auto"
      overflow="hidden"
      padding="4"
      position="relative"
      display="flex"
      flexDirection="column"
      {...cardStyles({ disableClick: true })}
      data-testid={props.dataTestId || "skill-set-card"}
    >
      <LoadingOverlay display={props.isLoading} spinnerSize="xl" spinnerTopPosition="60px" />

      {/* Name of the SkillSet */}
      {props.evaluationMode ? (
        <Box marginBottom="4">
          <Text
            fontSize="large"
            paddingY="3"
            paddingX="6"
            minHeight="51px"
            marginBottom="4"
            borderRadius="md"
            backgroundColor="teal.800"
            textColor="whiteAlpha.900"
          >
            {props.skillSet.name}
          </Text>
          <Tooltip label="Skill Set rating">
            <Box width="100%" display="flex" justifyContent="center">
              <CircularProgress
                value={percentage}
                color="orange.400"
                size="55px"
                capIsRound={true}
                trackColor="gray.200"
              >
                <CircularProgressLabel fontSize="large">
                  {averageRating > 0 ? averageRating.toFixed(1) : "-"}
                </CircularProgressLabel>
              </CircularProgress>
            </Box>
          </Tooltip>
        </Box>
      ) : (
        <Editable
          isDisabled={props.isLoading}
          defaultValue={props.skillSet.name}
          selectAllOnFocus={false}
          submitOnBlur={false}
          startWithEditView={props.isActive}
          onSubmit={(value) => handleValueChange("skillSet", props.skillSet.id || null, value)}
          onCancel={(value) => handleCancel(value)}
          onChange={(value) => {
            setActiveValueIsEmpty(!value);
            setDisableSubmit(false);
          }}
          onFocus={() => {
            setIsAddSkillDisabled(true);
          }}
          placeholder="Skill Set"
          minHeight="51px"
          marginBottom="4"
          borderRadius="md"
          display="flex"
          backgroundColor="teal.800"
          transition="background-color 0.3s ease"
          textColor="whiteAlpha.900"
          _hover={{
            textColor: "orange.400",
            backgroundColor: "teal.900",
          }}
          _focusWithin={{
            textColor: "orange.400",
            backgroundColor: "teal.900",
          }}
        >
          <EditablePreview width="100%" paddingY="3" paddingX="6" fontSize="large">
            {props.skillSet.name}
          </EditablePreview>
          <EditableInput
            width="100%"
            paddingY="3"
            paddingX="6"
            fontSize="large"
            textColor="orange.400"
            boxShadow="none !important"
            data-testid="skill-set-name-input"
          />
          <EditableControls
            onRemove={() => handleSubmit(props.skillSet, true)}
            displayRemove={activeValueIsEmpty}
            confirmationTitle={`Delete Skill Set ${props.skillSet.name}`}
            disableSubmit={disableSubmit || (props.isActive && activeValueIsEmpty)}
            entitiesToBeRemovedWithSkillSet={props.entitiesToBeRemovedWithSkillSet}
            onOpenRemoveConfirmation={async () =>
              await props.getEntitiesToBeRemovedWithSkillSet?.(props.skillSet.id || "")
            }
            isLoading={props.entitiesToBeRemovedLoading}
            skillSetToBeRemoved={props.skillSet}
          />
        </Editable>
      )}

      <Box flex="1" display="flex" flexDirection="column" overflow="hidden" marginRight={{ mobile: "0", laptop: "-4" }}>
        <CommonScrollbar overflow="auto" height="100%" paddingRight="0" invisibleBorderWidth="0px 4px 0px 4px">
          {props.skillSet.skills && props.skillSet.skills.length > 0 ? (
            <Stack direction="column" spacing="4" marginBottom="4">
              {props.skillSet.skills.map((skill, index) =>
                props.evaluationMode ? (
                  <Stack
                    key={skill.id || index}
                    cursor={props.isDisabled ? "not-allowed" : "auto"}
                    direction="row"
                    spacing="4"
                    alignItems="center"
                    paddingY="2"
                    paddingLeft="4"
                    paddingRight="6"
                    {...commonStyles(false)}
                  >
                    <Text flex="1" isTruncated>
                      {skill.name}
                    </Text>

                    <Slider
                      isDisabled={props.isDisabled}
                      opacity={props.isDisabled ? 0.6 : 1}
                      flex={1}
                      focusThumbOnChange={false}
                      min={minLevel}
                      max={maxLevel}
                      value={props.evaluationValues?.skillEvaluations?.[skill.id || ""]?.rating || 0}
                      onChange={(valueNumber) =>
                        props.onSkillEvaluationChange &&
                        props.onSkillEvaluationChange(props.skillSet.id!, skill.id!, valueNumber)
                      }
                    >
                      <SliderTrack>
                        <SliderFilledTrack backgroundColor="orange.400" />
                      </SliderTrack>
                      <SliderThumb
                        fontSize="md"
                        boxSize="32px"
                        children={props.evaluationValues?.skillEvaluations?.[skill.id || ""]?.rating || "-"}
                        backgroundColor={
                          props.evaluationValues?.skillEvaluations?.[skill.id || ""]?.rating ? "orange.400" : "gray.200"
                        }
                        color="white"
                        transition="background-color 0.3s ease"
                        _hover={{ backgroundColor: "orange.500" }}
                        _focusWithin={{ backgroundColor: "orange.500" }}
                        _active={{ backgroundColor: "orange.500" }}
                        _disabled={{
                          backgroundColor: props.evaluationValues?.skillEvaluations?.[skill.id || ""]?.rating
                            ? "orange.400"
                            : "gray.200",
                        }}
                      />
                    </Slider>
                  </Stack>
                ) : (
                  <Editable
                    key={skill.id || index}
                    isDisabled={props.isLoading}
                    defaultValue={skill.name}
                    selectAllOnFocus={false}
                    submitOnBlur={false}
                    startWithEditView={props.isActive && props.skillSet.skills.length === index + 1}
                    onSubmit={(value) => handleValueChange("skill", skill.id || null, value)}
                    onCancel={(value) => handleCancel(value)}
                    onChange={(value) => {
                      setActiveValueIsEmpty(!value);
                      if (value) {
                        setDisableSubmit(false);
                      } else if (!skill.name) {
                        setDisableSubmit(true);
                      }
                    }}
                    onFocus={() => {
                      setIsAddSkillDisabled(true);
                    }}
                    placeholder="Skill"
                    minHeight="48px"
                    textColor="blackAlpha.900"
                    display="flex"
                    {...commonStyles(false)}
                  >
                    <EditablePreview width="100%" paddingY="3" paddingX="6">
                      {skill.name}
                    </EditablePreview>
                    <EditableInput
                      width="100%"
                      paddingY="3"
                      paddingX="6"
                      boxShadow="none !important"
                      data-testid="skill-name-input"
                    />
                    <EditableControls
                      onRemove={() => handleSkillRemove(skill.id || "")}
                      displayRemove={activeValueIsEmpty}
                      confirmationTitle={`Delete Skill ${skill.name}`}
                      disableSubmit={disableSubmit}
                      entitiesToBeRemovedWithSkill={props.entitiesToBeRemovedWithSkill}
                      onOpenRemoveConfirmation={async () =>
                        await props.getEntitiesToBeRemovedWithSkill?.(skill.id || "")
                      }
                      skillToBeRemoved={skill}
                    />
                  </Editable>
                ),
              )}
            </Stack>
          ) : props.skillSet.id ? (
            <Text padding="2" textColor="blackAlpha.600">
              No Skills have been added to this Skill Set. Add Skills to plan, track, and evaluate training and
              performance
            </Text>
          ) : (
            <Text padding="2" textColor="blackAlpha.600">
              Define and save a Skill Set to add Skills
            </Text>
          )}
        </CommonScrollbar>
      </Box>

      {isEvaluationMode ? (
        <Box marginTop="4">
          {/* Render the CommonTextArea in evaluation mode */}
          <CommonTextArea
            value={props.evaluationValues?.comment || ""}
            disabled={props.isDisabled}
            onChange={(input) =>
              props.onSkillSetCommentChange && props.onSkillSetCommentChange(props.skillSet.id!, input)
            }
            placeholder="Comment"
          />
        </Box>
      ) : (
        <>
          {/* Render the Divider and Add Skill button in non-evaluation mode */}
          <Divider marginY="6" borderColor="blackAlpha.300" />
          <CommonIconButton
            fullWidth
            height="32px"
            onClick={handleAddSkill}
            disabled={isAddSkillDisabled || props.isLoading || !props.skillSet.id}
            dataTestId="add-skill"
          />
        </>
      )}

      {/* On Evaluation mode, render buttons only if values exists. On Skill Sets section, render always */}
      {((isEvaluationMode && props.skillSetEvaluationCriteriaIds?.includes(props.skillSet.id || "")) ||
        !isEvaluationMode) && (
        <>
          {/* Render the Popover in laptop */}
          <Box marginTop="4" display={{ mobile: "none", laptop: "block" }}>
            <Popover closeOnBlur={false} placement="auto" onOpen={handlePopoverOpen} isLazy>
              <PopoverTrigger>
                <CommonButton
                  fullWidth
                  variantType="outlineSecondary"
                  disabled={props.evaluationCriteriaLoading || !props.skillSet.id}
                >
                  Evaluation Criteria
                </CommonButton>
              </PopoverTrigger>
              <Portal>
                <PopoverContent paddingY="4" width="fit-content" maxWidth="xl" {...cardStyles({ selected: true })}>
                  <PopoverArrow backgroundColor="orange.400" />
                  <PopoverBody>
                    <EvaluationCriteria {...evaluationCriteriaProps} displayFeaturePurpose />
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
          </Box>
          {/* Render the Dialog in mobile */}
          <Box display={{ mobile: "block", laptop: "none" }} marginTop="4">
            <CommonButton
              fullWidth
              variantType="outlineSecondary"
              onClick={handleDialogOpenOnMobile}
              disabled={props.evaluationCriteriaLoading || !props.skillSet.id}
            >
              Evaluation Criteria
            </CommonButton>

            <ConfirmationDialog
              isOpen={mobileDialogOpen}
              message={<EvaluationCriteria {...evaluationCriteriaProps} displayFeaturePurpose />}
              onCancel={() => setMobileDialogOpen(false)}
              onConfirm={() => setMobileDialogOpen(false)}
              cancelButtonText="Close"
            />
          </Box>
        </>
      )}
    </Box>
  );
};
