import React, { useState } from "react";
import { TimesOneMobiledata } from "@mui/icons-material";

import { CoachSelect } from "../../../../../../../common/components/CoachSelect";
import { AthleteInput } from "../AthleteInput";
import { FormModal } from "../../../../../../../common/components/FormModal";
import { Coach, Venue, Drill, Athlete, Session, type SkillSet } from "../../../../../../../types";
import { VenueSelect } from "../../../../../../../common/components/VenueSelect";
import { DrillLibrary } from "../../../../../../../common/components/DrillLibrary";
import { DrillCard } from "../../../../../../../common/components/DrillCard";
import { updateUTCTime } from "../../../../../../../common/utils/dateAndTime";
import { filterSessions, filterDrills } from "../../../../../../../common/utils/dataProcessing";
import { Box, Grid, GridItem, Icon, Stack, Tab, Tabs, TabList, TabPanel, TabPanels, Text } from "@chakra-ui/react";
import { TimePicker } from "../../../../../../../common/components/TimePicker";
import { CommonDayPicker } from "../../../../../../../common/components/CommonDayPicker";
import type { TrainingEventUpdateInput } from "../../../../../types";
import { CommonScrollbar } from "../../../../../../../common/components/CommonScrollbar";
import { SessionLibrary } from "../../../../../../../common/components/SessionLibrary";
import { SessionCard } from "../../../../../../../common/components/SessionCard";
import { NotesInput } from "../NotesInput";

export type AddEventModalProps = {
  /** Callback function for saving new event */
  onSave: (singleTrainingEventInput: TrainingEventUpdateInput) => void;
  /** Callback function for closing the modal */
  onClose: () => void;
  /** Whether the modal is open or not */
  open: boolean;
  /** Initial date value */
  initialDate?: string;
  /** Initial time value for the start of the event */
  initialTime: string | null;
  /** Initial time value for the end of the event */
  initialEndTime: string | null;
  /** Initial venue selection */
  initialVenue: Venue;
  /** Array of all available coaches */
  allCoaches: Coach[];
  /** Array of all available venues */
  allVenues: Venue[];
  /** Array of all available athletes */
  allAthletes: Athlete[];
  /** Array of all available drills */
  allDrills: Drill[];
  /** Array of all available sessions */
  allSessions: Session[];
  allSkillSets: SkillSet[];
  /** Optional id of the event */
  id?: string;
  /** Whether the user is using a mobile device */
  isMobile: boolean;
};

/**
 * A modal component for adding a new event.
 *
 * @param props - The properties of the AddEventModal component
 * @returns The AddEventModal component
 */
export const AddEventModal: React.FC<AddEventModalProps> = (props) => {
  const [selectedDate, setSelectedDate] = useState(props.initialDate);
  const [selectedStartTime, setSelectedStartTime] = useState(props.initialTime);
  const [selectedEndTime, setSelectedEndTime] = useState(props.initialEndTime);
  const [selectedVenue, setSelectedVenue] = useState<Venue>(props.initialVenue);
  const [selectedCoaches, setSelectedCoaches] = useState<Coach[]>([]);
  const [selectedAthletes, setSelectedAthletes] = useState<Athlete[]>([]);
  const [groupNote, setGroupNote] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [searchSkillSets, setSearchSkillSets] = useState<SkillSet[]>([]);
  const [selectedDrills, setSelectedDrills] = useState<Drill[]>([]);
  const [selectedSession, setSelectedSession] = useState<Session | undefined>(undefined);
  const allSessionTags = Array.from(
    new Set(props.allSessions.flatMap((session) => (session.tags ? session.tags : []))),
  );

  /**
   * Generates a list of reasons why the submit button is disabled.
   *
   * @returns An object containing the submit disabled reasons if any condition is not met, otherwise undefined.
   */
  const getSubmitDisabledReasons = (): string | undefined => {
    const reasons: string[] = [];

    if (!selectedDate) {
      reasons.push("Date is selected");
    }
    if (!selectedStartTime || selectedStartTime === "24:00") {
      reasons.push("Start time is selected");
    }
    if (!selectedEndTime || selectedEndTime === "24:00") {
      reasons.push("End time is selected");
    }
    if (!selectedVenue) {
      reasons.push("Venue is selected");
    }

    return reasons.length > 0 ? `Saving is enabled once\n- ${reasons.join("\n- ")}` : undefined;
  };

  const submitDisabledReasons = getSubmitDisabledReasons();

  const handleSelectDrill = (drill: Drill) => {
    if (selectedDrills.find((d) => d.id === drill.id)) {
      setSelectedDrills(selectedDrills.filter((d) => d.id !== drill.id));
    } else {
      setSelectedDrills([...selectedDrills, drill]);
    }
    setSelectedSession(undefined);
  };

  const handleSelectSession = (session: Session) => {
    if (selectedSession && selectedSession.id === session.id) {
      setSelectedSession(undefined);
    } else {
      setSelectedSession(session);
    }
    setSelectedDrills([]);
  };

  /**
   * Handles the changes in search query and search tags. Updates the state variables 'searchQuery' and 'searchTags'.
   *
   * @param newSearchQuery - The new search query
   * @param newSearchTags - The new search tags
   * @param newSearchSkillSets - The new search skill sets
   */
  const handleSearchChange = (newSearchQuery: string, newSearchTags: string[], newSearchSkillSets: SkillSet[]) => {
    setSearchQuery(newSearchQuery);
    setSearchTags(newSearchTags);
    setSearchSkillSets(newSearchSkillSets);
  };

  /**
   * Handles the selection of athletes. Updates the state variable 'selectedAthletes'.
   *
   * @param athletes - An array of selected athletes
   */
  const handleAthleteSelection = (athletes: Athlete[]) => {
    setSelectedAthletes(athletes);
  };

  /**
   * Handles the change in the current tab. Updates the state variable 'tabIndex'.
   *
   * @param newValue - The new value of the tab index
   */
  const handleTabChange = (newValue: number) => {
    setTabIndex(newValue);
  };

  const handleSubmit = () => {
    const singleTrainingEventInput: TrainingEventUpdateInput = {
      id: props.id,
      startDateTime: updateUTCTime(selectedDate || "", selectedStartTime || ""),
      endDateTime: updateUTCTime(selectedDate || "", selectedEndTime || ""),
      venueId: selectedVenue.id || "",
      coachIds: selectedCoaches.map((coach) => coach.id || ""),
      athleteIds: selectedAthletes.map((selectedAthlete) => selectedAthlete.id || ""),
      drillIds: selectedDrills.map((drill) => drill.id || ""),
      notes: [
        {
          isGroupNote: true,
          content: groupNote,
          athleteIds: selectedAthletes.map((athlete) => athlete.id || ""),
        },
      ],
      sessionId: selectedSession?.id,
    };

    props.onSave(singleTrainingEventInput);
  };

  return (
    <FormModal
      fixedHeight
      open={props.open}
      handleSubmit={handleSubmit}
      onClose={props.onClose}
      title=""
      submitButtonText="Save"
      tabs={["General", "Training Structure"]}
      handleTabChange={handleTabChange}
      tabIndex={tabIndex}
      submitDisabled={!!submitDisabledReasons}
      submitButtonHoverText={submitDisabledReasons}
      handlePaddingXManually={true}
    >
      {/* First tab */}
      <Box data-testid="add-event-modal" height="100%" paddingX={{ mobile: "6", laptop: "0" }}>
        <CommonScrollbar
          height="100%"
          overflow="auto"
          invisibleBorderWidth="0px 8px 0px 0px"
          paddingRight="2"
          paddingLeft="6"
        >
          <Stack
            direction="row"
            spacing="2"
            justifyContent="center"
            alignItems="center"
            marginBottom={{ mobile: "6", laptop: "3" }}
          >
            <Text fontSize="lg" alignContent="center">
              New One-time Training
            </Text>
            <Icon as={TimesOneMobiledata} alignSelf="end" boxSize="8" color="blackAlpha.800" />
          </Stack>
          <Grid
            templateColumns="repeat(6, 1fr)"
            templateRows="auto"
            gap={{ mobile: "3", laptop: "4" }}
            marginBottom={{ mobile: "6", laptop: "8" }}
            height="auto"
          >
            <GridItem colSpan={{ mobile: 6, laptop: 2 }}>
              <CommonDayPicker
                initialDate={selectedDate ? new Date(selectedDate) : undefined}
                onDateChange={(date) => setSelectedDate(date)}
                dateUtcMode="start"
              />
            </GridItem>
            <GridItem colSpan={{ mobile: 3, laptop: 2 }}>
              <TimePicker
                selectedTime={selectedStartTime || ""}
                setSelectedTime={(value) => (typeof value === "string" ? setSelectedStartTime(value) : undefined)}
                label="Start"
                maxTime={selectedEndTime || undefined}
              />
            </GridItem>
            <GridItem colSpan={{ mobile: 3, laptop: 2 }}>
              <TimePicker
                selectedTime={selectedEndTime || ""}
                setSelectedTime={(value) => (typeof value === "string" ? setSelectedEndTime(value) : undefined)}
                label="End"
                minTime={selectedStartTime || undefined}
              />
            </GridItem>
            <GridItem colSpan={{ mobile: 6, laptop: 3 }}>
              <VenueSelect
                selectedVenue={selectedVenue}
                setSelectedVenue={(venueId) => {
                  const newVenue = props.allVenues.find((venue) => venue.id === venueId);
                  if (newVenue) {
                    setSelectedVenue(newVenue);
                  }
                }}
                venues={props.allVenues}
              />
            </GridItem>
            <GridItem colSpan={{ mobile: 6, laptop: 3 }}>
              <CoachSelect
                availableCoaches={props.allCoaches}
                selectedCoaches={selectedCoaches}
                setSelectedCoach={(coachArray) => setSelectedCoaches(coachArray)}
              />
            </GridItem>
            <GridItem colSpan={6}>
              <AthleteInput
                athletes={props.allAthletes}
                selectedAthletes={selectedAthletes}
                handleAthleteSelection={handleAthleteSelection}
              />
            </GridItem>
          </Grid>

          <Stack direction="column" spacing={{ mobile: "6", laptop: "8" }}>
            <Box>
              <Text fontSize="lg" marginBottom="2">
                Training Notes
              </Text>
              <NotesInput
                notes={groupNote}
                label="Add notes for the entire group"
                setNotes={setGroupNote}
                minRows={4}
              />
            </Box>

            <Box>
              <Text fontSize="lg" marginBottom="2">
                Training Structure
              </Text>
              {selectedDrills.length > 0 && (
                <Stack direction="column" spacing="4">
                  {selectedDrills.map((drill, index) => (
                    <DrillCard
                      key={index}
                      drill={drill}
                      selected={true}
                      disableHover={true}
                      isMobile={props.isMobile}
                    />
                  ))}
                </Stack>
              )}
              {selectedSession && (
                <SessionCard
                  session={selectedSession}
                  readOnly={true}
                  selected={true}
                  disableHover={true}
                  isMobile={props.isMobile}
                />
              )}
              {selectedDrills.length === 0 && !selectedSession && (
                <Text textColor="blackAlpha.600">
                  This training is still unplanned! You can create a plan from the "Training Structure" tab.
                </Text>
              )}
            </Box>
          </Stack>
        </CommonScrollbar>
      </Box>

      {/* Training structure tab */}
      <Box
        display="flex"
        height="100%"
        overflow={{ laptop: "hidden" }}
        id="training-plan-tab"
        paddingX={{ mobile: "6", laptop: "0" }}
      >
        <Tabs
          defaultIndex={selectedSession ? 1 : 0}
          variant="solid-rounded"
          size="md"
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <TabList justifyContent="center">
            <Grid templateColumns="repeat(2, 1fr)" columnGap="8">
              <GridItem colSpan={1}>
                <Tab
                  border="1px solid #00000029" // blackAlpha.300
                  transition="all 0.3s ease"
                  _selected={{ backgroundColor: "orange.400", color: "white", border: "1px solid transparent" }}
                  _hover={{ backgroundColor: "orange.500", color: "white", border: "1px solid transparent" }}
                >
                  Drills
                </Tab>
              </GridItem>
              <GridItem colSpan={1}>
                <Tab
                  border="1px solid #00000029" // blackAlpha.300
                  transition="all 0.3s ease"
                  _selected={{ backgroundColor: "orange.400", color: "white", border: "1px solid transparent" }}
                  _hover={{ backgroundColor: "orange.500", color: "white", border: "1px solid transparent" }}
                >
                  Sessions
                </Tab>
              </GridItem>
            </Grid>
          </TabList>

          <TabPanels
            overflow={{ laptop: "hidden" }}
            paddingTop="4" /* paddingTop instead of sibling's marginBottom due to the CommonFormLabel cut-off */
          >
            <TabPanel padding="0" height="100%">
              <DrillLibrary
                allSkillSets={props.allSkillSets}
                drills={filterDrills(props.allDrills, searchQuery, searchTags, searchSkillSets)}
                selectedDrills={selectedDrills}
                onSelectDrill={handleSelectDrill}
                onSearchChange={handleSearchChange}
                searchQuery={searchQuery}
                searchTags={searchTags}
                searchSkillSets={searchSkillSets}
                showDrillCardCheckbox
                scrollbarHeight="100%"
                isMobile={props.isMobile}
              />
            </TabPanel>
            <TabPanel padding="0" height="100%">
              <SessionLibrary
                allSkillSets={props.allSkillSets}
                allTags={allSessionTags}
                onSearchChange={handleSearchChange}
                onSelectSession={handleSelectSession}
                searchQuery={searchQuery}
                searchTags={searchTags}
                searchSkillSets={searchSkillSets}
                sessions={filterSessions(props.allSessions, searchQuery, searchTags, searchSkillSets)}
                selectedSession={selectedSession}
                showSessionCardCheckbox={true}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </FormModal>
  );
};
