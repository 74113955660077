import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Divider,
  Icon,
  Stack,
  Text,
  Tooltip,
  keyframes,
} from "@chakra-ui/react";
import { FormModal } from "../../../common/components/FormModal";
import { CommonButton } from "../../../common/components/CommonButton";
import { CommonInput } from "../../../common/components/CommonInput";
import { LoadingOverlay } from "../../../common/components/LoadingOverlay";
import { Evaluation } from "./Evaluation/Evaluation";
import { Notes } from "./Notes/Notes";
import { Logs } from "./Logs/Logs";
import { TrainingGroups } from "./TrainingGroups/TrainingGroups";
import {
  type Athlete,
  type TrainingGroup,
  type Evaluation as EvaluationType,
  type NoteInput,
  type SkillSet,
  type Coach,
  type EvaluationCriteria,
  type TrainingSeason,
  type Note,
  type Venue,
  InviteType,
  type Log,
  type TrainingGroupEvent,
  type SingleTrainingEvent,
} from "../../../types";
import type {
  EvaluationInput,
  AthleteInput,
  TrainingGroupInput,
  SkillEvaluationInput,
  SkillSetEvaluationInput,
} from "../containers/AthleteContainer";
import { ComponentContainer } from "../../../common/components/ComponentContainer";
import { CommonTable, type Column } from "../../../common/components/CommonTable/CommonTable";
import { CommonIconButton } from "../../../common/components/CommonIconButton";
import { CommonScrollbar } from "../../../common/components/CommonScrollbar";
import { MultiTagSelectMenu } from "../../../common/components/MultiTagSelectMenu";
import { useAlert } from "../../../common/components/AlertProvider";
import { CommonSelect } from "../../../common/components/CommonSelect";
import { generateFullInviteLink } from "../../Auth/utils";
import { OpenInNew } from "@mui/icons-material";
import { TrainingEvents } from "./TrainingEvents/TrainingEvents";
import { validateAndCheckChanges } from "../../../common/utils/dataProcessing";

type AthletesProps = {
  allVenues: Venue[];
  athletes: Athlete[];
  coaches: Coach[];
  skillSets: SkillSet[];
  evaluationCriteria: EvaluationCriteria[];
  trainingSeasons: TrainingSeason[];
  allTrainingGroups: TrainingGroup[];
  athleteTrainingGroups: TrainingGroup[];
  athleteLogs: Log[];
  organizationData: any;
  onSaveAthlete: (athleteInput: AthleteInput) => Promise<void | string>;
  onSaveTrainingGroup: (eventInput: TrainingGroupInput, athleteId: string) => Promise<void>;
  onSaveNote: (noteInput: NoteInput) => Promise<void>;
  onSaveEvaluation: (evaluationInput: EvaluationInput) => Promise<void>;
  isLoading: boolean;
  isTrainingGroupLoading: boolean;
  isAthleteLoading: boolean;
  onAthleteChange: (athlete: Athlete) => Promise<void>;
  evaluation: EvaluationType;
  evaluationLoading: boolean;
  notes: Note[];
  notesPage: number;
  notesTotalCount: number;
  onNextNotesPage: (athleteId: string) => void;
  onPrevNotesPage: (athleteId: string) => void;
  notesLoading: boolean;
  logsLoading: boolean;
  getSkillSetEvaluationCriteria: (skillSetId: string) => Promise<void>;
  skillSetEvaluationCriteriaData: { [key: string]: EvaluationCriteria[] };
  skillSetEvaluationCriteriaLoading: { [key: string]: boolean };
  skillSetEvaluationCriteriaIds: string[];
  onCreateAthleteInvite: (receiver: string, athleteId: string) => Promise<string | undefined>;
  createAthleteAccessInviteLoading: boolean;
  upcomingTrainingEvents: (TrainingGroupEvent | SingleTrainingEvent)[];
  totalUpcomingTrainingEvents: number;
  pastTrainingEvents: (TrainingGroupEvent | SingleTrainingEvent)[];
  totalPastTrainingEvents: number;
  upcomingTrainingEventsLoading: boolean;
  pastTrainingEventsLoading: boolean;
  /** Current page for upcoming events */
  upcomingEventsPage: number;
  /** Current page for past events */
  pastEventsPage: number;
  /** Callback for going to next page of upcoming events */
  onNextUpcomingEventsPage: (athleteId: string) => void;
  /** Callback for going to prev page of upcoming events */
  onPrevUpcomingEventsPage: (athleteId: string) => void;
  /** Callback for going to next page of past events */
  onNextPastEventsPage: (athleteId: string) => void;
  /** Callback for going to prev page of past events */
  onPrevPastEventsPage: (athleteId: string) => void;
  totalLogsCount: number;
  logsAggregations: {
    withdrawns: number;
    cancellations: number;
    replacements: number;
  };
  logsPage: number;
  onNextLogsPage: (athleteId: string) => void;
  onPrevLogsPage: (athleteId: string) => void;
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(10px);
  }
`;

export const Athletes: React.FC<AthletesProps> = (props) => {
  const [selectedAthlete, setSelectedAthlete] = useState<Athlete | null>(null);
  const [initialAthleteData, setInitialAthleteData] = useState<Athlete | undefined>(undefined);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [changeAthleteAnimation, setChangeAthleteAnimation] = useState("");
  const [isHeaderDisabled, setIsHeaderDisabled] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTrainingGroups, setSelectedTrainingGroups] = useState<TrainingGroup[]>([]);
  const [isAthleteInviteModalOpen, setIsAthleteInviteModalOpen] = useState(false);
  const [inviteReceiver, setInviteReceiver] = useState("");
  const [selectedAthleteForInvite, setSelectedAthleteForInvite] = useState<Athlete | null>(null);

  const { showAlert } = useAlert();

  const [sortConfig, setSortConfig] = useState<{
    key: keyof Athlete;
    direction: "ascending" | "descending";
  }>({ key: "name", direction: "ascending" });

  useEffect(() => {
    if (selectedAthleteForInvite?.id) {
      const updatedAthlete = props.athletes.find((a) => a.id === selectedAthleteForInvite.id);
      if (updatedAthlete) {
        setSelectedAthleteForInvite(updatedAthlete);
      }
    }
  }, [props.athletes, selectedAthleteForInvite?.id]);

  const handleAthleteChange = (newAthlete: Athlete) => {
    setIsHeaderDisabled(true);
    setChangeAthleteAnimation(`${fadeOut} 0.5s ease`);
    setInitialAthleteData(newAthlete);

    setTimeout(() => {
      setChangeAthleteAnimation(`${fadeIn} 0.5s ease`);
      setSelectedAthlete(newAthlete);
      setIsHeaderDisabled(false);
      // start loading evaluation for the new athlete only after the animation is done for smoother transition
      props.onAthleteChange(newAthlete);
    }, 400);
  };

  const handleAddAthlete = () => {
    setSelectedAthlete({
      id: undefined,
      name: "",
      trainingGroups: [],
    });
    setInitialAthleteData(undefined);
    setIsDialogOpen(true);
  };

  const handleSaveAthlete = async (remove: boolean) => {
    if (!selectedAthlete) {
      return;
    }

    const athleteInput: AthleteInput = {
      name: selectedAthlete.name,
      id: selectedAthlete.id,
      birthYear: selectedAthlete.birthYear,
      remove,
    };

    const createdAthleteId = await props.onSaveAthlete(athleteInput);
    if (createdAthleteId) {
      handleAthleteChange({ id: createdAthleteId, name: selectedAthlete.name, birthYear: selectedAthlete.birthYear });
    } else if (remove) {
      setSelectedAthlete(null);
      setInitialAthleteData(undefined);
    } else {
      setInitialAthleteData(selectedAthlete);
    }

    setIsDialogOpen(false);
  };

  const handleSaveTrainingGroup = async (eventToSave: TrainingGroup) => {
    const existingAthleteIds = eventToSave.athletes?.map((athlete) => athlete.id || "") || [];
    const athleteIdsToSave = eventToSave.remove
      ? existingAthleteIds.filter((athleteId) => athleteId !== selectedAthlete?.id || "")
      : [...existingAthleteIds, selectedAthlete?.id || ""];

    const dataToSave: TrainingGroupInput = {
      name: eventToSave.name,
      id: eventToSave.id,
      recurrenceEndTime: eventToSave.recurrenceEndTime,
      recurrenceStartTime: eventToSave.recurrenceStartTime,
      recurrenceWeekday: eventToSave.recurrenceWeekday,
      coachIds: eventToSave.coaches?.map((coach) => coach.id || "") || [],
      venueId: eventToSave.venue?.id,
      athleteIds: athleteIdsToSave,
      trainingSeasonId: eventToSave.trainingSeason.id || "",
      // always false under athletes, if whole training group should be removed, it needs to be done through training management
      remove: false,
    };
    await props.onSaveTrainingGroup(dataToSave, selectedAthlete?.id || "");
  };

  const handleSaveNote = async (note: Note, remove?: boolean) => {
    if (!selectedAthlete) {
      return;
    }
    const noteToSave: NoteInput = {
      id: note.id,
      content: note.content,
      isGroupNote: note.isGroupNote,
      athleteIds: [selectedAthlete.id || ""],
      remove: remove,
    };
    await props.onSaveNote(noteToSave);
  };

  const handleSaveEvaluation = async (evaluation: EvaluationType) => {
    if (!selectedAthlete) {
      return;
    }

    // Map over each SkillSetEvaluation to create SkillSetEvaluationInput
    const skillSetEvaluationsInput: SkillSetEvaluationInput[] = evaluation.skillSetEvaluations.map(
      (skillSetEvaluation) => {
        // Map over each SkillEvaluation to create SkillEvaluationInput
        const skillEvaluationsInput: SkillEvaluationInput[] = skillSetEvaluation.skillEvaluations.map(
          (skillEvaluation) => {
            return {
              id: skillEvaluation.id,
              skillId: skillEvaluation.skill.id || "",
              rating: skillEvaluation.rating,
            };
          },
        );

        // Create SkillSetEvaluationInput
        const skillSetEvaluationInput: SkillSetEvaluationInput = {
          id: skillSetEvaluation.id,
          skillSetId: skillSetEvaluation.skillSet.id || "",
          skillEvaluations: skillEvaluationsInput,
          comment: skillSetEvaluation.comment,
        };

        return skillSetEvaluationInput;
      },
    );

    const evaluationInput: EvaluationInput = {
      id: evaluation.id,
      athleteId: selectedAthlete.id || "",
      skillSetEvaluations: skillSetEvaluationsInput,
      remove: evaluation.remove,
    };

    await props.onSaveEvaluation(evaluationInput);
  };

  const handleCloseModal = () => {
    if (!selectedAthlete?.id) {
      setSelectedAthlete(null);
    }
    setIsDialogOpen(false);
  };

  const handleAthleteNameChange = (name: string) => {
    if (selectedAthlete) {
      setSelectedAthlete({
        ...selectedAthlete,
        name,
      });
    }
  };

  const handleAthleteBirthYearChange = (valueAsString: string) => {
    if (selectedAthlete) {
      const year = parseInt(valueAsString, 10);
      const isNumber = !isNaN(year);

      setSelectedAthlete({
        ...selectedAthlete,
        birthYear: valueAsString.trim() === "" ? null : isNumber ? year : selectedAthlete.birthYear,
      });
    }
  };

  const handleSave = () => {
    setIsDialogOpen(false);
    handleSaveAthlete(false);
  };

  const handleRemove = () => {
    setIsDialogOpen(false);
    handleSaveAthlete(true);
  };

  const handleOpenAthleteInviteModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, athlete: Athlete) => {
    event.preventDefault();
    event.stopPropagation();

    setSelectedAthleteForInvite(athlete);
    setInviteReceiver(athlete.name);
    setIsAthleteInviteModalOpen(true);
  };

  const handleAthleteInviteSubmit = async () => {
    const result = await props.onCreateAthleteInvite(inviteReceiver, selectedAthleteForInvite?.id || "");
    if (!result) {
      setIsAthleteInviteModalOpen(false);
    }
  };

  /**
   * Copies the given link to the clipboard.
   *
   * @param {string} link - The invite link to copy.
   */
  const handleCopyLink = (link: string) => {
    navigator.clipboard.writeText(generateFullInviteLink(link));
    setIsAthleteInviteModalOpen(false);
    showAlert("Invite link copied to clipboard!", "info", 2000);
  };

  const validateAthleteData = (currentData: Athlete, allAthletes: Athlete[]): string[] => {
    const reasons: string[] = [];

    if (currentData.name.trim() === "") {
      reasons.push("Athlete name cannot be empty");
    } else if (
      allAthletes.some(
        (athlete) => athlete.id !== currentData.id && athlete.name.toLowerCase() === currentData.name.toLowerCase(),
      )
    ) {
      reasons.push(`Athlete "${currentData.name}" already exists`);
    }

    if (
      currentData.birthYear !== null &&
      currentData.birthYear !== undefined &&
      (currentData.birthYear < 1900 || currentData.birthYear > 2100)
    ) {
      reasons.push("Birth year must be between 1900 and 2100");
    }

    return reasons;
  };

  const compareAthleteData = (currentData: Athlete, initialData: Athlete): boolean => {
    return currentData.name !== initialData.name || currentData.birthYear !== initialData.birthYear;
  };

  const validationResult = useMemo(() => {
    if (!selectedAthlete) {
      return {
        submitDisabledReasons: "No athlete selected",
        hasChanges: false,
      };
    }

    return validateAndCheckChanges(
      selectedAthlete,
      (current) => validateAthleteData(current, props.athletes),
      initialAthleteData,
      compareAthleteData,
    );
  }, [selectedAthlete, initialAthleteData, props.athletes]);

  const { submitDisabledReasons, hasChanges } = validationResult;

  const handleSearchChange = (value: string) => {
    setSearchQuery(value);
  };

  /** Opens the read-only preview for the selected athlete */
  const handlePreview = (athlete: Athlete) => {
    window.open(`/athletes-preview/${athlete.id}`, "_blank");
  };

  const filteredAthletes = useMemo(() => {
    return props.athletes.filter((athlete) => {
      const matchesName = athlete.name.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesGroups =
        selectedTrainingGroups.length === 0 ||
        athlete.trainingGroups?.some((group) =>
          selectedTrainingGroups.some((selectedGroup) => selectedGroup.id === group.id),
        );
      return matchesName && matchesGroups;
    });
  }, [props.athletes, searchQuery, selectedTrainingGroups]);

  const sortedAthletes = useMemo(() => {
    if (!sortConfig) return filteredAthletes;
    const sorted = [...filteredAthletes];
    sorted.sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      if (sortConfig.key === "evaluation") {
        const aRating = a.evaluation?.overallRating ?? -1;
        const bRating = b.evaluation?.overallRating ?? -1;
        return sortConfig.direction === "ascending" ? aRating - bRating : bRating - aRating;
      }

      if (sortConfig.key === "invite") {
        const aInvite = a.invite?.used ? 1 : a.invite ? 0 : -1;
        const bInvite = b.invite?.used ? 1 : b.invite ? 0 : -1;
        return sortConfig.direction === "ascending" ? aInvite - bInvite : bInvite - aInvite;
      }

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
      return 0;
    });
    return sorted;
  }, [filteredAthletes, sortConfig]);

  const columns: Column<Athlete>[] = [
    {
      label: "Name",
      key: "name",
      width: { mobile: "250px", laptop: "20%" },
      sortable: true,
    },
    {
      label: "Evaluation",
      key: "evaluation",
      width: { mobile: "200px", laptop: "12%" },
      sortable: true,
      paddingY: "0",
      render: (athlete) => (
        <CircularProgress
          min={props.organizationData?.evaluationScaleMin || 1}
          max={props.organizationData?.evaluationScaleMax || 10}
          value={athlete.evaluation?.overallRating || 0}
          color="orange.400"
          size="48px"
          capIsRound={true}
          trackColor="gray.200"
        >
          <CircularProgressLabel fontSize="medium">
            {athlete.evaluation?.overallRating?.toFixed(1) || "-"}
          </CircularProgressLabel>
        </CircularProgress>
      ),
    },
    {
      label: "Training Groups",
      key: "trainingGroups",
      width: { mobile: "250px", laptop: "18%" },
      render: (athlete) => (
        <Tooltip label={athlete.trainingGroups?.map((trainingGroup) => trainingGroup.name).join(", ")}>
          <Text isTruncated color={athlete.trainingGroups?.length ? undefined : "blackAlpha.300"}>
            {athlete.trainingGroups?.length
              ? athlete.trainingGroups.map((trainingGroup) => trainingGroup.name).join(", ")
              : "No training groups"}
          </Text>
        </Tooltip>
      ),
    },
    {
      label: "Status",
      key: "invite",
      width: { mobile: "200px", laptop: "12%" },
      sortable: true,
      render: (athlete) => (
        <Text
          border="1px solid"
          borderRadius="md"
          borderColor={athlete.invite ? (athlete.invite.used ? "teal.800" : "orange.400") : "blackAlpha.100"}
          textColor="white"
          backgroundColor={athlete.invite ? (athlete.invite.used ? "teal.800" : "orange.400") : "blackAlpha.300"}
          width="fit-content"
          paddingY="1"
          paddingX="2"
        >
          {athlete.invite ? (athlete.invite.used ? "Joined" : "Invited") : "Not Invited"}
        </Text>
      ),
    },
    {
      label: "Invitation",
      key: "inviteLink" as keyof Athlete, // not real key, but needed for unique key
      width: { mobile: "200px", laptop: "18%" },
      render: (athlete) => (
        <CommonButton
          fullWidth
          variantType="outlineSecondary"
          onClick={(event) => handleOpenAthleteInviteModal(event, athlete)}
        >
          {athlete.invite ? "Open Invitation" : "Create Invitation"}
        </CommonButton>
      ),
    },
    {
      label: "Athlete View",
      key: "preview" as keyof Athlete,
      width: { mobile: "200px", laptop: "20%" },
      render: (athlete) => (
        <CommonButton
          fullWidth
          variantType="outlineSecondary"
          onClick={(e) => {
            e.stopPropagation();
            handlePreview(athlete);
          }}
          tooltip="Open in a new tab to see the Athlete's perspective"
        >
          View as Athlete {<Icon as={OpenInNew} marginLeft="2" />}
        </CommonButton>
      ),
    },
  ];

  return (
    <Stack id="athletes" direction="column" spacing="16" data-testid="athletes">
      <LoadingOverlay display={props.isAthleteLoading} spinnerSize="xl" spinnerTopPosition="60px" />

      <ComponentContainer handlePaddingManually={true}>
        {/* Athletes Table */}
        <Stack direction="row" padding="6" spacing="4" width="100%" justifyContent="center">
          <Text fontSize="x-large">Athletes ({props.athletes.length})</Text>
          <CommonIconButton height="36px" onClick={handleAddAthlete} dataTestId="add-athlete" />
        </Stack>

        <Stack
          direction={{ mobile: "column", laptop: "row" }}
          spacing="4"
          paddingX={{ mobile: "0", laptop: "6" }}
          marginBottom="6"
        >
          <Box width={{ mobile: "100%", laptop: "30%" }}>
            <CommonInput
              placeholder="Search by Name"
              value={searchQuery}
              onChange={(value) => handleSearchChange(value)}
              dataTestId="search-athlete"
            />
          </Box>
          <Box width={{ mobile: "100%", laptop: "30%" }}>
            <MultiTagSelectMenu
              selectedTags={selectedTrainingGroups}
              handleTagSelection={setSelectedTrainingGroups}
              label="Filter by Training Groups"
              options={props.allTrainingGroups}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Box>
        </Stack>

        <CommonScrollbar maxHeight="45svh" overflow="auto" invisibleBorderWidth="0px 8px 0px 0px" position="relative">
          <LoadingOverlay display={props.createAthleteAccessInviteLoading} spinnerSize="xl" spinnerTopPosition="40px" />

          <CommonTable
            columns={columns}
            rows={sortedAthletes}
            emptyMessage={
              props.athletes.length === 0
                ? "No Athletes"
                : "No Athletes match the the applied filters. Clear filters to view all Athletes"
            }
            sortConfig={sortConfig}
            onSort={(key) =>
              setSortConfig((prev) =>
                prev?.key === key
                  ? { key, direction: prev.direction === "ascending" ? "descending" : "ascending" }
                  : { key, direction: "ascending" },
              )
            }
            onRowClick={(athlete) => handleAthleteChange(athlete)}
          />
        </CommonScrollbar>
      </ComponentContainer>

      <Divider />

      {/* Selected Athlete */}
      <Box>
        <Stack
          direction={{ mobile: "column", laptop: "row" }}
          spacing={3}
          justifyContent={{ mobile: "center", laptop: "space-between" }}
          alignItems={{ mobile: "center", laptop: "start" }}
          marginBottom="8"
        >
          <Text fontSize="x-large" width="fit-content" animation={changeAthleteAnimation}>
            {selectedAthlete?.name || "Select Athlete to open their profile"}
          </Text>
          {selectedAthlete && (
            <Stack direction="row" spacing="3" alignItems="center">
              <Text
                border="1px solid"
                borderRadius="md"
                borderColor={
                  selectedAthlete?.invite
                    ? selectedAthlete?.invite.used
                      ? "teal.800"
                      : "orange.400"
                    : "blackAlpha.100"
                }
                textColor="white"
                backgroundColor={
                  selectedAthlete?.invite
                    ? selectedAthlete?.invite.used
                      ? "teal.800"
                      : "orange.400"
                    : "blackAlpha.300"
                }
                width="fit-content"
                paddingY="1"
                paddingX="2"
              >
                {selectedAthlete?.invite ? (selectedAthlete?.invite.used ? "Joined" : "Invited") : "Not Invited"}
              </Text>
              {selectedAthlete?.birthYear && new Date().getFullYear() - selectedAthlete.birthYear < 16 && (
                <Tooltip label="Athlete is under 16 years old">
                  <Text
                    border="1px solid"
                    borderRadius="md"
                    borderColor="orange.400"
                    textColor="white"
                    backgroundColor="orange.400"
                    width="fit-content"
                    paddingY="1"
                    paddingX="2"
                  >
                    Junior
                  </Text>
                </Tooltip>
              )}
            </Stack>
          )}
          <Box flex={1} textAlign="end" visibility={selectedAthlete ? "visible" : "hidden"}>
            <CommonButton
              variantType="outlineSecondary"
              disabled={!selectedAthlete || isHeaderDisabled}
              onClick={() => setIsDialogOpen(true)}
              dataTestId="edit-athlete"
            >
              {`Edit ${selectedAthlete?.name}`}
            </CommonButton>
          </Box>
        </Stack>

        {selectedAthlete && (
          <Stack animation={changeAthleteAnimation} direction="column" spacing="8">
            <Divider display={{ mobile: "block", laptop: "none" }} />

            <ComponentContainer order={3} handlePaddingManually={true}>
              <Evaluation
                evaluation={props.evaluation || null}
                allSkillSets={props.skillSets}
                allCriteria={props.evaluationCriteria}
                organization={props.organizationData || null}
                disabled={false}
                onSaveEvaluation={handleSaveEvaluation}
                getSkillSetEvaluationCriteria={props.getSkillSetEvaluationCriteria}
                skillSetEvaluationCriteriaData={props.skillSetEvaluationCriteriaData}
                skillSetEvaluationCriteriaLoading={props.skillSetEvaluationCriteriaLoading}
                isLoading={props.evaluationLoading}
                skillSetEvaluationCriteriaIds={props.skillSetEvaluationCriteriaIds}
              />
            </ComponentContainer>

            <Divider order={4} display={{ mobile: "block", laptop: "none" }} />

            <Stack order={5} direction={{ mobile: "column", laptop: "row" }} spacing="8" width="100%">
              <ComponentContainer order={6} handlePaddingManually={true}>
                <Notes
                  athleteId={selectedAthlete.id || ""}
                  onNextPage={props.onNextNotesPage}
                  onPrevPage={props.onPrevNotesPage}
                  page={props.notesPage}
                  totalNoteCount={props.notesTotalCount}
                  notes={props.notes}
                  onSave={handleSaveNote}
                  isLoading={props.notesLoading}
                />
              </ComponentContainer>

              <Divider order={7} display={{ mobile: "block", laptop: "none" }} />

              <ComponentContainer order={8} handlePaddingManually={true}>
                {/* Upcoming training events */}
                <TrainingEvents
                  title="Upcoming Training Events"
                  totalEventCount={props.totalUpcomingTrainingEvents}
                  allEvents={props.upcomingTrainingEvents}
                  isLoading={props.upcomingTrainingEventsLoading}
                  athleteId={selectedAthlete.id || ""}
                  page={props.upcomingEventsPage}
                  onNextPage={props.onNextUpcomingEventsPage}
                  onPrevPage={props.onPrevUpcomingEventsPage}
                />
              </ComponentContainer>
            </Stack>

            <Divider order={9} display={{ mobile: "block", laptop: "none" }} />

            <Stack order={10} direction={{ mobile: "column", laptop: "row" }} spacing="8" width="100%">
              <ComponentContainer order={{ mobile: 13, laptop: 11 }} handlePaddingManually={true}>
                <Logs
                  athleteId={selectedAthlete.id || ""}
                  totalLogsCount={props.totalLogsCount}
                  logs={props.athleteLogs}
                  aggregations={props.logsAggregations}
                  onNextPage={props.onNextLogsPage}
                  onPrevPage={props.onPrevLogsPage}
                  page={props.logsPage}
                  isLoading={props.logsLoading}
                />
              </ComponentContainer>

              <Divider order={12} display={{ mobile: "block", laptop: "none" }} />

              <ComponentContainer order={{ mobile: 11, laptop: 13 }} handlePaddingManually={true}>
                {/* Past training events */}
                <TrainingEvents
                  title="Past Training Events"
                  totalEventCount={props.totalPastTrainingEvents}
                  allEvents={props.pastTrainingEvents}
                  isLoading={props.pastTrainingEventsLoading}
                  athleteId={selectedAthlete.id || ""}
                  page={props.pastEventsPage}
                  onNextPage={props.onNextPastEventsPage}
                  onPrevPage={props.onPrevPastEventsPage}
                />
              </ComponentContainer>
            </Stack>

            <Divider order={14} display={{ mobile: "block", laptop: "none" }} />

            <ComponentContainer order={15}>
              <TrainingGroups
                athleteName={selectedAthlete?.name || ""}
                coaches={props.coaches}
                venues={props.allVenues}
                allTrainingGroups={props.allTrainingGroups}
                athletesTrainingGroups={props.athleteTrainingGroups}
                allTrainingSeasons={props.trainingSeasons}
                onSave={handleSaveTrainingGroup}
                onRemove={(eventToRemove) => handleSaveTrainingGroup({ ...eventToRemove, remove: true })}
                isLoading={props.isTrainingGroupLoading}
              />
            </ComponentContainer>
          </Stack>
        )}
      </Box>

      <FormModal
        handleSubmit={handleSave}
        open={isDialogOpen}
        submitButtonText={!selectedAthlete?.id ? "Create" : "Confirm"}
        title={!selectedAthlete?.id ? "Create new athlete" : `Edit ${selectedAthlete.name}`}
        handleRemove={handleRemove}
        removeDisabled={!selectedAthlete?.id}
        removeDisabledReason={!selectedAthlete?.id ? "Athlete isn't saved yet" : undefined}
        onClose={handleCloseModal}
        submitDisabled={!!submitDisabledReasons || !hasChanges}
        submitButtonHoverText={submitDisabledReasons || (!hasChanges ? "No changes to save" : undefined)}
        confirmationDialogTitle="Delete Athlete"
        confirmationDialogMessage={
          <Stack direction="column" spacing="3">
            <Text>
              You're about to delete the Athlete{" "}
              <Text as="span" color="red.500">
                {selectedAthlete?.name}
              </Text>
              . This action will permanently remove all data associated with this Athlete.
            </Text>
            {selectedAthlete?.invite && (
              <Text>Additionally, the invited User will be deleted and will no longer be able to log in.</Text>
            )}
            <Text>This action is irreversible. Please confirm to proceed.</Text>
          </Stack>
        }
      >
        <Stack direction={{ mobile: "column", laptop: "row" }} spacing="4" data-testid="athlete-modal">
          <CommonInput
            placeholder="Athlete name"
            value={selectedAthlete?.name || ""}
            onChange={handleAthleteNameChange}
            dataTestId="athlete-name"
          />
          <Box width="auto">
            <CommonInput
              placeholder="Birth Year (YYYY)"
              maxLength={4}
              value={selectedAthlete?.birthYear?.toString() || ""}
              onChange={handleAthleteBirthYearChange}
            />
          </Box>
        </Stack>
      </FormModal>

      <FormModal
        open={isAthleteInviteModalOpen}
        title={`Create an Invitation Link for ${selectedAthleteForInvite?.name || "Athlete"}`}
        submitButtonText={selectedAthleteForInvite?.invite ? "Invitation Link created" : "Create Invitation Link"}
        handleSubmit={handleAthleteInviteSubmit}
        onClose={() => setIsAthleteInviteModalOpen(false)}
        submitDisabled={
          inviteReceiver.trim().length === 0 ||
          !!selectedAthleteForInvite?.invite ||
          props.createAthleteAccessInviteLoading
        }
        submitButtonHoverText={
          selectedAthleteForInvite?.invite
            ? "This athlete already has an invite link. Copy the link to share it."
            : inviteReceiver.trim().length === 0
              ? "Add a reference to create the link"
              : undefined
        }
      >
        <Stack spacing="4" position="relative">
          <LoadingOverlay display={props.createAthleteAccessInviteLoading} spinnerSize="xl" spinnerTopPosition="50%" />

          <Text marginBottom="4">
            Create a unique invitation link for the selected athlete. Once the link is created, share it with the person
            you want to invite. The link allows them to join your organization as this athlete. Each link is valid for a
            single use only.
          </Text>

          <Stack direction={{ mobile: "column", laptop: "row" }} spacing={{ mobile: "4", laptop: "2" }}>
            <CommonInput
              placeholder="Reference (to track who this invitation was sent to)"
              value={inviteReceiver}
              onChange={setInviteReceiver}
              disabled={!!selectedAthleteForInvite?.invite}
            />
            <CommonSelect
              placeholder="Access type"
              value={InviteType.JoinOrganizationAthleteAccess}
              options={[
                { label: "Full", value: InviteType.JoinOrganizationFullAccess, disabled: true },
                { label: "Limited", value: InviteType.JoinOrganizationLimitedAccess, disabled: true },
                { label: "Athlete", value: InviteType.JoinOrganizationAthleteAccess },
              ]}
              isDisabled={!!selectedAthleteForInvite?.invite}
              onChange={() => {
                // only athlete access is available
              }}
            />
          </Stack>

          <Stack direction="row" spacing={{ mobile: "4", laptop: "2" }}>
            <CommonInput
              placeholder="Invite link"
              value={
                selectedAthleteForInvite?.invite?.inviteCode
                  ? generateFullInviteLink(selectedAthleteForInvite.invite.inviteCode)
                  : ""
              }
              onChange={() => {}} // read only, but copiable
              disabled={!selectedAthleteForInvite?.invite}
            />
            <CommonButton
              variantType="outlineSecondary"
              onClick={() => handleCopyLink(selectedAthleteForInvite?.invite?.inviteCode || "")}
              tooltip="Copy link to clipboard and close the modal"
              disabled={!selectedAthleteForInvite?.invite}
            >
              Copy Link
            </CommonButton>
          </Stack>
        </Stack>
      </FormModal>
    </Stack>
  );
};
