import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Box } from "@chakra-ui/react";
import { useAlert } from "../../../common/components/AlertProvider";
import { GET_ALL_ATHLETES } from "../graphql/get-all-athletes.query";
import { GET_ALL_COACHES } from "../../../common/graphql/get-all-coaches.query";
import { GET_ALL_SKILL_SETS } from "../../../common/graphql/get-all-skill-sets.query";
import { SAVE_ATHLETE } from "../graphql/save-athlete.mutation";
import { SAVE_TRAINING_GROUP } from "../../../common/graphql/save-training-group.mutation";
import { SAVE_NOTE } from "../graphql/save-note.mutation";
import { SAVE_EVALUATION } from "../graphql/save-evaluation.mutation";
import { useAsyncMutation, useHandleQueryErrors } from "../../../common/hooks";
import { LoadingContainer, loadingContainerFadeIn } from "../../../common/components/LoadingContainer";
import { Athletes } from "../components/Athletes";
import {
  type Center,
  type NoteInput,
  type Athlete,
  type EvaluationCriteria,
  InviteType,
  type TrainingGroupEvent,
  type SingleTrainingEvent,
} from "../../../types";
import { GET_ALL_TRAINING_GROUPS } from "../../../common/graphql/get-all-training-groups.query";
import { GET_GENERIC_EVALUATION_CRITERIA } from "../../SkillSets/graphql/get-generic-evaluation-criteria.query";
import { GET_ALL_TRAINING_SEASONS } from "../../Organization/graphql/get-all-training-seasons.query";
import { GET_ATHLETE_EVALUATION } from "../graphql/get-athlete-evaluation.query";
import { GET_ATHLETE_NOTES } from "../graphql/get-athlete-notes.query";
import { GET_ALL_CENTERS } from "../../../common/graphql/get-all-centers.query";
import { GET_SKILL_SET_EVALUATION_CRITERIA } from "../../SkillSets/graphql/get-skill-set-evaluation-criteria.query";
import { GET_USER_ORGANIZATION } from "../../../common/graphql/get-user-organization.query";
import { GET_ALL_SKILL_SET_EVALUATION_CRITERIA } from "../graphql/get-all-skill-set-evaluation-criteria.query";
import { CREATE_INVITE_JOIN_ORGANIZATION_ATHLETE_ACCESS } from "../graphql/create-invite-join-organization-athlete-access.mutation";
import { GET_ATHLETE_TRAINING_GROUPS } from "../graphql/get-athlete-training-groups.query";
import { GET_ATHLETE_LOGS } from "../graphql/get-athlete-logs.query";
import { GET_ATHLETE_TRAINING_EVENTS } from "../graphql/get-athlete-training-events.query";

export type AthleteInput = {
  id?: string;
  name: string;
  birthYear?: number | null;
  remove?: boolean;
};

export type TrainingGroupInput = {
  /** Weekday for event, 1 = monday */
  recurrenceWeekday: number;
  /** Start time for event, e.g. "09.00" */
  recurrenceStartTime: string;
  /** End time for event, e.g. "10.30" */
  recurrenceEndTime: string;
  /** Unique identifier */
  id?: string;
  /** Array of coach IDs */
  coachIds?: string[];
  /** Array of Athlete IDs */
  athleteIds?: string[];
  /** Venue ID */
  venueId?: string;
  /** Indicates if the event should be removed */
  remove?: boolean;
  /** When the first possible date is for Training Group */
  recurrenceStartDate?: string;
  /** When the last possible date is for Training Group */
  recurrenceEndDate?: string;
  /** Training group name */
  name: string;
  /** Training Season ID */
  trainingSeasonId: string;
};

export type SkillEvaluationInput = {
  /** Unique identifier */
  id?: string;
  /** Skill ID */
  skillId: string;
  /** Rating */
  rating: number;
};

export type SkillSetEvaluationInput = {
  id?: string;
  skillSetId: string;
  skillEvaluations: SkillEvaluationInput[];
  comment?: string;
};

export type EvaluationInput = {
  /** Unique identifier */
  id?: string;
  /** Athlete ID */
  athleteId: string;
  /** Array of skill set evaluations */
  skillSetEvaluations: SkillSetEvaluationInput[];
  /** Indicates if the evaluation should be removed */
  remove?: boolean;
};

export const TRAINING_EVENTS_PAGINATION_LIMIT = 10;
export const NOTES_PAGINATION_LIMIT = 10;
export const LOGS_PAGINATION_LIMIT = 5;

export const AthleteContainer: React.FC = () => {
  const { showAlert } = useAlert();

  // Queries
  const { data: athleteData, loading: athleteDataLoading, error: athleteDataError } = useQuery(GET_ALL_ATHLETES);
  const { data: coachData, loading: coachDataLoading, error: coachDataError } = useQuery(GET_ALL_COACHES);
  const { data: skillSetData, loading: skillSetDataLoading, error: skillSetDataError } = useQuery(GET_ALL_SKILL_SETS);
  const {
    data: evaluationCriteriaData,
    loading: evaluationCriteriaLoading,
    error: evaluationCriteriaError,
  } = useQuery(GET_GENERIC_EVALUATION_CRITERIA);
  const {
    data: allSkillSetEvaluationCriteriaData,
    loading: allSkillSetEvaluationCriteriaLoading,
    error: allSkillSetEvaluationCriteriaError,
  } = useQuery(GET_ALL_SKILL_SET_EVALUATION_CRITERIA);
  const {
    data: allTrainingSeasonsData,
    loading: allTrainingSeasonsLoading,
    error: allTrainingSeasonsError,
  } = useQuery(GET_ALL_TRAINING_SEASONS);
  const {
    data: allTrainingGroupsData,
    loading: allTrainingGroupsDataLoading,
    error: allTrainingGroupsDataError,
  } = useQuery(GET_ALL_TRAINING_GROUPS);
  const { data: centerData, loading: centerLoading, error: centerError } = useQuery(GET_ALL_CENTERS);
  const {
    data: organizationData,
    loading: organizationDataLoading,
    error: organizationDataError,
  } = useQuery(GET_USER_ORGANIZATION);

  const [
    getAthleteEvaluation,
    { data: athleteEvaluationData, loading: athleteEvaluationLoading, error: athleteEvaluationError },
  ] = useLazyQuery(GET_ATHLETE_EVALUATION, { fetchPolicy: "network-only" });
  const [getAthleteNotes, { data: athleteNotesData, loading: athleteNotesLoading, error: athleteNotesError }] =
    useLazyQuery(GET_ATHLETE_NOTES, { fetchPolicy: "network-only" });
  const [getAthleteLogs, { data: athleteLogsData, loading: athleteLogsLoading, error: athleteLogsError }] =
    useLazyQuery(GET_ATHLETE_LOGS, { fetchPolicy: "network-only" });

  const [getSkillSetEvaluationCriteriaQuery, { error: skillSetEvaluationCriteriaDataError }] = useLazyQuery(
    GET_SKILL_SET_EVALUATION_CRITERIA,
    { fetchPolicy: "network-only" },
  );

  const [
    getAthleteTrainingGroups,
    { data: athleteTrainingGroupsData, loading: athleteTrainingGroupsLoading, error: athleteTrainingGroupsError },
  ] = useLazyQuery(GET_ATHLETE_TRAINING_GROUPS, { fetchPolicy: "network-only" });

  // no-cache due to the manual filtering of season plans to events
  // TODO: to improve, create direct relation with season plan & training group events
  const [getUpcomingEvents, { data: upcomingEventsData, loading: upcomingLoading, error: upcomingError }] =
    useLazyQuery(GET_ATHLETE_TRAINING_EVENTS, { fetchPolicy: "no-cache" });
  const [getPastEvents, { data: pastEventsData, loading: pastLoading, error: pastError }] = useLazyQuery(
    GET_ATHLETE_TRAINING_EVENTS,
    { fetchPolicy: "no-cache" },
  );

  // Mutations
  const { execute: saveAthleteMutation, loading: saveAthleteLoading } = useAsyncMutation(SAVE_ATHLETE, {
    awaitRefetchQueries: true,
  });

  const { execute: saveTrainingGroupMutation, loading: saveTrainingGroupLoading } = useAsyncMutation(
    SAVE_TRAINING_GROUP,
    {
      awaitRefetchQueries: true,
    },
  );

  const { execute: saveNoteMutation, loading: saveNoteLoading } = useAsyncMutation(SAVE_NOTE, {
    awaitRefetchQueries: true,
  });

  const { execute: saveEvaluationMutation, loading: saveEvaluationLoading } = useAsyncMutation(SAVE_EVALUATION, {
    awaitRefetchQueries: true,
  });
  const { execute: createAthleteAccessInviteMutation, loading: createAthleteAccessInviteLoading } = useAsyncMutation(
    CREATE_INVITE_JOIN_ORGANIZATION_ATHLETE_ACCESS,
    { awaitRefetchQueries: true },
  );

  // Loading and error states
  const isLoading =
    athleteDataLoading ||
    coachDataLoading ||
    skillSetDataLoading ||
    evaluationCriteriaLoading ||
    allTrainingSeasonsLoading ||
    allTrainingGroupsDataLoading ||
    centerLoading ||
    organizationDataLoading ||
    allSkillSetEvaluationCriteriaLoading;
  const [skillSetEvaluationCriteriaData, setSkillSetEvaluationCriteriaData] = useState<{
    [key: string]: EvaluationCriteria[];
  }>({});
  const [skillSetEvaluationCriteriaLoading, setSkillSetEvaluationCriteriaLoading] = useState<{
    [key: string]: boolean;
  }>({});

  const [upcomingPage, setUpcomingPage] = useState<number>(1);
  const [pastPage, setPastPage] = useState<number>(1);
  const [notesPage, setNotesPage] = useState<number>(1);
  const [logsPage, setLogsPage] = useState<number>(1);

  const [showContent, setShowContent] = useState(!isLoading);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    } else {
      setShowContent(false);
    }
  }, [isLoading]);

  useHandleQueryErrors([
    athleteDataError,
    coachDataError,
    skillSetDataError,
    evaluationCriteriaError,
    allTrainingSeasonsError,
    allTrainingGroupsDataError,
    athleteEvaluationError,
    athleteNotesError,
    centerError,
    skillSetEvaluationCriteriaDataError,
    organizationDataError,
    allSkillSetEvaluationCriteriaError,
    athleteTrainingGroupsError,
    athleteLogsError,
    upcomingError,
    pastError,
  ]);

  const handleSaveAthlete = async (athleteInput: AthleteInput) => {
    if (athleteInput.remove) {
      showAlert("Deleting Athlete", "info", undefined, true);
    } else if (athleteInput.id) {
      showAlert("Updating Athlete", "info", undefined, true);
    } else {
      showAlert("Creating new Athlete", "info", undefined, true);
    }

    const result = await saveAthleteMutation({ data: athleteInput });

    if (result) {
      if (athleteInput.remove) {
        showAlert("Athlete deleted!", "success", 5000);
      } else if (athleteInput.id) {
        showAlert("Athlete updated!", "success", 5000);
      } else {
        showAlert("Athlete created!", "success", 5000);
        // If a new athlete was created, we need to return the created id to update the athlete
        return result?.saveAthlete?.id;
      }
    }
  };

  const loadUpcomingEvents = async (athleteId: string, page: number) => {
    await getUpcomingEvents({
      variables: {
        athleteId,
        isUpcoming: true,
        page,
        limit: TRAINING_EVENTS_PAGINATION_LIMIT,
      },
    });
  };

  const loadPastEvents = async (athleteId: string, page: number) => {
    await getPastEvents({
      variables: {
        athleteId,
        isUpcoming: false,
        page,
        limit: TRAINING_EVENTS_PAGINATION_LIMIT,
      },
    });
  };

  const handleSaveTrainingGroup = async (eventInput: TrainingGroupInput, athleteId: string) => {
    showAlert("Updating athlete training groups", "info", undefined, true);

    const result = await saveTrainingGroupMutation({ data: eventInput });

    if (result) {
      showAlert("Updated athlete training groups!", "success", 5000);
    }

    loadUpcomingEvents(athleteId, upcomingPage);
    loadPastEvents(athleteId, pastPage);
  };

  const handleSaveNote = async (noteInput: NoteInput) => {
    const result = await saveNoteMutation({
      data: noteInput,
    });

    if (result) {
      if (noteInput.remove) {
        showAlert("Note removed!", "success", 5000);
      } else if (noteInput.id) {
        showAlert("Note updated!", "success", 5000);
      } else {
        showAlert("Note saved!", "success", 5000);
      }
    }
  };

  const handleSaveEvaluation = async (evaluationInput: EvaluationInput) => {
    showAlert("Updating Evaluation", "info", undefined, true);

    const result = await saveEvaluationMutation({ data: evaluationInput });

    if (result) {
      showAlert("Evaluation saved!", "success", 5000);
    }
  };

  const handleNextUpcomingEventsPage = async (athleteId: string) => {
    setUpcomingPage((prev) => prev + 1);
    await loadUpcomingEvents(athleteId, upcomingPage + 1);
  };

  const handlePrevUpcomingEventsPage = async (athleteId: string) => {
    if (upcomingPage > 1) {
      setUpcomingPage((prev) => prev - 1);
      await loadUpcomingEvents(athleteId, upcomingPage - 1);
    }
  };

  const handleNextPastEventsPage = async (athleteId: string) => {
    setPastPage((prev) => prev + 1);
    await loadPastEvents(athleteId, pastPage + 1);
  };

  const handlePrevPastEventsPage = async (athleteId: string) => {
    if (pastPage > 1) {
      setPastPage((prev) => prev - 1);
      await loadPastEvents(athleteId, pastPage - 1);
    }
  };

  const loadNotes = async (athleteId: string, page: number) => {
    await getAthleteNotes({
      variables: {
        athleteId,
        page,
        limit: NOTES_PAGINATION_LIMIT,
      },
    });
  };

  const handleNextNotesPage = (athleteId: string) => {
    setNotesPage((prev) => prev + 1);
    loadNotes(athleteId, notesPage + 1);
  };

  const handlePrevNotesPage = (athleteId: string) => {
    if (notesPage > 1) {
      setNotesPage((prev) => prev - 1);
      loadNotes(athleteId, notesPage - 1);
    }
  };

  const loadLogs = async (athleteId: string, page: number) => {
    await getAthleteLogs({
      variables: {
        athleteId,
        page,
        limit: LOGS_PAGINATION_LIMIT,
      },
    });
  };

  const handleNextLogsPage = (athleteId: string) => {
    setLogsPage((prev) => prev + 1);
    loadLogs(athleteId, logsPage + 1);
  };

  const handlePrevLogsPage = (athleteId: string) => {
    if (logsPage > 1) {
      setLogsPage((prev) => prev - 1);
      loadLogs(athleteId, logsPage - 1);
    }
  };

  const handleAthleteChange = async (athlete: Athlete) => {
    setNotesPage(1);
    setUpcomingPage(1);
    setPastPage(1);
    setLogsPage(1);

    await Promise.all([
      getAthleteEvaluation({ variables: { id: athlete.id } }),
      loadNotes(athlete.id || "", 1),
      loadLogs(athlete.id || "", 1),
      getAthleteTrainingGroups({ variables: { athleteId: athlete.id } }),
      loadUpcomingEvents(athlete.id || "", 1),
      loadPastEvents(athlete.id || "", 1),
    ]);
  };

  const getSkillSetEvaluationCriteria = async (skillSetId: string) => {
    setSkillSetEvaluationCriteriaLoading((prev) => ({
      ...prev,
      [skillSetId]: true,
    }));
    const { data } = await getSkillSetEvaluationCriteriaQuery({
      variables: { skillSetId },
    });
    setSkillSetEvaluationCriteriaData((prev) => ({
      ...prev,
      [skillSetId]: data.getSkillSetEvaluationCriteria,
    }));

    setSkillSetEvaluationCriteriaLoading((prev) => ({
      ...prev,
      [skillSetId]: false,
    }));
  };

  const createAthleteInvite = async (receiver: string, athleteId: string): Promise<string | undefined> => {
    const input = {
      inviteType: InviteType.JoinOrganizationAthleteAccess,
      receiver,
      athleteId,
    };

    const result = await createAthleteAccessInviteMutation({ data: input });
    if (result) {
      return result;
    } else {
      return undefined;
    }
  };

  return (
    <Box id="athletes-container">
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Box animation={isLoading ? undefined : `${loadingContainerFadeIn} 0.3s`}>
          <Athletes
            allVenues={
              centerData?.getAllCenters?.flatMap((center: Center) =>
                center.venues.map((venue) => ({ ...venue, center })),
              ) || []
            }
            athletes={athleteData?.getAllAthletes || []}
            coaches={coachData?.getAllCoaches || []}
            skillSets={skillSetData?.getAllSkillSets || []}
            evaluationCriteria={evaluationCriteriaData?.getGenericEvaluationCriteria || []}
            trainingSeasons={allTrainingSeasonsData?.getAllTrainingSeasons || []}
            allTrainingGroups={allTrainingGroupsData?.getAllTrainingGroups || []}
            athleteTrainingGroups={athleteTrainingGroupsData?.getAthleteTrainingGroups || []}
            athleteLogs={athleteLogsData?.getAthleteLogs?.logs || []}
            organizationData={organizationData?.getUserOrganization || null}
            onSaveAthlete={handleSaveAthlete}
            onSaveTrainingGroup={handleSaveTrainingGroup}
            onSaveNote={handleSaveNote}
            onSaveEvaluation={handleSaveEvaluation}
            isLoading={isLoading}
            isTrainingGroupLoading={saveTrainingGroupLoading || athleteTrainingGroupsLoading}
            isAthleteLoading={saveAthleteLoading}
            onAthleteChange={handleAthleteChange}
            evaluation={athleteEvaluationData?.getAthleteEvaluation || null}
            evaluationLoading={athleteEvaluationLoading || saveEvaluationLoading}
            notes={athleteNotesData?.getAthleteNotes?.notes || []}
            notesTotalCount={athleteNotesData?.getAthleteNotes?.totalCount || 0}
            notesPage={notesPage}
            onNextNotesPage={handleNextNotesPage}
            onPrevNotesPage={handlePrevNotesPage}
            notesLoading={athleteNotesLoading || saveNoteLoading}
            logsLoading={athleteLogsLoading}
            getSkillSetEvaluationCriteria={getSkillSetEvaluationCriteria}
            skillSetEvaluationCriteriaData={skillSetEvaluationCriteriaData}
            skillSetEvaluationCriteriaLoading={skillSetEvaluationCriteriaLoading}
            skillSetEvaluationCriteriaIds={Array.from(
              new Set(
                allSkillSetEvaluationCriteriaData?.getAllSkillSetEvaluationCriteria?.map(
                  (criteria: any) => criteria?.skillSet?.id,
                ),
              ),
            )}
            onCreateAthleteInvite={createAthleteInvite}
            createAthleteAccessInviteLoading={createAthleteAccessInviteLoading}
            upcomingTrainingEvents={upcomingEventsData?.getAthleteTrainingEvents?.allTrainingEvents || []}
            totalUpcomingTrainingEvents={upcomingEventsData?.getAthleteTrainingEvents?.totalCount || 0}
            pastTrainingEvents={pastEventsData?.getAthleteTrainingEvents?.allTrainingEvents || []}
            totalPastTrainingEvents={pastEventsData?.getAthleteTrainingEvents?.totalCount || 0}
            upcomingTrainingEventsLoading={upcomingLoading}
            pastTrainingEventsLoading={pastLoading}
            upcomingEventsPage={upcomingPage}
            onNextUpcomingEventsPage={handleNextUpcomingEventsPage}
            onPrevUpcomingEventsPage={handlePrevUpcomingEventsPage}
            pastEventsPage={pastPage}
            onNextPastEventsPage={handleNextPastEventsPage}
            onPrevPastEventsPage={handlePrevPastEventsPage}
            logsPage={logsPage}
            logsAggregations={athleteLogsData?.getAthleteLogs?.aggregations || null}
            onNextLogsPage={handleNextLogsPage}
            onPrevLogsPage={handlePrevLogsPage}
            totalLogsCount={athleteLogsData?.getAthleteLogs?.totalCount || 0}
          />
        </Box>
      )}
    </Box>
  );
};
