import React, { useState, useEffect } from "react";
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  IconButton,
  Stack,
  FormControl,
  Text,
} from "@chakra-ui/react";
import { CalendarIcon, CloseIcon } from "@chakra-ui/icons";
import { DayPicker } from "react-day-picker";
import { format, isValid, parse } from "date-fns";
import "react-day-picker/dist/style.css";
import "./CommonDayPicker.css";
import { CommonFormLabel } from "../CommonFormLabel";
import { commonStyles } from "../../utils/styles";
import { formatToUtcDayStartString, formatToUtcDayEndString } from "../../utils/dateAndTime";

/** Props for the CommonDayPicker component. */
export type CommonDayPickerProps = {
  /**
   * Callback function triggered whenever a valid date is selected or typed. It returns a string in UTC format (or
   * `undefined` if the date is invalid or cleared).
   */
  onDateChange: (date?: string) => void;
  /**
   * Determines whether the returned UTC date should represent the start or end of the selected day.
   *
   * - "start" => T00:00:00.000Z
   * - "end" => T23:59:59.999Z
   */
  dateUtcMode: "start" | "end";
  /** An optional initial date to display in the picker and input fields. */
  initialDate?: Date;
  /** Whether the input should be disabled. */
  disabled?: boolean;
  /** Label text for the date field. */
  label?: string;
  /** Optional data-testid for testing. */
  dataTestId?: string;
};

/**
 * CommonDayPicker component that allows users to pick a date from a calendar or manually type a date using separate
 * segments: day, month, and year. It always returns a UTC date string to `onDateChange`, adjusted to either the start
 * or end of the day (based on `dateUtcMode`).
 */
export const CommonDayPicker: React.FC<CommonDayPickerProps> = (props) => {
  /** Converts a Date object to a UTC date string (start or end of day). */
  const getUtcDateString = (date: Date): string => {
    if (props.dateUtcMode === "end") {
      return formatToUtcDayEndString(date);
    }
    return formatToUtcDayStartString(date);
  };

  /** Calls the parent's onDateChange with a UTC string if the date is valid, or undefined if it's cleared or invalid. */
  const callOnDateChange = (date?: Date): void => {
    if (date) {
      props.onDateChange(getUtcDateString(date));
    } else {
      props.onDateChange(undefined);
    }
  };

  /**
   * Attempts to build a valid Date from day/month/year strings.
   *
   * @returns A valid Date if successful, otherwise undefined.
   */
  const parseDateSegments = (dayStr: string, monthStr: string, yearStr: string): Date | undefined => {
    // If we don't have enough digits for a complete date
    if (dayStr.length < 1 || monthStr.length < 1 || yearStr.length < 4) {
      return undefined;
    }
    const dateString = `${dayStr.padStart(2, "0")}/${monthStr.padStart(2, "0")}/${yearStr}`;
    const parsedDate = parse(dateString, "dd/MM/yyyy", new Date());
    if (isValid(parsedDate) && parsedDate.getFullYear() >= 2000) {
      return parsedDate;
    }
    return undefined;
  };

  /** Extracts initial day/month/year parts from `props.initialDate`. */
  const getInitialParts = (date?: Date) => {
    if (!date) return { initDay: "", initMonth: "", initYear: "" };
    return {
      initDay: format(date, "dd"),
      initMonth: format(date, "MM"),
      initYear: format(date, "yyyy"),
    };
  };

  const { initDay, initMonth, initYear } = getInitialParts(props.initialDate);

  // Local states for the day, month, year segments:
  const [day, setDay] = useState(initDay);
  const [month, setMonth] = useState(initMonth);
  const [year, setYear] = useState(initYear);
  // The actual Date object for the selected date (used in DayPicker's "selected" prop).
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(props.initialDate);
  // A separate state for controlling which month is shown in the DayPicker:
  const [calendarMonth, setCalendarMonth] = useState<Date>(props.initialDate || new Date());

  const { isOpen, onOpen, onClose } = useDisclosure();

  /** Whenever day/month/year changes, re-parse the date. If valid, set it; otherwise, call with undefined. */
  useEffect(() => {
    if (props.disabled) {
      return;
    }
    const maybeDate = parseDateSegments(day, month, year);
    setSelectedDate(maybeDate);
    if (maybeDate) {
      setCalendarMonth(maybeDate);
    }
    callOnDateChange(maybeDate);
  }, [day, month, year]);

  /**
   * Called when a date is selected from the calendar. This updates all three segments from the chosen date, sets
   * selectedDate, and calls onDateChange.
   */
  const handleDayPickerSelect = (date?: Date) => {
    if (!date) {
      // User deselected
      setDay("");
      setMonth("");
      setYear("");
      setSelectedDate(undefined);
      callOnDateChange(undefined);
      return;
    }
    setDay(format(date, "dd"));
    setMonth(format(date, "MM"));
    setYear(format(date, "yyyy"));
    setSelectedDate(date);
    setCalendarMonth(date);
    onClose();
  };

  /** Clears the date fields. */
  const handleClear = (): void => {
    setDay("");
    setMonth("");
    setYear("");
    setSelectedDate(undefined);
    setCalendarMonth(new Date());
    callOnDateChange(undefined);
  };

  /**
   * Updates a single segment state (day, month, or year) onChange. Allows only digits, truncates to 2 chars (except
   * year => 4).
   */
  const updateSegment = (value: string, type: "day" | "month" | "year") => {
    const numericValue = value.replace(/\D/g, "");
    let maxLen = 2;
    if (type === "year") {
      maxLen = 4;
    }
    const trimmed = numericValue.slice(0, maxLen);

    if (type === "day") setDay(trimmed);
    if (type === "month") setMonth(trimmed);
    if (type === "year") setYear(trimmed);
  };

  /** Clamps numeric input to a valid range and pads with zeros if needed. */
  const clampAndPad = (value: string, minValue: number, maxValue: number, padLength: number): string => {
    if (!value) return "";
    const numeric = parseInt(value, 10);
    if (isNaN(numeric)) return "";

    // Clamp to [minValue, maxValue]
    let clamped = Math.max(Math.min(numeric, maxValue), minValue);
    // Zero-pad to desired length
    return clamped.toString().padStart(padLength, "0");
  };

  /** Called when day/month/year fields lose focus: we clamp and pad values. */
  const handleSegmentBlur = (type: "day" | "month" | "year") => {
    if (type === "day") {
      if (!day) return; // If empty, do nothing
      setDay((prev) => clampAndPad(prev, 1, 31, 2));
    } else if (type === "month") {
      if (!month) return;
      setMonth((prev) => clampAndPad(prev, 1, 12, 2));
    } else {
      if (!year) return;
      // For year, clamp to 2000–2100, pad to length 4
      setYear((prev) => {
        // If it's less than 4 digits typed, the parse below won't clamp well
        // so let's do the clamp, then ensure we keep the final numeric in [2000..2100].
        const padded = clampAndPad(prev, 2000, 2100, 4);
        return padded;
      });
    }
  };

  const dataTestId = props.dataTestId || "common-day-picker";

  return (
    <Box
      position="relative"
      width="100%"
      opacity={props.disabled ? 0.6 : 1}
      {...commonStyles(Boolean(day || month || year))}
      data-testid={dataTestId}
    >
      <FormControl id="dayPicker">
        <CommonFormLabel display={!!props.label}>{props.label}</CommonFormLabel>
        <InputGroup>
          {/* The Box below holds the three inputs plus the slashes */}
          <Box display="flex" alignItems="center">
            <Input
              placeholder="DD"
              type="number"
              min={1}
              max={31}
              isDisabled={props.disabled}
              value={day}
              onChange={(e) => updateSegment(e.target.value, "day")}
              onBlur={() => handleSegmentBlur("day")}
              border="none"
              width="auto"
              padding="0"
              marginLeft="4"
              marginRight={day ? "-2" : "-1"}
              _focusVisible={{}}
              data-testid={`${dataTestId}-day`}
            />
            <Text color="blackAlpha.600" marginX="1">
              /
            </Text>
            <Input
              placeholder="MM"
              type="number"
              min={1}
              max={12}
              isDisabled={props.disabled}
              value={month}
              onChange={(e) => updateSegment(e.target.value, "month")}
              onBlur={() => handleSegmentBlur("month")}
              border="none"
              width="auto"
              padding="0"
              marginRight={month ? "-2" : "0"}
              _focusVisible={{}}
              data-testid={`${dataTestId}-month`}
            />
            <Text color="blackAlpha.600" marginX="1">
              /
            </Text>
            <Input
              placeholder="YYYY"
              type="number"
              min={2000}
              max={2100}
              isDisabled={props.disabled}
              value={year}
              onChange={(e) => updateSegment(e.target.value, "year")}
              onBlur={() => handleSegmentBlur("year")}
              border="none"
              width="auto"
              padding="0"
              _focusVisible={{}}
              data-testid={`${dataTestId}-year`}
            />
          </Box>

          {/* Icon buttons on the right */}
          <InputRightElement width="auto">
            <Stack direction="row">
              <IconButton
                aria-label="clear"
                variant="unstyled"
                isDisabled={props.disabled}
                onClick={handleClear}
                icon={<CloseIcon />}
                display={day || month || year ? "flex" : "none"}
                color="blackAlpha.300"
                transition="all 0.3s ease"
                _hover={props.disabled ? {} : { color: "blackAlpha.800" }}
                data-testid={`${dataTestId}-clear`}
              />
              <IconButton
                aria-label="Open calendar to choose a date"
                variant="unstyled"
                isDisabled={props.disabled}
                onClick={onOpen}
                icon={<CalendarIcon />}
                backgroundColor="orange.400"
                outline="1px solid"
                outlineOffset="-1px"
                outlineColor="orange.400"
                borderEndRadius="5px"
                borderStartRadius="0px"
                transition="all 0.3s ease"
                _hover={
                  props.disabled
                    ? {}
                    : {
                        backgroundColor: "orange.500",
                        outlineColor: "orange.500",
                      }
                }
                data-testid={`${dataTestId}-calendar`}
              />
            </Stack>
          </InputRightElement>
        </InputGroup>
      </FormControl>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width={{ mobile: "100%", laptop: "fit-content" }}>
          <ModalHeader width="100%">
            {selectedDate ? `Selected: ${format(selectedDate, "EEEE dd/MM/yyyy")}` : "Pick a Date"}
            <ModalCloseButton size="lg" display={{ mobile: "flex", laptop: "none" }} />
          </ModalHeader>
          <ModalBody width="100%" padding="0">
            <Box width="100%">
              <DayPicker
                showOutsideDays
                showWeekNumber
                fixedWeeks
                weekStartsOn={1}
                month={calendarMonth}
                onMonthChange={setCalendarMonth}
                mode="single"
                selected={selectedDate}
                onSelect={handleDayPickerSelect}
                data-testid={`${dataTestId}-day-picker`}
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
