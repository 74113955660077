import React, { useEffect, useRef, useMemo } from "react";
import { Alert, AlertIcon, AlertDescription, CloseButton, Box, Stack, Progress } from "@chakra-ui/react";

/** Props for the AlertComponent component. */
export type AlertComponentProps = {
  /** The message to be displayed in the alert. */
  message: string;
  /** The severity level of the alert. */
  severity: "success" | "info" | "warning" | "error";
  /** Function to hide the alert. */
  hideAlert: () => void;
  /** Duration in milliseconds before the alert auto-hides. */
  autoHideDuration?: number;
  /** Flag to indicate if the progress bar should be indeterminate. */
  isIndeterminate?: boolean;
  /** Flag to indicate if the alert is shown on a mobile breakpoint, but device might be a laptop */
  maybeLaptop?: boolean;
};

/**
 * Returns the color scheme based on the alert severity
 *
 * @param severity - The severity level of the alert.
 * @returns The Chakra color scheme corresponding to the severity (e.g. "green", "gray", "orange", "red").
 */
const getColorScheme = (severity: string): string | undefined => {
  switch (severity) {
    case "success":
      return "green";
    case "info":
      return "gray";
    case "warning":
      return "orange";
    case "error":
      return "red";
    default:
      return undefined;
  }
};

/**
 * Returns the background color for the alert or progress track based on severity.
 *
 * @param severity - The severity level of the alert.
 * @returns A background color token (e.g. "green.100", "gray.100", "orange.100", "red.100") or undefined.
 */
const getBackgroundColor = (severity: string): string | undefined => {
  return (
    (severity === "success" && "green.100") ||
    (severity === "info" && "gray.100") ||
    (severity === "warning" && "orange.100") ||
    (severity === "error" && "red.100") ||
    undefined
  );
};

/**
 * AlertComponent to display alert messages with different severity levels, using a single setTimeout for auto-hide and
 * a CSS-based transition for the progress bar animation.
 */
export const AlertComponent: React.FC<AlertComponentProps> = (props) => {
  // Memoize the color scheme for the progress bar or components that require a color scheme token.
  const colorScheme = useMemo(() => getColorScheme(props.severity), [props.severity]);

  // Memoize the background color for the progress track or the wrapping container.
  const backgroundColor = useMemo(() => getBackgroundColor(props.severity), [props.severity]);

  // A ref to the progress bar DOM node for applying CSS transitions (width 100% -> 0%).
  const progressBarRef = useRef<HTMLDivElement | null>(null);

  /**
   * Handles the auto-hide timing with a single setTimeout (if autoHideDuration is defined and the progress is not
   * indeterminate)
   */
  useEffect(() => {
    if (!props.autoHideDuration || props.isIndeterminate) {
      return;
    }

    const timer = setTimeout(() => {
      props.hideAlert();
    }, props.autoHideDuration);

    return () => {
      clearTimeout(timer);
    };
  }, [props.autoHideDuration, props.isIndeterminate, props.hideAlert]);

  /** Triggers a CSS-based transition of the progress bar from 100% to 0% */
  useEffect(() => {
    if (!props.autoHideDuration || props.isIndeterminate) {
      return;
    }
    if (!progressBarRef.current) {
      return;
    }

    // Initialize the bar to full width (100%)
    progressBarRef.current.style.width = "100%";

    // Use requestAnimationFrame to ensure the browser registers the initial width
    requestAnimationFrame(() => {
      if (progressBarRef.current) {
        progressBarRef.current.style.transition = `width ${props.autoHideDuration}ms linear`;
        progressBarRef.current.style.width = "0%";
      }
    });
  }, [props.autoHideDuration, props.isIndeterminate, props.message]);

  /**
   * Closes the alert immediately when the close button is clicked.
   *
   * @param event - The optional click event.
   */
  const handleClose = (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event?.stopPropagation();
    props.hideAlert();
  };

  return (
    <Alert
      status={props.severity || "success"}
      colorScheme={colorScheme}
      variant="subtle"
      position="fixed"
      bottom={{ mobile: props.maybeLaptop ? "48px" : "0px", laptop: "48px" }}
      zIndex="overlay"
      width={{ mobile: props.maybeLaptop ? "lg" : "100%", laptop: "lg" }}
      borderRadius={{ mobile: props.maybeLaptop ? "md" : undefined, laptop: "md" }}
      right={{ mobile: "0px", laptop: "64px" }}
      alignItems="stretch"
      padding="4"
      data-testid="alert"
    >
      <Stack direction="column" spacing="0" width="100%">
        <Box width="100%" display="flex">
          <AlertIcon alignSelf="center" />
          <AlertDescription flex={1} alignContent="center" whiteSpace="pre-wrap">
            {props.message}
          </AlertDescription>
          <Box height="inherit" borderLeft="1px solid" borderColor="blackAlpha.200" marginX="2" />
          <Box onClick={handleClose} alignContent="center" cursor="pointer">
            <CloseButton alignSelf="center" onClick={handleClose} data-testid="alert-close-button" />
          </Box>
        </Box>

        {(props.autoHideDuration || props.isIndeterminate) && (
          <Box
            width="calc(100% + 32px)"
            marginBottom="-16px"
            marginX="-16px"
            paddingTop="2"
            background={backgroundColor}
          >
            {props.isIndeterminate ? (
              <Progress size="xs" isIndeterminate colorScheme={colorScheme} background={backgroundColor} />
            ) : (
              <Box
                ref={progressBarRef}
                height="3px"
                backgroundColor={`${colorScheme}.500`}
                width="0%"
                data-testid="alert-progress-bar"
              />
            )}
          </Box>
        )}
      </Stack>
    </Alert>
  );
};
