import { gql } from "@apollo/client";

export const GET_USER_ATHLETE = gql`
  query GetUserAthlete {
    getUserAthlete {
      id
      name
      birthYear
    }
  }
`;
