import { Button, FormControl, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import React, { useState } from "react";
import { CommonFormLabel } from "../CommonFormLabel";
import { commonStyles } from "../../utils/styles";

/** Props for the CommonInput component. */
export type CommonInputProps = {
  /** The current value of the input field. */
  value: string;
  /** Placeholder text for the input field. */
  placeholder: string;
  /** Callback function to handle changes in the input field. */
  onChange: (value: string) => void;
  /** Whether the input field is disabled. */
  disabled?: boolean;
  /** Whether the input field is required. */
  required?: boolean;
  /** The data-testid attribute to be added to the input field. */
  dataTestId?: string;
  /** The type of the input field. */
  type?: string;
  /** Whether the input field is read-only. */
  readOnly?: boolean;
  /** Minimum length of the input value. */
  minLength?: number;
  /** Maximum length of the input value. */
  maxLength?: number;
};

/** A common input component that supports different customization options. */
export const CommonInput: React.FC<CommonInputProps> = (props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;

    // Validate minimum length
    if (props.minLength !== undefined && newValue.length < props.minLength) {
      newValue = newValue.padEnd(props.minLength, "-"); // Fill to meet minLength
    }

    // Validate maximum length
    if (props.maxLength !== undefined && newValue.length > props.maxLength) {
      newValue = newValue.slice(0, props.maxLength); // Trim to meet maxLength
    }

    props.onChange(newValue);
  };

  //  TODO: set id from prop

  return (
    <InputGroup>
      <FormControl id="common-input">
        <CommonFormLabel display={!!props.value}>{props.placeholder}</CommonFormLabel>
        <Input
          placeholder={props.placeholder}
          value={props.value}
          onChange={handleChange}
          required={props.required}
          height="42px"
          opacity={props.disabled ? 0.6 : 1}
          isDisabled={props.disabled}
          type={props.type === "password" && isPasswordVisible ? "text" : props.type}
          paddingRight={props.type === "password" ? "14" : undefined}
          pointerEvents={props.readOnly ? "none" : "auto"}
          {...commonStyles(!!props.value)}
          data-testid={props.dataTestId}
        />
        {props.type === "password" && (
          <InputRightElement marginRight="2">
            <Button variant="unstyled" size="sm" onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
              {isPasswordVisible ? "Hide" : "Show"}
            </Button>
          </InputRightElement>
        )}
      </FormControl>
    </InputGroup>
  );
};
