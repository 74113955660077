import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Box, useBreakpoint } from "@chakra-ui/react";
import {
  Header,
  CalendarContainer,
  DrillLibraryContainer,
  AthleteContainer,
  OrganizationContainer,
  Login,
  AdminContainer,
  SkillSetsContainer,
  SeasonPlanContainer,
  CreateAccountRoute,
  TrainingManagementContainer,
  AccountContainer,
  ResetPassword,
  AthleteReadOnlyContainer,
} from "./features";
import { MobileHeader } from "./features/Header/MobileHeader";
import { CommonScrollbar } from "./common/components/CommonScrollbar";
import { parseToken } from "./features/Auth/utils";
import { useLazyQuery } from "@apollo/client";
import { GET_USER_ORGANIZATION } from "./common/graphql/get-user-organization.query";
import { OrganizationEventListener } from "./common/components/OrganizationEventListener/OrganizationEventListener";
import { useLogout } from "./features/Auth/hooks/useLogout";
import { useAuth } from "./features/Auth/components/AuthProvider";
import { AccessType, OrganizationStatus } from "./types";
import { useAlert } from "./common/components/AlertProvider";
import { addDays } from "date-fns";
import { extractDateFromUTC } from "./common/utils/dateAndTime";

/**
 * Main App component:
 *
 * - Checks localStorage for an Cognito idToken
 * - Conditionally renders public or authenticated routes based on the Cognito idToken
 */
const App: React.FC = () => {
  const [getUserOrganization, { data: organizationData, error: organizationError }] =
    useLazyQuery(GET_USER_ORGANIZATION);
  const { isAuthenticated, accessType, setIsAuthenticated, setAccessType, setEmail, setIsEmailVerified } = useAuth();
  const [isCheckingToken, setIsCheckingToken] = useState(true);

  const currentBreakPoint = useBreakpoint({ ssr: false });
  const logoutUser = useLogout();
  const { showAlert } = useAlert();

  /** Parse Cognito idToken and validate with backend */
  useEffect(() => {
    const idToken = localStorage.getItem("idToken");
    if (idToken) {
      // Parse Cognito idToken
      const { authStatus, accessType, email, isEmailVerified } = parseToken(idToken);
      setIsAuthenticated(authStatus);
      setAccessType(accessType);
      setEmail(email);
      setIsEmailVerified(isEmailVerified);

      // Validate Cognito idToken with backend by querying user organization
      getUserOrganization();
    }

    setIsCheckingToken(false);
  }, []);

  useEffect(() => {
    if (organizationError) {
      logoutUser(true);
    }
  }, [organizationError]);

  useEffect(() => {
    const organizationStatus = organizationData?.getUserOrganization?.status;
    if (organizationStatus === OrganizationStatus.PendingDeletion) {
      const effectiveDeletionDate = organizationData?.getUserOrganization?.pendingDeletionAt
        ? extractDateFromUTC(
            addDays(organizationData?.getUserOrganization?.pendingDeletionAt, 14).toISOString(),
            ".",
            true,
          )
        : "N/A";

      showAlert(
        `Your organization is scheduled for permanent deletion. All data will be permanently erased on ${effectiveDeletionDate}`,
        "warning",
      );
    } else if (organizationStatus === OrganizationStatus.Disabled) {
      showAlert(
        "Your organization is currently disabled. No changes or updates can be made until it is reactivated",
        "warning",
      );
    }
  }, [organizationData]);

  const renderRoutes = () => {
    if (!isAuthenticated) {
      // Public routes
      return (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/create-account" element={<CreateAccountRoute />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      );
    } else {
      // Authenticated routes
      const fullAccessRoutes = (
        <>
          <Route path="/" element={<CalendarContainer />} />
          <Route path="/season-plan" element={<SeasonPlanContainer />} />
          <Route path="/athletes" element={<AthleteContainer />} />
          <Route path="/training-management" element={<TrainingManagementContainer />} />
          <Route path="/skill-sets" element={<SkillSetsContainer />} />
          <Route path="/drill-library" element={<DrillLibraryContainer />} />
          <Route path="/organization" element={<OrganizationContainer />} />
          <Route path="/account" element={<AccountContainer />} />
          <Route path="/athletes-preview/:athleteId" element={<AthleteReadOnlyContainer />} />
        </>
      );

      switch (accessType) {
        case AccessType.Admin:
          return (
            <>
              <OrganizationEventListener />
              <Routes>
                {fullAccessRoutes}
                <Route path="/admin" element={<AdminContainer />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </>
          );
        case AccessType.Owner:
          return (
            <>
              <OrganizationEventListener />
              <Routes>
                {fullAccessRoutes}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </>
          );
        case AccessType.Full:
          return (
            <>
              <OrganizationEventListener />
              <Routes>
                {fullAccessRoutes}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </>
          );
        case AccessType.Athlete:
          return (
            <>
              <OrganizationEventListener />
              <Routes>
                <Route path="/dashboard" element={<AthleteReadOnlyContainer />} />
                <Route path="/account" element={<AccountContainer />} />
                <Route path="*" element={<Navigate to="/dashboard" replace />} />
              </Routes>
            </>
          );
        default:
          return <Navigate to="/login" replace />;
      }
    }
  };

  return (
    <Router>
      <Box>
        {currentBreakPoint === "mobile" ? <MobileHeader /> : <Header />}

        {isCheckingToken ? null : (
          <CommonScrollbar
            isMainContainer
            as="main"
            position="relative"
            top="56px"
            width="100%"
            height="calc(100svh - 56px)"
            paddingTop="8"
            paddingLeft="16"
            paddingBottom="12"
            paddingRight="12"
            bgColor="white"
            overflowY="visible"
            overflowX="hidden"
            data-testid="mainBox"
          >
            {renderRoutes()}
          </CommonScrollbar>
        )}
      </Box>
    </Router>
  );
};

export default App;
