import { gql } from "@apollo/client";

export const MARK_ORGANIZATION_PENDING_DELETION = gql`
  mutation MarkOrganizationPendingDeletion {
    markOrganizationPendingDeletion {
      id
      status
      pendingDeletionAt
    }
  }
`;
