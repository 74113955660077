import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  StepTitle,
  StepSeparator,
  Stack,
  Text,
  useSteps,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import { useAsyncMutation } from "../../../../common/hooks";
import { useAlert } from "../../../../common/components/AlertProvider";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { CommonButton } from "../../../../common/components/CommonButton";
import { CommonInput } from "../../../../common/components/CommonInput";
import { FORGOT_PASSWORD } from "../../graphql/forgot-password.mutation";
import { RESET_PASSWORD } from "../../graphql/reset-password.mutation";
import { slideInFromLeft, slideInFromRight, slideOutToLeft, slideOutToRight } from "../CreateAccount/utils";
import { useNavigate } from "react-router-dom";

export const ResetPassword: React.FC = () => {
  const headerRef = useRef<HTMLDivElement>(null);

  const steps = [{ title: "Request Reset Code" }, { title: "Set New Password" }];

  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const { activeStep, setActiveStep } = useSteps({ index: 0, count: steps.length });

  const [email, setEmail] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [changeStepAnimation, setChangeStepAnimation] = useState("");
  const animationTimeInMs = 600;
  const animationDurations = `${animationTimeInMs / 1000}s ease`;

  const { execute: forgotPassword, loading: forgotPasswordLoading } = useAsyncMutation(FORGOT_PASSWORD);
  const { execute: resetPassword, loading: resetPasswordLoading } = useAsyncMutation(RESET_PASSWORD, {
    onCustomError(error) {
      if (error.message.includes("Invalid")) {
        return error.message;
      } else {
        return undefined;
      }
    },
  });

  /** Scroll to top of the view whenever step changes */
  useEffect(() => {
    headerRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [activeStep]);

  /**
   * HandleStepChange
   *
   * Animates the step content out, then in from the correct direction.
   */
  const handleStepChange = (direction: "next" | "prev") => {
    if (direction === "next") {
      setChangeStepAnimation(`${slideOutToLeft} ${animationDurations}`);
      setTimeout(() => setChangeStepAnimation(`${slideInFromRight} ${animationDurations}`), animationTimeInMs / 2);
    } else {
      setChangeStepAnimation(`${slideOutToRight} ${animationDurations}`);
      setTimeout(() => setChangeStepAnimation(`${slideInFromLeft} ${animationDurations}`), animationTimeInMs / 2);
    }
  };

  const handleNext = async () => {
    // If not at final step, move to the next step
    if (activeStep < steps.length - 1) {
      if (activeStep === 0) {
        // Step 0: Forgot Password
        showAlert("Requesting password-reset code", "info", undefined, true);

        const result = await forgotPassword({ email });
        if (result?.forgotPassword) {
          showAlert(
            "If the provided email is verified, a reset code has been sent to your inbox. Please check your email",
            "success",
          );
          handleStepChange("next");
          setTimeout(() => setActiveStep(activeStep + 1), animationTimeInMs / 2);
        }
        return;
      }
    }

    if (activeStep === 1) {
      // Step 1: Confirm Forgot Password (final step)
      showAlert("Resetting your password", "info", undefined, true);
      const result = await resetPassword({
        email,
        code: resetCode,
        newPassword: newPassword,
      });

      if (result?.resetPassword) {
        showAlert(
          "Your password has been successfully reset! You can now log in with your new password",
          "success",
          5000,
        );
        navigate("/login");
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate("/login");
    } else {
      handleStepChange("prev");
      setTimeout(() => {
        setActiveStep(activeStep - 1);
      }, animationTimeInMs / 2);
    }
  };

  const validateInput = () => {
    const reasons = [];

    if (activeStep === 0) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!email) {
        reasons.push("Email field is required");
      } else if (!emailRegex.test(email)) {
        reasons.push("Please enter a valid email address (e.g., 'example@domain.com')");
      }
    }

    if (activeStep === 1) {
      if (!resetCode) {
        reasons.push("Reset code field is required");
      } else if (resetCode.length !== 6) {
        reasons.push("Reset code must be exactly 6 digits");
      }

      if (!newPassword) {
        reasons.push("New password field is required");
      } else if (newPassword.length < 8) {
        reasons.push("New password must be at least 8 characters long");
      }
    }

    return reasons;
  };

  const submitDisabledReasons = validateInput();
  const isFinalStep = activeStep === steps.length - 1;

  return (
    <Stack
      position="relative"
      direction="column"
      spacing={{ mobile: "4", laptop: "8" }}
      margin={{ mobile: undefined, laptop: "auto" }}
      maxWidth="600px"
      height={{ mobile: "auto", laptop: "100%" }}
      maxHeight={{ mobile: undefined, laptop: "1000px" }}
      overflow={{ mobile: undefined, laptop: "hidden" }}
      flex={1}
    >
      <LoadingOverlay display={forgotPasswordLoading || resetPasswordLoading} spinnerSize="xl" />

      {/* Scroll target for mobile, under the actual header */}
      <Box position="absolute" top="-56px" ref={headerRef} />

      {/* Stepper Header */}
      <Stack direction="column" spacing={{ mobile: "2", laptop: "4" }}>
        <Text fontSize="x-large" align="center">
          Reset Password
        </Text>
        <Text textAlign="center" color="blackAlpha.600" display={{ mobile: "block", laptop: "none" }}>
          Step {activeStep + 1} - {steps[activeStep].title}
        </Text>

        <Stepper size="md" index={activeStep}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
              </StepIndicator>
              <Box display={{ mobile: "none", laptop: "block" }}>
                <StepTitle>{step.title}</StepTitle>
              </Box>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      </Stack>

      {/* Step content */}
      <Box
        width="100%"
        height="100%"
        alignSelf="center"
        overflow={{ mobile: "auto", laptop: "auto" }}
        flex={{ mobile: undefined, laptop: 1 }}
        animation={{ mobile: undefined, laptop: changeStepAnimation }}
      >
        {activeStep === 0 && (
          <Stack spacing="4">
            <Text color="blackAlpha.600">
              Please enter the email address associated with your account. Only verified accounts will receive a reset
              code. If your account is unverified, you can't reset your password
            </Text>

            <CommonInput
              placeholder="Enter your email address"
              type="email"
              value={email}
              onChange={(value) => setEmail(value)}
            />
          </Stack>
        )}

        {activeStep === 1 && (
          <Stack spacing="4">
            <Text color="blackAlpha.600">
              Enter the 6-digit reset code sent to your email, and set a new password for your account
            </Text>

            <Grid templateColumns="1fr" gap={{ mobile: "4", laptop: "6" }}>
              <GridItem>
                <Text
                  paddingX="2"
                  paddingY="1"
                  borderRadius="md"
                  backgroundColor="teal.800"
                  color="white"
                  width="fit-content"
                >
                  {email}
                </Text>
              </GridItem>

              <GridItem>
                <CommonInput
                  placeholder="6-digit reset code"
                  type="number"
                  value={resetCode}
                  onChange={(val) => setResetCode(val)}
                />
              </GridItem>

              <GridItem>
                <CommonInput
                  placeholder="New password (min 8 characters)"
                  type="password"
                  value={newPassword}
                  onChange={(val) => setNewPassword(val)}
                />
              </GridItem>
            </Grid>
          </Stack>
        )}
      </Box>

      {/* Footer/Buttons */}
      <Stack direction="row" spacing="4" justifyContent="center">
        <CommonButton
          variantType="outlineSecondary"
          onClick={handleBack}
          disabled={forgotPasswordLoading || resetPasswordLoading}
        >
          {activeStep === 0 ? "Cancel" : "Back"}
        </CommonButton>
        <CommonButton
          fullWidth
          variantType="solidPrimary"
          buttonType="submit"
          onClick={handleNext}
          tooltip={submitDisabledReasons.join("\n")}
          disabled={forgotPasswordLoading || resetPasswordLoading || submitDisabledReasons.length > 0}
        >
          {isFinalStep ? "Reset Password" : "Continue"}
        </CommonButton>
      </Stack>
    </Stack>
  );
};
