import { Checkbox } from "@chakra-ui/react";
import React from "react";

export const CommonCheckbox: React.FC<{
  checked: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  large?: boolean;
}> = ({ children, checked, onChange, large }) => {
  return (
    <Checkbox
      size={large ? "lg" : "md"}
      isChecked={checked}
      onChange={onChange}
      iconColor="white"
      transition="all 0.3s ease"
      display="flex"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      __css={{
        ".chakra-checkbox__control": {
          transition: "all 0.3s ease",
          backgroundColor: checked ? "orange.400 !important" : undefined,
          borderColor: checked ? "orange.400 !important" : undefined,
          _hover: {
            backgroundColor: checked ? "orange.500 !important" : "transparent",
            borderColor: checked ? "orange.500 !important" : "orange.400 !important",
            cursor: "pointer",
          },
        },
      }}
    >
      {children}
    </Checkbox>
  );
};
