import React, { useState } from "react";
import { Box, Divider, Icon, Stack, Text, Tooltip } from "@chakra-ui/react";
import { useAlert } from "../../../../common/components/AlertProvider";
import { FormModal } from "../../../../common/components/FormModal";
import { CommonInput } from "../../../../common/components/CommonInput";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { CommonButton } from "../../../../common/components/CommonButton";
import { validateAndCheckChanges } from "../../../../common/utils/dataProcessing";
import { BillingInfo, BillingInput, AccessType, Invoice, Organization } from "../../../../types";
import { CountrySelect } from "./components/CountrySelect";
import { CommonCheckbox } from "../../../../common/components/CommonCheckbox";
import { SUPPORTED_BILLING_COUNTRIES } from "./utils";
import { CommonTable, type Column } from "../../../../common/components/CommonTable/CommonTable";
import { GetApp, OpenInNew } from "@mui/icons-material";
import { extractDateFromUTC } from "../../../../common/utils/dateAndTime";

/** Props for the Billing component. */
type BillingProps = {
  /** Current billing info from the server, or null if it could not be fetched. */
  billingInfo: BillingInfo | null;
  /** Indicates if any billing info is missing. */
  missingAnyBillingInfo: boolean;
  /** Indicates if the component is in loading state */
  loading: boolean;
  /** Mutation function to update the billing info. */
  updateBillingInfo: (data: BillingInput) => Promise<any>;
  /** Mutation function to generate the estimate invoice. Should return a base64-encoded PDF string. */
  generateEstimateInvoice: () => Promise<any>;
  /** Current user access type to determine if edit is allowed. */
  currentUserAccess: AccessType | null;
  /** Array of invoices for the organization. */
  invoices: Invoice[];
  /** Organization data. */
  organization: Organization;
};

// TODO: improve error handling on invalid VAT
// TODO: don't render in production before all free trial data is set

/** A component for displaying and editing the organization's billing info. */
export const Billing: React.FC<BillingProps> = (props) => {
  const { showAlert } = useAlert();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [estimateInvoice, setEstimateInvoice] = useState<string | null>(null);

  /** Temporary form state for the billing data. */
  const [tempData, setTempData] = useState<BillingInput>({
    address: props.billingInfo?.address ?? "",
    city: props.billingInfo?.city ?? "",
    country: props.billingInfo?.country,
    email: props.billingInfo?.email ?? "",
    name: props.billingInfo?.name ?? "",
    postalCode: props.billingInfo?.postalCode ?? "",
    vatNumber: props.billingInfo?.vatNumber ?? "",
  });

  const originalData: BillingInput = {
    address: props.billingInfo?.address ?? "",
    city: props.billingInfo?.city ?? "",
    country: props.billingInfo?.country,
    email: props.billingInfo?.email ?? "",
    name: props.billingInfo?.name ?? "",
    postalCode: props.billingInfo?.postalCode ?? "",
    vatNumber: props.billingInfo?.vatNumber ?? "",
  };

  const validationLogic = (current: BillingInput): string[] => {
    const reasons: string[] = [];
    if (!current.name || current.name.trim() === "") {
      reasons.push("Billing name cannot be empty");
    }
    return reasons;
  };

  const compareLogic = (current: BillingInput, initial: BillingInput): boolean => {
    return (
      current.address !== initial.address ||
      current.city !== initial.city ||
      current.country !== initial.country ||
      current.email !== initial.email ||
      current.name !== initial.name ||
      current.postalCode !== initial.postalCode ||
      current.vatNumber !== initial.vatNumber
    );
  };

  const { submitDisabledReasons, hasChanges } = validateAndCheckChanges(
    tempData,
    validationLogic,
    originalData,
    compareLogic,
  );

  const handleOpenEdit = (): void => {
    setTempData({
      address: props.billingInfo?.address ?? "",
      city: props.billingInfo?.city ?? "",
      country: props.billingInfo?.country,
      email: props.billingInfo?.email ?? "",
      name: props.billingInfo?.name ?? "",
      postalCode: props.billingInfo?.postalCode ?? "",
      vatNumber: props.billingInfo?.vatNumber ?? "",
    });
    setIsDialogOpen(true);
  };

  const handleSave = async (): Promise<void> => {
    setIsDialogOpen(false);
    showAlert("Updating billing info", "info", undefined, true);

    const result = await props.updateBillingInfo(tempData);
    if (result) {
      showAlert("Billing information updated!", "success", 5000);
    }
  };

  /** Calls the generateEstimateInvoice mutation, which should return a base64-encoded PDF string. */
  const handleGenerateInvoice = async (): Promise<void> => {
    setEstimateInvoice(null); // reset any previous esto,ate
    showAlert("Generating estimate for the next invoice", "info", undefined, true);

    // base64 PDF returned from the server
    const pdfBase64 = await props.generateEstimateInvoice();
    if (pdfBase64?.generateEstimateInvoice) {
      setEstimateInvoice(pdfBase64?.generateEstimateInvoice);
      showAlert("Estimate generated!", "success", 5000);
    }
  };

  /** Opens the PDF in a new browser tab using a Blob URL */
  const openPdfInNewTab = (): void => {
    if (!estimateInvoice) {
      return;
    }

    const byteCharacters = atob(estimateInvoice);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, "_blank");
  };

  const submitDisabledTooltip = submitDisabledReasons
    ? submitDisabledReasons
    : !hasChanges
      ? "No changes to save"
      : undefined;

  const canEditBillingInfo =
    props.currentUserAccess === AccessType.Admin || props.currentUserAccess === AccessType.Owner;

  const renderBillingRow = (label: string, value?: string | null) => {
    return (
      <Stack direction="row" justify="space-between" align="center">
        <Text fontSize="large" color="blackAlpha.600">
          {label}
        </Text>
        <Text fontSize="large" color={!value ? "red.500" : undefined}>
          {value ? value : "Not provided"}
        </Text>
      </Stack>
    );
  };

  const columns: Column<Invoice>[] = [
    {
      label: "Billing Period",
      key: "billingMonth",
      width: { mobile: "200px", laptop: "20%" },
      render: (invoice) => {
        const getMonthName = (monthNumber: number): string => {
          const date = new Date(0);
          date.setMonth(monthNumber - 1); // Month is 0-indexed
          return date.toLocaleString("en-US", { month: "long" });
        };

        return (
          <Text>
            {invoice.freeTrialInvoice ? "Free Trial" : `${getMonthName(invoice.billingMonth)} ${invoice.billingYear}`}
          </Text>
        );
      },
    },
    {
      label: "Status",
      key: "status",
      width: { mobile: "200px", laptop: "20%" },
      render: (invoice) => (
        <Text
          border="1px solid"
          borderRadius="md"
          borderColor={invoice.status === "paid" ? "teal.800" : "red.500"}
          textColor="white"
          backgroundColor={invoice.status === "paid" ? "teal.800" : "red.500"}
          width="fit-content"
          paddingY="1"
          paddingX="2"
        >
          {invoice.status === "paid" ? "Paid" : "Unpaid"}
        </Text>
      ),
    },
    {
      label: "Due Date",
      key: "dueDateTimestampMs",
      width: { mobile: "200px", laptop: "18%" },
      render: (invoice) => (
        <Text>{invoice.dueDateTimestampMs ? new Date(invoice.dueDateTimestampMs).toLocaleDateString() : "-"}</Text>
      ),
    },
    {
      label: "",
      key: "hostedInvoiceUrl",
      width: { mobile: "250px", laptop: "21%" },
      render: (invoice) => (
        <CommonButton
          fullWidth
          variantType="outlineSecondary"
          onClick={(e) => {
            e.stopPropagation();
            window.open(invoice.hostedInvoiceUrl, "_blank");
          }}
          tooltip="Open the invoice to review and pay online"
        >
          View Invoice {<Icon as={OpenInNew} marginLeft="2" />}
        </CommonButton>
      ),
    },
    {
      label: "",
      key: "invoicePdf",
      width: { mobile: "250px", laptop: "21%" },
      render: (invoice) => (
        <CommonButton
          fullWidth
          variantType="outlineSecondary"
          onClick={(e) => {
            e.stopPropagation();
            window.open(invoice.invoicePdf);
          }}
          tooltip="Download a copy of the invoice in PDF format"
        >
          Download PDF {<Icon as={GetApp} marginLeft="2" />}
        </CommonButton>
      ),
    },
  ];

  const isOnFreeTrial = props.organization?.freeTrialEnd
    ? new Date() < new Date(props.organization.freeTrialEnd)
    : false;
  const freeTrialEnd = props.organization?.freeTrialEnd
    ? extractDateFromUTC(props.organization.freeTrialEnd, ".", true)
    : "-";
  const oneTimeInvoice = props.invoices.find((invoice) => invoice.postFreeTrialOneTimeInvoice);
  const isPostFreeTrialOneTimeInvoicePaid = !oneTimeInvoice ? true : oneTimeInvoice.status === "paid";

  return (
    <Stack direction="column" spacing="12" position="relative" paddingTop="6">
      <LoadingOverlay display={props.loading} spinnerSize="xl" spinnerTopPosition="40px" />

      <Box paddingX="6">
        <Stack direction="row" spacing="4" marginBottom="6" width="100%" justifyContent="space-between">
          <Box flex={1} />

          <Text flex={1} fontSize="x-large" textAlign="center">
            Billing Details
          </Text>

          <Box flex={1} textAlign="end">
            <CommonButton
              variantType="outlineSecondary"
              onClick={handleOpenEdit}
              disabled={!canEditBillingInfo}
              tooltip={!canEditBillingInfo ? "Only the owner can edit billing information" : undefined}
            >
              Edit
            </CommonButton>
          </Box>
        </Stack>

        {props.missingAnyBillingInfo &&
          (isOnFreeTrial ? (
            <Text fontSize="large" color="red.500" marginBottom="6">
              Your organization's free trial ends on{" "}
              <Text as="span" decoration="underline">
                {freeTrialEnd}
              </Text>
              . Please complete your Billing Details before the trial ends to avoid your organization being disabled.
            </Text>
          ) : (
            <Text fontSize="large" color="red.500" marginBottom="6">
              Your organization is disabled because your Billing Details are incomplete and your free trial has ended.
              Please complete your Billing Details and pay the invoice to re-enable your organization.
            </Text>
          ))}

        {!props.missingAnyBillingInfo && !isPostFreeTrialOneTimeInvoicePaid && (
          <Text fontSize="large" color="red.500" marginBottom="6">
            Your organization is disabled because your initial invoice is unpaid. Please pay the invoice to re-enable
            your organization.
          </Text>
        )}

        <Stack direction="column" spacing="4" justifyContent="space-between">
          {renderBillingRow("Billing Name (organization's official name)", props.billingInfo?.name)}
          <Divider />
          {renderBillingRow("Email (for invoices and receipts)", props.billingInfo?.email)}
          <Divider />
          {renderBillingRow("City", props.billingInfo?.city)}
          <Divider />
          {renderBillingRow("Street Address", props.billingInfo?.address)}
          <Divider />
          {renderBillingRow("Postal Code", props.billingInfo?.postalCode)}
          <Divider />
          {renderBillingRow(
            "Country",
            SUPPORTED_BILLING_COUNTRIES.find((country) => country.code === props.billingInfo?.country)?.name,
          )}
          <Divider />
          {renderBillingRow("VAT Number (if applicable)", props.billingInfo?.vatNumber)}
        </Stack>
      </Box>

      <Box>
        <Stack direction="row" spacing="4" marginBottom="6" width="100%" justifyContent="space-between" paddingX="6">
          <Box flex={1} />
          <Text flex={1} fontSize="x-large" textAlign="center">
            Invoices
          </Text>

          <Box flex={1} textAlign="end">
            {estimateInvoice ? (
              <CommonButton
                variantType="solidPrimary"
                onClick={openPdfInNewTab}
                tooltip="View the generated estimate for the next invoice"
              >
                View Estimate
              </CommonButton>
            ) : (
              <CommonButton
                variantType="outlineSecondary"
                onClick={handleGenerateInvoice}
                disabled={!canEditBillingInfo || props.missingAnyBillingInfo || !!estimateInvoice}
                tooltip={
                  !canEditBillingInfo
                    ? "Only the owner can generate estimate"
                    : props.missingAnyBillingInfo
                      ? "Estimate can't be generated because you're missing Billing Details"
                      : "Generate an estimate for the next invoice"
                }
              >
                Generate Estimate
              </CommonButton>
            )}
          </Box>
        </Stack>

        <Text fontSize="medium" color="blackAlpha.600" marginBottom="4" paddingX="6">
          Invoices are generated on the 1st of each month
          {isOnFreeTrial ? (
            <Text as="span">
              {" "}
              starting after your organization's free trial, which ends on {freeTrialEnd}. They will appear here for
              review and payment.{" "}
            </Text>
          ) : (
            <Text as="span"> and they will appear here for review and payment. </Text>
          )}
          The amount of the invoice may vary each month depending on the number of coaches and athletes in your
          organization. You can always check your current charges by clicking the "Generate Estimate" button above.
        </Text>

        <CommonTable
          columns={columns}
          rows={props.invoices.map((invoice) => ({
            billingMonth: invoice.billingMonth,
            billingYear: invoice.billingYear,
            dueDateTimestampMs: invoice.dueDateTimestampMs,
            hostedInvoiceUrl: invoice.hostedInvoiceUrl,
            status: invoice.status,
            invoicePdf: invoice.invoicePdf,
            freeTrialInvoice: invoice.freeTrialInvoice,
            postFreeTrialOneTimeInvoice: invoice.postFreeTrialOneTimeInvoice,
          }))}
          emptyMessage="No Invoices"
        />
      </Box>

      <FormModal
        open={isDialogOpen}
        title="Edit Billing Information"
        submitButtonText="Confirm"
        handleSubmit={handleSave}
        onClose={() => setIsDialogOpen(false)}
        submitDisabled={!!submitDisabledReasons || !hasChanges}
        submitButtonHoverText={submitDisabledTooltip}
      >
        <Stack direction="column" spacing="6">
          <CommonInput
            placeholder="Official name of your organization (e.g., ACME Inc.)"
            value={tempData.name || ""}
            onChange={(value) => setTempData((prev) => ({ ...prev, name: value }))}
          />

          <CommonInput
            placeholder="Email for invoices (e.g., example@yourorganization.com)"
            value={tempData.email || ""}
            onChange={(value) => setTempData((prev) => ({ ...prev, email: value }))}
          />

          <CommonInput
            placeholder="City (e.g., Helsinki)"
            value={tempData.city || ""}
            onChange={(value) => setTempData((prev) => ({ ...prev, city: value }))}
          />

          <CommonInput
            placeholder="Street Address (e.g., 123 Main St)"
            value={tempData.address || ""}
            onChange={(value) => setTempData((prev) => ({ ...prev, address: value }))}
          />

          <CommonInput
            placeholder="Postal Code (e.g., 00100)"
            value={tempData.postalCode || ""}
            onChange={(value) => setTempData((prev) => ({ ...prev, postalCode: value }))}
          />

          <CountrySelect
            onChange={(countryCode) => setTempData((prev) => ({ ...prev, country: countryCode }))}
            initialValue={tempData.country}
          />

          <Tooltip label="Provide a VAT Number or select 'Not VAT-registered' if applicable">
            <Stack direction="row" alignItems="center" spacing="4">
              <CommonInput
                placeholder="VAT Number (e.g., FI12345678)"
                value={tempData.vatNumber || ""}
                disabled={tempData.vatNumber === "Not applicable"}
                onChange={(value) => setTempData((prev) => ({ ...prev, vatNumber: value }))}
              />

              <Text as="span" fontSize="sm" color="blackAlpha.600">
                Or
              </Text>

              <CommonCheckbox
                checked={tempData.vatNumber === "Not applicable"}
                large
                onChange={(e) =>
                  setTempData((prev) => ({
                    ...prev,
                    vatNumber: e.target.checked ? "Not applicable" : "",
                  }))
                }
              >
                <Text fontSize="small" noOfLines={1} width="max-content">
                  My organization is not VAT-registered
                </Text>
              </CommonCheckbox>
            </Stack>
          </Tooltip>
        </Stack>
      </FormModal>
    </Stack>
  );
};
