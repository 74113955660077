/** Base type for entities with an optional id and removable flag */
export type BaseEntity = {
  /** Unique identifier */
  id?: string;
  /** Indicates if the entity should be removed */
  remove?: boolean;
  /** Autogenerated by graphql */
  __typename?: string;
};

/** Base type for entities with name */
type NamedEntity = BaseEntity & {
  /** Name of the entity */
  name: string;
};

type TrainingEventDetails = BaseEntity & {
  eventId: string;
  eventStartDateTime: string;
  eventEndDateTime: string;
  eventCenter: string;
  eventVenue: string;
  eventTrainingGroupName: string;
  eventCoaches?: string;
};

/** Represents a note with content and creation date */
export type Note = BaseEntity & {
  /** Content of the note */
  content: string;
  /** Date when the note was created */
  createdAt?: string;
  /** User who created the note */
  createdBy?: OrganizationUser;
  isGroupNote?: boolean;
  athletes?: Athlete[];
  trainingEventDetails?: TrainingEventDetails;
};

export type NoteInput = {
  id?: string;
  content: string;
  athleteIds: string[];
  isGroupNote?: boolean;
  remove?: boolean;
};

export enum LogType {
  ParticipationWithdrawn = "ParticipationWithdrawn",
  JoinedReplacementEvent = "JoinedReplacementEvent",
  EventCancelled = "EventCancelled",
  ParticipatedInSingleTrainingEvent = "ParticipatedInSingleTrainingEvent",
}

/** Represents a log with description and creation date */
export type Log = BaseEntity & {
  createdAt: string;
  athlete: Athlete;
  type: LogType;
  eventId: string;
  eventStartDateTime: string;
  eventEndDateTime: string;
  eventVenue: string;
  eventCenter: string;
  eventTrainingGroupName: string;
  description?: string;
  eventCoaches?: string;
};

export type SkillEvaluation = BaseEntity & {
  skill: Skill;
  rating: number;
  comment?: string;
};

export type SkillSetEvaluation = BaseEntity & {
  skillSet: SkillSet;
  skillEvaluations: SkillEvaluation[];
  rating?: number;
  comment?: string;
};

export type Evaluation = BaseEntity & {
  skillSetEvaluations: SkillSetEvaluation[];
  overallRating?: number;
};

/** Represents a athlete with associated training events and a progress framework */
export type Athlete = NamedEntity & {
  /** List of Training Groups associated with the athlete */
  trainingGroups?: TrainingGroup[];
  notes?: Note[];
  logs?: Log[];
  /** List of Season Plans associated with the athlete (atm used only with TrainingGroupEvent) */
  seasonPlans?: SeasonPlan[];
  evaluation?: Evaluation;
  invite?: Invite;
  birthYear?: number | null;
};

/** Represents a drill with specific details */
export type Drill = NamedEntity & {
  /** Goal of the drill */
  goal: string;
  /** How the drill is to be implemented */
  implementation: string;
  /** Tags associated with the drill */
  tags?: string[];
  /** Coaches associated with the drill */
  coaches?: Coach[];
  /** Date when the drill was created */
  createdAt?: string;
  /** Date when the drill was last updated */
  updatedAt?: string;
  /** User who created the drill */
  createdBy?: OrganizationUser;
  /** Skill Sets associated with the drill */
  skillSets?: SkillSet[];
};

/** Extended Drill entity, which is part of Session */
type SessionDrill = {
  /** ID of the SessionDrill */
  id: string;
  /** Related Drill */
  drill: Drill;
  /** Duration of the Drill (in minutes) on Session */
  duration: number;
};

/** Represents a training session including drills and total duration */
export type Session = NamedEntity & {
  /** The goal of the session */
  goal: string;
  /** The tags associated with the session */
  tags?: string[];
  /** The drills included in the session (with durations) */
  sessionDrills: SessionDrill[];
  /** The total duration of the session */
  totalDuration: number;
  /** Date when the drill was created */
  createdAt?: string;
  /** Date when the drill was last updated */
  updatedAt?: string;
  /** User who created the drill */
  createdBy?: OrganizationUser;
  /** Skill Sets associated with the session */
  skillSets?: SkillSet[];
};

/** Represents a coach with a unique identifier and name */
export type Coach = NamedEntity & {
  user?: OrganizationUser;
};

/** Represents a venue with a unique identifier and name */
export type Venue = NamedEntity & {
  /** The center where the venue is located */
  center?: Center;
};

/** Represents the base properties for training events */
type BaseTrainingEvent = BaseEntity & {
  /** The venue of the training event */
  venue: Venue;
  /** Array of coaches associated with the training event */
  coaches?: Coach[];
  /** Array of athletes participating in the training event */
  athletes?: Athlete[];
};

/** Represents the base properties for metadata of training events */
type BaseTrainingEventMetadata = BaseTrainingEvent & {
  /** Start time of the training event, UTC, e.g. "2023-09-05T11:30:00.000Z" */
  startDateTime: string;
  /** End time of the training event, UTC, e.g. "2023-09-05T13:00:00.000Z" */
  endDateTime: string;
  /** Array of notes associated with the training event */
  notes?: Note[];
  /** Array of drills associated with the training event */
  drills?: Drill[];
  /** The session associated with the training event */
  session?: Session;
};

/** Represents a TrainingGroup event in the database */
export type TrainingGroup = BaseTrainingEvent & {
  /**
   * The weekday when the training event occurs, represented as a number (0 for Sunday, 1 for Monday, 2 for Tuesday,
   * etc.)
   */
  recurrenceWeekday: number;
  /** The start time of the training event, formatted as "HH:mm", e.g. "09:00" */
  recurrenceStartTime: string;
  /** The end time of the training event, formatted as "HH:mm", e.g. "11:30" */
  recurrenceEndTime: string;
  /** The name of the training event */
  name: string;
  /** The season plans associated with the training event */
  seasonPlans?: SeasonPlan[];
  /** Related Training Season of the event */
  trainingSeason: TrainingSeason;
};

/** Represents the trainingGroupEvent of a training group */
export type TrainingGroupEvent = BaseTrainingEventMetadata & {
  /** The details of the TrainingGroup */
  trainingGroup: TrainingGroup;
};

/** Represents a single training event in the database */
export type SingleTrainingEvent = BaseTrainingEventMetadata;

export enum OrganizationStatus {
  Active = "Active",
  Disabled = "Disabled",
  PendingDeletion = "PendingDeletion",
}

/** Represents an organization with a unique identifier, name, and users */
export type Organization = NamedEntity & {
  /** Array of users associated with the organization */
  users: OrganizationUser[];
  /** Expected number of athletes per training event */
  expectedAthletesPerTrainingEvent: number;
  /** Minimum value of the evaluation scale */
  evaluationScaleMin: number;
  /** Maximum value of the evaluation scale */
  evaluationScaleMax: number;
  /** Date when the free trial ends */
  freeTrialEnd?: string;
  /** Status of the organization */
  status: OrganizationStatus;
  /** Date when the organization was marked for deletion */
  pendingDeletionAt?: string;
};

export enum AccessType {
  Owner = "Owner",
  Admin = "Admin",
  Full = "Full",
  Limited = "Limited",
  Athlete = "Athlete",
}

/** Represents a user within an organization */
export type OrganizationUser = BaseEntity & {
  /** Username of the user */
  username: string;
  createdAt?: string;
  accessType?: AccessType;
};

/** Represents a center with a unique identifier, name, and venues */
export type Center = NamedEntity & {
  /** Array of venues associated with the center */
  venues: Venue[];
};

export type MissingTrainingGroupEvent = BaseEntity & {
  trainingGroupId: string;
  startDateTime: string;
  athletes: Athlete[];
  venue: Venue;
  coaches: Coach[];
  trainingGroup: TrainingGroup;
};

export type Skill = NamedEntity;

export type SkillSet = NamedEntity & {
  skills: Skill[];
};

type SeasonPlanSkillSet = BaseEntity & {
  skillSet: SkillSet;
  skills: Skill[];
};

export type SeasonPlan = BaseEntity & {
  startDateTime: string;
  endDateTime: string;
  skillSets: SeasonPlanSkillSet[];
  trainingGroups?: TrainingGroup[];
  athletes?: Athlete[];
  isOrganizationWide?: boolean;
};

export type EvaluationCriteria = BaseEntity & {
  fromRatingLevel: number;
  toRatingLevel: number;
  definition: string;
  requirements: string;
  skillSetId?: string;
};

export enum TrainingSeasonStatus {
  Completed = "Completed",
  Active = "Active",
  Upcoming = "Upcoming",
}

export type TrainingSeason = NamedEntity & {
  seasonStart: string;
  seasonEnd: string;
  status: TrainingSeasonStatus;
};

type DeletionInfo = {
  id: string;
  name: string;
  type: string;
};

export type DeletionNode = {
  data: DeletionInfo;
  children: DeletionNode[];
};

export enum DeletionInfoEntityType {
  TrainingSeason = "TrainingSeason",
  SkillSet = "SkillSet",
  Skill = "Skill",
  TrainingGroup = "TrainingGroup",
  Venue = "Venue",
  Center = "Center",
}

export type DeletionInfoInput = {
  id: string;
  entityType: DeletionInfoEntityType;
};

export type OrganizationInput = {
  id?: string;
  name?: string;
  expectedAthletesPerTrainingEvent?: number;
  evaluationScaleMin?: number;
  evaluationScaleMax?: number;
  remove?: boolean;
};
export enum InviteType {
  CreateOrganization = "CreateOrganization",
  JoinOrganizationFullAccess = "JoinOrganizationFullAccess",
  JoinOrganizationLimitedAccess = "JoinOrganizationLimitedAccess",
  JoinOrganizationAthleteAccess = "JoinOrganizationAthleteAccess",
}

export type Invite = BaseEntity & {
  createdAt: string;
  updatedAt: string;
  used: boolean;
  type: InviteType;
  organizationId?: string;
  organizationName?: string;
  athlete?: Athlete;
  inviteCode: string;
  receiver?: string;
  usedAt?: string;
  coach?: Coach;
};

export type CreateInviteInput = {
  receiver: string;
  inviteType: InviteType;
  coachId: string;
};

export type BillingInfo = {
  id: string;
  name: string;
  address: string;
  city: string;
  email: string;
  postalCode: string;
  country: string;
  vatNumber: string;
};

export type BillingInput = {
  name?: string;
  address?: string;
  city?: string;
  email?: string;
  postalCode?: string;
  country?: string;
  vatNumber?: string;
};

export type Invoice = {
  billingMonth: number;
  billingYear: number;
  dueDateTimestampMs: number;
  hostedInvoiceUrl: string;
  invoicePdf: string;
  status: "draft" | "open" | "paid" | "uncollectible" | "void";
  freeTrialInvoice?: boolean;
  postFreeTrialOneTimeInvoice?: boolean;
};
