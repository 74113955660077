import React, { useEffect, useState } from "react";
import { Box, Stack, Text } from "@chakra-ui/react";
import { CommonScrollbar } from "../../../../common/components/CommonScrollbar";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import type { TrainingGroupEvent, SingleTrainingEvent } from "../../../../types";
import { TrainingEventCard } from "./components/TrainingEventCard";
import { TRAINING_EVENTS_PAGINATION_LIMIT } from "../../containers/AthleteContainer";
import { PaginationControls } from "../../../../common/components/PaginationControls";

/** Props for TrainingEvents component. Single unified pagination for all events in 'allEvents'. */
type TrainingEventsProps = {
  /** Total number of events in the paginated list */
  totalEventCount: number;
  /** Combined events array (both TrainingGroupEvent & SingleTrainingEvent) */
  allEvents: (TrainingGroupEvent | SingleTrainingEvent)[];
  /** Section title, e.g. "Upcoming Training Events" */
  title: string;
  /** If true, show loading spinner overlay. */
  isLoading?: boolean;
  /** Athlete ID for pagination callback. */
  athleteId: string;
  /** Current page number */
  page: number;
  /** Called when user clicks 'Next page' */
  onNextPage: (athleteId: string) => void;
  /** Called when user clicks 'Previous page' */
  onPrevPage: (athleteId: string) => void;
};

/** Displays a single list of training events with a single pagination. */
export const TrainingEvents: React.FC<TrainingEventsProps> = (props) => {
  const [displayedTotalCount, setDisplayedTotalCount] = useState<number>(props.totalEventCount);
  const [displayedEvents, setDisplayedEvents] = useState<(TrainingGroupEvent | SingleTrainingEvent)[]>([]);

  useEffect(() => {
    if (!props.isLoading) {
      setDisplayedTotalCount(props.totalEventCount);
      setDisplayedEvents(props.allEvents);
    }
  }, [props.allEvents, props.isLoading]);

  return (
    <Box
      position="relative"
      width="100%"
      minHeight={{ laptop: "400px" }}
      maxHeight={{ laptop: "75svh" }}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <LoadingOverlay display={props.isLoading || false} spinnerSize="xl" spinnerTopPosition="50%" />

      {/* Title */}
      <Text
        padding="6"
        paddingTop={{ mobile: "0", laptop: "6" }}
        fontSize="xl"
        width={{ laptop: "fit-content" }}
        alignSelf={{ mobile: "center", laptop: "start" }}
        fontWeight="bold"
      >
        {`${props.title} (${displayedTotalCount})`}
      </Text>

      {/* Scrollable content */}
      <CommonScrollbar
        height="auto"
        overflow="auto"
        invisibleBorderWidth="0px 8px 0px 0px"
        paddingRight="2"
        paddingLeft="6"
        paddingBottom="6"
        marginBottom="2"
        flexGrow={1}
      >
        {displayedEvents.length === 0 ? (
          <Text color="blackAlpha.600">No Training Events</Text>
        ) : (
          <Stack direction="column" spacing="6">
            {displayedEvents.map((event) => (
              <TrainingEventCard key={event.id} event={event} isOneTimeTrainingEvent={!("trainingGroup" in event)} />
            ))}
          </Stack>
        )}
      </CommonScrollbar>

      <PaginationControls
        currentPage={props.page}
        itemsPerPage={TRAINING_EVENTS_PAGINATION_LIMIT}
        onNextPage={() => props.onNextPage(props.athleteId)}
        onPrevPage={() => props.onPrevPage(props.athleteId)}
        totalItems={displayedTotalCount}
        disableButtons={props.isLoading}
      />
    </Box>
  );
};
