import React from "react";
import { Box, Text, Stack } from "@chakra-ui/react";
import { CommonIconButton } from "../CommonIconButton";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

export type PaginationControlsProps = {
  /** The current page number (1-based). */
  currentPage: number;
  /** The total number of items (e.g. notes, events). */
  totalItems: number;
  /** The number of items per page. */
  itemsPerPage: number;
  /** Callback when the user clicks to go to the previous page. */
  onPrevPage: () => void;
  /** Callback when the user clicks to go to the next page. */
  onNextPage: () => void;
  /**
   * Pass `true` if the previous or next button should be disabled even if not on the first or last page. For example,
   * you may want to disable pagination while data is loading.
   */
  disableButtons?: boolean;
};

/**
 * Reusable pagination component that shows "Previous" and "Next" buttons and displays the page range (e.g. "1-10 of
 * 30").
 */
export const PaginationControls: React.FC<PaginationControlsProps> = (props) => {
  const step = props.itemsPerPage;

  // Calculate total pages based on total items.
  const totalPages = Math.ceil(props.totalItems / props.itemsPerPage);

  // Edge-case: totalItems = 0 -> totalPages = 1 to avoid dividing by zero.
  const safeTotalPages = totalPages === 0 ? 1 : totalPages;

  // The first item on the current page (1-based index).
  const startIndex = (props.currentPage - 1) * props.itemsPerPage + 1;

  // The last item on the current page (1-based index).
  let endIndex = props.currentPage * props.itemsPerPage;
  if (endIndex > props.totalItems) {
    endIndex = props.totalItems;
  }

  // Default text: "startIndex-endIndex of totalItems"
  const defaultPaginationText = props.totalItems === 0 ? `0 of 0` : `${startIndex}-${endIndex} of ${props.totalItems}`;

  // Check if prev or next should be disabled
  const isPrevDisabled = props.disableButtons || props.currentPage <= 1;
  const isNextDisabled = props.disableButtons || props.currentPage >= safeTotalPages;

  return (
    <Box
      paddingX={{ mobile: "0", laptop: "6" }}
      paddingY="4"
      borderTopWidth={{ mobile: "0px", laptop: "1px" }}
      borderColor="blackAlpha.300"
    >
      <Stack direction="row" spacing="2" justifyContent="center" alignItems="center">
        <CommonIconButton
          ariaLabel="previous"
          onClick={props.onPrevPage}
          icon={<ChevronLeftIcon boxSize="6" color="blackAlpha.800" />}
          disabled={isPrevDisabled}
          tooltip={`Previous ${props.itemsPerPage} items`}
        />

        <Text minWidth="112px" textAlign="center">
          {defaultPaginationText}
        </Text>

        <CommonIconButton
          ariaLabel="next"
          onClick={props.onNextPage}
          icon={<ChevronRightIcon boxSize="6" color="blackAlpha.800" />}
          disabled={isNextDisabled}
          tooltip={`Next ${step} items`}
        />
      </Stack>
    </Box>
  );
};
