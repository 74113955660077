import React, { useState } from "react";
import { Venue, DeletionInfoEntityType, type DeletionNode } from "../../../../../types";
import { useAlert } from "../../../../../common/components/AlertProvider";
import { sortNames } from "../../../../../common/utils/dataProcessing";
import { Box, Grid, GridItem, SimpleGrid, Stack, Text, Tooltip } from "@chakra-ui/react";
import { FormModal } from "../../../../../common/components/FormModal";
import { CommonButton } from "../../../../../common/components/CommonButton";
import { CommonIconButton } from "../../../../../common/components/CommonIconButton";
import { CommonInput } from "../../../../../common/components/CommonInput";
import { LoadingOverlay } from "../../../../../common/components/LoadingOverlay";
import type { VenueInput } from "../../../containers/OrganizationContainer";
import { DeletionInfo } from "../../../../../common/components/DeletionInfo";
import { CommonScrollbar } from "../../../../../common/components/CommonScrollbar";

type VenuesProps = {
  title: string;
  venueCount: number;
  allVenues: Venue[];
  centerId: string | null;
  isLoading: boolean;
  saveVenue: (data: VenueInput, centerId: string) => Promise<any>;
  entitiesToBeRemoved: DeletionNode[];
  entitiesToBeRemovedLoading: boolean;
  getEntitiesToBeRemoved: (id: string) => Promise<void>;
};

export const Venues: React.FC<VenuesProps> = (props) => {
  const [selectedVenue, setSelectedVenue] = useState<Venue | null>(null);
  const [isNewVenue, setIsNewVenue] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { showAlert } = useAlert();

  const handleOpenDialog = (venue: Venue) => {
    setSelectedVenue(venue);
    setIsDialogOpen(true);
  };

  const handleSaveVenue = async (remove: boolean) => {
    if (!selectedVenue || !props.centerId) return;
    if (remove) {
      showAlert("Deleting Venue", "info", undefined, true);
    } else if (selectedVenue?.id) {
      showAlert("Updating Venue", "info", undefined, true);
    } else {
      showAlert("Creating new Venue", "info", undefined, true);
    }

    setIsNewVenue(false);
    setIsDialogOpen(false);

    const { __typename, center, ...dataToSave } = selectedVenue;
    const result = await props.saveVenue({ ...dataToSave, remove }, props.centerId);

    if (result) {
      showAlert(remove ? "Venue deleted!" : selectedVenue?.id ? "Venue updated!" : "Venue created!", "success", 5000);
    }
  };

  const handleSubmit = () => {
    handleSaveVenue(false);
  };

  const handleRemove = () => {
    handleSaveVenue(true);
  };

  const handleClose = () => {
    setIsNewVenue(false);
    setIsDialogOpen(false);
  };

  const getSubmitDisabledReasons = (): string | undefined => {
    const venueNames = props.allVenues.map((venue) => venue.name.toLowerCase());
    if (venueNames.includes(selectedVenue?.name.toLowerCase() || "")) {
      return `Venue "${selectedVenue?.name}" already exists`;
    } else if (selectedVenue?.name.trim() === "") {
      return "Venue name cannot be empty";
    }
    return undefined;
  };

  const submitDisabledReasons = getSubmitDisabledReasons();

  const featurePurpose =
    "Venues are specific training areas within a Center. Add Venues to define where training events occur";

  return (
    <Box position="relative" paddingY="6" data-testid="venues">
      <LoadingOverlay display={props.isLoading} spinnerSize="xl" spinnerTopPosition="40px" />
      <Stack direction="row" spacing="4" marginBottom="6" width="100%" justifyContent="center">
        <Stack direction="row" spacing="1">
          <Tooltip label={featurePurpose}>
            <Text fontSize="x-large" noOfLines={1}>
              {props.title}
            </Text>
          </Tooltip>
          <Text fontSize="x-large">({props.venueCount})</Text>
        </Stack>
        <CommonIconButton
          height="36px"
          onClick={() => {
            setIsNewVenue(true);
            const newVenue: Venue = { name: "" };
            handleOpenDialog(newVenue);
          }}
          disabled={!props.centerId}
          tooltip={!props.centerId ? "No Center selected, select one first before creating a new Venue" : undefined}
          dataTestId="add-venue"
        />
      </Stack>
      <CommonScrollbar
        maxHeight="30svh"
        overflow="auto"
        paddingLeft="6"
        paddingRight="2"
        invisibleBorderWidth="0px 8px 0px 0px"
      >
        <SimpleGrid
          gap="4"
          width={props.allVenues.length < 3 ? "fit-content" : "100%"}
          columns={props.allVenues.length < 3 ? props.allVenues.length : 3}
          justifySelf={props.allVenues.length < 3 ? "center" : "start"}
        >
          {props.allVenues.length === 0 ? (
            <GridItem colSpan={4}>
              <Text
                fontSize="large"
                textAlign="center"
                color="blackAlpha.600"
                noOfLines={props.centerId ? undefined : 1}
                data-testid="no-venues"
              >
                {props.centerId ? featurePurpose : "Select a Center to manage its Venues"}
              </Text>
            </GridItem>
          ) : (
            props.allVenues
              .slice()
              .sort((a, b) => sortNames(a.name, b.name))
              .map((venue, index) => (
                <GridItem key={index} colSpan={1} data-testid="venue-grid">
                  <CommonButton
                    variantType="outlineSecondary"
                    fullWidth
                    onClick={() => handleOpenDialog(venue)}
                    dataTestId={`venue-${index}`}
                  >
                    <Text fontSize="medium" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                      {venue.name}
                    </Text>
                  </CommonButton>
                </GridItem>
              ))
          )}
        </SimpleGrid>
      </CommonScrollbar>
      <FormModal
        key={selectedVenue?.id}
        handleSubmit={handleSubmit}
        open={isDialogOpen}
        submitButtonText={isNewVenue ? "Create" : "Confirm"}
        title={isNewVenue ? "Create new Venue" : "Edit Venue"}
        onClose={handleClose}
        handleRemove={handleRemove}
        confirmationDialogTitle="Delete Venue"
        submitDisabled={!!submitDisabledReasons}
        submitButtonHoverText={submitDisabledReasons}
        confirmationDialogMessage={
          <DeletionInfo
            entityName={selectedVenue?.name || ""}
            entitiesToBeRemoved={props.entitiesToBeRemoved}
            entityType={DeletionInfoEntityType.Venue}
            loading={props.entitiesToBeRemovedLoading}
          />
        }
        onConfirmationDialogOpen={async () => await props.getEntitiesToBeRemoved(selectedVenue?.id || "")}
        isConfirmingDeletion={true}
      >
        <Grid templateColumns="repeat(2, 1fr)" data-testid="venue-modal">
          <GridItem colSpan={2}>
            <CommonInput
              placeholder="Venue name"
              value={selectedVenue?.name || ""}
              onChange={(value) => {
                setSelectedVenue({ ...selectedVenue!, name: value });
              }}
              dataTestId="venue-name"
            />
          </GridItem>
        </Grid>
      </FormModal>
    </Box>
  );
};
