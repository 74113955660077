import { gql } from "@apollo/client";

export const GET_USER_ORGANIZATION = gql`
  query GetUserOrganization {
    getUserOrganization {
      id
      name
      expectedAthletesPerTrainingEvent
      evaluationScaleMin
      evaluationScaleMax
      freeTrialEnd
      status
      pendingDeletionAt
      users {
        id
        username
        accessType
        createdAt
      }
    }
  }
`;
