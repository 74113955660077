import { useEffect, useRef } from "react";
import { useAlert } from "../components/AlertProvider";
import { handleError } from "./utils/handleErrors";
import { useLogout } from "../../features/Auth/hooks/useLogout";

/**
 * A custom hook for handling errors from GraphQL queries. Displays error messages to the user and avoids showing
 * duplicate messages within the same render cycle.
 *
 * @param errors - An array of error objects from GraphQL queries.
 */
export const useHandleQueryErrors = (errors: any[]) => {
  const { showAlert } = useAlert();
  const previousErrorMessagesRef = useRef<string[]>([]);
  const logoutUser = useLogout();

  useEffect(() => {
    const filteredErrors = errors.filter(Boolean);

    const { message, hasAuthenticationError } = handleError(filteredErrors);

    if (hasAuthenticationError) {
      logoutUser(true);
      return;
    }

    if (message) {
      // Check if the error message is different from the previous one to avoid duplicate alerts
      if (!previousErrorMessagesRef.current.includes(message)) {
        showAlert(message, "error");
        previousErrorMessagesRef.current.push(message);
      }
    }
  }, [errors, showAlert, logoutUser]);
};
