import React, { useEffect, useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Box, useBreakpoint } from "@chakra-ui/react";
import { LoadingContainer, loadingContainerFadeIn } from "../../../common/components/LoadingContainer";
import { Calendar } from "../components/Calendar";
import { GET_ALL_TRAINING_GROUP_EVENTS_FOR_CALENDAR } from "../graphql/get-all-training-group-events.query";
import { GET_ALL_DRILLS } from "../../../common/graphql/get-all-drills.query";
import { GET_ALL_SESSIONS } from "../../../common/graphql/get-all-sessions.query";
import { GET_ALL_ATHLETES_FOR_CALENDAR } from "../graphql/get-all-athletes-for-calendar.query";
import { GET_ALL_COACHES } from "../../../common/graphql/get-all-coaches.query";
import { GET_ALL_SINGLE_TRAINING_EVENTS } from "../graphql/get-all-single-training-events.query";
import { GET_ALL_SKILL_SETS } from "../../../common/graphql/get-all-skill-sets.query";
import { SAVE_SINGLE_TRAINING_EVENT } from "../graphql/save-single-training-event.mutation";
import { UPDATE_TRAINING_GROUP_EVENT } from "../graphql/update-training-group-event.mutation";
import { useAsyncMutation, useHandleQueryErrors } from "../../../common/hooks";
import { GET_ALL_CENTERS } from "../../../common/graphql/get-all-centers.query";
import { GET_TRAINING_GROUP_EVENT } from "../graphql/get-training-group-event.query";
import type { SingleTrainingEvent, TrainingGroupEvent } from "../../../types";
import { GET_SINGLE_TRAINING_EVENT } from "../graphql/get-single-training-event.query";
import { GET_USER_ORGANIZATION } from "../../../common/graphql/get-user-organization.query";

export const CalendarContainer: React.FC = () => {
  const currentBreakPoint = useBreakpoint({ ssr: false });

  // GraphQL queries
  const {
    data: trainingGroupEventsData,
    loading: trainingGroupEventsLoading,
    error: trainingGroupEventsError,
  } = useQuery(GET_ALL_TRAINING_GROUP_EVENTS_FOR_CALENDAR);
  const { data: drillData, loading: drillLoading, error: drillError } = useQuery(GET_ALL_DRILLS);
  const { data: sessionData, loading: sessionLoading, error: sessionError } = useQuery(GET_ALL_SESSIONS);
  const { data: athleteData, loading: athleteLoading, error: athleteError } = useQuery(GET_ALL_ATHLETES_FOR_CALENDAR);
  const { data: coachData, loading: coachLoading, error: coachError } = useQuery(GET_ALL_COACHES);
  const {
    data: singleTrainingEventData,
    loading: singleTrainingEventLoading,
    error: singleTrainingEventError,
  } = useQuery(GET_ALL_SINGLE_TRAINING_EVENTS);
  const { data: skillSetsData, loading: skillSetsLoading, error: skillSetsError } = useQuery(GET_ALL_SKILL_SETS);
  const { data: centerData, loading: centerLoading, error: centerError } = useQuery(GET_ALL_CENTERS);
  const {
    data: organizationData,
    loading: organizationDataLoading,
    error: organizationDataError,
  } = useQuery(GET_USER_ORGANIZATION);
  const [getTrainingGroupEvent, { error: trainingGroupEventError }] = useLazyQuery(GET_TRAINING_GROUP_EVENT, {
    fetchPolicy: "network-only", // for notes
  });
  const [getSingleTrainingEvent, { error: getSingleTrainingEventError }] = useLazyQuery(GET_SINGLE_TRAINING_EVENT, {
    fetchPolicy: "network-only", // for notes
  });

  // GraphQL mutations
  const { execute: saveSingleTrainingEvent } = useAsyncMutation(SAVE_SINGLE_TRAINING_EVENT, {
    awaitRefetchQueries: true,
  });
  const { execute: updateTrainingGroupEvent } = useAsyncMutation(UPDATE_TRAINING_GROUP_EVENT, {
    awaitRefetchQueries: true,
  });

  const isLoading =
    trainingGroupEventsLoading ||
    drillLoading ||
    sessionLoading ||
    athleteLoading ||
    coachLoading ||
    singleTrainingEventLoading ||
    skillSetsLoading ||
    centerLoading ||
    organizationDataLoading;
  const [showContent, setShowContent] = useState(!isLoading);
  useEffect(() => {
    if (!isLoading) {
      // Timeout needs to match with LoadingContainer animation duration
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    } else {
      setShowContent(false);
    }
  }, [isLoading]);

  useHandleQueryErrors([
    drillError,
    sessionError,
    athleteError,
    coachError,
    singleTrainingEventError,
    trainingGroupEventsError,
    skillSetsError,
    centerError,
    trainingGroupEventError,
    getSingleTrainingEventError,
    organizationDataError,
  ]);

  const getTrainingGroupEventData = async (id: string) => {
    const result = await getTrainingGroupEvent({ variables: { id } });
    if (result.data?.getTrainingGroupEvent?.__typename === "TrainingGroupEvent") {
      return result.data.getTrainingGroupEvent as TrainingGroupEvent;
    } else {
      return null;
    }
  };

  const getSingleTrainingEventData = async (id: string) => {
    const result = await getSingleTrainingEvent({ variables: { id } });
    if (result.data?.getSingleTrainingEvent?.__typename === "SingleTrainingEvent") {
      return result.data.getSingleTrainingEvent as SingleTrainingEvent;
    } else {
      return null;
    }
  };

  return (
    <Box id="calendar">
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Box animation={!isLoading ? `${loadingContainerFadeIn} 0.3s` : undefined}>
          <Calendar
            currentBreakPoint={currentBreakPoint}
            trainingGroupEvents={trainingGroupEventsData}
            drillData={drillData}
            sessionData={sessionData}
            athleteData={athleteData}
            coachData={coachData}
            singleTrainingEventData={singleTrainingEventData}
            skillSetsData={skillSetsData}
            centerData={centerData}
            organizationData={organizationData}
            saveSingleTrainingEvent={saveSingleTrainingEvent}
            updateTrainingGroupEvents={updateTrainingGroupEvent}
            getTrainingGroupEventData={getTrainingGroupEventData}
            getSingleTrainingEventData={getSingleTrainingEventData}
          />
        </Box>
      )}
    </Box>
  );
};
