import { gql } from "@apollo/client";

export const GET_ATHLETE_BY_ID = gql`
  query GetAthleteById($athleteId: String!) {
    getAthleteById(athleteId: $athleteId) {
      id
      name
      birthYear
    }
  }
`;
