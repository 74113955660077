import React, { useEffect, useState, useMemo } from "react";
import { Box, Text, Tooltip, Stack } from "@chakra-ui/react";
import type { OrganizationUsageStatistics } from "../../types";
import { CommonTable, type Column, type SortConfig } from "../../../../common/components/CommonTable/CommonTable";
import { ConfirmationDialog } from "../../../../common/components/ConfirmationDialog";
import { type Organization } from "../../../../types";

type UsageStatsProps = {
  usageStats: OrganizationUsageStatistics[];
  isLoadingOrganization: boolean;
  onGetOrganizationById: (id: string) => Promise<Organization>;
  onDelete: (id: string) => Promise<void>;
};

/** UsageStats component displays usage statistics for organizations */
export const UsageStats: React.FC<UsageStatsProps> = (props) => {
  const [organizationToBeRemoved, setOrganizationToBeRemoved] = useState<Organization | null>(null);
  const [organizationTotalStats, setOrganizationTotalStats] = useState(0);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState<SortConfig<OrganizationUsageStatistics> | undefined>(undefined);

  useEffect(() => {
    if (organizationToBeRemoved) {
      const orgStats = props.usageStats.find((org) => org.id === organizationToBeRemoved.id);
      if (orgStats) {
        const totalStats = [
          orgStats.coachesCount,
          orgStats.athletesCount,
          orgStats.centersCount,
          orgStats.venuesCount,
          orgStats.trainingSeasonsCount,
          orgStats.trainingGroupsCount,
          orgStats.trainingGroupEventsCount,
          orgStats.singleTrainingEventsCount,
          orgStats.drillsCount,
          orgStats.sessionsCount,
          orgStats.seasonPlansCount,
          orgStats.skillSetsCount,
          orgStats.skillsCount,
          orgStats.evaluationsCount,
          orgStats.notesCount,
        ].reduce((total, count) => total + count, 0);

        setOrganizationTotalStats(totalStats);
      }
    }
  }, [organizationToBeRemoved]);

  const sortedUsageStats = useMemo(() => {
    let sortable = [...props.usageStats];

    if (sortConfig) {
      sortable.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === "number" && typeof bValue === "number") {
          return sortConfig.direction === "ascending" ? aValue - bValue : bValue - aValue;
        } else if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }

        return 0;
      });
    }

    return sortable;
  }, [props.usageStats, sortConfig]);

  const handleClickRow = async (id: string) => {
    const organizationToBeRemoved = await props.onGetOrganizationById(id);
    setOrganizationToBeRemoved(organizationToBeRemoved);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirm = async () => {
    if (organizationToBeRemoved && organizationToBeRemoved?.id) {
      await props.onDelete(organizationToBeRemoved.id);
      setIsDeleteDialogOpen(false);
    }
  };

  const columns: Column<OrganizationUsageStatistics>[] = [
    {
      label: "Organization",
      key: "organizationName",
      width: "200px",
      render: (stat) => (
        <Tooltip label={stat.organizationName}>
          <Text isTruncated>{stat.organizationName}</Text>
        </Tooltip>
      ),
    },
    { label: "Coaches", key: "coachesCount", width: "200px", sortable: true },
    { label: "Athletes", key: "athletesCount", width: "200px", sortable: true },
    { label: "Centers", key: "centersCount", width: "200px", sortable: true },
    { label: "Venues", key: "venuesCount", width: "200px", sortable: true },
    { label: "Seasons", key: "trainingSeasonsCount", width: "200px", sortable: true },
    { label: "Groups", key: "trainingGroupsCount", width: "200px", sortable: true },
    { label: "Group Events", key: "trainingGroupEventsCount", width: "200px", sortable: true },
    { label: "Single Events", key: "singleTrainingEventsCount", width: "200px", sortable: true },
    { label: "Drills", key: "drillsCount", width: "200px", sortable: true },
    { label: "Sessions", key: "sessionsCount", width: "200px", sortable: true },
    { label: "Season Plans", key: "seasonPlansCount", width: "200px", sortable: true },
    { label: "Skill Sets", key: "skillSetsCount", width: "200px", sortable: true },
    { label: "Skills", key: "skillsCount", width: "200px", sortable: true },
    { label: "Evaluations", key: "evaluationsCount", width: "200px", sortable: true },
    { label: "Notes", key: "notesCount", width: "200px", sortable: true },
  ];

  return (
    <Box position="relative" data-testid="usage-stats">
      <Box padding="6" display="flex" justifyContent="center">
        <Text fontSize="x-large" fontWeight="bold">
          Organization Usage Statistics ({props.usageStats.length})
        </Text>
      </Box>

      <CommonTable
        columns={columns}
        rows={sortedUsageStats}
        sortConfig={sortConfig}
        onSort={(key) =>
          setSortConfig((prev) =>
            prev?.key === key
              ? { key, direction: prev.direction === "ascending" ? "descending" : "ascending" }
              : { key, direction: "ascending" },
          )
        }
        onRowClick={(row) => handleClickRow(row.id)}
        emptyMessage="No usage statistics available"
      />

      {organizationToBeRemoved && (
        <ConfirmationDialog
          isOpen={isDeleteDialogOpen}
          isDeletion={true}
          isLoading={props.isLoadingOrganization}
          message={
            <Stack direction="column" spacing="4">
              <Text fontSize="large">
                Delete Organization{" "}
                <Text as="span" color="red.500">
                  {organizationToBeRemoved.name}
                </Text>{" "}
                and users
              </Text>
              <Stack direction="column" spacing="4">
                {organizationToBeRemoved.users.length > 0 ? (
                  organizationToBeRemoved.users.map((user) => (
                    <Text fontSize="large" key={user.id}>
                      - User{" "}
                      <Text as="span" color="red.500">
                        {user.username}
                      </Text>
                    </Text>
                  ))
                ) : (
                  <Text fontSize="large">- No users</Text>
                )}
              </Stack>
              <Text fontSize="large">
                Organization has{" "}
                <Text as="span" color="red.500">
                  {organizationTotalStats}
                </Text>{" "}
                entities from usage stats
              </Text>
            </Stack>
          }
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={handleConfirm}
          title="Delete Organization"
          confirmButtonText="Delete"
          cancelButtonText="Cancel"
        />
      )}
    </Box>
  );
};
