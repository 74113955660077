import { gql } from "@apollo/client";

export const COGNITO_LOGIN = gql`
  mutation CognitoLogin($email: String!, $password: String!) {
    cognitoLogin(email: $email, password: $password) {
      idToken
      accessToken
    }
  }
`;
