import { gql } from "@apollo/client";

export const GET_ALL_SKILL_SET_EVALUATION_CRITERIA = gql`
  query GetAllSkillSetEvaluationCriteria {
    getAllSkillSetEvaluationCriteria {
      id
      skillSet {
        id
      }
    }
  }
`;
