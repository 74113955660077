import { gql } from "@apollo/client";

export const GET_ALL_TRAINING_GROUP_EVENTS_FOR_CALENDAR = gql`
  query GetAllTrainingGroupEventsForCalendar {
    getAllTrainingGroupEvents {
      id
      startDateTime
      endDateTime
      coaches {
        id
        name
      }
      venue {
        id
        name
      }
      athletes {
        id
        name
      }
      trainingGroup {
        id
        name
      }
    }
  }
`;
