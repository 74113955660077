import { gql } from "@apollo/client";

export const GET_ORGANIZATION_USAGE_STATS = gql`
  query GetOrganizationUsageStats {
    getOrganizationUsageStats {
      id
      organizationName
      coachesCount
      athletesCount
      centersCount
      venuesCount
      trainingSeasonsCount
      trainingGroupsCount
      trainingGroupEventsCount
      singleTrainingEventsCount
      drillsCount
      sessionsCount
      seasonPlansCount
      skillSetsCount
      skillsCount
      evaluationsCount
      notesCount
    }
  }
`;
