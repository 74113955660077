import React, { useMemo, useState } from "react";
import { Box, Text, Grid, GridItem } from "@chakra-ui/react";
import { CommonInput } from "../../../../common/components/CommonInput";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { InviteType, type Coach, type Invite } from "../../../../types";
import { CommonSelect } from "../../../../common/components/CommonSelect";
import { InviteTable, type InviteSortConfig } from "./components/InviteTable";
import { FormModal } from "../../../../common/components/FormModal";
import { useAlert } from "../../../../common/components/AlertProvider";

type InvitessProps = {
  allCoaches: Coach[];
  allInvites: Invite[];
  createInvite: (receiver: string, coach: Coach) => Promise<void>;
  createInviteLoading: boolean;
};

export const Invites: React.FC<InvitessProps> = (props) => {
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [receiver, setReceiver] = useState("");
  const [associatedCoach, setAssociatedCoach] = useState<Coach | null>(null);
  const [sortConfig, setSortConfig] = useState<InviteSortConfig>(null);

  const { showAlert } = useAlert();

  /** Creates a new invite link for the given receiver. */
  const handleCreateInvite = async () => {
    if (!associatedCoach) {
      return;
    }

    showAlert("Creating invite link", "info", undefined, true);
    setReceiver("");
    setAssociatedCoach(null);
    setIsInviteModalOpen(false);

    await props.createInvite(receiver, associatedCoach);
    showAlert("Invite link created!", "success", 5000);
  };

  /**
   * Handles sorting logic for invites.
   *
   * @param {keyof Invite} key - The attribute of the Invite object to sort by.
   */
  const handleSort = (key: keyof Invite) => {
    if (sortConfig?.key === key) {
      setSortConfig({
        key,
        direction: sortConfig.direction === "ascending" ? "descending" : "ascending",
      });
    } else {
      setSortConfig({ key, direction: "ascending" });
    }
  };

  /** Sorts invites based on the current sortConfig. */
  const sortedInvites = useMemo(() => {
    let sorted = [...props.allInvites];
    if (sortConfig) {
      sorted.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (sortConfig.key === "usedAt") {
          aValue = aValue || "N/A";
          bValue = bValue || "N/A";
        }

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }

        if (typeof aValue === "number" && typeof bValue === "number") {
          return sortConfig.direction === "ascending" ? aValue - bValue : bValue - aValue;
        }

        return 0;
      });
    }
    return sorted;
  }, [props.allInvites, sortConfig]);

  /**
   * Copies the given link to the clipboard.
   *
   * @param {string} link - The invite link to copy.
   */
  const handleCopyLink = (link: string) => {
    navigator.clipboard.writeText(link);
    showAlert("Invite link copied to clipboard!", "info", 2000);
  };

  const handleAssociatedCoachChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCoach = props.allCoaches.find((coach) => coach.id === event.target.value);
    setAssociatedCoach(selectedCoach || null);
  };

  const availableCoaches = props.allCoaches.filter((coach) => !coach.user);

  return (
    <Box position="relative" paddingTop="6">
      <LoadingOverlay display={props.createInviteLoading} spinnerSize="xl" spinnerTopPosition="40px" />

      <InviteTable
        invites={sortedInvites}
        sortConfig={sortConfig}
        onSort={handleSort}
        onCopyLink={handleCopyLink}
        onInviteModalOpen={() => setIsInviteModalOpen(true)}
      />

      <FormModal
        open={isInviteModalOpen}
        title="Create an Invitation Link"
        submitButtonText="Create Link"
        handleSubmit={handleCreateInvite}
        onClose={() => setIsInviteModalOpen(false)}
        submitDisabled={receiver.trim().length === 0 || !associatedCoach || availableCoaches.length === 0}
        submitButtonHoverText={
          receiver.trim().length === 0
            ? "Add reference to create link"
            : !associatedCoach || availableCoaches.length === 0
              ? "Select associated coach"
              : undefined
        }
      >
        <Grid templateColumns="repeat(6, 1fr)" gap="4">
          <GridItem colSpan={6}>
            <Text>
              Create an invitation link to allow a new user to join your organization. Once the link is created, share
              it with the user you want to invite. The link allows them to create an account and join your organization.
              Each link is unique and can only be used once
            </Text>
          </GridItem>
          <GridItem colSpan={3}>
            <CommonInput
              placeholder="Reference (to track who this invitation was sent to)"
              value={receiver}
              onChange={(value) => setReceiver(value)}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <CommonSelect
              placeholder="Associated Coach"
              value={availableCoaches.length === 0 ? "-" : associatedCoach?.id || undefined}
              options={[
                ...(availableCoaches.length === 0
                  ? [{ value: "-", label: "No coaches available", disabled: true }]
                  : []),
                ...props.allCoaches.map((coach) => ({
                  label: coach.name,
                  value: coach.id || "",
                  disabled: !!coach.user,
                })),
              ]}
              disableSelectedStyle={availableCoaches.length === 0}
              onChange={handleAssociatedCoachChange}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <CommonSelect
              placeholder="Access type"
              value={InviteType.JoinOrganizationFullAccess}
              options={[
                { label: "Owner", value: -1, disabled: true },
                { label: "Full", value: InviteType.JoinOrganizationFullAccess },
                { label: "Limited", value: InviteType.JoinOrganizationLimitedAccess, disabled: true },
                { label: "Athlete", value: InviteType.JoinOrganizationAthleteAccess, disabled: true },
              ]}
              onChange={() => {
                // doesn't do anything until other access types are implemented
              }}
            />
          </GridItem>
        </Grid>
      </FormModal>
    </Box>
  );
};
