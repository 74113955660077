import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Stack,
  Box,
  Grid,
  Tag,
  Tooltip,
  TagLabel,
} from "@chakra-ui/react";
import type { TrainingGroupEvent, SingleTrainingEvent } from "../../../../../types";
import { format, getISOWeek } from "date-fns";
import { extractDateFromUTC, extractUtcTime } from "../../../../../common/utils/dateAndTime";
import { ConfirmationDialog } from "../../../../../common/components/ConfirmationDialog";
import { FormModal } from "../../../../../common/components/FormModal";
import { cardStyles, tagStyles } from "../../../../../common/utils/styles";

type TrainingEventModalProps = {
  isOpen: boolean;
  onClose: () => void;
  event: TrainingGroupEvent | SingleTrainingEvent;
  isOneTimeTrainingEvent: boolean;
  groupName: string;
};

/** A modal that shows detailed info about a training event */
export const TrainingEventModal: React.FC<TrainingEventModalProps> = (props) => {
  const formatWeekday = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, "EEEE");
  };

  const formatWeekNumber = (dateString: string) => {
    const date = new Date(dateString);
    return getISOWeek(date);
  };

  const eventSeasonPlans =
    !props.isOneTimeTrainingEvent && (props.event as TrainingGroupEvent).trainingGroup.seasonPlans;

  return (
    <FormModal
      open={props.isOpen}
      title="Training Details"
      onClose={props.onClose}
      handleSubmit={props.onClose}
      submitButtonText="Close"
      hideSecondaryButton
    >
      <Stack as="dl" direction="column" spacing="6" paddingBottom="4">
        <Grid
          templateColumns={{ mobile: "1fr", laptop: "max-content 1fr" }}
          columnGap="6"
          rowGap={{ mobile: "0", laptop: "3" }}
          padding="4"
          {...cardStyles({ disableClick: true })}
        >
          <Text as="dt" color="blackAlpha.600">
            Training date
          </Text>
          <Text as="dd" marginBottom={{ mobile: "4", laptop: "0" }}>
            {`${formatWeekday(props.event.startDateTime)}, ${format(extractDateFromUTC(props.event.startDateTime), "dd.MM.yyyy")} `}
            <Text as="span" color="blackAlpha.600">
              (week {formatWeekNumber(props.event.startDateTime)})
            </Text>
          </Text>
          <Text as="dt" color="blackAlpha.600">
            Training time
          </Text>
          <Text as="dd" marginBottom={{ mobile: "4", laptop: "0" }}>
            {extractUtcTime(props.event.startDateTime)} - {extractUtcTime(props.event.endDateTime)}
          </Text>
          <Text as="dt" color="blackAlpha.600">
            Training venue
          </Text>
          <Text as="dd">
            {props.event.venue?.name}{" "}
            <Text as="span" color="blackAlpha.600">
              ({props.event.venue?.center?.name})
            </Text>
          </Text>
        </Grid>

        <Grid
          templateColumns={{ mobile: "1fr", laptop: "max-content 1fr" }}
          columnGap="6"
          rowGap={{ mobile: "0", laptop: "3" }}
          padding="4"
          {...cardStyles({ disableClick: true })}
        >
          <Text as="dt" color="blackAlpha.600">
            Training Group
          </Text>
          <Text
            as="dd"
            marginBottom={{ mobile: "4", laptop: "0" }}
            color={props.isOneTimeTrainingEvent ? "blackAlpha.600" : undefined}
          >
            {props.groupName}
          </Text>
          <Text as="dt" color="blackAlpha.600">
            Athletes
          </Text>
          <Text as="dd">{props.event.athletes?.map((athlete) => athlete.name).join(", ") || "No athletes"}</Text>
        </Grid>

        <Grid
          templateColumns={{ mobile: "1fr", laptop: "max-content 1fr" }}
          columnGap="6"
          rowGap={{ mobile: "0", laptop: "3" }}
          padding="4"
          {...cardStyles({ disableClick: true })}
        >
          <Text as="dt" color="blackAlpha.600">
            Skill Sets to train
          </Text>
          <Stack
            direction={{ mobile: "column", laptop: "row" }}
            spacing="2"
            as="dd"
            marginBottom={{ mobile: "4", laptop: "0" }}
          >
            {props.isOneTimeTrainingEvent ? (
              <Text color="blackAlpha.600">No Season Plans for One-time training events</Text>
            ) : eventSeasonPlans && eventSeasonPlans?.length > 0 ? (
              eventSeasonPlans.map((plan) =>
                plan.skillSets?.map((skillSet) => (
                  <Tag key={skillSet.id} maxWidth="200px" width="fit-content" {...tagStyles("teal.800")}>
                    <Tooltip hasArrow label={`${skillSet.skillSet.name}`} aria-label="Tag" placement="top">
                      <TagLabel wordBreak="break-all">{skillSet.skillSet.name}</TagLabel>
                    </Tooltip>
                  </Tag>
                )),
              )
            ) : (
              <Tag maxWidth="200px" width="fit-content" {...tagStyles("blackAlpha.600")}>
                <TagLabel wordBreak="break-all">Not planned</TagLabel>
              </Tag>
            )}
          </Stack>
          <Text as="dt" color="blackAlpha.600">
            Skills to train
          </Text>
          <Stack direction={{ mobile: "column", laptop: "row" }} spacing="2" as="dd">
            {props.isOneTimeTrainingEvent ? (
              <Text color="blackAlpha.600">No Season Plans for One-time training events</Text>
            ) : eventSeasonPlans && eventSeasonPlans?.length > 0 ? (
              eventSeasonPlans.map((plan) =>
                plan.skillSets?.map((skillSet) =>
                  skillSet.skills?.map((skill) => (
                    <Tag key={skill.id} maxWidth="200px" width="fit-content" {...tagStyles()}>
                      <Tooltip hasArrow label={`${skill.name}`} aria-label="Tag" placement="top">
                        <TagLabel>{skill.name}</TagLabel>
                      </Tooltip>
                    </Tag>
                  )),
                ),
              )
            ) : (
              <Tag maxWidth="200px" width="fit-content" {...tagStyles("blackAlpha.600")}>
                <TagLabel wordBreak="break-all">Not planned</TagLabel>
              </Tag>
            )}
          </Stack>
        </Grid>
      </Stack>
    </FormModal>
  );
};
