import React from "react";
import { Box } from "@chakra-ui/react";
import { type OrganizationUser } from "../../../../types";
import { UserTable } from "./components/UserTable";

type UsersProps = {
  allUsers: OrganizationUser[];
};

export const Users: React.FC<UsersProps> = (props) => {
  return (
    <Box position="relative" paddingTop="6">
      <UserTable allUsers={props.allUsers} />
    </Box>
  );
};
