import React, { useState, useMemo } from "react";
import { Box, Text, Tooltip, Stack } from "@chakra-ui/react";
import {
  Coach,
  Venue,
  TrainingGroup,
  type Athlete,
  type TrainingSeason,
  TrainingSeasonStatus,
  type DeletionNode,
  DeletionInfoEntityType,
  type Center,
} from "../../../../types";
import { FormModal } from "../../../../common/components/FormModal";
import type { TrainingGroupInput } from "../../../Athletes/containers/AthleteContainer";
import { useAlert } from "../../../../common/components/AlertProvider";
import { CommonIconButton } from "../../../../common/components/CommonIconButton";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { TrainingGroupEditor } from "../../../../common/components/TrainingGroupEditor";
import { TrainingGroupsFilterOptions } from "./components/TrainingGroupsFilterOptions";
import { DeletionInfo } from "../../../../common/components/DeletionInfo";
import { CommonTable, type Column } from "../../../../common/components/CommonTable/CommonTable";
import { weekdayOptions } from "../../../../common/utils/dataProcessing";

type TrainingGroupsProps = {
  events: TrainingGroup[];
  coaches: Coach[];
  centers: Center[];
  athletes: Athlete[];
  trainingSeasons: TrainingSeason[];
  onSave: (eventToSave: TrainingGroupInput) => Promise<any>;
  isLoading: boolean;
  entitiesToBeRemoved: DeletionNode[];
  entitiesToBeRemovedLoading: boolean;
  getEntitiesToBeRemoved: (id: string) => Promise<void>;
};

export const TrainingGroups: React.FC<TrainingGroupsProps> = (props) => {
  const [selectedEvent, setSelectedEvent] = useState<TrainingGroup | null>(null);
  const [initialEvent, setInitialEvent] = useState<TrainingGroup | null>(null);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof TrainingGroup;
    direction: "ascending" | "descending";
  }>({ key: "trainingSeason", direction: "ascending" });
  const [isOpen, setIsOpen] = useState(false);
  const [isCreatingNewGroup, setIsCreatingNewGroup] = useState(false);
  const { showAlert } = useAlert();

  const [selectedTrainingSeasons, setSelectedTrainingSeasons] = useState<TrainingSeason[]>([]);
  const [selectedCoaches, setSelectedCoaches] = useState<Coach[]>([]);
  const [selectedAthletes, setSelectedAthletes] = useState<Athlete[]>([]);
  const [selectedVenues, setSelectedVenues] = useState<Venue[]>([]);
  const [selectedWeekdays, setSelectedWeekdays] = useState<number[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const venues = props.centers.flatMap((center: Center) => center.venues.map((venue) => ({ ...venue, center }))) || [];
  const isMultipleCenters = props.centers.length > 1;

  const columns: Column<TrainingGroup>[] = [
    {
      label: "Training Season",
      key: "trainingSeason",
      width: "17%",
      sortable: true,
      render: (event) => (
        <Tooltip label={event.trainingSeason.name}>
          <Text isTruncated color="inherit">
            {event.trainingSeason.name}
          </Text>
        </Tooltip>
      ),
    },
    {
      label: "Group",
      key: "name",
      width: "15%",
      sortable: true,
      render: (event) => (
        <Tooltip label={event.name}>
          <Text isTruncated color="inherit">
            {event.name}
          </Text>
        </Tooltip>
      ),
    },
    {
      label: "Weekday",
      key: "recurrenceWeekday",
      width: "12%",
      sortable: true,
      render: (event) => weekdayOptions[(event.recurrenceWeekday + 6) % 7].label,
    },
    {
      label: "Start",
      key: "recurrenceStartTime",
      width: "8%",
      sortable: true,
      render: (event) => event.recurrenceStartTime,
    },
    {
      label: "End",
      key: "recurrenceEndTime",
      width: "8%",
      sortable: true,
      render: (event) => event.recurrenceEndTime,
    },
    {
      label: "Venue",
      key: "venue",
      width: "10%",
      sortable: true,
      render: (event) => (
        <Tooltip
          label={
            isMultipleCenters ? venues.find((venue) => venue.id === event.venue.id)?.center?.name : event.venue.name
          }
        >
          <Text isTruncated color="inherit">
            {isMultipleCenters
              ? `${event.venue.name} (${venues.find((venue) => venue.id === event.venue.id)?.center?.name}
          )`
              : event.venue.name}
          </Text>
        </Tooltip>
      ),
    },
    {
      label: "Coaches",
      key: "coaches",
      width: "10%",
      render: (event) => (
        <Tooltip label={event.coaches?.map((coach) => coach.name).join(", ")}>
          <Text isTruncated color="inherit">
            {event.coaches && event.coaches?.length > 0
              ? event.coaches.map((coach) => coach.name).join(", ")
              : "No coaches"}
          </Text>
        </Tooltip>
      ),
    },
    {
      label: "Athletes",
      key: "athletes",
      width: "20%",
      render: (event) => (
        <Tooltip label={event.athletes?.map((athlete) => athlete.name).join(", ")}>
          <Text isTruncated color="inherit">
            {event.athletes && event.athletes?.length > 0
              ? event.athletes.map((athlete) => athlete.name).join(", ")
              : "No athletes"}
          </Text>
        </Tooltip>
      ),
    },
  ];

  const sortedEvents = React.useMemo(() => {
    let filteredEvents = [...props.events];

    // Filter by selected training seasons
    if (selectedTrainingSeasons.length > 0) {
      const selectedSeasonIds = selectedTrainingSeasons.map((season) => season.id);
      filteredEvents = filteredEvents.filter((event) => selectedSeasonIds.includes(event.trainingSeason.id));
    }

    // Filter by selected coaches
    if (selectedCoaches.length > 0) {
      const selectedCoachIds = selectedCoaches.map((coach) => coach.id);
      filteredEvents = filteredEvents.filter((event) =>
        event.coaches?.some((coach) => selectedCoachIds.includes(coach.id)),
      );
    }

    // Filter by selected athletes
    if (selectedAthletes.length > 0) {
      const selectedAthleteIds = selectedAthletes.map((athlete) => athlete.id);
      filteredEvents = filteredEvents.filter((event) =>
        event.athletes?.some((athlete) => selectedAthleteIds.includes(athlete.id)),
      );
    }

    // Filter by selected venues
    if (selectedVenues.length > 0) {
      const selectedVenueIds = selectedVenues.map((venue) => venue.id);
      filteredEvents = filteredEvents.filter((event) => selectedVenueIds.includes(event.venue.id));
    }

    // Filter by selected weekdays
    if (selectedWeekdays.length > 0) {
      filteredEvents = filteredEvents.filter((event) => selectedWeekdays.includes(event.recurrenceWeekday));
    }

    // Filter by search query
    if (searchQuery.trim() !== "") {
      const query = searchQuery.toLowerCase();
      filteredEvents = filteredEvents.filter(
        (event) =>
          event.name.toLowerCase().includes(query) ||
          weekdayOptions
            .map((weekday) => weekday.label)
            [event.recurrenceWeekday].toLowerCase()
            .includes(query),
      );
    }

    // Now proceed with sorting
    let sortableEvents = [...filteredEvents];
    if (sortConfig !== null) {
      sortableEvents.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (sortConfig.key === "venue") {
          aValue = a.venue.name;
          bValue = b.venue.name;
        }
        if (sortConfig.key === "recurrenceWeekday") {
          aValue = (a.recurrenceWeekday + 6) % 7;
          bValue = (b.recurrenceWeekday + 6) % 7;
        }

        if (sortConfig.key === "trainingSeason") {
          const getSeasonPriority = (status: TrainingSeasonStatus): number => {
            switch (status) {
              case TrainingSeasonStatus.Active:
                return 1;
              case TrainingSeasonStatus.Upcoming:
                return 2;
              case TrainingSeasonStatus.Completed:
                return 3;
              default:
                return 99;
            }
          };

          aValue = getSeasonPriority(a.trainingSeason.status);
          bValue = getSeasonPriority(b.trainingSeason.status);
        }

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }

        if (typeof aValue === "number" && typeof bValue === "number") {
          return sortConfig.direction === "ascending" ? aValue - bValue : bValue - aValue;
        }

        return 0;
      });
    }
    return sortableEvents;
  }, [
    props.events,
    selectedTrainingSeasons,
    selectedCoaches,
    selectedAthletes,
    selectedVenues,
    selectedWeekdays,
    searchQuery,
    sortConfig,
  ]);

  const handleRowClick = (event: TrainingGroup) => {
    setIsCreatingNewGroup(false);
    setSelectedEvent(event);
    setInitialEvent(event);
    setIsOpen(true);
  };

  const handleSave = async (remove: boolean) => {
    setIsOpen(false);
    if (selectedEvent) {
      const eventToSave: TrainingGroupInput = {
        id: selectedEvent.id,
        name: selectedEvent.name || "unnamed",
        recurrenceWeekday: selectedEvent.recurrenceWeekday,
        recurrenceStartTime: selectedEvent.recurrenceStartTime,
        recurrenceEndTime: selectedEvent.recurrenceEndTime,
        venueId: selectedEvent.venue.id,
        coachIds: selectedEvent.coaches?.map((coach) => coach.id || "") || [],
        athleteIds: selectedEvent.athletes?.map((athlete) => athlete.id || "") || [],
        remove: remove,
        trainingSeasonId: selectedEvent.trainingSeason.id || "",
      };

      if (eventToSave.remove) {
        showAlert("Removing Training Group", "info", undefined, true);
      } else if (eventToSave.id) {
        showAlert("Updating Training Group", "info", undefined, true);
      } else {
        showAlert("Creating new Training Group", "info", undefined, true);
      }

      const result = await props.onSave(eventToSave);

      if (result) {
        if (eventToSave.remove) {
          showAlert("Training Group removed!", "success", 5000);
        } else {
          if (eventToSave.id) {
            showAlert("Training Group updated!", "success", 5000);
          } else {
            showAlert("Training Group created!", "success", 5000);
          }
        }
      }
    }
  };

  const handleCreateNewGroup = () => {
    if (props.trainingSeasons.length === 0) {
      showAlert("No Training Seasons available", "warning");
      return;
    } else if (venues.length === 0) {
      showAlert("No Venues available", "warning");
      return;
    }
    setIsCreatingNewGroup(true);
    setSelectedEvent({
      name: "",
      recurrenceWeekday: 1,
      recurrenceStartTime: "17:00",
      recurrenceEndTime: "18:30",
      venue: venues[0],
      coaches: [],
      athletes: [],
      trainingSeason:
        props.trainingSeasons.find((season) => season.status === TrainingSeasonStatus.Active) ||
        props.trainingSeasons[0],
    });
    setIsOpen(true);
  };

  /**
   * Generates a list of reasons why the submit button is disabled.
   *
   * @returns An object containing the submit disabled reasons if any condition is not met, otherwise undefined.
   */
  const getSubmitDisabledReasons = (): string | undefined => {
    const reasons: string[] = [];

    if (!selectedEvent?.name || selectedEvent?.name.trim() === "") {
      reasons.push("Provide a Training Group name");
    }
    if (
      selectedEvent?.recurrenceWeekday === undefined ||
      selectedEvent?.recurrenceWeekday < 0 ||
      selectedEvent?.recurrenceWeekday > 6
    ) {
      reasons.push("Choose a weekday");
    }
    if (!selectedEvent?.recurrenceStartTime || selectedEvent?.recurrenceStartTime === "24:00") {
      reasons.push("Set a start time");
    }
    if (!selectedEvent?.recurrenceEndTime || selectedEvent?.recurrenceEndTime === "24:00") {
      reasons.push("Set an end time");
    }
    if (!selectedEvent?.venue) {
      reasons.push("Select a venue");
    }
    if (!selectedEvent?.trainingSeason) {
      reasons.push("Select a Training Season");
    }

    const identicalTrainingGroup = props.events.find((event) => {
      // Skip the current event if editing an existing one
      if (selectedEvent?.id && event.id === selectedEvent.id) {
        return false;
      }

      return (
        event.recurrenceWeekday === selectedEvent?.recurrenceWeekday &&
        event.recurrenceStartTime === selectedEvent.recurrenceStartTime &&
        event.recurrenceEndTime === selectedEvent.recurrenceEndTime &&
        event.venue.id === selectedEvent.venue.id &&
        event.trainingSeason.id === selectedEvent.trainingSeason.id
      );
    });

    if (identicalTrainingGroup) {
      reasons.push(
        `Training Group with the same time, venue, and Training Season exists already (${identicalTrainingGroup.name})`,
      );
    }

    if (JSON.stringify(selectedEvent) === JSON.stringify(initialEvent)) {
      reasons.push("There are no changes to save");
    }

    return reasons.length > 0
      ? `To enable saving, please address the following:\n- ${reasons.join("\n- ")}`
      : undefined;
  };

  const submitDisabledReasons = getSubmitDisabledReasons();

  const featurePurpose =
    "Training Groups are organized teams of athletes linked to a Training Season. Add Training Groups to manage weekly training events";

  return (
    <Box position="relative" data-testid="organization-training-groups">
      <LoadingOverlay display={props.isLoading} spinnerSize="xl" spinnerTopPosition="40px" />

      <Stack direction="row" padding="6" spacing="4" width="100%" justifyContent="center">
        <Tooltip label={featurePurpose}>
          <Text fontSize="x-large">Training Groups ({props.events.length})</Text>
        </Tooltip>
        <CommonIconButton
          height="36px"
          onClick={handleCreateNewGroup}
          disabled={props.trainingSeasons.length === 0 || venues.length === 0}
          tooltip={
            props.trainingSeasons.length === 0
              ? "No Training Seasons available, create one first before a new Training Group"
              : venues.length === 0
                ? "No Venues available, create one first before a new Training Group"
                : undefined
          }
          dataTestId="add-training-group"
        />
      </Stack>

      <TrainingGroupsFilterOptions
        allTrainingSeasons={props.trainingSeasons}
        selectedTrainingSeasons={selectedTrainingSeasons}
        setSelectedTrainingSeasons={setSelectedTrainingSeasons}
        allCoaches={props.coaches}
        selectedCoaches={selectedCoaches}
        setSelectedCoaches={setSelectedCoaches}
        allAthletes={props.athletes}
        selectedAthletes={selectedAthletes}
        setSelectedAthletes={setSelectedAthletes}
        allVenues={venues}
        selectedVenues={selectedVenues}
        setSelectedVenues={setSelectedVenues}
        selectedWeekdays={selectedWeekdays}
        setSelectedWeekdays={setSelectedWeekdays}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />

      <CommonTable
        columns={columns}
        rows={sortedEvents}
        sortConfig={sortConfig}
        onSort={(key) =>
          setSortConfig((prev) =>
            prev?.key === key
              ? { key, direction: prev.direction === "ascending" ? "descending" : "ascending" }
              : { key, direction: "ascending" },
          )
        }
        emptyMessage={
          props.events.length === 0
            ? featurePurpose
            : "No Training Groups match the applied filters. Clear filters to view all Training Groups"
        }
        getRowStyle={(event) => ({
          color:
            event.trainingSeason.status === TrainingSeasonStatus.Completed
              ? "blackAlpha.300"
              : event.trainingSeason.status === TrainingSeasonStatus.Upcoming
                ? "blackAlpha.600"
                : undefined,
        })}
        onRowClick={handleRowClick}
      />

      <FormModal
        open={isOpen}
        title={isCreatingNewGroup ? "Create new Training Group" : "Edit Training Group"}
        submitButtonText={isCreatingNewGroup ? "Create" : "Save"}
        handleSubmit={() => handleSave(false)}
        onClose={() => setIsOpen(false)}
        handleRemove={() => handleSave(true)}
        removeDisabled={!selectedEvent?.id}
        removeDisabledReason="Group is not created"
        confirmationDialogTitle="Delete Training Group"
        submitDisabled={!!submitDisabledReasons}
        submitButtonHoverText={submitDisabledReasons}
        confirmationDialogMessage={
          <DeletionInfo
            entityName={initialEvent?.name || ""}
            entitiesToBeRemoved={props.entitiesToBeRemoved}
            entityType={DeletionInfoEntityType.TrainingGroup}
            loading={props.entitiesToBeRemovedLoading}
          />
        }
        onConfirmationDialogOpen={async () => await props.getEntitiesToBeRemoved(selectedEvent?.id || "")}
        isConfirmingDeletion={true}
      >
        {selectedEvent && (
          <TrainingGroupEditor
            allCoaches={props.coaches}
            allVenues={venues}
            allAthletes={props.athletes}
            allTrainingSeasons={props.trainingSeasons}
            trainingGroup={selectedEvent}
            displayAthletes={true}
            onChange={(editedEvent) => setSelectedEvent(editedEvent)}
            initialTrainingGroup={initialEvent || undefined}
          />
        )}
      </FormModal>
    </Box>
  );
};
