import type FullCalendar from "@fullcalendar/react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import type { Center, Venue } from "../../../../../types";
import type { EventClickArg } from "fullcalendar";

/**
 * Generates common properties for FullCalendar components.
 *
 * @param events - The array of events to display in the calendar.
 * @param index - The index for the calendar reference.
 * @param venue - The venue information.
 * @returns An object containing common properties for FullCalendar components.
 */
export const getCommonCalendarProps = (
  events: any,
  index: number,
  venue: Venue | null,
  dayViewDate: Date,
  calendarRefs: React.MutableRefObject<(FullCalendar | null)[]>,
  handleEventClick: (args: EventClickArg) => void,
  globalSelectedCenter: Center | null,
  showAlert: (message: string, type: "error" | "success" | "info" | "warning") => void,
  dateSelectHandler: (args: any, venue: Venue | null) => void,
) => ({
  timeZone: "UTC",
  initialDate: dayViewDate,
  ref: (ref: any) => (calendarRefs.current[index] = ref),
  firstDay: 1,
  slotMinTime: "08:00:00",
  slotMaxTime: "22:00:00",
  weekends: true,
  allDaySlot: false,
  weekNumbers: true,
  plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
  events,
  selectable: true,
  selectMirror: true,
  unselectAuto: false,
  expandRows: true,
  eventTimeFormat: {
    hour: "2-digit" as "2-digit",
    minute: "2-digit" as "2-digit",
    hourCycle: "h24" as "h24",
  },
  headerToolbar: false as false,
  eventDisplay: "block",
  contentHeight: "auto",
  eventClick: handleEventClick,
  select: (args: any) => {
    if (globalSelectedCenter?.venues.length === 0) {
      showAlert(
        "A Venue is required for the selected Center to create a Training Event. Add Venues in the 'Organization' section",
        "warning",
      );
      return;
    } else {
      dateSelectHandler(args, venue);
    }
  },
});
