import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { SeasonPlanCalendar } from "./components/SeasonPlanCalendar/SeasonPlanCalendar";
import { SeasonPlanModal } from "./components/SeasonPlanModal/SeasonPlanModal";
import type { FullCalendarSeasonPlanEvent } from "../types";
import { EventClickArg, DateSelectArg } from "@fullcalendar/core";
import type { TrainingGroup, SeasonPlan, Athlete, SkillSet } from "../../../types";
import { formatToUtcDayStartString, updateUTCTime } from "../../../common/utils/dateAndTime";
import type { AlertContextType } from "../../../common/components/AlertProvider";

type SkillSetSelectionInput = {
  skillSetId: string;
  selectedSkillIds?: string[];
};

export type SeasonPlanInput = {
  id?: string;
  skillSetSelections: SkillSetSelectionInput[];
  trainingGroupIds?: string[];
  athleteIds?: string[];
  startDateTime: string;
  endDateTime: string;
  isOrganizationWide?: boolean;
  remove?: boolean;
};

type SeasonPlansProps = {
  seasonPlans: SeasonPlan[];
  allTrainingGroups: TrainingGroup[];
  allSkillSets: SkillSet[];
  allAthletes: Athlete[];
  onSaveSeasonPlan: (seasonPlanInput: SeasonPlanInput) => Promise<void>;
  showAlert: AlertContextType["showAlert"];
  currentBreakPoint: string;
  isLoading: boolean;
};

export const SeasonPlans: React.FC<SeasonPlansProps> = (props) => {
  const [fullCalendarEvents, setFullCalendarEvents] = useState<FullCalendarSeasonPlanEvent[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeFullCalendarEvent, setActiveFullCalendarEvent] = useState<FullCalendarSeasonPlanEvent | null>(null);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [selectedAthletes, setSelectedAthletes] = useState<Array<{ name: string }>>([]);
  const [selectedGroups, setSelectedGroups] = useState<Array<{ name: string }>>([]);
  const [showOnlyOrganizationWide, setShowOnlyOrganizationWide] = useState<boolean>(false);
  const [showOnlyTrainingGroups, setShowOnlyTrainingGroups] = useState<boolean>(false);
  const [showOnlyAthletePlans, setShowOnlyAthletePlans] = useState<boolean>(false);

  useEffect(() => {
    if (props.seasonPlans) {
      setFullCalendarEvents(
        props.seasonPlans.map((seasonPlan: SeasonPlan) => {
          const { id, startDateTime, endDateTime } = seasonPlan;
          const seasonPlanFCEvent: FullCalendarSeasonPlanEvent = {
            id: id || "",
            title: "Season Plan",
            start: new Date(startDateTime),
            end: new Date(endDateTime),
            seasonPlan,
          };
          return seasonPlanFCEvent;
        }),
      );
    }
  }, [props.seasonPlans]);

  const handleSaveSeasonPlan = async (seasonPlanInput: SeasonPlanInput) => {
    await props.onSaveSeasonPlan(seasonPlanInput);
    handleModalClose();
  };

  /** Handle clicking on an existing event (season plan). */
  const handleEventClick = (clickInfo: EventClickArg) => {
    const event = clickInfo.event;
    const seasonPlan: FullCalendarSeasonPlanEvent = {
      id: event.id,
      title: event.title,
      start: event.start!,
      end: event.end!,
      seasonPlan: event.extendedProps.seasonPlan,
    };
    setActiveFullCalendarEvent(seasonPlan);
    setStartDate(undefined);
    setEndDate(undefined);
    setIsModalOpen(true);
  };

  /** Handle selecting a date range to create a new season plan. */
  const handleDateSelect = (selectInfo: DateSelectArg) => {
    if (
      props.allTrainingGroups.length === 0 ||
      props.allTrainingGroups.flatMap((group) => group.athletes).length === 0
    ) {
      props.showAlert(
        "A Training Group (including Athletes) is required to create a Season Plan. Add Training Groups in the 'Training Management' section",
        "warning",
      );
      return;
    }
    if (props.allSkillSets.length === 0) {
      props.showAlert(
        "A Skill Set is required to create a Season Plan. Add Skill Sets in the 'Skill Sets' section",
        "warning",
      );
      return;
    }

    const startDate = selectInfo.start;
    const endDate = new Date(selectInfo.end);
    endDate.setDate(endDate.getDate() - 1); // Adjust for FullCalendar's exclusive end date

    setStartDate(formatToUtcDayStartString(startDate));
    setEndDate(updateUTCTime(formatToUtcDayStartString(endDate), "23:59"));
    setActiveFullCalendarEvent(null);
    setIsModalOpen(true);
  };

  /** Close the modal and reset state. */
  const handleModalClose = () => {
    setIsModalOpen(false);
    setActiveFullCalendarEvent(null);
    setStartDate(undefined);
    setEndDate(undefined);
  };

  // Function to filter season plans based on selected filters
  const getFilteredSeasonPlans = () => {
    return fullCalendarEvents.filter((plan) => {
      // Filter based on selected athletes
      let athleteMatch = true;
      if (selectedAthletes.length > 0) {
        const planAthleteNames = plan.seasonPlan.athletes?.map((athlete) => athlete.name) || [];
        athleteMatch = selectedAthletes.some((selectedAthlete) => planAthleteNames.includes(selectedAthlete.name));
      }

      // Filter based on selected groups
      let groupMatch = true;
      if (selectedGroups.length > 0) {
        const planGroupNames =
          (!plan.seasonPlan.isOrganizationWide && plan.seasonPlan.trainingGroups?.map((event) => event.name)) || [];
        groupMatch = selectedGroups.some((selectedGroup) => planGroupNames.includes(selectedGroup.name));
      }

      // Filter based on showOnlyOrganizationWide, showOnlyTrainingGroups, showOnlyAthletePlans
      // If none are selected, include all plans
      let typeMatch = true;
      if (showOnlyOrganizationWide) {
        typeMatch = plan.seasonPlan.isOrganizationWide === true;
      } else if (showOnlyTrainingGroups) {
        typeMatch =
          (plan.seasonPlan.trainingGroups?.length &&
            plan.seasonPlan.trainingGroups?.length > 0 &&
            plan.seasonPlan.isOrganizationWide === false) ||
          false;
      } else if (showOnlyAthletePlans) {
        typeMatch = (plan.seasonPlan.athletes?.length && plan.seasonPlan.athletes?.length > 0) || false;
      }

      return athleteMatch && groupMatch && typeMatch;
    });
  };

  return (
    <Box>
      <SeasonPlanCalendar
        eventsToDisplay={getFilteredSeasonPlans()}
        handleEventClick={handleEventClick}
        dateSelectHandler={handleDateSelect}
        showAlert={props.showAlert}
        isMobile={props.currentBreakPoint === "mobile"}
        isLoading={props.isLoading}
        allAthletes={props.allAthletes.map((athlete) => ({ name: athlete.name }))}
        selectedAthletes={selectedAthletes}
        setSelectedAthletes={setSelectedAthletes}
        allGroups={props.allTrainingGroups.map((group) => ({ name: group.name || "" }))}
        selectedGroups={selectedGroups}
        setSelectedGroups={setSelectedGroups}
        showOnlyOrganizationWide={showOnlyOrganizationWide}
        setShowOnlyOrganizationWide={setShowOnlyOrganizationWide}
        showOnlyTrainingGroups={showOnlyTrainingGroups}
        setShowOnlyTrainingGroups={setShowOnlyTrainingGroups}
        showOnlyAthletePlans={showOnlyAthletePlans}
        setShowOnlyAthletePlans={setShowOnlyAthletePlans}
      />

      {isModalOpen && (
        <SeasonPlanModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          onSave={handleSaveSeasonPlan}
          allSkillSets={props.allSkillSets}
          allTrainingGroups={props.allTrainingGroups}
          fullCalendarEvent={activeFullCalendarEvent}
          initialStartDate={activeFullCalendarEvent?.seasonPlan?.startDateTime || startDate}
          initialEndDate={activeFullCalendarEvent?.seasonPlan?.endDateTime || endDate}
        />
      )}
    </Box>
  );
};
