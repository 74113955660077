import React from "react";
import {
  AdminPanelSettings,
  Assignment,
  Event,
  LocalLibrary,
  Person,
  Dashboard,
  Timeline,
  AccountTree,
} from "@mui/icons-material";
import { NavigationLink, type NavigationLinkProps } from "./components";
import { Button, Card, Divider, Icon, Stack, Text } from "@chakra-ui/react";
import { useAuth } from "../Auth/components/AuthProvider";
import { Link, useLocation } from "react-router-dom";
import { AccessType } from "../../types";

/** Array of links to display */
export const links: NavigationLinkProps[] = [
  {
    to: "/",
    icon: <Icon as={Event} />,
    primaryText: "Calendar",
    accessTypes: [AccessType.Admin, AccessType.Owner, AccessType.Full],
  },
  {
    to: "/season-plan",
    icon: <Icon as={Timeline} />,
    primaryText: "Season Plan",
    isDisabledOnMobile: true,
    accessTypes: [AccessType.Admin, AccessType.Owner, AccessType.Full],
  },
  {
    to: "/athletes",
    icon: <Icon as={Person} />,
    primaryText: "Athletes",
    accessTypes: [AccessType.Admin, AccessType.Owner, AccessType.Full],
  },
  {
    to: "/training-management",
    icon: <Icon as={Dashboard} />,
    primaryText: "Training Management",
    isDisabledOnMobile: true,
    accessTypes: [AccessType.Admin, AccessType.Owner, AccessType.Full],
  },
  {
    to: "/skill-sets",
    icon: <Icon as={Assignment} />,
    primaryText: "Skill Sets",
    isDisabledOnMobile: true,
    accessTypes: [AccessType.Admin, AccessType.Owner, AccessType.Full],
  },
  {
    to: "/drill-library",
    icon: <Icon as={LocalLibrary} />,
    primaryText: "Drill Library",
    isDisabledOnMobile: true,
    accessTypes: [AccessType.Admin, AccessType.Owner, AccessType.Full],
  },
  {
    to: "/organization",
    icon: <Icon as={AccountTree} />,
    primaryText: "Organization",
    isDisabledOnMobile: true,
    accessTypes: [AccessType.Admin, AccessType.Owner, AccessType.Full],
  },
  {
    to: "/dashboard",
    icon: <Icon as={Dashboard} />,
    primaryText: "My Dashboard",
    accessTypes: [AccessType.Athlete],
  },
  {
    to: "/admin",
    icon: <Icon as={AdminPanelSettings} color="red" />,
    primaryText: "Admin",
    isDisabledOnMobile: true,
    accessTypes: [AccessType.Admin],
  },
];

export const Header: React.FC = () => {
  const { isAuthenticated, accessType } = useAuth();
  const location = useLocation();

  const isPreviewPath = location.pathname.includes("athletes-preview");
  const effectiveAccessType = isPreviewPath ? AccessType.Athlete : accessType;
  const filteredLinks = links.filter(
    (link) => !link.accessTypes || (effectiveAccessType && link.accessTypes.includes(effectiveAccessType)),
  );

  return (
    <Card
      zIndex={11}
      position="fixed"
      width="100%"
      height="56px"
      direction="row"
      alignContent="center"
      justifyContent={isAuthenticated ? "center" : "start"}
      bgColor="teal.800"
      paddingY="2"
      paddingX="4"
      variant="outline"
      border={0}
      borderRadius={0}
      data-testid="header"
    >
      <Text fontSize="2xl" fontFamily="logo" textTransform="uppercase" alignContent="end" color="whiteAlpha.900">
        Striveon
      </Text>

      {isAuthenticated && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          flex={1}
          spacing="4"
          paddingX="4"
          data-testid="header-links"
        >
          {effectiveAccessType &&
            filteredLinks.map((link, index) => (
              <React.Fragment key={link.to}>
                <NavigationLink
                  to={isPreviewPath ? undefined : link.to}
                  icon={link.icon}
                  primaryText={link.primaryText}
                  accessTypes={[effectiveAccessType]}
                />
                {index < filteredLinks.length - 1 && <Divider orientation="vertical" borderColor="whiteAlpha.500" />}
              </React.Fragment>
            ))}
        </Stack>
      )}

      {isAuthenticated && (
        <Button
          as={Link}
          to={isPreviewPath ? undefined : "/account"}
          variant="outline"
          paddingX="2"
          color={location.pathname === "/account" ? "orange.400" : "white"}
          borderRadius="md"
          borderColor={location.pathname === "/account" ? "orange.400" : "white"}
          boxShadow="sm"
          transition="all 0.3s ease"
          _hover={{ color: "orange.500", borderColor: "orange.500" }}
          data-testid="header-account"
        >
          Account
        </Button>
      )}
    </Card>
  );
};
