import React, { useEffect, useState } from "react";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import { TrainingSeasons } from "../components/TrainingSeasons/TrainingSeasons";
import { LoadingContainer, loadingContainerFadeIn } from "../../../common/components/LoadingContainer";
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_ALL_TRAINING_SEASONS } from "../graphql/get-all-training-seasons.query";
import { GET_MISSING_TRAINING_GROUP_EVENTS } from "../graphql/get-missing-training-group-events.query";
import { GET_ALL_COACHES } from "../../../common/graphql/get-all-coaches.query";
import { useAsyncMutation, useHandleQueryErrors } from "../../../common/hooks";
import { GET_ALL_CENTERS } from "../../../common/graphql/get-all-centers.query";
import { SAVE_TRAINING_SEASON } from "../graphql/save-training-season.mutation";
import { CREATE_MISSING_TRAINING_GROUP_EVENTS } from "../graphql/create-missing-training-group-events.mutation";
import { ComponentContainer } from "../../../common/components/ComponentContainer";
import { DeletionInfoEntityType, type DeletionInfoInput, type TrainingSeasonStatus } from "../../../types";
import { SAVE_TRAINING_GROUP } from "../../../common/graphql/save-training-group.mutation";
import { GET_ALL_TRAINING_GROUPS } from "../../../common/graphql/get-all-training-groups.query";
import { TrainingGroups } from "../components/TrainingGroups/TrainingGroups";
import type { TrainingGroupInput } from "../../Athletes/containers/AthleteContainer";
import { GET_ENTITIES_TO_REMOVE } from "../../../common/graphql/get-entities-to-remove.query";
import { GET_ALL_ATHLETES_FOR_TRAINING_MANAGEMENT } from "../graphql/get-all-athletes-for-training-management.query";

type TrainingGroupCopyInput = {
  id: string;
  name: string;
};

export type TrainingSeasonInput = {
  id?: string;
  name: string;
  seasonStart: string;
  seasonEnd: string;
  status: TrainingSeasonStatus;
  trainingGroupsToCopy?: TrainingGroupCopyInput[];
  remove?: boolean;
};

export type CreateMissingTrainingGroupEventInput = {
  trainingSeasonId: string;
  trainingGroupId: string;
  startDateTime: string;
};

export const TrainingManagementContainer: React.FC = () => {
  // Queries
  const {
    data: allTrainingSeasonsData,
    loading: allTrainingSeasonsLoading,
    error: allTrainingSeasonsError,
  } = useQuery(GET_ALL_TRAINING_SEASONS);
  const { data: coachData, loading: coachLoading, error: coachError } = useQuery(GET_ALL_COACHES);
  const { data: centerData, loading: centerLoading, error: centerError } = useQuery(GET_ALL_CENTERS);
  const {
    data: trainingGroupsData,
    loading: trainingGroupsLoading,
    error: trainingGroupsError,
  } = useQuery(GET_ALL_TRAINING_GROUPS);
  const {
    data: athleteData,
    loading: athleteLoading,
    error: athleteError,
  } = useQuery(GET_ALL_ATHLETES_FOR_TRAINING_MANAGEMENT);
  const [
    getMissingMTrainingGroupEvents,
    {
      data: missingTrainingGroupEventsData,
      loading: missingTrainingGroupEventsLoading,
      error: missingTrainingGroupEventsError,
    },
  ] = useLazyQuery(GET_MISSING_TRAINING_GROUP_EVENTS, { fetchPolicy: "network-only" });
  const [
    getEntitiesToBeRemovedWithTrainingSeason,
    { data: entitiesToBeRemovedData, loading: entitiesToBeRemovedLoading, error: entitiesToBeRemovedError },
  ] = useLazyQuery(GET_ENTITIES_TO_REMOVE, {
    // changes with saveTrainingGroupMutation doesn't update cache, and for now
    // it's too much hassle with training season ids to do refetchQuery for this
    fetchPolicy: "network-only",
  });
  const [
    getEntitiesToBeRemovedWithTrainingGroup,
    {
      data: entitiesToBeRemovedWithTrainingGroupData,
      loading: entitiesToBeRemovedWithTrainingGroupLoading,
      error: entitiesToBeRemovedWithTrainingGroupError,
    },
  ] = useLazyQuery(GET_ENTITIES_TO_REMOVE, {
    fetchPolicy: "network-only",
  });

  // Mutations
  const { execute: saveTrainingSeasonMutation, loading: saveTrainingSeasonLoading } = useAsyncMutation(
    SAVE_TRAINING_SEASON,
    { awaitRefetchQueries: true },
  );
  const { execute: createMissingTrainingGroupEventsMutation, loading: createTrainingGroupEventsLoading } =
    useAsyncMutation(CREATE_MISSING_TRAINING_GROUP_EVENTS, {
      awaitRefetchQueries: true,
    });
  const { execute: saveTrainingGroupMutation, loading: saveTrainingGroupLoading } = useAsyncMutation(
    SAVE_TRAINING_GROUP,
    { awaitRefetchQueries: true },
  );

  const isLoading =
    allTrainingSeasonsLoading || athleteLoading || trainingGroupsLoading || coachLoading || centerLoading;
  const [showContent, setShowContent] = useState(!isLoading);

  useEffect(() => {
    if (!isLoading) {
      // Timeout needs to match with LoadingContainer animation duration
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    } else {
      setShowContent(false);
    }
  }, [isLoading]);

  useHandleQueryErrors([
    allTrainingSeasonsError,
    coachError,
    centerError,
    missingTrainingGroupEventsError,
    athleteError,
    trainingGroupsError,
    entitiesToBeRemovedError,
    entitiesToBeRemovedWithTrainingGroupError,
  ]);

  const saveTrainingSeason = async (trainingSeasonInput: TrainingSeasonInput): Promise<void> => {
    return await saveTrainingSeasonMutation({ data: trainingSeasonInput });
  };

  const createMissingTrainingGroupEvents = async (input: CreateMissingTrainingGroupEventInput[]): Promise<void> => {
    await createMissingTrainingGroupEventsMutation({
      data: input,
    });

    await getMissingMTrainingGroupEvents({ variables: { id: input[0].trainingSeasonId } });
  };

  const getTrainingSeasonRelatedEntities = async (id: string) => {
    const input: DeletionInfoInput = {
      entityType: DeletionInfoEntityType.TrainingSeason,
      id: id,
    };
    try {
      await getEntitiesToBeRemovedWithTrainingSeason({
        variables: { data: input },
      });
    } catch {
      // catch silently, all errors are handled in useEffect
    }
  };

  const saveTrainingGroup = async (trainingGroupInput: TrainingGroupInput): Promise<void> => {
    return await saveTrainingGroupMutation({ data: trainingGroupInput });
  };

  const getTrainingGroupRelatedEntities = async (id: string) => {
    const input: DeletionInfoInput = {
      entityType: DeletionInfoEntityType.TrainingGroup,
      id: id,
    };
    try {
      await getEntitiesToBeRemovedWithTrainingGroup({
        variables: { data: input },
      });
    } catch {
      // catch silently, all errors are handled in useEffect
    }
  };

  const getMissingTrainingGroupEvents = async (trainingSeasonId: string) => {
    await getMissingMTrainingGroupEvents({ variables: { id: trainingSeasonId } });
  };

  return (
    <Box>
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Grid
          animation={!isLoading ? `${loadingContainerFadeIn} 0.3s` : undefined}
          templateColumns="repeat(3, 1fr)"
          templateRows="max-content"
          gap="8"
        >
          <GridItem colSpan={3} height="min-content">
            <ComponentContainer handlePaddingManually={true}>
              <TrainingSeasons
                allTrainingGroups={trainingGroupsData?.getAllTrainingGroups || []}
                trainingSeasons={allTrainingSeasonsData?.getAllTrainingSeasons || []}
                missingTrainingGroupEvents={missingTrainingGroupEventsData?.getMissingTrainingGroupEvents || []}
                updateTrainingSeasonLoading={saveTrainingSeasonLoading}
                createTrainingGroupEventsLoading={createTrainingGroupEventsLoading}
                saveTrainingSeason={saveTrainingSeason}
                createMissingTrainingGroupEvents={createMissingTrainingGroupEvents}
                getEntitiesToBeRemoved={getTrainingSeasonRelatedEntities}
                entitiesToBeRemoved={entitiesToBeRemovedData?.getEntitiesToRemove || []}
                entitiesToBeRemovedLoading={entitiesToBeRemovedLoading}
                onGetMissingTrainingGroupEvents={getMissingTrainingGroupEvents}
                missingTrainingGroupEventsLoading={missingTrainingGroupEventsLoading}
              />
            </ComponentContainer>
          </GridItem>

          <GridItem colSpan={3}>
            <ComponentContainer handlePaddingManually={true}>
              <TrainingGroups
                coaches={coachData?.getAllCoaches || []}
                centers={centerData?.getAllCenters || []}
                events={trainingGroupsData?.getAllTrainingGroups || []}
                trainingSeasons={allTrainingSeasonsData?.getAllTrainingSeasons || []}
                athletes={athleteData?.getAllAthletes || []}
                onSave={saveTrainingGroup}
                isLoading={saveTrainingGroupLoading}
                getEntitiesToBeRemoved={getTrainingGroupRelatedEntities}
                entitiesToBeRemoved={entitiesToBeRemovedWithTrainingGroupData?.getEntitiesToRemove || []}
                entitiesToBeRemovedLoading={entitiesToBeRemovedWithTrainingGroupLoading}
              />
            </ComponentContainer>
          </GridItem>
        </Grid>
      )}
    </Box>
  );
};
