import React, { useEffect, useState } from "react";
import { Coach, Venue, type TrainingGroup, type TrainingSeason } from "../../../../types";
import { Box, Divider, Stack, Text, Tooltip } from "@chakra-ui/react";
import { CommonButton } from "../../../../common/components/CommonButton";
import { DeleteIcon } from "@chakra-ui/icons";
import { TrainingGroupAdder } from "./components/TrainingGroupAdder";
import { ConfirmationDialog } from "../../../../common/components/ConfirmationDialog";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { SingleTrainingGroup } from "./components/SingleTrainingGroup";

/** TrainingGroups props */
export type TrainingGroupsProps = {
  /** Athlete name */
  athleteName: string;
  /** Coaches to link to Training Group */
  coaches: Coach[];
  /** Venues to link to Training Group */
  venues: Venue[];
  /** The athlete's training groups */
  athletesTrainingGroups: TrainingGroup[];
  /** All possible training groups */
  allTrainingGroups?: TrainingGroup[];
  /** All possible Training Seasons */
  allTrainingSeasons?: TrainingSeason[];
  /** Function to handle event save */
  onSave?: (trainingGroupToSave: TrainingGroup) => void;
  /** Function to handle event remove */
  onRemove?: (trainingGroupToRemove: TrainingGroup) => void;
  /** Flag to indicate whether the component is loading */
  isLoading?: boolean;
  /** Whether the component is in read-only mode */
  readOnly?: boolean;
};

/** Component for displaying and editing a athlete's Training Groups */
export const TrainingGroups: React.FC<TrainingGroupsProps> = (props) => {
  const [isAddingNewGroup, setIsAddingNewGroup] = useState(false);
  const [trainingGroupToRemove, setTrainingGroupToRemove] = useState<TrainingGroup | null>(null);

  useEffect(() => {
    if (isAddingNewGroup) {
      // scroll to bottom of the page
      window.scrollTo({ top: document.body.scrollHeight, behavior: "auto" });
    }
  }, [isAddingNewGroup]);

  /** Function to handle saving the Training Group */
  const handleSaveGroup = (event: TrainingGroup) => {
    if (props.onSave) {
      props.onSave(event);
    }
    setIsAddingNewGroup(false);
  };

  /** Function to handle removing a group from the athlete's events */
  const handleRemoveGroup = (event: TrainingGroup) => {
    if (props.onRemove) {
      setTrainingGroupToRemove(null);
      props.onRemove(event);
    }
  };

  /** Function to handle adding a new group */
  const handleAddNewGroup = () => {
    setIsAddingNewGroup(true);
  };

  /** Function to handle canceling editing or adding */
  const handleCancel = () => {
    setIsAddingNewGroup(false);
  };

  const nonParticipatedTrainingGroups = props.allTrainingGroups?.filter(
    (event) => !props.athletesTrainingGroups?.some((athleteEvent) => athleteEvent.id === event.id),
  );

  return (
    <Box position="relative" data-testid="training-groups">
      <LoadingOverlay display={props.isLoading || false} spinnerSize="xl" spinnerTopPosition="60px" />

      <Tooltip
        label="Training Groups organize athletes into manageable teams for weekly training schedules and event planning"
        placement="auto-start"
      >
        <Text fontSize="xl" paddingBottom="6" justifySelf={{ mobile: "center", laptop: "start" }}>
          Training Groups ({props.athletesTrainingGroups.length})
        </Text>
      </Tooltip>

      {props.allTrainingSeasons?.length === 0 ? (
        <Text textColor="blackAlpha.600">
          Training Groups require a Training Season to be assigned. Create a Training Season in the 'Training
          Management' section to enable group scheduling
        </Text>
      ) : (
        <Stack direction="column" spacing={{ mobile: "6", laptop: "8" }} divider={<Divider />}>
          {props.athletesTrainingGroups.length > 0 ? (
            props.athletesTrainingGroups.map((event, index) => (
              <Box key={JSON.stringify(event)} data-testid={`training-group-${index}`}>
                <Stack direction={{ mobile: "column", laptop: "row" }} spacing="4" alignItems="center">
                  <SingleTrainingGroup event={event} allVenues={props.venues} readOnly={props.readOnly} />
                  {!props.readOnly && (
                    <CommonButton
                      variantType="outlineSecondary"
                      aria-label="remove"
                      hoverColor="red.600"
                      onClick={() => setTrainingGroupToRemove(event)}
                      dataTestId={`remove-training-group-${index}`}
                      tooltip={`Remove ${props.athleteName} from training group ${event.name}`}
                    >
                      <DeleteIcon color="red.600" />
                    </CommonButton>
                  )}
                </Stack>
              </Box>
            ))
          ) : (
            <Text textColor="blackAlpha.600">{`${props.athleteName} is not assigned to any Training Group.`}</Text>
          )}

          {!props.readOnly &&
          isAddingNewGroup &&
          props.allTrainingGroups &&
          props.coaches &&
          props.venues &&
          props.allTrainingSeasons ? (
            <TrainingGroupAdder
              nonParticipatedTrainingGroups={nonParticipatedTrainingGroups || []}
              allTrainingGroups={props.allTrainingGroups}
              allCoaches={props.coaches}
              allVenues={props.venues}
              allTrainingSeasons={props.allTrainingSeasons}
              onSave={handleSaveGroup}
              onCancel={handleCancel}
              loading={props.isLoading}
            />
          ) : (
            !props.readOnly && (
              <Box width={{ mobile: "100%", laptop: "fit-content" }} alignSelf="end">
                <CommonButton
                  fullWidth
                  variantType="solidPrimary"
                  onClick={handleAddNewGroup}
                  disabled={props.venues.length === 0}
                  tooltip={
                    props.venues.length === 0
                      ? "No venues available to create a new group, venues can be added from the 'Organization' section"
                      : undefined
                  }
                  dataTestId="add-training-group"
                >
                  {`Add ${props.athleteName} to group`}
                </CommonButton>
              </Box>
            )
          )}
        </Stack>
      )}

      {!props.readOnly && (
        <ConfirmationDialog
          isOpen={!!trainingGroupToRemove}
          message={
            <Text>
              Please confirm that you want to remove
              <Text as="span" color="red">{` ${props.athleteName} `}</Text>
              from the training group
              <Text as="span" color="red">{` ${trainingGroupToRemove?.name}`}</Text>. This cannot be undone.
            </Text>
          }
          onCancel={() => setTrainingGroupToRemove(null)}
          onClose={() => setTrainingGroupToRemove(null)}
          onConfirm={() => (trainingGroupToRemove ? handleRemoveGroup(trainingGroupToRemove) : undefined)}
          title="Remove from training group"
          cancelButtonText="Cancel"
          confirmButtonText="Remove"
        />
      )}
    </Box>
  );
};
