import { gql } from "@apollo/client";

export const GET_BILLING_INFO = gql`
  query GetBillingInfo {
    getBillingInfo {
      id
      address
      city
      country
      email
      name
      postalCode
      vatNumber
    }
  }
`;
