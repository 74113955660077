import React, { useState } from "react";
import { Box, Text, Stack, Icon, Grid, GridItem, Divider } from "@chakra-ui/react";
import { LoadingOverlay } from "../../../common/components/LoadingOverlay";
import { CommonButton } from "../../../common/components/CommonButton";
import { CommonInput } from "../../../common/components/CommonInput";

import { Logout } from "@mui/icons-material";
import { CommonIconButton } from "../../../common/components/CommonIconButton";
import { useAuth } from "../../Auth/components/AuthProvider";
import { useLogout } from "../../Auth/hooks/useLogout";

type AccountProps = {
  userEmail: string;
  isEmailVerified: boolean;
  loading: boolean;
  onSendVerificationEmail: () => Promise<void>;
  onVerifyEmail: (code: string) => Promise<void>;
};

export const Account: React.FC<AccountProps> = (props) => {
  const { setIsAuthenticated } = useAuth();
  const logoutUser = useLogout();
  const [emailVerificationCode, setEmailVerificationCode] = useState<string>("");
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleLogout = async () => {
    logoutUser(false);
    setIsAuthenticated(false);
  };

  /**
   * Sends a verification email to the user's address via AWS Cognito. After sending, the user should check their inbox
   * for the code.
   */
  const handleSendVerificationEmail = async (): Promise<void> => {
    setIsEmailSent(true);
    await props.onSendVerificationEmail();
  };

  /** Verifies the user's email with the provided verification code via AWS Cognito. */
  const handleVerifyEmail = async (): Promise<void> => {
    if (!emailVerificationCode.trim()) return;
    await props.onVerifyEmail(emailVerificationCode.trim());
    setEmailVerificationCode("");
  };

  return (
    <Box position="relative">
      <LoadingOverlay display={props.loading} spinnerSize="xl" spinnerTopPosition="50%" />
      <Stack
        direction={{ mobile: "column", laptop: "row" }}
        spacing="4"
        paddingBottom="6"
        justifyContent="space-between"
        alignItems={{ mobile: "center", laptop: "flex-start" }}
      >
        <Box flex={1} display={{ mobile: "none", laptop: "flex" }} />
        <Text flex={1} fontSize="x-large">
          Personal Account
        </Text>
        <CommonButton variantType="outlineSecondary" onClick={handleLogout}>
          <Box
            display="flex"
            alignItems="center"
            color="blackAlpha.600"
            transition="all 0.3s ease"
            _hover={{ color: "orange.500" }}
          >
            {<Icon as={Logout} marginRight="1" color="inherit" />}
            <Text color="inherit">Log Out</Text>
          </Box>
        </CommonButton>
      </Stack>

      <Grid templateColumns="min-content 1fr" gap={{ mobile: "2", laptop: "8" }}>
        <GridItem colSpan={{ mobile: 2, laptop: 1 }}>
          <Text fontSize="large" color="blackAlpha.600">
            Email
          </Text>
        </GridItem>
        <GridItem colSpan={{ mobile: 2, laptop: 1 }}>
          <Text
            fontSize="large"
            border="1px solid"
            borderColor="teal.800"
            borderRadius="md"
            backgroundColor="teal.800"
            color="white"
            paddingX={2}
            maxWidth="fit-content"
            noOfLines={1}
          >
            {props.userEmail}
          </Text>
        </GridItem>

        <GridItem colSpan={{ mobile: 2, laptop: 1 }}>
          <Text fontSize="large" color="blackAlpha.600">
            Status
          </Text>
        </GridItem>
        <GridItem colSpan={{ mobile: 2, laptop: 1 }}>
          <Text
            fontSize="large"
            border="1px solid"
            borderRadius="md"
            borderColor={props.isEmailVerified ? "teal.800" : "red.500"}
            color="white"
            backgroundColor={props.isEmailVerified ? "teal.800" : "red.500"}
            width="fit-content"
            paddingX={2}
          >
            {props.isEmailVerified ? "Verified" : "Unverified"}
          </Text>
        </GridItem>

        {!props.isEmailVerified && (
          <>
            <GridItem colSpan={2}>
              <Divider marginY={{ mobile: "4", laptop: "0" }} />
            </GridItem>

            <GridItem colSpan={2}>
              <Grid templateColumns="repeat(3, 1fr)" gap="6">
                <GridItem colSpan={3}>
                  <Text fontSize="lg" marginBottom="2">
                    Why should you verify your email?
                  </Text>
                  <Text marginBottom="1">
                    - Password recovery{" "}
                    <Text as="span" color="blackAlpha.600">
                      - Verifying your email is required to reset your password securely
                    </Text>
                  </Text>
                  <Text>
                    - Enhanced security{" "}
                    <Text as="span" color="blackAlpha.600">
                      - A verified email protects your account from unauthorized access
                    </Text>
                  </Text>
                </GridItem>

                <GridItem colSpan={3}>
                  <Text fontSize="lg" marginBottom="2">
                    How to verify your email?
                  </Text>
                  <Text marginBottom="1">
                    1. Click{" "}
                    <Text as="span" color="orange.500" fontStyle="italic">
                      Send Verification Code to Email
                    </Text>{" "}
                    to receive a verification code at{" "}
                    <Text as="span" color="orange.500" fontStyle="italic">
                      {props.userEmail}
                    </Text>
                  </Text>

                  <Text marginBottom="1">2. Check your inbox and copy the 6-digit code you receive</Text>

                  <Text>
                    3. Enter the code in the{" "}
                    <Text as="span" color="orange.500" fontStyle="italic">
                      Verification Code
                    </Text>{" "}
                    field below and click{" "}
                    <Text as="span" color="orange.500" fontStyle="italic">
                      Verify Email
                    </Text>{" "}
                    to complete the verification process
                  </Text>
                </GridItem>

                <GridItem colSpan={3}>
                  <Stack direction={{ mobile: "column", laptop: "row" }} spacing={4}>
                    <CommonButton
                      fullWidth
                      variantType="outlineSecondary"
                      onClick={handleSendVerificationEmail}
                      disabled={isEmailSent}
                      tooltip={isEmailSent ? "Verification email is sent already, please check your inbox" : undefined}
                    >
                      Send Verification Code to Email
                    </CommonButton>

                    <CommonInput
                      placeholder="Verification Code (6-digit)"
                      type="number"
                      value={emailVerificationCode}
                      onChange={(val) => setEmailVerificationCode(val)}
                    />

                    <CommonButton
                      fullWidth
                      variantType="solidPrimary"
                      onClick={handleVerifyEmail}
                      tooltip={
                        emailVerificationCode.trim().length !== 6
                          ? "Verify your email with the code you received, it should be 6-digit number"
                          : undefined
                      }
                      disabled={emailVerificationCode.trim().length !== 6}
                    >
                      Verify Email
                    </CommonButton>
                  </Stack>
                </GridItem>
              </Grid>
            </GridItem>
          </>
        )}
      </Grid>
    </Box>
  );
};
