import { gql } from "@apollo/client";

export const GET_ALL_VENUES = gql`
  query GetAllVenues {
    getAllVenues {
      id
      name
      center {
        id
        name
      }
    }
  }
`;
