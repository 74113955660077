import { gql } from "@apollo/client";

export const GET_ALL_ATHLETES = gql`
  query GetAllAthletes {
    getAllAthletes {
      id
      name
      birthYear
      trainingGroups {
        id
        name
      }
      evaluation {
        id
        overallRating
      }
      invite {
        id
        inviteCode
        used
        receiver
        usedAt
      }
    }
  }
`;
