import React, { useEffect, useMemo, useState } from "react";
import { Coaches } from "../components/Coaches/Coaches";
import { Centers } from "../components/Centers/Centers";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import { LoadingContainer, loadingContainerFadeIn } from "../../../common/components/LoadingContainer";
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_ALL_COACHES } from "../../../common/graphql/get-all-coaches.query";
import { useAsyncMutation, useHandleQueryErrors } from "../../../common/hooks";
import { GET_ALL_CENTERS } from "../../../common/graphql/get-all-centers.query";
import { SAVE_COACH } from "../graphql/save-coach.mutation";
import { SAVE_CENTER } from "../graphql/save-center.mutation";
import { SAVE_VENUE } from "../graphql/save-venue.mutation";
import { GET_CENTER } from "../graphql/get-center.query";
import { ComponentContainer } from "../../../common/components/ComponentContainer";
import {
  AccessType,
  DeletionInfoEntityType,
  InviteType,
  type Coach,
  type CreateInviteInput,
  type DeletionInfoInput,
  type OrganizationInput,
} from "../../../types";
import { GET_ENTITIES_TO_REMOVE } from "../../../common/graphql/get-entities-to-remove.query";
import { useGlobalContext } from "../../../common/components/GlobalProvider";
import { OrganizationSettings } from "../components/OrganizationSettings/OrganizationSettings";
import { GET_USER_ORGANIZATION } from "../../../common/graphql/get-user-organization.query";
import { UPDATE_USER_ORGANIZATION_SETTINGS } from "../graphql/update-user-organization-settings.mutation";
import { CREATE_INVITE_JOIN_ORGANNIZATION_FULL_ACCESS } from "../graphql/create-invite-join-organization-full-access.mutation";
import { GET_ALL_ORGANIZATION_INVITES } from "../graphql/get-all-organization-invites.query";
import { Users } from "../components/Users/Users";
import { Invites } from "../components/Invites/Invites";
import { GET_ALL_VENUES } from "../graphql/get-all-venues.query";
import { GET_BILLING_INFO } from "../graphql/get-billing-info.query";
import { UPDATE_BILLING_INFO } from "../graphql/update-billing-info.mutation";
import { useAuth } from "../../Auth/components/AuthProvider";
import { Billing } from "../components/Billing/Billing";
import { GENERATE_ESTIMATE_INVOICE } from "../graphql/generate-invoice-preview.mutation";
import { GET_INVOICES } from "../graphql/get-invoices.query";
import { DangerZone } from "../components/DangerZone/DangerZone";
import { MARK_ORGANIZATION_PENDING_DELETION } from "../graphql/mark-organization-pending-deletion.mutation";
import { CANCEL_ORGANIZATION_DELETION } from "../graphql/cancel-organization-deletion.mutation";

export type CreateMissingTrainingGroupEventInput = {
  trainingSeasonId: string;
  trainingGroupId: string;
  startDateTime: string;
};

export type CoachInput = {
  id?: string;
  name: string;
  remove?: boolean;
};

export type VenueInput = {
  id?: string;
  name: string;
  remove?: boolean;
};

export type CenterInput = {
  id?: string;
  venues?: VenueInput[];
  name: string;
  remove?: boolean;
};

export const OrganizationContainer: React.FC = () => {
  // Queries
  const { data: coachData, loading: coachLoading, error: coachError } = useQuery(GET_ALL_COACHES);
  const { data: centerData, loading: centerLoading, error: centerError } = useQuery(GET_ALL_CENTERS);
  const { data: venueData, loading: venueLoading, error: venueError } = useQuery(GET_ALL_VENUES);
  const {
    data: organizationData,
    loading: organizationLoading,
    error: organizationError,
  } = useQuery(GET_USER_ORGANIZATION);

  const { data: billingData, loading: billingLoading, error: billingError } = useQuery(GET_BILLING_INFO);
  const { data: invoiceData, loading: invoiceLoading, error: invoiceError } = useQuery(GET_INVOICES);

  const [
    getEntitiesToBeRemovedWithVenue,
    {
      data: entitiesToBeRemovedWithVenueData,
      loading: entitiesToBeRemovedWithVenueLoading,
      error: entitiesToBeRemovedWithVenueError,
    },
  ] = useLazyQuery(GET_ENTITIES_TO_REMOVE, {
    fetchPolicy: "network-only",
  });
  const [
    getEntitiesToBeRemovedWithCenter,
    {
      data: entitiesToBeRemovedWithCenterData,
      loading: entitiesToBeRemovedWithCenterLoading,
      error: entitiesToBeRemovedWithCenterError,
    },
  ] = useLazyQuery(GET_ENTITIES_TO_REMOVE, {
    fetchPolicy: "network-only",
  });

  const { data: inviteData, loading: inviteLoading, error: inviteError } = useQuery(GET_ALL_ORGANIZATION_INVITES);

  // Mutations
  const { execute: saveCoachMutation, loading: saveCoachLoading } = useAsyncMutation(SAVE_COACH, {
    awaitRefetchQueries: true,
  });
  const { execute: saveCenterMutation, loading: saveCenterLoading } = useAsyncMutation(SAVE_CENTER, {
    awaitRefetchQueries: true,
  });
  const { execute: saveVenueMutation, loading: saveVenueLoading } = useAsyncMutation(SAVE_VENUE, {
    awaitRefetchQueries: true,
  });
  const [getCenter, { error: getCenterError, loading: getCenterLoading }] = useLazyQuery(GET_CENTER, {
    fetchPolicy: "network-only",
  });
  const { execute: updateOrganizationSettingsMutation, loading: updateOrganizationLoading } = useAsyncMutation(
    UPDATE_USER_ORGANIZATION_SETTINGS,
    { awaitRefetchQueries: true },
  );

  const { execute: createFullAccessInviteMutation, loading: createFullAccessInviteLoading } = useAsyncMutation(
    CREATE_INVITE_JOIN_ORGANNIZATION_FULL_ACCESS,
    {
      awaitRefetchQueries: true,
    },
  );

  const { execute: updateBillingInfoMutation, loading: updateBillingLoading } = useAsyncMutation(UPDATE_BILLING_INFO, {
    awaitRefetchQueries: true,
  });
  const { execute: generateEstimateInvoiceMutation, loading: generateEstimateInvoiceLoading } = useAsyncMutation(
    GENERATE_ESTIMATE_INVOICE,
    {
      awaitRefetchQueries: true,
      timeoutMs: 30000,
    },
  );
  const { execute: markPendingDeletionMutation, loading: markDeletionLoading } = useAsyncMutation(
    MARK_ORGANIZATION_PENDING_DELETION,
    {
      awaitRefetchQueries: true,
    },
  );

  const { execute: cancelPendingDeletionMutation, loading: cancelDeletionLoading } = useAsyncMutation(
    CANCEL_ORGANIZATION_DELETION,
    {
      awaitRefetchQueries: true,
    },
  );

  const { globalSetSelectedCenter } = useGlobalContext();
  const { accessType: currentUserAccess } = useAuth();

  const isLoading =
    coachLoading || venueLoading || centerLoading || organizationLoading || inviteLoading || billingLoading;

  const [showContent, setShowContent] = useState(!isLoading);

  useEffect(() => {
    if (!isLoading) {
      // Timeout needs to match with LoadingContainer animation duration
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    } else {
      setShowContent(false);
    }
  }, [isLoading]);

  useHandleQueryErrors([
    coachError,
    venueError,
    centerError,
    getCenterError,
    entitiesToBeRemovedWithVenueError,
    entitiesToBeRemovedWithCenterError,
    organizationError,
    inviteError,
    billingError,
    invoiceError,
  ]);

  const saveCoach = async (coachInput: CoachInput): Promise<void> => {
    return await saveCoachMutation({ data: coachInput });
  };

  const saveCenter = async (centerInput: CenterInput): Promise<void> => {
    const result = await saveCenterMutation({ data: centerInput });
    if (result) {
      if (result.data?.saveCenter?.id) {
        // center is created or updated
        const { data: centerData } = await getCenter({ variables: { id: result.data?.saveCenter?.id } });
        if (centerData) {
          globalSetSelectedCenter(centerData.getCenter);
        }
      } else {
        // center is removed
        globalSetSelectedCenter(null);
      }
    }

    return result;
  };

  const saveVenue = async (venueInput: VenueInput, centerId: string): Promise<void> => {
    const result = await saveVenueMutation({ data: venueInput, centerId });
    if (result) {
      const { data: centerData } = await getCenter({ variables: { id: centerId } });
      if (centerData) {
        globalSetSelectedCenter(centerData.getCenter);
      }
    }

    return result;
  };

  const getVenueRelatedEntities = async (id: string) => {
    const input: DeletionInfoInput = {
      entityType: DeletionInfoEntityType.Venue,
      id: id,
    };
    try {
      await getEntitiesToBeRemovedWithVenue({
        variables: { data: input },
      });
    } catch {
      // catch silently, all errors are handled in useEffect
    }
  };

  const getCenterRelatedEntities = async (id: string) => {
    const input: DeletionInfoInput = {
      entityType: DeletionInfoEntityType.Center,
      id: id,
    };
    try {
      await getEntitiesToBeRemovedWithCenter({
        variables: { data: input },
      });
    } catch {
      // catch silently, all errors are handled in useEffect
    }
  };

  const updateOrganizationSettings = async (organizationInput: OrganizationInput) => {
    return await updateOrganizationSettingsMutation({ data: organizationInput });
  };

  const createFullAccessInvite = async (receiver: string, coach: Coach) => {
    const input: CreateInviteInput = {
      inviteType: InviteType.JoinOrganizationFullAccess,
      receiver,
      coachId: coach.id || "",
    };
    return await createFullAccessInviteMutation({ data: { ...input } });
  };

  const updateBillingInfo = async (input: any) => {
    return await updateBillingInfoMutation({ data: input });
  };

  const missingAnyBillingInfo = useMemo(() => {
    return (
      !billingData?.getBillingInfo?.address ||
      !billingData?.getBillingInfo?.city ||
      !billingData?.getBillingInfo?.country ||
      !billingData?.getBillingInfo?.email ||
      !billingData?.getBillingInfo?.name ||
      !billingData?.getBillingInfo?.postalCode ||
      !billingData?.getBillingInfo?.vatNumber
    );
  }, [billingData?.getBillingInfo]);

  return (
    <Box>
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Grid
          animation={!isLoading ? `${loadingContainerFadeIn} 0.3s` : undefined}
          templateColumns="repeat(6, 1fr)"
          gap="8"
        >
          {/* Organization Settings */}
          <GridItem colSpan={3}>
            <ComponentContainer>
              <OrganizationSettings
                organization={organizationData?.getUserOrganization || null}
                updateUserOrganizationSettings={updateOrganizationSettings}
                updateOrganizationLoading={updateOrganizationLoading}
              />
            </ComponentContainer>
          </GridItem>
          {/* Users */}
          <GridItem colSpan={3}>
            <ComponentContainer handlePaddingManually={true}>
              <Users allUsers={organizationData?.getUserOrganization?.users || []} />
            </ComponentContainer>
          </GridItem>
          {/* Coaches */}
          <GridItem colSpan={2}>
            <ComponentContainer handlePaddingManually>
              <Coaches
                allCoaches={coachData?.getAllCoaches || []}
                saveCoach={saveCoach}
                saveCoachLoading={saveCoachLoading}
              />
            </ComponentContainer>
          </GridItem>
          {/* Invites */}
          <GridItem colSpan={4}>
            <ComponentContainer handlePaddingManually={true}>
              <Invites
                allCoaches={coachData?.getAllCoaches || []}
                allInvites={inviteData?.getAllOrganizationInvites || []}
                createInvite={createFullAccessInvite}
                createInviteLoading={createFullAccessInviteLoading}
              />
            </ComponentContainer>
          </GridItem>
          {/* Centers */}
          <GridItem colSpan={6}>
            <Centers
              allCenters={centerData?.getAllCenters || []}
              allVenues={venueData?.getAllVenues || []}
              saveCenter={saveCenter}
              saveCenterLoading={saveCenterLoading}
              saveVenue={saveVenue}
              saveVenueLoading={saveVenueLoading || getCenterLoading}
              entitiesToBeRemovedWithVenue={entitiesToBeRemovedWithVenueData?.getEntitiesToRemove || []}
              entitiesToBeRemovedWithVenueLoading={entitiesToBeRemovedWithVenueLoading}
              getEntitiesToBeRemovedWithVenue={getVenueRelatedEntities}
              entitiesToBeRemovedWithCenter={entitiesToBeRemovedWithCenterData?.getEntitiesToRemove || []}
              entitiesToBeRemovedWithCenterLoading={entitiesToBeRemovedWithCenterLoading}
              getEntitiesToBeRemovedWithCenter={getCenterRelatedEntities}
            />
          </GridItem>
          {/* Billing */}
          {/* TODO: render for all users one testing & free trials are set to every organization */}
          {currentUserAccess === AccessType.Admin && (
            <GridItem colSpan={6}>
              <ComponentContainer handlePaddingManually={true} errorHighlight={missingAnyBillingInfo}>
                <Billing
                  billingInfo={billingData?.getBillingInfo || null}
                  missingAnyBillingInfo={missingAnyBillingInfo}
                  loading={updateBillingLoading || generateEstimateInvoiceLoading || invoiceLoading}
                  updateBillingInfo={updateBillingInfo}
                  currentUserAccess={currentUserAccess}
                  generateEstimateInvoice={generateEstimateInvoiceMutation}
                  invoices={invoiceData?.getInvoices || []}
                  organization={organizationData?.getUserOrganization || null}
                />
              </ComponentContainer>
            </GridItem>
          )}
          {/* Danger Zone */}
          <GridItem colSpan={4} marginTop="24" /> {/* Empty item to push danger zone to bottom right corner */}
          <GridItem colSpan={2} marginTop="24">
            <ComponentContainer errorHighlight={true}>
              <DangerZone
                organization={organizationData?.getUserOrganization || null}
                currentUserAccess={currentUserAccess}
                onMarkDeletion={markPendingDeletionMutation}
                onCancelDeletion={cancelPendingDeletionMutation}
                loading={markDeletionLoading || cancelDeletionLoading}
              />
            </ComponentContainer>
          </GridItem>
        </Grid>
      )}
    </Box>
  );
};
