import React, { useEffect, useState } from "react";
import { SkillSets } from "../components/SkillSets";
import { Box, Stack } from "@chakra-ui/react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_ALL_SKILL_SETS } from "../../../common/graphql/get-all-skill-sets.query";
import { SAVE_SKILL_SET } from "../graphql/save-skill-set.mutation";
import { GET_GENERIC_EVALUATION_CRITERIA } from "../graphql/get-generic-evaluation-criteria.query";
import { SAVE_EVALUATION_CRITERIA } from "../graphql/save-evaluation-criteria.mutation";
import {
  type SkillSet,
  type EvaluationCriteria as EvaluationCriteriaType,
  type DeletionInfoInput,
  DeletionInfoEntityType,
} from "../../../types";
import { EvaluationCriteria } from "../components/EvaluationCriteria";
import { useAlert } from "../../../common/components/AlertProvider";
import { useAsyncMutation, useHandleQueryErrors } from "../../../common/hooks";
import { LoadingContainer, loadingContainerFadeIn } from "../../../common/components/LoadingContainer";
import { ComponentContainer } from "../../../common/components/ComponentContainer";
import { GET_ENTITIES_TO_REMOVE } from "../../../common/graphql/get-entities-to-remove.query";
import { GET_SKILL_SET_EVALUATION_CRITERIA } from "../graphql/get-skill-set-evaluation-criteria.query";
import { GET_USER_ORGANIZATION } from "../../../common/graphql/get-user-organization.query";

type SkillInput = {
  id?: string;
  name: string;
  remove?: boolean;
};

type SkillSetInput = {
  id?: string;
  name: string;
  skills: SkillInput[];
  remove?: boolean;
};

type EvaluationCriteriaInput = {
  id?: string;
  fromRatingLevel: number;
  toRatingLevel: number;
  definition: string;
  requirements: string;
  skillSetId?: string;
  remove?: boolean;
};

export const SkillSetsContainer: React.FC = () => {
  // Queries
  const { data: skillSetData, loading: skillSetDataLoading, error: skillSetDataError } = useQuery(GET_ALL_SKILL_SETS);

  const {
    data: genericEvaluationCriteriaData,
    loading: genericEvaluationCriteriaLoading,
    error: genericEvaluationCriteriaError,
  } = useQuery(GET_GENERIC_EVALUATION_CRITERIA);

  const {
    data: organizationData,
    loading: organizationDataLoading,
    error: organizationDataError,
  } = useQuery(GET_USER_ORGANIZATION);

  const [
    getEntitiesToBeRemovedWithSkillSet,
    {
      data: entitiesToBeRemovedWithSkillSetData,
      loading: entitiesToBeRemovedWithSkillSetLoading,
      error: entitiesToBeRemovedWithSkillSetError,
    },
  ] = useLazyQuery(GET_ENTITIES_TO_REMOVE, {
    fetchPolicy: "network-only",
  });

  const [
    getEntitiesToBeRemovedWithSkill,
    {
      data: entitiesToBeRemovedWithSkillData,
      loading: entitiesToBeRemovedWithSkillLoading,
      error: entitiesToBeRemovedWithSkillError,
    },
  ] = useLazyQuery(GET_ENTITIES_TO_REMOVE, {
    fetchPolicy: "network-only",
  });

  const [getSkillSetEvaluationCriteriaQuery, { error: skillSetEvaluationCriteriaDataError }] = useLazyQuery(
    GET_SKILL_SET_EVALUATION_CRITERIA,
    { fetchPolicy: "network-only" },
  );

  // Mutations
  const { execute: saveSkillSetMutation } = useAsyncMutation(SAVE_SKILL_SET, {
    awaitRefetchQueries: true,
    onCustomError(error) {
      if (error?.message === "Entity with the given name already exists for this organization") {
        setRefreshKey((prevKey) => prevKey + 1);
        return "Couldn't save: Skill Set or Skill with given name exists already. Try again with another name.";
      }
      // For other errors, use the default error message
      return undefined;
    },
  });

  const { execute: saveEvaluationCriteriaMutation } = useAsyncMutation(SAVE_EVALUATION_CRITERIA, {
    awaitRefetchQueries: true,
    onCustomError(error) {
      if (error?.message === "Entity with the given range already exists") {
        return "Couldn't save Evaluation Criteria: Overlapping rating levels exist";
      }
      // For other errors, use the default error message
      return undefined;
    },
  });

  const [refreshKey, setRefreshKey] = useState(0);
  const [skillSetEvaluationCriteriaData, setSkillSetEvaluationCriteriaData] = useState<{
    [key: string]: EvaluationCriteriaType[];
  }>({});
  const [skillSetEvaluationCriteriaLoading, setSkillSetEvaluationCriteriaLoading] = useState<{
    [key: string]: boolean;
  }>({});
  const { showAlert } = useAlert();
  const isLoading = skillSetDataLoading || genericEvaluationCriteriaLoading || organizationDataLoading;
  const [showContent, setShowContent] = useState(!isLoading);

  useEffect(() => {
    if (!isLoading) {
      // Timeout needs to match with LoadingContainer animation duration
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    }
  }, [isLoading]);

  useHandleQueryErrors([
    skillSetDataError,
    genericEvaluationCriteriaError,
    entitiesToBeRemovedWithSkillSetError,
    entitiesToBeRemovedWithSkillError,
    skillSetEvaluationCriteriaDataError,
    organizationDataError,
  ]);

  const onSaveSkillSet = async (skillSetToSave: SkillSet) => {
    const dataToSave: SkillSetInput = {
      id: skillSetToSave.id,
      name: skillSetToSave.name,
      skills: skillSetToSave.skills.map((skill) => ({ id: skill.id, name: skill.name, remove: skill.remove })),
      remove: skillSetToSave.remove,
    };

    if (dataToSave.remove) {
      showAlert("Deleting Skill Set", "info", undefined, true);
    } else if (dataToSave.id) {
      showAlert("Updating Skill Set", "info", undefined, true);
    } else {
      showAlert("Creating new Skill Set", "info", undefined, true);
    }

    const result = await saveSkillSetMutation({ data: dataToSave });

    if (result) {
      if (dataToSave.remove) {
        showAlert("Skill Set deleted!", "success", 5000);
      } else if (dataToSave.id) {
        showAlert("Skill Set updated!", "success", 5000);
      } else {
        showAlert("Skill Set created!", "success", 5000);
      }
    }
  };

  const onSaveEvaluationCriteria = async (criteriaToSave: EvaluationCriteriaType) => {
    const dataToSave: EvaluationCriteriaInput = {
      id: criteriaToSave.id,
      fromRatingLevel: criteriaToSave.fromRatingLevel,
      toRatingLevel: criteriaToSave.toRatingLevel,
      definition: criteriaToSave.definition,
      requirements: criteriaToSave.requirements,
      remove: criteriaToSave.remove,
      skillSetId: criteriaToSave.skillSetId,
    };

    if (dataToSave.remove) {
      showAlert("Deleting Evaluation Criteria", "info", undefined, true);
    } else if (dataToSave.id) {
      showAlert("Updating Evaluation Criteria", "info", undefined, true);
    } else {
      showAlert("Creating new Evaluation Criteria", "info", undefined, true);
    }

    const result = await saveEvaluationCriteriaMutation({ data: dataToSave });
    if (criteriaToSave.skillSetId) {
      await getSkillSetEvaluationCriteria(criteriaToSave.skillSetId);
    }

    if (result) {
      if (dataToSave.remove) {
        showAlert("Evaluation Criteria deleted!", "success", 5000);
      } else if (dataToSave.id) {
        showAlert("Evaluation Criteria updated!", "success", 5000);
      } else {
        showAlert("Evaluation Criteria created!", "success", 5000);
      }
    }
  };

  const getSkillSetRelatedEntities = async (id: string) => {
    const input: DeletionInfoInput = {
      entityType: DeletionInfoEntityType.SkillSet,
      id: id,
    };
    try {
      await getEntitiesToBeRemovedWithSkillSet({
        variables: { data: input },
      });
    } catch {
      // catch silently, all errors are handled in useEffect
    }
  };

  const getSkillRelatedEntities = async (id: string) => {
    const input: DeletionInfoInput = {
      entityType: DeletionInfoEntityType.Skill,
      id: id,
    };
    try {
      await getEntitiesToBeRemovedWithSkill({
        variables: { data: input },
      });
    } catch {
      // catch silently, all errors are handled in useEffect
    }
  };

  const getSkillSetEvaluationCriteria = async (skillSetId: string) => {
    setSkillSetEvaluationCriteriaLoading((prev) => ({
      ...prev,
      [skillSetId]: true,
    }));
    const { data } = await getSkillSetEvaluationCriteriaQuery({
      variables: { skillSetId },
    });
    setSkillSetEvaluationCriteriaData((prev) => ({
      ...prev,
      [skillSetId]: data.getSkillSetEvaluationCriteria,
    }));

    setSkillSetEvaluationCriteriaLoading((prev) => ({
      ...prev,
      [skillSetId]: false,
    }));
  };

  return (
    <Box id="skill-sets">
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Stack animation={isLoading ? undefined : `${loadingContainerFadeIn} 0.3s`} direction="column" spacing="8">
          <ComponentContainer>
            <EvaluationCriteria
              onSave={onSaveEvaluationCriteria}
              allCriteria={genericEvaluationCriteriaData?.getGenericEvaluationCriteria || []}
              organization={organizationData?.getUserOrganization || null}
              isLoading={genericEvaluationCriteriaLoading}
              displayFeaturePurpose
            />
          </ComponentContainer>

          <ComponentContainer>
            <SkillSets
              key={refreshKey}
              onSave={onSaveSkillSet}
              skillSets={skillSetData?.getAllSkillSets || []}
              entitiesToBeRemovedWithSkillSet={entitiesToBeRemovedWithSkillSetData?.getEntitiesToRemove || []}
              entitiesToBeRemovedWithSkill={entitiesToBeRemovedWithSkillData?.getEntitiesToRemove || []}
              getEntitiesToBeRemovedWithSkillSet={getSkillSetRelatedEntities}
              getEntitiesToBeRemovedWithSkill={getSkillRelatedEntities}
              entitiesToBeRemovedLoading={entitiesToBeRemovedWithSkillSetLoading || entitiesToBeRemovedWithSkillLoading}
              getSkillSetEvaluationCriteria={getSkillSetEvaluationCriteria}
              skillSetEvaluationCriteriaData={skillSetEvaluationCriteriaData}
              skillSetEvaluationCriteriaLoading={skillSetEvaluationCriteriaLoading}
              onSaveEvaluationCriteria={onSaveEvaluationCriteria}
              organization={organizationData?.getUserOrganization || null}
            />
          </ComponentContainer>
        </Stack>
      )}
    </Box>
  );
};
