import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
} from "@chakra-ui/react";
import { CommonButton } from "../CommonButton";

export type ConfirmationDialogProps = {
  /** The title of the confirmation dialog. */
  title?: string;
  /** The message to display inside the confirmation dialog. */
  message: string | React.ReactNode;
  /** Callback function to handle the confirmation action. */
  onConfirm: () => void;
  /** Callback function to handle the cancel action. */
  onCancel: () => void;
  /** Text for the confirm button. */
  confirmButtonText?: string;
  /** Text for the cancel button. */
  cancelButtonText?: string;
  /** Boolean to control the visibility of the dialog. */
  isOpen: boolean;
  /** Callback function to close the dialog. */
  onClose?: () => void;
  /** If true, switch scrollbehavior to require user to scroll to bottom before confirming deletion */
  isDeletion?: boolean;
  /** Wether the component is in loading state and actions are disabled */
  isLoading?: boolean;
};

/** A confirmation dialog component to confirm critical actions like data deletion. */
export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose || props.onCancel}
      closeOnOverlayClick={false}
      scrollBehavior={props.isDeletion ? "outside" : "inside"}
      motionPreset="slideInTop"
      blockScrollOnMount={true}
    >
      <ModalOverlay backdropFilter="auto" backdropBlur="5px" />
      <ModalContent data-testid="confirmation-dialog">
        {props.title && <ModalHeader>{props.title}</ModalHeader>}
        {props.onClose && <ModalCloseButton />}
        <ModalBody>{props.message}</ModalBody>
        <ModalFooter>
          <Stack direction="row" spacing={4}>
            {props.cancelButtonText && (
              <CommonButton
                variantType="outlineSecondary"
                onClick={props.onCancel}
                disabled={props.isLoading}
                dataTestId="confirmation-dialog-secondary-button"
              >
                {props.cancelButtonText}
              </CommonButton>
            )}
            {props.confirmButtonText && (
              <CommonButton
                variantType="solidPrimary"
                color="red"
                hoverColor="red.700"
                onClick={props.onConfirm}
                disabled={props.isLoading}
                dataTestId="confirmation-dialog-submit-button"
              >
                {props.confirmButtonText}
              </CommonButton>
            )}
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
