import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import { Box, Stack, Text } from "@chakra-ui/react";
import type { Center, Organization, Venue } from "../../../../../types";
import { CommonEventContent } from "./components/CommonEventContent";
import { areDatesEqual } from "../../../utils";
import { getCommonCalendarProps } from "./utils";
import type { FullCalendarEvent } from "../../../types";
import { extractUtcTime } from "../../../../../common/utils/dateAndTime";
import { CommonButton } from "../../../../../common/components/CommonButton";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import type { EventClickArg } from "fullcalendar";
import "./calendar.css";
import { useAlert } from "../../../../../common/components/AlertProvider";

/** Props for the CalendarBody component. */
export type CalendarBodyProps = {
  /** The active view of the calendar. */
  activeView: string;
  /** The date to display in the day view. */
  dayViewDate: Date;
  /** The events to be displayed in the calendar. */
  eventsToDisplay: FullCalendarEvent[];
  /** The selected center which venues & events to display on calendar */
  selectedCenter: Center | null;
  /** References to FullCalendar instances. */
  calendarRefs: React.MutableRefObject<(FullCalendar | null)[]>;
  /** Unique key for FullCalendar instances. */
  fullCalendarKey: number;
  organization: Organization;
  /** Handler for when an event is clicked. */
  handleEventClick: (info: EventClickArg) => void;
  /** Handler for when a date is selected. */
  dateSelectHandler: (args: any, venue: Venue | null) => void;
  /** Whether the user is using a mobile device. */
  isMobile: boolean;
};

/**
 * CalendarBody component for displaying a calendar with events and venues, using pagination for performance
 * improvement.
 */
export const CalendarBody: React.FC<CalendarBodyProps> = (props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 9;
  const { showAlert } = useAlert();

  if (!props.selectedCenter) {
    return null;
  }

  const paginatedVenues = props.selectedCenter.venues
    .slice(1)
    .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  const totalPages = Math.ceil(props.selectedCenter.venues.length / itemsPerPage);
  return (
    <Box>
      <Stack
        id="calendar-body"
        direction="row"
        spacing={0}
        display={!props.selectedCenter ? "none" : "flex"}
        overflowX="auto"
        __css={{ "& .fc": { minWidth: "300px !important" } }}
        data-testid="calendar-body"
      >
        <FullCalendar
          key={props.fullCalendarKey}
          initialView={props.isMobile ? "timeGridDay" : props.activeView}
          eventContent={(args) => <CommonEventContent args={args} organization={props.organization} />}
          views={{
            timeGridWeek: {
              dayHeaderContent: (args) => (
                <Box marginY={2}>
                  {args.date.toLocaleString("en-US", { weekday: "long" })}{" "}
                  {args.date.toLocaleString("fi-FI", { day: "numeric", month: "numeric" })}
                </Box>
              ),
              slotLabelContent: (args) => extractUtcTime(args.date.toISOString()),
            },
            timeGridDay: {
              weekends: true,
              dayHeaders: true,
              weekNumbers: true,
              dayHeaderContent: () =>
                props.isMobile ? (
                  <Text marginY="1">All venues</Text>
                ) : (
                  <Text>
                    {props.selectedCenter?.venues[0]?.name ||
                      (props.selectedCenter
                        ? `No Venues defined for ${props.selectedCenter.name}!`
                        : "No Center selected!")}
                  </Text>
                ),
              slotLabelContent: (args) => extractUtcTime(args.date.toISOString()),
            },
          }}
          {...getCommonCalendarProps(
            props.activeView === "timeGridDay" && !props.isMobile
              ? props.eventsToDisplay.filter(
                  (event) =>
                    event.eventVenue.id === props.selectedCenter?.venues[0]?.id &&
                    areDatesEqual(event.start, props.dayViewDate),
                )
              : props.eventsToDisplay.filter((event) =>
                  props.selectedCenter?.venues.some((venue: Venue) => venue.id === event.eventVenue.id),
                ),
            0,
            props.selectedCenter?.venues[0]?.id ? props.selectedCenter?.venues[0] : null,
            props.dayViewDate,
            props.calendarRefs,
            props.handleEventClick,
            props.selectedCenter,
            showAlert,
            props.dateSelectHandler,
          )}
        />

        <Box display={{ mobile: "none", laptop: props.activeView === "timeGridDay" ? "flex" : "none" }}>
          {paginatedVenues.map((venue, index) => (
            <FullCalendar
              key={props.fullCalendarKey + index}
              initialView="timeGridDay"
              eventContent={(args) => <CommonEventContent args={args} organization={props.organization} />}
              viewClassNames={["daily-view-fc-instance"]}
              views={{
                timeGridDay: {
                  weekends: true,
                  dayHeaders: true,
                  weekNumbers: false,
                  dayHeaderContent: () => <Text>{venue.name}</Text>,
                },
              }}
              {...getCommonCalendarProps(
                props.eventsToDisplay.filter((event) => event.eventVenue.id === venue.id),
                index + 1,
                venue,
                props.dayViewDate,
                props.calendarRefs,
                props.handleEventClick,
                props.selectedCenter,
                showAlert,
                props.dateSelectHandler,
              )}
            />
          ))}
        </Box>
      </Stack>
      <Stack
        display={
          props.activeView === "timeGridDay" && !props.isMobile && props.selectedCenter.venues.length > itemsPerPage + 1
            ? "flex"
            : "none"
        }
        direction="row"
        justify="center"
        spacing="4"
        marginTop="4"
      >
        <CommonButton
          variantType="outlineSecondary"
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
          disabled={currentPage === 0}
        >
          <ChevronLeftIcon boxSize="6" color="blackAlpha.800" />
          Previous {itemsPerPage + 1} Venues
        </CommonButton>
        <CommonButton
          variantType="outlineSecondary"
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1))}
          disabled={currentPage >= totalPages - 1}
        >
          Next {itemsPerPage + 1} Venues
          <ChevronRightIcon boxSize="6" color="blackAlpha.800" />
        </CommonButton>
      </Stack>
    </Box>
  );
};
