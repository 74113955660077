import { useApolloClient } from "@apollo/client";
import { useAlert } from "../../../common/components/AlertProvider";
import { useAuth } from "../components/AuthProvider";

/**
 * Custom hook for handling user logout programmatically or manual logout.
 *
 * Clears Apollo Client cache, localStorage, and updates authentication state.
 *
 * @returns A logout function that can optionally show alert
 */
export const useLogout = () => {
  const client = useApolloClient();
  const { showAlert, hideAlert } = useAlert();
  const { setIsAuthenticated } = useAuth();

  const logoutUser = (showAlertOnLogout: boolean = true) => {
    client.clearStore();
    setIsAuthenticated(false);
    localStorage.clear();

    if (showAlertOnLogout) {
      showAlert("Your session has expired, please log in again", "warning");
    } else {
      // clear any existing alerts
      hideAlert();
    }
  };

  return logoutUser;
};
