import React, { useState } from "react";
import { Stack, Text, Button } from "@chakra-ui/react";
import { CommonInput } from "../../../../common/components/CommonInput";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../../../common/components/AlertProvider";
import { useAsyncMutation } from "../../../../common/hooks";
import { CREATE_ACCOUNT_WITH_INVITE } from "../../graphql/create-account-with-invite.mutation";
import { CommonButton } from "../../../../common/components/CommonButton";
import { useAuth } from "../AuthProvider";
import { parseToken } from "../../utils";
import type { Invite } from "../../../../types";

type JoinOrganizationProps = {
  invite: Invite;
};

export const JoinOrganization: React.FC<JoinOrganizationProps> = (props) => {
  const { setIsAuthenticated, setEmail: authSetEmail, setAccessType, setIsEmailVerified } = useAuth();
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { execute: createAccountWithInvite, loading } = useAsyncMutation(CREATE_ACCOUNT_WITH_INVITE);

  const handleSubmit = async () => {
    showAlert("Creating account", "info");

    const result = await createAccountWithInvite({
      inviteCode: props.invite.inviteCode,
      data: {
        email,
        password,
      },
    });

    if (result?.createAccountWithInvite) {
      const idToken = result.createAccountWithInvite.idToken;
      const { email, accessType, authStatus, isEmailVerified } = parseToken(idToken);
      localStorage.setItem("idToken", idToken);
      localStorage.setItem("accessToken", result.createAccountWithInvite.accessToken);
      setIsAuthenticated(authStatus);
      setAccessType(accessType);
      authSetEmail(email);
      setIsEmailVerified(isEmailVerified);

      showAlert("Account created, welcome to Striveon!", "success", 5000);
      navigate("/");
    }
  };

  const validateInput = () => {
    const reasons = [];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      reasons.push("Email can't be empty");
    } else {
      if (!email.includes("@")) {
        reasons.push("Email must contain '@'");
      }
      if (!email.split("@")[1]?.includes(".")) {
        reasons.push("Email must contain a domain (e.g. '.com')");
      }
      if (/\s/.test(email)) {
        reasons.push("Email must not contain spaces");
      }
      if (!emailRegex.test(email)) {
        reasons.push("Email must be a valid format (e.g. 'example@domain.com')");
      }
    }

    if (!password) {
      reasons.push("Password can't be empty");
    } else if (password.length < 8) {
      reasons.push("Password must be at least 8 characters long");
    }

    return reasons;
  };

  const submitDisabledReasons = validateInput();

  return (
    <Stack direction="column" spacing="6" maxWidth="400px" margin={{ mobile: undefined, laptop: "auto" }}>
      <Stack direction="column" display={{ mobile: "none", laptop: "block" }}>
        <Text fontSize="x-large" textAlign="center">
          Create Account to join
        </Text>
        <Text fontSize="x-large" textAlign="center">
          {props.invite.organizationName}
        </Text>
      </Stack>

      <Text fontSize="x-large" textAlign="center" display={{ mobile: "block", laptop: "none" }}>
        Join {props.invite.organizationName}
      </Text>

      {props.invite.athlete && (
        <Text fontSize="medium" textAlign="center" color="blackAlpha.600">
          This is a personal invitation for {props.invite.athlete.name} to join the {props.invite.organizationName}
        </Text>
      )}

      {props.invite.coach && (
        <Text fontSize="medium" textAlign="center" color="blackAlpha.600">
          This is a personal invitation for {props.invite.coach.name} to join the {props.invite.organizationName}
        </Text>
      )}

      <Stack direction="column" spacing="4">
        <CommonInput
          placeholder="Email"
          type="email"
          value={email}
          onChange={(value) => setEmail(value)}
          disabled={loading}
        />
        <CommonInput
          placeholder="Password (min 8 characters)"
          type="password"
          value={password}
          onChange={(value) => setPassword(value)}
          disabled={loading}
        />
      </Stack>

      <CommonButton
        variantType="solidPrimary"
        onClick={handleSubmit}
        fullWidth
        disabled={submitDisabledReasons.length > 0}
        tooltip={submitDisabledReasons.join("\n")}
      >
        Create Account
      </CommonButton>

      <Text fontSize="sm" color="blackAlpha.600">
        Already have an account?{" "}
        <Button
          variant="link"
          color="blackAlpha.600"
          textDecoration="underline"
          fontSize="sm"
          onClick={() => navigate("/login")}
        >
          Go to Login Page
        </Button>
      </Text>
    </Stack>
  );
};
