import React, { useEffect, useState } from "react";
import { Box, Text, Stack, Tag, Tooltip, TagLabel } from "@chakra-ui/react";
import { type Note } from "../../../../types";
import { CommonButton } from "../../../../common/components/CommonButton";
import { CommonTextArea } from "../../../../common/components/CommonTextArea";
import { cardStyles, tagStyles } from "../../../../common/utils/styles";
import { extractLocalTime, extractDateFromUTC, extractUtcTime } from "../../../../common/utils/dateAndTime";
import { CommonScrollbar } from "../../../../common/components/CommonScrollbar";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { format } from "date-fns";
import { FormModal } from "../../../../common/components/FormModal";
import { useAlert } from "../../../../common/components/AlertProvider";
import { NOTES_PAGINATION_LIMIT } from "../../containers/AthleteContainer";
import { PaginationControls } from "../../../../common/components/PaginationControls";

type NotesProps = {
  /** Total number of notes in the paginated list */
  totalNoteCount: number;
  notes: Note[];
  onSave?: (noteToSave: Note, remove?: boolean) => void;
  isLoading: boolean;
  readOnly?: boolean;
  /** Athlete ID for pagination callback. */
  athleteId: string;
  /** Current page number */
  page: number;
  /** Called when user clicks 'Next page' */
  onNextPage: (athleteId: string) => void;
  /** Called when user clicks 'Previous page' */
  onPrevPage: (athleteId: string) => void;
};

type DisplayNote = Note & {
  tags?: {
    label: string;
    tooltip: React.ReactNode;
  }[];
};

export const Notes: React.FC<NotesProps> = (props) => {
  const [displayedTotalCount, setDisplayedTotalCount] = useState<number>(props.totalNoteCount);
  const [displayedNotes, setDisplayedNotes] = useState<Note[]>([]);
  const [newNoteContent, setNewNoteContent] = useState("");
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const [isNewNote, setIsNewNote] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { showAlert } = useAlert();

  useEffect(() => {
    if (!props.isLoading) {
      setDisplayedTotalCount(props.totalNoteCount);
      setDisplayedNotes(props.notes);
    }
  }, [props.notes, props.isLoading]);

  const handleOpenDialog = (note: Note) => {
    if (props.readOnly) return;
    setSelectedNote(note);
    setIsNewNote(false);
    setIsDialogOpen(true);
  };

  const handleUpdateNote = async (remove: boolean) => {
    if (!selectedNote || !props.onSave) {
      return;
    }

    if (remove) {
      showAlert("Deleting Note", "info", undefined, true);
    } else {
      showAlert("Updating Note", "info", undefined, true);
    }

    setIsNewNote(false);
    setIsDialogOpen(false);

    const updatedContent = selectedNote.content.trim();

    if (remove) {
      props.onSave({ ...selectedNote, content: "Deletion confirmed" }, true);
    } else {
      props.onSave({ ...selectedNote, content: updatedContent });
    }
  };

  const handleSaveNewNote = () => {
    if (newNoteContent.trim() !== "" && props.onSave) {
      showAlert("Creating new Note", "info", undefined, true);
      props.onSave({ content: newNoteContent });
      setNewNoteContent("");
    }
  };

  const handleClose = () => {
    setIsNewNote(false);
    setIsDialogOpen(false);
  };

  const getSubmitDisabledReasons = (): string | undefined => {
    if (selectedNote?.content.trim() === "") {
      return "Note content cannot be empty";
    }
    return undefined;
  };

  const submitDisabledReasons = getSubmitDisabledReasons();

  const featurePurpose = "Notes are used to keep track of important information related to Athlete";

  const displayNotes: DisplayNote[] =
    displayedNotes.map((note) => {
      const tags = [];

      if (note.trainingEventDetails) {
        const trainingEventDate = extractDateFromUTC(note.trainingEventDetails.eventStartDateTime, ".", true);
        const trainingEventStartTime = extractUtcTime(note.trainingEventDetails.eventStartDateTime);
        const trainingEventEndTime = extractUtcTime(note.trainingEventDetails.eventEndDateTime);

        if (note.isGroupNote) {
          tags.push({
            label: "group",
            tooltip: (
              <Stack direction="column" spacing="2" padding="1" borderRadius="md">
                <Text fontSize="medium" color="whiteAlpha.900">
                  Group note related to training event:
                </Text>
                <Text fontSize="medium" color="whiteAlpha.900">
                  - Group: {note.trainingEventDetails.eventTrainingGroupName}
                </Text>
                <Text fontSize="medium" color="whiteAlpha.900">
                  - Time: {`${trainingEventDate}  (${trainingEventStartTime}-${trainingEventEndTime})`}
                </Text>
                <Text fontSize="medium" color="whiteAlpha.900">
                  - Venue: {note.trainingEventDetails.eventVenue}
                </Text>
                <Text fontSize="medium" color="whiteAlpha.900">
                  - Coach:{" "}
                  {note.trainingEventDetails.eventCoaches
                    ? JSON.parse(note.trainingEventDetails.eventCoaches).join(", ")
                    : "Not assigned"}
                </Text>
                <Text fontSize="medium" color="whiteAlpha.900">
                  - Athletes: {note.athletes?.map((athlete) => athlete.name).join(", ") || ""}
                </Text>
              </Stack>
            ),
          });
        } else {
          tags.push({
            label: "personal",
            tooltip: (
              <Stack direction="column" spacing="2" padding="1" borderRadius="md">
                <Text fontSize="medium" color="whiteAlpha.900">
                  Personal note related to training event:
                </Text>
                <Text fontSize="medium" color="whiteAlpha.900">
                  - Group: {note.trainingEventDetails.eventTrainingGroupName}
                </Text>
                <Text fontSize="medium" color="whiteAlpha.900">
                  - Time: {`${trainingEventDate}  (${trainingEventStartTime}-${trainingEventEndTime})`}
                </Text>
                <Text fontSize="medium" color="whiteAlpha.900">
                  - Venue: {note.trainingEventDetails.eventVenue}
                </Text>
                <Text fontSize="medium" color="whiteAlpha.900">
                  - Coach:{" "}
                  {note.trainingEventDetails.eventCoaches
                    ? JSON.parse(note.trainingEventDetails.eventCoaches).join(", ")
                    : "Not assigned"}
                </Text>
                <Text fontSize="medium" color="whiteAlpha.900">
                  - Athletes: {note.athletes?.map((athlete) => athlete.name).join(", ") || ""}
                </Text>
              </Stack>
            ),
          });
        }
      }

      return {
        ...note,
        tags: tags.length > 0 ? tags : undefined,
      };
    }) || [];

  return (
    <Box
      position="relative"
      width="100%"
      minHeight={{ laptop: "400px" }}
      maxHeight={{ laptop: "75svh" }}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <LoadingOverlay display={props.isLoading} spinnerSize="xl" spinnerTopPosition="60px" />
      <Tooltip label={featurePurpose}>
        <Text
          padding="6"
          paddingTop={{ mobile: "0", laptop: "6" }}
          fontSize="xl"
          width={{ laptop: "fit-content" }}
          alignSelf={{ mobile: "center", laptop: "start" }}
        >
          Notes ({displayedTotalCount})
        </Text>
      </Tooltip>
      <CommonScrollbar
        height="auto"
        overflow="auto"
        invisibleBorderWidth="0px 8px 0px 0px"
        paddingRight="2"
        paddingLeft="6"
        paddingBottom="6"
        marginBottom="6"
        flex={1}
      >
        {displayNotes.length > 0 ? (
          <Stack direction="column" spacing="6" align="stretch">
            {displayNotes.map((note, index) => (
              <Box
                key={index}
                padding="4"
                onClick={() => handleOpenDialog(note)}
                {...cardStyles({})}
                data-testid="note-card"
              >
                <Stack direction="column" spacing="2">
                  {note.tags && (
                    <Stack direction="row" spacing="2">
                      {note.tags.map((tag, index) => (
                        <Tag key={index} height="fit-content" {...tagStyles()}>
                          <Tooltip hasArrow label={tag.tooltip} aria-label={`${tag.label} tag`} placement="top">
                            <TagLabel>{tag.label}</TagLabel>
                          </Tooltip>
                        </Tag>
                      ))}
                    </Stack>
                  )}
                  <Text fontWeight="medium" whiteSpace="pre-wrap" marginBottom="3" color="blackAlpha.900">
                    {note.content}
                  </Text>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Text fontSize="xs" color="blackAlpha.600">
                    {note.createdBy?.username ? note.createdBy.username : "Removed User"}
                  </Text>
                  <Stack direction="row" spacing="4">
                    <Text fontSize="xs" color="blackAlpha.600">
                      {format(extractDateFromUTC(note.createdAt || ""), "dd.MM.yyyy")}
                    </Text>
                    <Text fontSize="xs" color="blackAlpha.600">
                      {extractLocalTime(note.createdAt || "")}
                    </Text>
                  </Stack>
                </Stack>
              </Box>
            ))}
          </Stack>
        ) : (
          <Text color="blackAlpha.600">No notes</Text>
        )}
      </CommonScrollbar>
      {!props.readOnly && (
        <Stack
          direction={{ mobile: "column", laptop: "row" }}
          paddingX={{ mobile: "0", laptop: "6" }}
          paddingTop={{ mobile: "6", laptop: "0" }}
          marginBottom={{ mobile: "0", laptop: "6" }}
          spacing="4"
        >
          <Box width="100%">
            <CommonTextArea
              placeholder="Add a note"
              value={newNoteContent}
              onChange={(value) => setNewNoteContent(value)}
              minHeight="80px"
              disableRezise={true}
              dataTestId="add-manual-note"
            />
          </Box>
          <Box width={{ mobile: "100%", laptop: "fit-content" }} alignSelf="end">
            <CommonButton
              fullWidth
              variantType="solidPrimary"
              onClick={handleSaveNewNote}
              dataTestId="save-manual-note"
              disabled={newNoteContent.trim() === ""}
            >
              Save Note
            </CommonButton>
          </Box>
        </Stack>
      )}

      <PaginationControls
        currentPage={props.page}
        itemsPerPage={NOTES_PAGINATION_LIMIT}
        onNextPage={() => props.onNextPage(props.athleteId)}
        onPrevPage={() => props.onPrevPage(props.athleteId)}
        totalItems={displayedTotalCount}
        disableButtons={props.isLoading}
      />

      {!props.readOnly && (
        <FormModal
          key={selectedNote?.id}
          handleSubmit={() => handleUpdateNote(false)}
          open={isDialogOpen}
          submitButtonText={isNewNote ? "Create" : "Confirm"}
          title={isNewNote ? "Create new Note" : "Edit Note"}
          onClose={handleClose}
          handleRemove={() => handleUpdateNote(true)}
          confirmationDialogTitle="Delete Note"
          submitDisabled={!!submitDisabledReasons}
          submitButtonHoverText={submitDisabledReasons}
          confirmationDialogMessage="The Note will be deleted permanently. This action cannot be undone."
          confirmationDialogSubmitButtonText="Delete Note"
          isConfirmingDeletion={true}
        >
          <Stack direction="column" spacing="4" data-testid="note-modal">
            <CommonTextArea
              placeholder="Note content"
              value={selectedNote?.content || ""}
              onChange={(value) => {
                setSelectedNote({ ...selectedNote!, content: value });
              }}
              minHeight="80px"
              dataTestId="note-content"
            />
          </Stack>
        </FormModal>
      )}
    </Box>
  );
};
