import React from "react";
import { Stack, Text } from "@chakra-ui/react";
import { CommonInput } from "../../../../../common/components/CommonInput";
import { CommonIconButton } from "../../../../../common/components/CommonIconButton";
import { CommonButton } from "../../../../../common/components/CommonButton";
import { MinusIcon } from "@chakra-ui/icons";

type Step_2_TeamProps = {
  coachNames: string[];
  setCoachNames: (value: string[]) => void;
  athleteNames: string[];
  setAthleteNames: (value: string[]) => void;
  loading: boolean;
};

/** Step_2_Team renders the UI for the third step (Staff details: coaches & athletes) We require at least 1 Coach name. */
export const Step_2_Team: React.FC<Step_2_TeamProps> = (props) => {
  const addCoach = () => {
    props.setCoachNames([...props.coachNames, ""]);
  };

  const addAthlete = () => {
    props.setAthleteNames([...props.athleteNames, ""]);
  };

  const handleCoachChange = (index: number, value: string) => {
    const updated = [...props.coachNames];
    updated[index] = value;
    props.setCoachNames(updated);
  };

  const handleAthleteChange = (index: number, value: string) => {
    const updated = [...props.athleteNames];
    updated[index] = value;
    props.setAthleteNames(updated);
  };

  return (
    <Stack direction="column" spacing="12" marginBottom={{ mobile: "8", laptop: "0" }}>
      {/* Coaches */}
      <Stack direction="column" spacing="4">
        <Text fontSize="large">
          Add your organization's Coaches{" "}
          <Text as="span" color="blackAlpha.600" fontSize="medium">
            (at least 1 required)
          </Text>
        </Text>
        {props.coachNames.map((coach, index) => (
          <Stack key={index} direction="row" spacing="2">
            <CommonInput
              placeholder={index === 0 ? "Coach Name (linked to your account)" : "Coach Name"}
              value={coach}
              onChange={(value) => handleCoachChange(index, value)}
              disabled={props.loading}
            />
            {index > 0 && (
              <CommonIconButton
                icon={<MinusIcon />}
                onClick={() => {
                  const updated = [...props.coachNames];
                  updated.splice(index, 1);
                  props.setCoachNames(updated);
                }}
              />
            )}
          </Stack>
        ))}
        <CommonButton variantType="outlineSecondary" onClick={addCoach}>
          Add Coach
        </CommonButton>
      </Stack>

      {/* Athletes */}
      <Stack direction="column" spacing="4">
        <Text fontSize="large">
          Add your organization's Athletes{" "}
          <Text as="span" color="blackAlpha.600" fontSize="medium">
            (optional)
          </Text>
        </Text>
        {props.athleteNames.map((athlete, index) => (
          <Stack key={index} direction="row" spacing="2">
            <CommonInput
              placeholder="Athlete Name"
              value={athlete}
              onChange={(value) => handleAthleteChange(index, value)}
              disabled={props.loading}
            />
            <CommonIconButton
              icon={<MinusIcon />}
              onClick={() => {
                const updated = [...props.athleteNames];
                updated.splice(index, 1);
                props.setAthleteNames(updated);
              }}
            />
          </Stack>
        ))}
        <CommonButton variantType="outlineSecondary" onClick={addAthlete}>
          Add Athlete
        </CommonButton>
      </Stack>
    </Stack>
  );
};
