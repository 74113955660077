import React, { useMemo, useState } from "react";
import {
  Box,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Text,
  Divider,
  Stack,
  DrawerCloseButton,
  Button,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { NavigationLink } from "./components";
import { CommonIconButton } from "../../common/components/CommonIconButton";
import { links } from "./Header";
import { AlertComponent } from "../../common/components/AlertProvider/components/AlertComponent";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../Auth/components/AuthProvider";
import { AccessType } from "../../types";
import { UAParser } from "ua-parser-js";

/**
 * MobileHeader component is shown when breakpoint is "mobile" (under 80rem / 1280px). It uses ua-parser-js to detect if
 * the user is actually on desktop but with a small window/zoom. In that case, a user-friendly alert is shown to
 * encourage them to zoom out or enlarge the window.
 */
export const MobileHeader: React.FC = () => {
  const { isAuthenticated, accessType } = useAuth();
  const [isAlertOpen, setIsAlertOpen] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();

  const isPreviewPath = location.pathname.includes("athletes-preview");
  const effectiveAccessType = isPreviewPath ? AccessType.Athlete : accessType;
  const filteredLinks = links.filter(
    (link) => !link.accessTypes || (effectiveAccessType && link.accessTypes.includes(effectiveAccessType)),
  );

  /**
   * Use ua-parser-js to detect if this is likely a desktop device (deviceType undefined) If deviceType is "mobile" or
   * "tablet", we consider it a real mobile device
   */
  const parser = useMemo(() => new UAParser(), []);
  const deviceType = parser.getDevice().type || "";
  const isDesktopUA = deviceType !== "mobile" && deviceType !== "tablet";

  /** Decide header title based on pathname */
  const headerTitle =
    location.pathname === "/create-account" || location.pathname === "/create-account/" ? (
      <Text fontSize="large" fontFamily="logo" textTransform="uppercase" alignContent="center" color="whiteAlpha.900">
        Create Your Striveon Account
      </Text>
    ) : (
      <Text fontSize="x-large" fontFamily="logo" textTransform="uppercase" alignContent="end" color="whiteAlpha.900">
        Striveon
      </Text>
    );

  return (
    <Box
      position="fixed"
      top="0"
      width="100%"
      bg="teal.800"
      paddingY="2"
      paddingX="4"
      zIndex="11"
      display={{ mobile: "flex", laptop: "none" }}
      justifyContent="space-between"
      height="56px"
    >
      <Logo title={headerTitle} />
      {isAuthenticated && (
        <CommonIconButton
          backgroundColor="transparent"
          borderColor="whiteAlpha.700"
          icon={isOpen ? <CloseIcon color="orange.400" /> : <HamburgerIcon color="whiteAlpha.700" />}
          onClick={isOpen ? onClose : onOpen}
          ariaLabel="Toggle Navigation"
        />
      )}
      <Drawer placement="top" onClose={onClose} isOpen={isOpen} autoFocus={false}>
        <DrawerOverlay />
        <DrawerContent bg="teal.800" color="whiteAlpha.900" height="100dvh" overflow="auto">
          <DrawerHeader marginBottom="12" paddingBottom="0">
            <Logo title={headerTitle} />
            <DrawerCloseButton color="whiteAlpha.700" size="lg" padding="6" />
          </DrawerHeader>

          {isAlertOpen && accessType !== AccessType.Athlete && !isPreviewPath && (
            <AlertComponent
              severity="info"
              message={
                isDesktopUA
                  ? "It looks like you're using a laptop, but the window size or zoom level is too small for Striveon to display all features. To access all tools and functionality, please zoom out in your browser or maximize the window by clicking the square icon in the top-right corner of your browser"
                  : "To access all features, use Striveon on a laptop or larger screen"
              }
              hideAlert={() => setIsAlertOpen(false)}
            />
          )}

          <DrawerBody
            maxHeight="100dvh"
            overflow="auto"
            display="flex"
            flexDirection="column"
            paddingTop="0"
            paddingBottom="6"
          >
            <Stack flex={1} direction="column" spacing="2" align="start" divider={<Divider />} marginBottom="4">
              {accessType &&
                filteredLinks.map((link) => (
                  <NavigationLink
                    key={link.to}
                    to={isPreviewPath ? undefined : link.to}
                    icon={link.icon}
                    primaryText={link.primaryText}
                    accessTypes={link.accessTypes}
                    onClick={onClose}
                    isDisabledOnMobile={link.isDisabledOnMobile}
                  />
                ))}
            </Stack>
            <Button
              as={Link}
              to={isPreviewPath ? undefined : "/account"}
              onClick={onClose}
              variant="outline"
              paddingY="2"
              color={location.pathname === "/account" ? "orange.400" : "white"}
              borderRadius="md"
              borderColor={location.pathname === "/account" ? "orange.400" : "white"}
              boxShadow="sm"
              transition="all 0.3s ease"
              _hover={{ color: "orange.500", borderColor: "orange.500" }}
            >
              Account
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

const Logo: React.FC<{ title: React.ReactNode }> = ({ title }) => {
  return <Box display="flex">{title}</Box>;
};
