import React, { useEffect, useState } from "react";
import { Center, DeletionInfoEntityType, type DeletionNode, type Venue } from "../../../../types";
import { Venues } from "./components/Venues";
import { useAlert } from "../../../../common/components/AlertProvider";
import { Box, Grid, GridItem, SimpleGrid, Stack, Text, Tooltip } from "@chakra-ui/react";
import { FormModal } from "../../../../common/components/FormModal";
import { CommonButton } from "../../../../common/components/CommonButton";
import { CommonIconButton } from "../../../../common/components/CommonIconButton";
import { CommonInput } from "../../../../common/components/CommonInput";
import { LoadingOverlay } from "../../../../common/components/LoadingOverlay";
import { useGlobalContext } from "../../../../common/components/GlobalProvider";
import type { CenterInput, VenueInput } from "../../containers/OrganizationContainer";
import { ComponentContainer } from "../../../../common/components/ComponentContainer";
import { CommonScrollbar } from "../../../../common/components/CommonScrollbar";
import { DeletionInfo } from "../../../../common/components/DeletionInfo";

type CentersProps = {
  allCenters: Center[];
  allVenues: Venue[];
  saveCenterLoading: boolean;
  saveVenueLoading: boolean;
  saveCenter: (data: CenterInput) => Promise<any>;
  saveVenue: (data: VenueInput, centerId: string) => Promise<any>;
  entitiesToBeRemovedWithVenue: DeletionNode[];
  entitiesToBeRemovedWithVenueLoading: boolean;
  getEntitiesToBeRemovedWithVenue: (id: string) => Promise<void>;
  entitiesToBeRemovedWithCenter: DeletionNode[];
  entitiesToBeRemovedWithCenterLoading: boolean;
  getEntitiesToBeRemovedWithCenter: (id: string) => Promise<void>;
};

export const Centers: React.FC<CentersProps> = (props) => {
  const { globalSelectedCenter } = useGlobalContext();
  const [selectedCenter, setSelectedCenter] = useState<Center | null>(globalSelectedCenter);
  const [isNewCenter, setIsNewCenter] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [activeCenter, setActiveCenter] = useState<Center | null>(selectedCenter);
  const { showAlert } = useAlert();

  useEffect(() => {
    // update component everytime the global center changes (after mutation)
    setSelectedCenter(globalSelectedCenter);
    setActiveCenter(globalSelectedCenter);
  }, [globalSelectedCenter]);

  const handleOpenDialog = (center: Center) => {
    setActiveCenter(center);
    setIsDialogOpen(true);
  };

  const handleSaveCenter = async (remove: boolean) => {
    if (!activeCenter) return;
    if (remove) {
      showAlert("Deleting Center", "info", undefined, true);
    } else if (activeCenter?.id) {
      showAlert("Updating Center", "info", undefined, true);
    } else {
      showAlert("Creating new Center", "info", undefined, true);
    }
    setIsNewCenter(false);
    setIsDialogOpen(false);

    const { __typename, ...centerToSave } = activeCenter;
    const venuesToSave = activeCenter.venues?.map((venue) => {
      const { __typename, ...rest } = venue;
      return rest;
    });

    const dataToSave: CenterInput = {
      ...centerToSave,
      venues: venuesToSave,
      remove: remove,
    };
    const result = await props.saveCenter(dataToSave);

    if (result) {
      if (remove) {
        showAlert("Center deleted!", "success", 5000);
      } else {
        showAlert(activeCenter?.id ? "Center updated!" : "Center created!", "success", 5000);
      }
    }
  };

  const handleSetActiveCenter = () => {
    if (activeCenter) {
      setSelectedCenter(activeCenter);
    }
    setIsDialogOpen(false);
  };

  const handleSubmit = () => {
    handleSaveCenter(false);
  };

  const handleClose = () => {
    setIsNewCenter(false);
    setIsDialogOpen(false);
  };

  const handleAddNew = () => {
    setIsNewCenter(true);
    const newCenter: Center = { name: "", venues: [] };
    setActiveCenter(newCenter);
    handleOpenDialog(newCenter);
  };

  const getActivateCenterTooltip = () => {
    if (activeCenter?.id === selectedCenter?.id) {
      return "This center is already active";
    } else if (isNewCenter) {
      return "This center needs to be created first before it can be set as active";
    }
    return "Setting this center as active will display its venues and related information";
  };

  const getSubmitDisabledReasons = (): string | undefined => {
    const centerNames = props.allCenters.map((center) => center.name.toLowerCase());
    if (centerNames.includes(activeCenter?.name.toLowerCase() || "")) {
      return `Center "${activeCenter?.name}" already exists`;
    } else if (activeCenter?.name.trim() === "") {
      return "Center name cannot be empty";
    }
    return undefined;
  };

  const submitDisabledReasons = getSubmitDisabledReasons();

  const featurePurpose =
    "Centers include Venues where training events take place. Add Centers to manage Venues and organize training";

  const venuesToUse = props.allVenues.filter((venue) => venue.center?.id === selectedCenter?.id);

  return (
    <Box id="centers" height="100%">
      <Grid templateColumns="repeat(5, 1fr)" gap="8" height="100%">
        {/* Centers */}
        <GridItem position="relative" colSpan={2} data-testid="centers">
          <ComponentContainer handlePaddingManually>
            <Box paddingY="6">
              <LoadingOverlay display={props.saveCenterLoading} spinnerSize="xl" spinnerTopPosition="40px" />
              <Stack direction="row" spacing="4" marginBottom="6" width="100%" justifyContent="center">
                <Tooltip label={featurePurpose}>
                  <Text fontSize="x-large">Centers ({props.allCenters.length})</Text>
                </Tooltip>
                <CommonIconButton height="36px" onClick={handleAddNew} dataTestId="add-center" />
              </Stack>
              <CommonScrollbar
                maxHeight="30svh"
                overflow="auto"
                paddingLeft="6"
                paddingRight="2"
                invisibleBorderWidth="0px 8px 0px 0px"
              >
                <SimpleGrid
                  gap="4"
                  width={props.allCenters.length < 2 ? "fit-content" : "100%"}
                  columns={props.allCenters.length < 2 ? 1 : 2}
                  justifySelf={props.allCenters.length < 2 ? "center" : "start"}
                >
                  {props.allCenters.length === 0 ? (
                    <GridItem colSpan={4}>
                      <Text fontSize="large" color="blackAlpha.600" textAlign="center" data-testid="no-centers">
                        {featurePurpose}
                      </Text>
                    </GridItem>
                  ) : (
                    props.allCenters.map((center, index) => (
                      <GridItem key={index} colSpan={1} data-testid="center-grid">
                        <CommonButton
                          fullWidth
                          variantType={center.id === selectedCenter?.id ? "solidPrimary" : "outlineSecondary"}
                          disableHover={center.id === selectedCenter?.id}
                          onClick={() => handleOpenDialog(center)}
                          dataTestId={`center-${index}`}
                        >
                          <Text
                            fontSize="medium"
                            color={center.id === selectedCenter?.id ? "white" : "black"}
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            {center.name}
                          </Text>
                        </CommonButton>
                      </GridItem>
                    ))
                  )}
                </SimpleGrid>
              </CommonScrollbar>
            </Box>
          </ComponentContainer>
        </GridItem>

        {/* Venues */}
        <GridItem colSpan={3}>
          <ComponentContainer handlePaddingManually>
            <Venues
              key={selectedCenter?.id}
              title={props.allCenters.length === 0 || !selectedCenter ? "Venues" : `Venues at ${selectedCenter.name}`}
              venueCount={venuesToUse?.length || 0}
              allVenues={venuesToUse || []}
              centerId={selectedCenter?.id || null}
              isLoading={props.saveVenueLoading}
              saveVenue={props.saveVenue}
              entitiesToBeRemoved={props.entitiesToBeRemovedWithVenue}
              entitiesToBeRemovedLoading={props.entitiesToBeRemovedWithVenueLoading}
              getEntitiesToBeRemoved={props.getEntitiesToBeRemovedWithVenue}
            />
          </ComponentContainer>
        </GridItem>

        <FormModal
          handleSubmit={handleSubmit}
          open={isDialogOpen}
          submitButtonText={isNewCenter ? "Create" : "Confirm"}
          title={isNewCenter ? "Create new Center" : "Edit Center"}
          onClose={handleClose}
          handleRemove={() => handleSaveCenter(true)}
          submitDisabled={!!submitDisabledReasons}
          submitButtonHoverText={submitDisabledReasons}
          removeDisabled={!activeCenter?.id}
          removeDisabledReason="Center isn't created yet"
          confirmationDialogTitle="Delete Center"
          confirmationDialogMessage={
            <DeletionInfo
              entityName={activeCenter?.name || ""}
              entitiesToBeRemoved={props.entitiesToBeRemovedWithCenter}
              entityType={DeletionInfoEntityType.Center}
              loading={props.entitiesToBeRemovedWithCenterLoading}
            />
          }
          onConfirmationDialogOpen={async () => await props.getEntitiesToBeRemovedWithCenter(activeCenter?.id || "")}
          isConfirmingDeletion={true}
        >
          <Grid templateColumns="repeat(2, 1fr)" gap="4" marginTop="4" data-testid="center-modal">
            <GridItem colSpan={2}>
              <CommonButton
                variantType="outlineSecondary"
                fullWidth
                onClick={handleSetActiveCenter}
                tooltip={getActivateCenterTooltip()}
                disabled={activeCenter?.id === selectedCenter?.id || isNewCenter}
                dataTestId="set-active-center-button"
              >
                Set as the active center
              </CommonButton>
            </GridItem>
            <GridItem colSpan={2}>
              <CommonInput
                placeholder="Center name"
                value={activeCenter?.name || ""}
                onChange={(value) => {
                  setActiveCenter({ ...activeCenter!, name: value });
                }}
                dataTestId="center-name"
              />
            </GridItem>
          </Grid>
        </FormModal>
      </Grid>
    </Box>
  );
};
