import React from "react";

import { Grid, GridItem, Stack } from "@chakra-ui/react";
import { type Venue, type TrainingGroup, TrainingSeasonStatus } from "../../../../../types";
import { CommonInput } from "../../../../../common/components/CommonInput";
import { CoachSelect } from "../../../../../common/components/CoachSelect";
import { weekdayOptions } from "../../../../../common/utils/dataProcessing";

type SingleTrainingGroupProps = {
  event: TrainingGroup;
  allVenues: Venue[];
  readOnly?: boolean;
};

/** Component for displaying a single training group in a read-only state */
export const SingleTrainingGroup: React.FC<SingleTrainingGroupProps> = (props) => {
  const seasonStatus = props.event.trainingSeason.status;

  return (
    <Stack
      direction="row"
      spacing="4"
      width="100%"
      opacity={
        seasonStatus === TrainingSeasonStatus.Active
          ? 1
          : seasonStatus === TrainingSeasonStatus.Upcoming
            ? 0.8
            : seasonStatus === TrainingSeasonStatus.Completed
              ? 0.4
              : undefined
      }
    >
      <Grid templateColumns="repeat(16, 1fr)" gap="4" alignItems="center" width="100%">
        <GridItem colSpan={{ mobile: 16, laptop: 4 }}>
          <CommonInput
            readOnly
            placeholder="Group name"
            value={props.event.name || ""}
            onChange={() => {}}
            dataTestId="single-training-group"
          />
        </GridItem>
        <GridItem colSpan={{ mobile: 16, laptop: 4 }}>
          <CommonInput
            readOnly
            placeholder="Weekday"
            value={weekdayOptions[(props.event.recurrenceWeekday + 6) % 7].label}
            onChange={() => {}}
          />
        </GridItem>
        <GridItem colSpan={{ mobile: 8, laptop: 2 }}>
          <CommonInput readOnly placeholder="Start" value={props.event.recurrenceStartTime} onChange={() => {}} />
        </GridItem>
        <GridItem colSpan={{ mobile: 8, laptop: 2 }}>
          <CommonInput readOnly placeholder="End" value={props.event.recurrenceEndTime} onChange={() => {}} />
        </GridItem>
        <GridItem colSpan={{ mobile: 16, laptop: 4 }}>
          <CommonInput
            readOnly
            placeholder="Venue"
            value={`${props.event.venue.name} (${props.event.venue.center?.name})`}
            onChange={() => {}}
          />
        </GridItem>

        <GridItem colSpan={{ mobile: 16, laptop: 8 }}>
          <CoachSelect
            label="Athletes"
            selectedCoaches={props.event.athletes?.map((athlete) => athlete) || []}
            disabled={true}
            disabledOpacity={1}
            dataTestId="single-training-group-athletes"
          />
        </GridItem>
        <GridItem colSpan={{ mobile: 16, laptop: 4 }}>
          <CoachSelect
            selectedCoaches={props.event.coaches?.map((coach) => coach) || []}
            disabled={true}
            disabledOpacity={1}
          />
        </GridItem>
        <GridItem colSpan={{ mobile: 16, laptop: 4 }}>
          <CommonInput
            readOnly
            placeholder="Training Season"
            value={props.event.trainingSeason.name || ""}
            onChange={() => {}}
          />
        </GridItem>
      </Grid>
    </Stack>
  );
};
