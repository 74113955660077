import React, { useState } from "react";
import { CommonSelect } from "../../../../../common/components/CommonSelect";
import { Box, Text } from "@chakra-ui/react";
import { SUPPORTED_BILLING_COUNTRIES } from "../utils";

type CountrySelectProps = {
  onChange: (countryCode: string) => void;
  initialValue?: string;
};

export const CountrySelect: React.FC<CountrySelectProps> = (props) => {
  const [selectedCountry, setSelectedCountry] = useState(props.initialValue);

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCode = event.target.value;
    setSelectedCountry(selectedCode);
    props.onChange(selectedCode);
  };

  return (
    <Box>
      <CommonSelect
        placeholder="Country"
        onChange={handleCountryChange}
        value={selectedCountry}
        options={SUPPORTED_BILLING_COUNTRIES.sort((a, b) => a.name.localeCompare(b.name)).map(
          (country: { code: string; name: string }) => ({
            value: country.code,
            label: country.name,
          }),
        )}
      />

      {!selectedCountry && (
        <Text as="span" position="absolute" color="blackAlpha.600" fontSize="small" marginLeft="2">
          If your country is not supported, contact us at{" "}
          <a href="mailto:support@striveon.app" style={{ textDecoration: "underline" }}>
            support@striveon.app
          </a>
        </Text>
      )}
    </Box>
  );
};
