import React from "react";
import {
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
  FormControl,
  InputGroup,
  Text,
} from "@chakra-ui/react";
import { CommonFormLabel } from "../CommonFormLabel";
import { commonStyles } from "../../utils/styles";

/** Props for the CommonSelect component */
type CommonSelectProps = ChakraSelectProps & {
  /** Array of options to be displayed in the Select component */
  options: { value: string | number; label: string; disabled?: boolean }[];
  /** If disabled options should be sorted as last options on select list */
  disabledOptionsToLast?: boolean;
  /** Whether the selected style should be disabled */
  disableSelectedStyle?: boolean;
};

/** CommonSelect component is a reusable Select component that uses Chakra UI's Select and extends its functionality. */
export const CommonSelect: React.FC<CommonSelectProps> = (props) => {
  const { options, disabledOptionsToLast, disableSelectedStyle, ...selectProps } = props;

  const sortedOptions = disabledOptionsToLast
    ? [...options].sort((a, b) => (a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1))
    : options;

  const isValueEmpty = props.value === null || props.value === undefined;

  return (
    <InputGroup width={props.width}>
      <FormControl id="common-select">
        <CommonFormLabel display={!isValueEmpty}>{props.placeholder}</CommonFormLabel>
        <ChakraSelect
          {...selectProps}
          placeholder={isValueEmpty ? props.placeholder : undefined}
          isInvalid={isValueEmpty && options.length === 0}
          height="42px"
          {...commonStyles(props.disableSelectedStyle ? false : !isValueEmpty)}
          sx={{ ...selectProps.sx }}
          color={isValueEmpty ? "blackAlpha.600" : undefined}
        >
          {sortedOptions.map((option, index) => (
            <Text
              as="option"
              key={index}
              value={option.value}
              color={option.disabled ? "blackAlpha.600" : "black"}
              disabled={option.disabled}
            >
              {option.label}
            </Text>
          ))}
        </ChakraSelect>
      </FormControl>
    </InputGroup>
  );
};
