import React, { useMemo, useState } from "react";
import { Button, Stack, Text, useBreakpoint } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { COGNITO_LOGIN } from "../graphql/cognito-login.mutation";
import { CommonButton } from "../../../common/components/CommonButton";
import { CommonInput } from "../../../common/components/CommonInput";
import { useAlert } from "../../../common/components/AlertProvider";
import { useAsyncMutation } from "../../../common/hooks";
import { LoadingOverlay } from "../../../common/components/LoadingOverlay";
import { AlertComponent } from "../../../common/components/AlertProvider/components/AlertComponent";
import { useGlobalContext } from "../../../common/components/GlobalProvider";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_CENTERS } from "../../../common/graphql/get-all-centers.query";
import { parseToken } from "../utils";
import { useAuth } from "./AuthProvider";
import { AccessType } from "../../../types";
import { UAParser } from "ua-parser-js";

export const Login: React.FC = () => {
  const isBreakpointMobile = useBreakpoint({ ssr: false }) === "mobile";
  const { setIsAuthenticated, setAccessType, setEmail: authSetEmail, setIsEmailVerified } = useAuth();
  const [isAlertOpen, setIsAlertOpen] = useState(isBreakpointMobile);
  const { showAlert, hideAlert } = useAlert();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [getAllCenters] = useLazyQuery(GET_ALL_CENTERS, {
    fetchPolicy: "network-only",
  });

  const { execute: cognitoLogin, loading: cognitoLoginLoading } = useAsyncMutation(COGNITO_LOGIN, {
    onCustomError: (error) => {
      // If incorrect credentials for Cognito
      if (error.message === "Incorrect username or password.") {
        return "Log in failed, please check your credentials and try again";
      }
      return undefined;
    },
  });

  const navigate = useNavigate();
  const { globalSelectedCenter, globalSetSelectedCenter, globalSetAllCenters } = useGlobalContext();

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsAlertOpen(false);

    showAlert("Logging in", "info", undefined, true);

    const result = await cognitoLogin({ email, password });
    const idToken = result?.cognitoLogin.idToken;
    const accessToken = result?.cognitoLogin.accessToken;

    if (result) {
      const { authStatus, accessType, email, isEmailVerified } = parseToken(idToken);
      // If login was successful
      if (authStatus) {
        localStorage.setItem("idToken", idToken);
        localStorage.setItem("accessToken", accessToken);
        setIsAuthenticated(authStatus);
        setAccessType(accessType);
        authSetEmail(email);
        setIsEmailVerified(isEmailVerified);

        if (!globalSelectedCenter && accessType !== AccessType.Athlete) {
          try {
            const centerResponse = await getAllCenters();
            globalSetSelectedCenter(centerResponse.data.getAllCenters[0]);
            globalSetAllCenters?.(centerResponse.data.getAllCenters);
          } catch {
            // Fail silently if initial center fetch fails
          }
        }
        navigate("/");
      }

      hideAlert();
    }
  };

  // Detect if user is on a desktop device with a "mobile layout"
  const parser = new UAParser();
  const deviceType = parser.getDevice().type || "";
  const isDesktopUA = deviceType !== "mobile" && deviceType !== "tablet";

  useMemo(() => {
    if (isDesktopUA) {
      if (!isBreakpointMobile) {
        setIsAlertOpen(false);
      } else if (isBreakpointMobile) {
        setIsAlertOpen(true);
      }
    }
  }, [isDesktopUA, isBreakpointMobile]);

  return (
    <Stack
      position="relative"
      width="100%"
      maxWidth="400px"
      margin={{ mobile: undefined, laptop: "auto" }}
      alignSelf="center"
      spacing="6"
      data-testid="login"
    >
      <LoadingOverlay display={cognitoLoginLoading} spinnerSize="xl" spinnerTopPosition="50%" />
      <Text fontSize="x-large" align="center">
        Log In
      </Text>
      <form onSubmit={handleLogin}>
        <Stack spacing="4">
          <CommonInput
            placeholder="Email"
            type="email"
            value={email}
            onChange={(value) => setEmail(value)}
            disabled={cognitoLoginLoading}
          />
          <CommonInput
            placeholder="Password"
            type="password"
            value={password}
            onChange={(value) => setPassword(value)}
            disabled={cognitoLoginLoading}
          />
          <CommonButton
            variantType="solidPrimary"
            fullWidth
            disabled={!email || !password || cognitoLoginLoading}
            buttonType="submit"
          >
            Log In
          </CommonButton>

          <Button
            variant="link"
            marginTop="4"
            color="blackAlpha.600"
            textDecoration="underline"
            fontSize="sm"
            onClick={() => navigate("/reset-password")}
            disabled={cognitoLoginLoading}
          >
            Forgot your password?
          </Button>
        </Stack>
      </form>
      {/* enable once ready for public launch <CommonButton
        variantType="outlineSecondary"
        onClick={() => navigate("/create-account")}
        disabled={cognitoLoginLoading || oldLoginLoading}
      >
        Register
      </CommonButton> */}

      {isAlertOpen && (
        <AlertComponent
          severity="info"
          message={
            isDesktopUA
              ? "It looks like you're using a laptop, but the window size or zoom level is too small for Striveon to display all features. To access all tools and functionality, please zoom out in your browser or maximize the window by clicking the square icon in the top-right corner of your browser"
              : "To access all features, use Striveon on a laptop or larger screen"
          }
          hideAlert={() => setIsAlertOpen(false)}
          maybeLaptop={isDesktopUA}
        />
      )}
    </Stack>
  );
};
