import { gql } from "@apollo/client";

export const GET_ATHLETE_TRAINING_GROUPS = gql`
  query GetAthleteTrainingGroups($athleteId: String!) {
    getAthleteTrainingGroups(athleteId: $athleteId) {
      id
      name
      recurrenceWeekday
      recurrenceStartTime
      recurrenceEndTime
      athletes {
        id
        name
      }
      coaches {
        id
        name
      }
      trainingSeason {
        id
        name
        status
      }
      venue {
        id
        name
        center {
          id
          name
        }
      }
    }
  }
`;
